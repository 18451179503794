import { Component, OnInit, Inject } from '@angular/core';
import { CrmUser } from 'src/app/models/crmuser.model';
import { MatDialogRef, MatDialogConfig, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { WorkitemItemDetailOutput } from 'src/app/models/workitemDetailOutput.model';
import { WorkItemCategory } from 'src/app/models/workitemcategory.model';
import { WorkItemSubCategory } from 'src/app/models/workitemsubcategory.model';
import { CreateWorkitem } from 'src/app/models/CreateWorkitem.model';
import { Attachment } from 'src/app/models/attachment.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CategoryAndSubCategoryService } from 'src/app/services/categoryandsubcategory.service';
import { DatePipe } from '@angular/common';
import { EmailReplyService } from 'src/app/services/emailReply.service';
import { CreateManualWorkitemService } from 'src/app/services/CreateManualWorkitem.service';
import { PropertiesList } from 'src/app/services/PropertiesList.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { UploadAttachmentComponent } from 'src/app/uploadAttachment/uploadAttachment.component';
import { UploadRequest } from 'src/app/UploadRequest.enum';
import { WorkitemSavedMessageComponent } from 'src/app/messagePopUp/workitem-saved-message/workitem-saved-message.component';
import { ConfigureEmailService } from '../../services/configureEmail.service';
import { WorkitemSubPropertiesModel } from 'src/app/models/WorktiemSubProperties.model';
import { ExcelWorkitem } from 'src/app/models/ExcelWorkitem.model';
import{AdditionalInfoVOForReassign} from '../../models/AdditionalInfoVOForReassign.model';

@Component({
  selector: 'app-add-manual-workitem',
  templateUrl: './add-manual-workitem.component.html',
  styleUrls: ['./add-manual-workitem.component.css']
})
export class AddManualWorkitemComponent implements OnInit {

  private token:string;
  submitted = false;
  crmUser:CrmUser;
  private userId:number;
  workItemType:string;
  selectedCategory:number;
  selectedSubCategory:number;
  workItemDetailOutput: WorkitemItemDetailOutput;
  workitemCategory:Array<WorkItemCategory>=new Array<WorkItemCategory>();
  workitemSubCategory:Array<WorkItemSubCategory>=new Array<WorkItemSubCategory>();
  createWorkitem:CreateWorkitem;
  priorityListValues = ["Select Priority","LOW","MEDIUM","HIGH"];
  maxDate=new Date();
  file:File;
  files:File[]=[];
  deleteAttachment:Attachment;
  randomNumber:any;
  emailItemId:number;
  dropdownSettings:IDropdownSettings = {};
  categoryNotSelected:boolean = false;

  localpropertiesList:Array<any> = [];
  pickerDate:string;

  itemSelectedName:string;
  addWorkitemForm:FormGroup;
  queueList = [];
  selectedProperty:number=0;
  workitemSubPropertiesArray:Array<WorkitemSubPropertiesModel> = [];
  workitemSubProperties = [];
  workitemProperties = [];
  selectedSubProperties = [];
  workitem:ExcelWorkitem;
  dateForExcel:any;
  priorityForExcel:string;

  rateMappingStatusList = ['PENDING_INTERNAL', 'CLOSED', 'OTHERS', 'PENDING_EXTERNAL'];
  gdsStatusList = ['PENDING_INTERNAL', 'CLOSED', 'OTHERS', 'PENDING_EXTERNAL'];

  constructor(
    private categoryandsubcategoryService:CategoryAndSubCategoryService,
    private datePipe: DatePipe,
    private emailReplyService:EmailReplyService,
    private createManualWorkitemService:CreateManualWorkitemService,
    private propertiesList:PropertiesList,
    private formBuilder: FormBuilder,
    private loginService:LoginService,
    private router:Router,
    private dialog:MatDialog,
    private configureEmailService:ConfigureEmailService,
    private dialogRef: MatDialogRef<AddManualWorkitemComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if(data!=null){
      
      this.workitem = data;
      // for(let priority in this.priorityListValues){
      //     if(priority==this.workitem.Priority)
      //       this.priorityForExcel = priority;
      // }
      for(let i=0;i<this.priorityListValues.length;i++){
        if(this.priorityListValues[i]==this.workitem.Priority){
          this.priorityForExcel = this.priorityListValues[i];
        }
      }

      this.dateForExcel = new Date((this.workitem.DueDate).toString());
    }
    else{
      this.workitem = new ExcelWorkitem();
      this.priorityForExcel = this.priorityListValues[0];
      this.dateForExcel = '';
    }
    let range = 100000;
    this.randomNumber = Math.floor(Math.random()*Math.floor(range));

    this.addWorkitemForm = this.formBuilder.group({

      name:new FormControl(this.workitem.Title,[
        Validators.required
      ]),
      category:new FormControl(0,[
        Validators.required
      ]),
      subCategory:new FormControl(0,[
      ]),
      priority:new FormControl(this.priorityForExcel,[
        Validators.required
      ]),
      browse:new FormControl('',[
      ]),
      description:new FormControl(this.workitem.Description,[
        Validators.required
      ]),
      propertyType:new FormControl(0,[
        Validators.required
      ]),
      properties:new FormControl('',[
        Validators.required
      ]),
      dueDate:new FormControl(this.dateForExcel,[
        Validators.required
      ]),
      queueName:new FormControl(0,[
        Validators.required
      ]),
      // ratePlan:new FormControl('',[
      //   Validators.required
      // ]),
      // transactionCount:new FormControl('',[
      //   Validators.required
      // ]),
      // gdsType:new FormControl(0,[
      //   Validators.required
      // ]),
      // rateLoading:new FormControl(0,[
      //   Validators.required
      // ]),
      // gdsStatus:new FormControl('Select GDS Status',[
      //   Validators.required
      // ]),
      // rateLoadingStatus:new FormControl('Select Rate Loading Status',[
      //   Validators.required
      // ]),

    });

   }

  ngOnInit() {
    this.selectedCategory = 0;
    this.selectedSubCategory = 0;
    this.createWorkitem = new CreateWorkitem();
    this.createWorkitem.additionalInfoVO = new AdditionalInfoVOForReassign();

    this.dropdownSettings = {
      maxHeight:100,
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit:3
    };

    this.propertiesList.currentData.subscribe(data=>{
      
      this.workitemProperties = data;

    });

    this.loginService.currentData.subscribe((data) => {
      this.crmUser = data;
     if(this.crmUser==null || this.crmUser.sessionToken == null){
         this.router.navigate(['']);
       }
       else{
         
         this.userId=this.crmUser.crmUserId;
         this.token=this.crmUser.sessionToken;

         this.propertiesList.getPropertiesList(this.userId,this.token).subscribe(newData=>{
           
          console.log(newData);
          this.localpropertiesList = newData.body["propertyVOS"];
          // for(let i=0;i<this.localpropertiesList.length;i++){
          //   this.propertyList[i] = this.localpropertiesList[i].name;
          // }
         });

         this.configureEmailService.getQueueList(this.userId,this.token).subscribe(
          data=>{
          console.log(data);
          if(data.status==200)
          {
            this.queueList=data.body['queueList'];
          }
          }
        );
       }
  });
  this.categoryandsubcategoryService.currentData.subscribe((data)=>{
    
    this.workitemCategory=data;
    console.log(this.workitemCategory);
  });
  }

  get f() {return this.addWorkitemForm.controls; }
  get searchHistory(){return this.addWorkitemForm.controls};

  getSelectedCategoryAndSubCategoryForEmail(){
    

    this.selectedCategory=this.workItemDetailOutput.workItemCategory['id'];

    var list = this.workitemCategory.filter(x => x.id == this.selectedCategory);

    var temp=list[0];
    if(temp!=undefined){
      this.workitemSubCategory=(temp['subCategories']);
      this.selectedSubCategory=this.workItemDetailOutput.workItemSubCategory['id'];
    }
    else{
      this.workitemSubCategory=[];
      this.selectedSubCategory = 0;
    }

  }

  onChangeCategory(){
    
   if(this.addWorkitemForm.value.category>0){
    var list = this.workitemCategory.filter(x => x.id == this.addWorkitemForm.value.category);

  var temp=list[0];
  this.workitemSubCategory=(temp['subCategories']);


    }else{

      this.workitemSubCategory=null;
      this.selectedSubCategory=0;
    }

  }

  deleteFiles(file){
    this.deleteAttachment= new Attachment();
    this.deleteAttachment.token=this.token;
    this.deleteAttachment.userId=this.userId;
    this.deleteAttachment.fileName=file.name;
    this.deleteAttachment.emailItemId=this.randomNumber;
    this.emailReplyService.deleteAttachment(this.deleteAttachment).subscribe(data=>{
      console.log(data);
    });
    this.files.splice(file,1);
  }

  openUploadDialog(){
    

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    //dialogConfig.width="300px";
    dialogConfig.height="200px";
    dialogConfig.data = {
      id: this.userId,
      token:  this.token,
      itemId:this.emailItemId,
      requestFrom:UploadRequest.MANUALWORKITEMCONTROLLER,
      randomNumber:this.randomNumber
    };
    this.dialog.open(UploadAttachmentComponent,dialogConfig).afterClosed().

    subscribe(files => {
      
      for (var i = 0; i < files.length; i++) {
        this.files.push(files[i]);
      }

    });
  }

  onSubmit(){
    
    this.submitted = true;

    if(this.addWorkitemForm.value.category==0){
      this.categoryNotSelected = true;
    }
    this.createWorkitem.crmUserId = this.userId;
    this.createWorkitem.sessionToken = this.token;
    this.createWorkitem.title = this.addWorkitemForm.value.name;
    this.createWorkitem.categoryId = this.addWorkitemForm.value.category;
    this.createWorkitem.subCategoryId = this.addWorkitemForm.value.subCategory;
    this.createWorkitem.additionalInfoVO.rateMappingStatus = this.addWorkitemForm.value.rateLoadingStatus;
    this.createWorkitem.additionalInfoVO.ratePlanText = this.addWorkitemForm.value.ratePlan;
    this.createWorkitem.additionalInfoVO.statusGDS = this.addWorkitemForm.value.gdsStatus;
    this.createWorkitem.additionalInfoVO.transactionCount = this.addWorkitemForm.value.transactionCount;
    //this.createWorkitem.additionalInfoVO.rateMappingStatus = ;
    // for(let i=0;i<this.localpropertiesList.length;i++){
    //   if(this.localpropertiesList[i].name==this.itemSelectedName){
    //     this.createWorkitem.propertyId = this.localpropertiesList[i].propertyId;
    //   }
    //  }
    this.createWorkitem.manualWorkItemPriority = this.addWorkitemForm.value.priority;
    //this.createWorkitem.propertyId = 1;
    this.createWorkitem.description = this.addWorkitemForm.value.description;
    this.createWorkitem.dueDate = this.datePipe.transform(this.addWorkitemForm.value.dueDate,"dd-MM-yy hh:mm a");
    console.log("date is ----------"+this.createWorkitem.dueDate);
    this.createWorkitem.manualWorkItemIdentifier = this.randomNumber;
    this.createWorkitem.queue = this.addWorkitemForm.value.queueName;
    this.createWorkitem.propertyIds = [];
    this.selectedItems.pop();
    for(let i=0;i<this.selectedItems.length;i++){
      this.createWorkitem.propertyIds.push(this.selectedItems[i].id);
    }
    if(this.addWorkitemForm.valid){

      this.createManualWorkitemService.createManualWorkitem(this.createWorkitem).subscribe(data=>{
        
        console.log(data);

        if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
          this.callDialogPopUpMessage("Workitem created successfully","Create Workitem");
          this.files = [];
        }
      });
    }
  }

  selectedItems:Array<any>=[];
  onItemSelect(item: any) {
    
    this.selectedItems.push(item);
  }

  onItemUnSelect(item:any){
    
    for(let i= 0;i<this.selectedItems.length;i++){
      if(this.selectedItems[i].id==item.id){
        for(let j = i;j<(this.selectedItems.length-1);j++){
          this.selectedItems[j] = this.selectedItems[j+1];
        }
        this.selectedItems.pop();
        break;
      }

    }
  }
  onSelectAll(items: any) {

    
    this.selectedItems = [];
    for(let i=0;i<items.length;i++){
      this.selectedItems.push(items[i]);
    }
  }

  onDeSelectAll(items:any){

    
    this.selectedItems = [];
  }

  callDialogPopUpMessage(  message,title){
    const dialogConfig= new MatDialogConfig();
    dialogConfig.disableClose=true;
    dialogConfig.autoFocus=true;
    dialogConfig.width="60%";
    dialogConfig.height="40%";
    dialogConfig.data = {
     popUpTitle:title,
     messageToBeDisplayed:message
  };
    this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);
    this.dialogRef.close(1);

  }


  close(){
    
    this.dialogRef.close(0);
  }


  onChangeProperty(){
    
    if(this.addWorkitemForm.value.propertyType>0){
      var list = this.workitemProperties.filter(x => x.id == (this.addWorkitemForm.value.propertyType));
      var temp=list[0];
      this.workitemSubPropertiesArray=(temp['properties']);
    }
    else{
      this.workitemSubProperties = null;
      this.workitemSubPropertiesArray = [];

      this.selectedSubProperties = [];
    }
    this.selectedItems = [];
  }


}
