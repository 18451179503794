import { Component,OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { CrmUser } from '../models/crmuser.model';
import { AgentProfileService } from '../services/AgentProfile.service';
import { AgentProfile } from '../models/AgentProfile.model';
import {MatDialogConfig,MatDialog,MAT_DIALOG_DATA} from '@angular/material';
import { AgentProfileComponent } from '../agent-profile/agent-profile.component';
import { LoginService } from '../services/login.service';
import { LogoutService } from '../services/logout.service';
import { Router} from '@angular/router';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import {FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { QueueReportingDashboardService } from 'src/app/services/QueueReportingDashboard.service';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
@Component({
  selector: 'wiDashboard',
  templateUrl: './widashboard.component.html',
  styleUrls: ['./widashboard.component.css'],
  providers:[DatePipe]
})
export class WIDashboardComponent {
  /** Based on the screen size, switch from standard to one column per row */
  crmUser:CrmUser;
  id:number;
  token:string;
  agentProfile:AgentProfile;
  agentWIReportForm:FormGroup; 
  maxDate=new Date();
  startDate:string;
  endDate:string;
  minDate:Date;
  isValidDate:any;
  submitted:boolean=false;
  error:any={isError:false,errorMessage:''};
  parts:any;
  showWiDashboard:boolean=false;
  public loading:boolean = false;
  cardLayout  = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 1,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 1, rows: 2 },
          table: { cols: 1, rows: 4 },
        };
      }
 
     return {
        columns: 4,
        miniCard: { cols: 2, rows: 2 },
        chart: { cols: 2, rows: 2},
        table: { cols: 4, rows: 4 },
      };
    })
  );
  constructor(
    private breakpointObserver: BreakpointObserver,
    private adminProfileService:AgentProfileService,
    private dialog:MatDialog,
    private loginService:LoginService, 
    private router:Router,
    private logoutService:LogoutService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private queueReportingDashboardService:QueueReportingDashboardService) {
      this.datePipe.transform(this.maxDate, 'mm/dd/yyyy');
    }
  
    ngOnInit() {
      window.scrollTo(0,0);
      this.agentProfile = new AgentProfile();
      this.loginService.currentData.subscribe(
        data=>{
          if(data==null || data.sessionToken == null){
            this.router.navigate(['']);
          }
          else{
           this.id=data.crmUserId;
           this.token=data.sessionToken;
          }
        }
      )
      this.agentWIReportForm = this.formBuilder.group({  
        startDate: new FormControl('', [  
          Validators.required
        ]),  
        endDate: new FormControl('', [  
          Validators.required  
        ])
       
      });
    }
    get f() {return this.agentWIReportForm.controls; }
    
   
    changePassword(){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
          sessionToken:this.token,
          userId:this.id
        };
        // dialogConfig.maxWidth='400px';
      this.dialog.open(ChangePasswordComponent,dialogConfig);
    }
    validateDates(sDate: Date, eDate: Date){
      this.isValidDate = true;
     
  
      if((eDate) < (sDate)){
       // this.error={isError:true,errorMessage:'Start date should be less than End date.'};
       alert("Start date should be less than End date.");
        this.isValidDate = false;
      }
      return this.isValidDate;
    }
    process(date:string){
      this.parts = date.split("-");
     return new Date(this.parts[2], this.parts[1] - 1, this.parts[0]);
  }
    logoutUser(){
      this.loginService.endInterval();
      this.logoutService.logoutUser(this.id, this.token).subscribe(
        data=>{
    
          console.log(data);
          if(data.status==200 ){
            this.router.navigate(['/']);
          }
        }
      )
     
    }
    viewWIDashboard()
    {
      
      this.submitted=true;
      this.startDate=this.datePipe.transform(this.agentWIReportForm.value.startDate,"dd-MM-yy");
      this.endDate=this.datePipe.transform(this.agentWIReportForm.value.endDate,"dd-MM-yy");
      if(this.startDate==null || this.endDate==null){
        alert("Please enter valid dates");
        return;
      }
      this.isValidDate = this.validateDates(this.process(this.startDate), this.process(this.endDate));
      if( this.agentWIReportForm.valid && this.isValidDate ) {
        this.loading = true;
        this.queueReportingDashboardService.getQueueReportingDashboard(this.id, this.token,this.startDate, this.endDate).subscribe(
          data=>{
            if(data.status==200){
              
              console.log(data.body['queueReportingDashboardDataVO']);
              this.queueReportingDashboardService.storeddata(data.body['queueReportingDashboardDataVO']);
              this.showWiDashboard=true;
              this.loading = false;
            }
          }
        );
  
    }
  else{
   
    this.showWiDashboard=false;
  }}
}
