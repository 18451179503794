import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/app/environment";
import { CustomFieldModel } from "src/app/models/AddCustomField.model";
import { BehaviorSubject } from "rxjs";
import { CustomFieldsForWorkitem } from "src/app/models/CustomFieldsForWorkitem.model";
import { WorkitemItemDetailInput } from "src/app/models/workitemDetailinput.model";

@Injectable()
export class CustomFieldsService{

    private addCustomFieldUrl=environment.baseUrl+'addCustomField';
    private editCustomFieldUrl=environment.baseUrl+'editCustomField';
    private getCustomFieldsListUrl=environment.baseUrl+'getCustomFieldList';
    private getCustomFieldsForWorkitemUrl=environment.baseUrl+'getCustomFieldsForWorkItem';
    private updateCustomFieldForWorkitemUrl=environment.baseUrl+'updateCustomFieldsForWorkItem';
    constructor(private http:HttpClient){
        
    }

    public StoredData: BehaviorSubject<any> = new BehaviorSubject<any>(null);  
    currentData = this.StoredData.asObservable();
      storeddata(data: any) {
        this.StoredData.next(data);
      }

    addCustomField(customFieldModel:CustomFieldModel){
        return this.http.post(this.addCustomFieldUrl,customFieldModel,{observe:'response'});
    }

    customFieldsList(crmUserId,sessionToken){
        return this.http.post(this.getCustomFieldsListUrl,{"crmUserId":crmUserId,"sessionToken":sessionToken},{observe:'response'});
    }

    editCustomField(customFieldModel:CustomFieldModel){
        return this.http.post(this.editCustomFieldUrl,customFieldModel,{observe:'response'});
    }

    updateCustomFieldsForWorkItem(customFieldsForWorkitem:CustomFieldsForWorkitem){
        return this.http.post(this.updateCustomFieldForWorkitemUrl,customFieldsForWorkitem,{observe:'response'});
    }

    getCustomFieldsForWorkItem(workItemDetailInput:WorkitemItemDetailInput){
        return this.http.post(this.getCustomFieldsForWorkitemUrl,workItemDetailInput,{observe:'response'});
    }
}