import{ Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
 import { environment } from "../environment";



@Injectable()
export class GetSkillsService{
     private Url=environment.baseUrl+'getSkills';
constructor(private http:HttpClient){
    
}
getSkills(crmUserId,sessionToken){
     return this.http.post<any>(this.Url, {"crmUserId" : crmUserId,"sessionToken":sessionToken }, {observe: 'response'})
   
}  

}