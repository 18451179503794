import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { ShowDashboard } from '../models/showDashboard.model';
import { WorkitemDetails } from '../models/workitemDetails.model';
@Injectable()
export class SecurityQuestionsService{

    private urlForGetSecurityQuestions=environment.baseUrl+'getAllSecurityQuestions';
    private urlForAddingAnswers = environment.baseUrl+'addSecurityQuestions';
    private urlForGetVerificationQuestionsForUser = environment.baseUrl+'getMappedQuestionsForUser';
    private urlForVerifySecureLogin = environment.baseUrl+'verifySecureLogin';
    private urlForOtpVerification = environment.baseUrl+'otpVerification';
    private urlForResetQuestions = environment.baseUrl+'resetQuestions';
   
    constructor(private http:HttpClient){
        
    }

    getSecurityQuestions(crmUserId,sessionToken){
        return this.http.post(this.urlForGetSecurityQuestions,{'crmUserId':crmUserId,'sessionToken':sessionToken},{observe:'response'});
    }
    addSecurityAnswers(addedFor,crmUserId,securityQuestionsList,sessionToken){
        return this.http.post(this.urlForAddingAnswers,{'addedFor':crmUserId,'crmUserId':crmUserId,'securityQuestionsList':securityQuestionsList,'sessionToken':sessionToken},{observe:'response'});

    }

    getVerificationQuestionsForUser(crmUserId,sessionToken){
        return this.http.post(this.urlForGetVerificationQuestionsForUser,{'crmUserId':crmUserId,'sessionToken':sessionToken},{observe:'response'});
    }

    verifySecureLogin(addedFor,crmUserId,securityQuestionsList,sessionToken){
        return this.http.post(this.urlForVerifySecureLogin,{'addedFor':crmUserId,'crmUserId':crmUserId,'securityQuestionsList':securityQuestionsList,'sessionToken':sessionToken},{observe:'response'});
    }

    verifyOtp(crmUserId,sessionToken,otp){
        return this.http.post(this.urlForOtpVerification,{'otp':otp,'crmUserId':crmUserId,'sessionToken':sessionToken},{observe:'response'});
    }

    resetQuestionAndSendOtp(crmUserId,sessionToken){
        return this.http.post(this.urlForResetQuestions,{'crmUserId':crmUserId,'sessionToken':sessionToken},{observe:'response'});
    }





   

    

}
