import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AddCustomFieldsComponent } from 'src/app/custom-fields/add-custom-fields/add-custom-fields.component';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { CustomFieldsService } from 'src/app/services/CustomFields.service';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.css']
})
export class CustomFieldsComponent implements OnInit {

  token: string;
  id: number;
  customFieldsList:Array<any> = new Array<any>();
  constructor(
    private dialog: MatDialog,
    private loginService: LoginService,
    private router: Router,
    private customFieldsService:CustomFieldsService,
  ) { }

  ngOnInit() {

    this.loginService.currentData.subscribe(
      data => {
        if (data == null || data.sessionToken == null) {
          this.router.navigate(['']);
        }
        else {
          this.id = data.crmUserId;
          this.token = data.sessionToken;
          this.getCustomFieldsList();
        }
      }
    );
  }

  getCustomFieldsList(){
    this.customFieldsService.customFieldsList(this.id,this.token).subscribe(data=>{
      
      console.log(data);
      if(data.status==200 && data.body['responseType']=="SUCCESS"){
        this.customFieldsList = data.body['customFieldVOS'];
        this.customFieldsService.storeddata(this.customFieldsList);
      }
    });
  }

  addCustomField(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        id: this.id,
        token:  this.token,
        isUpdateRequest:false
      };
      this.dialog.open(AddCustomFieldsComponent,dialogConfig).afterClosed().subscribe(()=>
      {
        this.getCustomFieldsList();
      });
  }

  editCustomField(selectedField){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        id: this.id,
        token:  this.token,
        isUpdateRequest:true,
        customField:selectedField
      };
      this.dialog.open(AddCustomFieldsComponent,dialogConfig).afterClosed().subscribe(()=>
      {
        this.getCustomFieldsList();
      });
  }
}
