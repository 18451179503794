import {Injectable} from "@angular/core";
import { environment } from "src/app/environment";

var SockJs = require("sockjs-client");
var Stomp = require("stompjs");

@Injectable()
export class WebSocketService {

    // Open connection with the back-end socket
    public connect() {
        
        let socket = new SockJs(environment.baseUrl+"socket");
        //let socket = new SockJs("http://52.41.133.143:9080/usercrm/socket");

        let stompClient = Stomp.over(socket);

        return stompClient;
    }
}