import { Injectable } from "@angular/core";
import { environment } from "src/app/environment";
import { HttpClient } from "@angular/common/http";
import { AddSkillModel } from "src/app/models/AddSkill.model";

@Injectable()
export class SkillConfigurationService{

    private urlForSkillsList=environment.baseUrl+'getSkills';
    private urlForAddingSkill = environment.baseUrl+'addSkill';
    private urlForEditSkill = environment.baseUrl+'editSkill';
    constructor(private http:HttpClient){
        
    }

    skillList(crmUserId,sessionToken){
        return this.http.post(this.urlForSkillsList,{'crmUserId':crmUserId,'sessionToken':sessionToken},{observe:'response'});
    }

    addSkill(addSkillModel:AddSkillModel){
        return this.http.post(this.urlForAddingSkill,addSkillModel,{observe: 'response'});
    }

    editSkill(editSkillModel:AddSkillModel){
        
        return this.http.post(this.urlForEditSkill,editSkillModel,{observe:'response'})
    }
}