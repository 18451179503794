import {AdditionalInfoVOForReassign} from './AdditionalInfoVOForReassign.model';

export class CreateWorkitem{
  public additionalInfoVO:AdditionalInfoVOForReassign;
  public crmUserId:number;
  public sessionToken:string;
  public title:string;
  public categoryId:number;
  public subCategoryId:number;
  public manualWorkItemPriority:string;
  public propertyId:number;
  public description:string;
  public dueDate:string;
  public manualWorkItemIdentifier:number;
  public queue:number;
  public propertyIds:Array<any>;
}
