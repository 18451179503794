import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import { LoginService } from '../services/login.service';
import { Router, Scroll, NavigationStart, NavigationEnd } from '@angular/router';
import { ForgotPasswordService } from '../services/forgotPassword.service';
import { Agent } from '../models/agent.model';
import { CrmUser } from '../models/crmuser.model';
import { ShowDashboard } from '../models/showDashboard.model';
import { ShowDashboardService } from '../services/showDashboard.service';
import { WorkitemDetails } from '../models/workitemDetails.model';
import { CategoryAndSubCategoryService } from '../services/categoryandsubcategory.service';
import { WorkItemCategory } from '../models/workitemcategory.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PasswordResetService } from '../services/PasswordReset.service';
import { LoadAdminWorkitemService } from '../services/LoadAdminWorkitems.service';
import { SecurityQuestions } from '../models/SecurityQuestions.model';
import { PropertiesList } from '../services/PropertiesList.service';
import { WorkitemProperty } from '../models/WorkitemProperty.model';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
import { WebSocketService } from 'src/app/services/WebSocket.service';
import { SecurityQuestionsService } from 'src/app/services/securityQuestions.service';
import { LogoutService } from '../services/logout.service';


@Component({
  selector: 'security-questions',
  templateUrl: './securityQuestions.component.html',
  styleUrls: ['./securityQuestions.component.css']
  
})
export class SecurityQuestionsComponent implements OnInit {


    securityQuestionsList:Array<any> = new Array<any>();
    
    securityAnswerList:Array<SecurityQuestions>;
    
   agent:Agent;
   crmUser:CrmUser;
   
   id:number;
   token:string;
   saveQuestionsInfo:FormGroup;
   listAnswers: any = [];
    constructor(private router:Router,private loginService:LoginService,
        private securityQuestionsService:SecurityQuestionsService, private formBuilder: FormBuilder,private logoutService:LogoutService) { 
    }

    ngOnInit() {

       this.agent=new Agent();
      this.crmUser=new CrmUser();
      this.securityAnswerList = new Array<SecurityQuestions>();
      this.loginService.currentData.subscribe((data) => {
        
        
              this.crmUser = data;
              this.token=this.crmUser.sessionToken;
              this.id=this.crmUser.crmUserId;
    });
this.securityQuestionsService.getSecurityQuestions(this.id,this.token).subscribe((data)=>
{
    if(data.status==200 && data.body['responseType']=="SUCCESS"){
        this.securityQuestionsList = data.body['questions'];
        
     
      }
});
 this.saveQuestionsInfo = this.formBuilder.group({
     answerText:new FormControl('',[Validators.required])
    
    
    });
}
    
   
saveAnswers() {
    
    console.log(this.listAnswers);

    for(let i=0;i<this.listAnswers.length;i++){
      if(this.listAnswers[i]=="" || this.listAnswers[i]==null || this.listAnswers[i]==undefined){
        alert("Please answer all the questions");
        return;
      }
    }
    if(this.saveQuestionsInfo.valid && this.listAnswers!=null){
     for(let i=0;i<this.securityQuestionsList.length;i++)
     {
        let answerArray: SecurityQuestions = {
            questionId: this.securityQuestionsList[i].id,
         answer: (this.listAnswers[i] === null || this.listAnswers[i] === undefined) ? "" : this.listAnswers[i] 
           }
           this.securityAnswerList.push(answerArray);
     }
     console.log(this.securityAnswerList);
     this.securityQuestionsService.addSecurityAnswers(this.id,this.id,this.securityAnswerList,this.token).subscribe((data=>{
       
            console.log("saved");
            this.logoutUser();
            
     })
    );}
    else{
        alert("Please enter the data to proceed");
        this.listAnswers = [];
        this.securityAnswerList = [];
    }
        }

        logoutUser(){
            
            this.loginService.endInterval();
            this.logoutService.logoutUser(this.crmUser.crmUserId, this.crmUser.sessionToken).subscribe(
              data=>{
        
                
                console.log(data);
                if(data.status==200 ){
                  this.router.navigate(['/']);
                }
              }
            )
        
          }
   
          get formControls() {
            return this.saveQuestionsInfo.controls;
          }
    
   
   


    


  
  }

