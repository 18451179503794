export class WorkitemDetails{
//  public  assignmentId: number;
//  public assignmentNotes: string;
//  public assignmentStatus: string;

// public ivrWorkItem: string;
// public liveChatWorkItem: string;
// public startTime: string;
// public workItemId: number;
// public workItemStatus: string;
// public workItemType: string;
public  assignmentId: number;
public assignmentNotes: string;
public assignmentStatus: string;
public attachmentCount: number;
public startTime: string;
public workItemDescription:string;
public workItemId: number;
public workItemStatus: string;
public workItemTitle:string
public workItemType: string;
}