export class CustomFieldsForWorkitem{
    public crmUserId:number;
    public sessionToken:string;
    public workItemId:number;
    public workItemCustomFieldVOS:Array<{
         customFieldId:number;
        customFieldName:string;
        customFieldValue:string;
        workItemId:number;
    }>;

}