import{ Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
 import { environment } from "../environment";
 import { BehaviorSubject } from 'rxjs';



@Injectable()
export class GetQueueListService{
     private Url=environment.baseUrl+'getQueueList';
constructor(private http:HttpClient){
    
}
public StoredData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
currentData = this.StoredData.asObservable();
storeddata(data: any) {
  this.StoredData.next(data)
}
getQueueList(crmUserId,sessionToken){
    
     return this.http.post<any>(this.Url, {"crmUserId" : crmUserId,"sessionToken":sessionToken }, {observe: 'response'})
   
}  

}