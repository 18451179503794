export const environment = {
    production: true,
 baseUrl: "https://sonesta.iqd.cloud/usercrm/"
//baseUrl:"http://localhost:9333/usercrm/"
//baseUrl:"http://35.92.192.111:9081/usercrm/"
 //baseUrl:"http://104.211.75.173:9080/usercrm/"
 //baseUrl: "https://52.41.133.143:9081/usercrm/",
 //aseUrl:"https://zipair.iqd.cloud/usercrm/"
 //baseUrl:"http://uat-sonesta.iqd.cloud/usercrm/"
 
}
