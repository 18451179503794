import { Component, OnInit , ElementRef, ViewChild,Inject} from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import { ReportsService } from '../services/reports.service';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
import { saveAs } from "file-saver";
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-agentWiDetailReport',
    templateUrl: './agentWIDetailReport.component.html',
    providers:[DatePipe]
  })
export class AgentWIDetailReportComponent implements OnInit{
  token:string;
  id:number;
  agentWIReportForm:FormGroup; 
  startDate:string;
  endDate:string;
  minDate:Date;
  isValidDate:any;
  submitted:boolean=false;
  maxDate=new Date();
  error:any={isError:false,errorMessage:''};
  parts:any;
    constructor(private reportService:ReportsService, private dialogRef: MatDialogRef<AgentWIDetailReportComponent>,
      private router:Router,@Inject(MAT_DIALOG_DATA) data,private formBuilder: FormBuilder,private datePipe: DatePipe){
        this.token=data.sessionToken;
        this.id=data.userId;
      
   this.datePipe.transform(this.maxDate, 'mm/dd/yyyy');

      }
    ngOnInit() {
      
      this.agentWIReportForm = this.formBuilder.group({  
        startDate: new FormControl('', [  
          Validators.required
        ]),  
        endDate: new FormControl('', [  
          Validators.required  
        ])
       
      }); 
    }
    get f() {return this.agentWIReportForm.controls; }
    close()
  {
    this.dialogRef.close();
  }
 
  downloadAgentWIReport(){
    
    this.submitted=true;
this.startDate=this.datePipe.transform(this.agentWIReportForm.value.startDate,"dd-MM-yy");
this.endDate=this.datePipe.transform(this.agentWIReportForm.value.endDate,"dd-MM-yy");
this.isValidDate = this.validateDates(this.process(this.startDate), this.process(this.endDate));
if(this.isValidDate && this.agentWIReportForm.valid) {
this.reportService.agentWorkItemDetailReport(1,this.id,1,"EXCEL",this.token,this.startDate,this.endDate).subscribe(
  data => {
    saveAs(data,"agentWorkItemDetailReport");
  },
  err => {
    alert("Problem while downloading the file.");
    console.error(err);
  });
  this.dialogRef.close();
  }}




  validateDates(sDate: Date, eDate: Date){
    this.isValidDate = true;
   

    if((eDate) < (sDate)){
     // this.error={isError:true,errorMessage:'Start date should be less than End date.'};
     alert("Start date should be less than End date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  process(date:string){
    this.parts = date.split("-");
   return new Date(this.parts[2], this.parts[1] - 1, this.parts[0]);
}
}