import { Component, OnInit , ElementRef, ViewChild,Inject} from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import { ReportsService } from '../services/reports.service';
import {MatDialogConfig,MatDialog,MAT_DIALOG_DATA} from '@angular/material';
import { AgentWIDetailReportComponent } from '../reports-module/agentWIDetailReport.component';
import { LoginService } from '../services/login.service';
import { CrmUser } from '../models/crmuser.model';
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
import {UpdateAgentStatusService} from '../services/updateAgentStatus.service';
import {LogoutService} from '../services/logout.service';
import { SingleWIReportComponent } from '../reports-module/singleWIReport.component';
import { WICountByCategoryReportComponent } from '../reports-module/wiCountByCategory.component';
import { WICountByTypeReportComponent } from '../reports-module/wiCountByType.component';
import { AgentProfileService } from '../services/AgentProfile.service';
import { AgentProfileComponent } from '../agent-profile/agent-profile.component';
import { AgentProfile } from '../models/AgentProfile.model';
import { WorkItemSLAReportComponent } from '../reports-module/work-item-slareport/work-item-slareport.component';
import { ReportByAllComponent } from '../reports-module/report-by-all/report-by-all.component';
import { ReportByCategoryComponent } from '../reports-module/report-by-category/report-by-category.component';
import { ReportByMailboxComponent } from '../reports-module/report-by-mailbox/report-by-mailbox.component';
import { ReportByAgentComponent } from '../reports-module/report-by-agent/report-by-agent.component';
import { CategoryAndSubCategoryService } from '../services/categoryandsubcategory.service';
import { WorkItemCategory } from '../models/workitemcategory.model';
import { ShowDashboard } from '../models/showDashboard.model';
import { ConfigureEmailService } from '../services/configureEmail.service';
import { ConfigureUserService } from '../services/configureUser.service';
import { WorkitemTypeEnum } from '../workitemTypeEnum';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
import { GetReminderRequest } from 'src/app/models/GetReminderRequest.model';
@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.css'],

  })
export class AdminDashboardComponent implements OnInit{
  crmUser:CrmUser;
  token:string;
  id:number;
  agentProfile:AgentProfile
  updateAgentStatusModel:UpdateAgentStatusModel;
  workitemCategory:Array<WorkItemCategory>=new Array<WorkItemCategory>();
  showDashBoard:ShowDashboard;
  data:any;
  agentList:Array<any>;
  mailBoxesList:Array<any>;
  getReminderRequest:GetReminderRequest;
    constructor(
      private configureUserService:ConfigureUserService,
      private configureEmailService: ConfigureEmailService,
      private categoryandsubcategoryService:CategoryAndSubCategoryService,
      private adminProfileService:AgentProfileService,
      reportService:ReportsService,
      private dialog:MatDialog,
      private loginService:LoginService,
      private router:Router,
      private updateAgentStatusService:UpdateAgentStatusService,
      private logoutService:LogoutService)
    {

    }

    ngOnInit() {
      
      this.showDashBoard = new ShowDashboard();
      this.agentProfile = new AgentProfile();
      this.getReminderRequest = new GetReminderRequest();
      this.loginService.currentData.subscribe((data) => {
        this.crmUser = data;
       this.token=this.crmUser.sessionToken;
       this.id=this.crmUser.crmUserId;

       this.showDashBoard.crmUserId = this.id;
       this.showDashBoard.sessionToken = this.token;
       if(this.crmUser==null || this.crmUser.sessionToken == null){
           this.router.navigate(['']);
         }
    });
    }
    agentWIDetailReport(){
      
      const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data={
   sessionToken:this.token,
   userId:this.id
    };
    // dialogConfig.maxWidth='400px';
    this.dialog.open(AgentWIDetailReportComponent,dialogConfig)
    }
    singleworkItemReport(){
      const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data={
   sessionToken:this.token,
   userId:this.id
    };
    // dialogConfig.maxWidth='400px';
    this.dialog.open(SingleWIReportComponent,dialogConfig)
    }
    wiCountByCategory(){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
       sessionToken:this.token,
       userId:this.id
        };
        // dialogConfig.maxWidth='400px';
        this.dialog.open(WICountByCategoryReportComponent,dialogConfig)
    }
    wiCountByType(){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
       sessionToken:this.token,
       userId:this.id
        };
        //  dialogConfig.maxWidth='500px';

        //  dialogConfig.minWidth='500px';
        this.dialog.open(WICountByTypeReportComponent,dialogConfig)
    }

    logoutUser(){
      
      this.loginService.endInterval();
      this.logoutService.logoutUser(this.crmUser.crmUserId, this.crmUser.sessionToken).subscribe(
        data=>{

          
          console.log(data);
          if(data.status==200 ){
            this.router.navigate(['/']);
          }
        }
      )

    }

    onAdminWorkitemControllerClick(){
      this.router.navigate(['adminWorkitemController']);
    }
    onDashboardClick(){
      //this.router.navigate(["adminDashBoard"]);
    }
    onEmailClick(){
      this.router.navigate(["emailConfiguration"]);
    }
    onQueueClick(){
      this.router.navigate(["queueConfiguration"]);
    }
    onUserClick(){
      this.router.navigate(["userConfiguration"]);
    }
    onSkillClick(){
      this.router.navigate(["skillConfiguration"]);
    }

    onLiveMonitorClick(){
      this.router.navigate(["liveMonitor"]);
    }
    onManualWorkitemClick(){
      this.router.navigate(["manualWorkitemController"]);
    }

    showAgentDashboard()
    {
      this.router.navigate(["dashboard"]);
    }
    showWIDashboard(){
      this.router.navigate(["wiDashboard"]);
    }


    openAdminProfile(){

      
        this.adminProfileService.getAgentDetails(this.crmUser.crmUserId, this.crmUser.sessionToken).subscribe(
          data=>{
            

            console.log(data);
            if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
              this.agentProfile.name = data.body['name'];
              this.agentProfile.emailId = data.body['emailAddress'];
              this.agentProfile.skills = data.body['skills'];
              this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              dialogConfig.height = "auto";
              dialogConfig.width = "600px";  ;
              dialogConfig.data = {
              agentProfile: this.agentProfile
              };
               this.dialog.open(AgentProfileComponent,dialogConfig);

            }
            else if(data.body['responseType']== 'SESSION_TIMEOUT'){

              alert("Session timeout!Please Login Again");
              this.loginService.storeddata(null);
              this.router.navigate(['']);
                                                          }
          }
        );


      }

      wiSLAReport(){
        const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data={
     sessionToken:this.token,
     userId:this.id,
     isSLA:true
      };
      // dialogConfig.maxWidth='400px';
      this.dialog.open(WorkItemSLAReportComponent,dialogConfig);
      }

      wiDetailReport(){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
          sessionToken:this.token,
          userId:this.id,
          isSLA:false
        };

        this.dialog.open(WorkItemSLAReportComponent,dialogConfig);
      }


      reportByAll(){
        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            sessionToken:this.token,
            userId:this.id
          };
          // dialogConfig.maxWidth='400px';
        this.dialog.open(ReportByAllComponent,dialogConfig);
      }

      reportByMailbox(){
        

        this.configureEmailService.getMailboxList(this.id,this.token).subscribe(
          data=>{
            if(data.status==200 && data.body["responseType"]=="SUCCESS")
            {
              console.log(data);
              this.mailBoxesList=data.body['mailboxes'];

              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.data={
                  sessionToken:this.token,
                  userId:this.id,
                  mailBoxesList:this.mailBoxesList,
                  reportType:WorkitemTypeEnum.EMAIL
                };
              this.dialog.open(ReportByMailboxComponent,dialogConfig);
            }
            else if(data.body['responseType']== 'SESSION_TIMEOUT'){
              alert("Session timeout!Please Login Again");
              this.loginService.storeddata(null);
              this.router.navigate(['']);
            }
          }
        );



      }

      reportByCategory(){
        
        // this.showDashBoard.crmUserId = this.id;
        // this.showDashBoard.sessionToken = this.token;
        this.workitemCategory = [];

        this.categoryandsubcategoryService.getCategoryAndSubCategory(this.showDashBoard).subscribe(data=>{
          if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
            var temp=data.body['workItemCategoryVOList'];
            for (let i = 0; i < temp.length; i++) {
              this.workitemCategory.push(temp[i]);
            }

            const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            sessionToken:this.token,
            userId:this.id,
            workitemCategory:this.workitemCategory,
            reportType:WorkitemTypeEnum.EMAIL
          };
        this.dialog.open(ReportByCategoryComponent,dialogConfig);

          }
          else if(data.body['responseType']== 'SESSION_TIMEOUT'){
            alert("Session timeout!Please Login Again");
            this.loginService.storeddata(null);
            this.router.navigate(['']);
          }

        });
      }

      reportByAgent(){
        

        this.configureUserService.getUserList(this.id,this.token).subscribe(data=>
          {
            if(data.status==200 && data.body["responseType"]=="SUCCESS"){
              console.log(data);
             this.agentList= data.body['users'];

             const dialogConfig = new MatDialogConfig();
             dialogConfig.disableClose = true;
               dialogConfig.autoFocus = true;
               dialogConfig.height = "400px";
               dialogConfig.data={
                 sessionToken:this.token,
                 userId:this.id,
                 agentList: this.agentList,
                 reportType:WorkitemTypeEnum.EMAIL
               };
             this.dialog.open(ReportByAgentComponent,dialogConfig);

            }

            else if(data.body['responseType']== 'SESSION_TIMEOUT'){
              alert("Session timeout!Please Login Again");
              this.loginService.storeddata(null);
              this.router.navigate(['']);
            }
          }

          );


      }

      chatReportByAgent(){
        

        this.configureUserService.getUserList(this.id,this.token).subscribe(data=>
          {
            if(data.status==200 && data.body["responseType"]=="SUCCESS"){
              console.log(data);
             this.agentList= data.body['users'];

             const dialogConfig = new MatDialogConfig();
             dialogConfig.disableClose = true;
               dialogConfig.autoFocus = true;
               dialogConfig.height = "400px";
               dialogConfig.data={
                 sessionToken:this.token,
                 userId:this.id,
                 agentList: this.agentList,
                 reportType:WorkitemTypeEnum.CHAT
               };
             this.dialog.open(ReportByAgentComponent,dialogConfig);

            }

            else if(data.body['responseType']== 'SESSION_TIMEOUT'){
              alert("Session timeout!Please Login Again");
              this.loginService.storeddata(null);
              this.router.navigate(['']);
            }
          }

          );


      }

      chatReportByALL(){
        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            sessionToken:this.token,
            userId:this.id,
            reportType:WorkitemTypeEnum.CHAT
          };

        this.dialog.open(ReportByAllComponent,dialogConfig);
      }
      chatReportByCategory(){
        

        this.workitemCategory = [];
        this.categoryandsubcategoryService.getCategoryAndSubCategory(this.showDashBoard).subscribe(data=>{
          if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
            var temp=data.body['workItemCategoryVOList'];
            for (let i = 0; i < temp.length; i++) {
              this.workitemCategory.push(temp[i]);
            }

            const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            sessionToken:this.token,
            userId:this.id,
            workitemCategory:this.workitemCategory,
            reportType:WorkitemTypeEnum.CHAT
          };
        this.dialog.open(ReportByCategoryComponent,dialogConfig);

          }
          else if(data.body['responseType']== 'SESSION_TIMEOUT'){
            alert("Session timeout!Please Login Again");
            this.loginService.storeddata(null);
            this.router.navigate(['']);
          }

        });
      }


      changePassword(){
        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            sessionToken:this.token,
            userId:this.id
          };
          // dialogConfig.maxWidth='400px';
        this.dialog.open(ChangePasswordComponent,dialogConfig);
      }

      reminder(){
        
         
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
          crmUserId: this.id,
          sessionToken: this.token,
          detailedWorkitem:null,
          isServed:false
        };
        this.dialog.open(ReminderConfigurationComponent,dialogConfig);
       }
    
}
