import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-pop-up',
  templateUrl: './notification-pop-up.component.html',
  styleUrls: ['./notification-pop-up.component.css']
})
export class NotificationPopUpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
