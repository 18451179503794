export class ExcelWorkitem{
  public Title:String;
  public Description:String;
  public DueDate:String;
  public Priority:String;
  public category:number;
  public subCategory:number;
  public subCategoryList:any;
  public property:number;
  public subProperty:number;
  public subPropertiesList:any;
  public queue:number;

}
