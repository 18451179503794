import { Component, OnInit,Input } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import {MatDialog,MatDialogConfig} from '@angular/material';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material';
import {AddCustomerComponent} from '../addCustomer/addCustomer.component';

@Component({
  selector: 'app-addCustomerMessage',
  templateUrl: './addCustomerMessage.component.html'
  
})
export class AddCustomerMessageComponent implements OnInit {
    
 // @Input()  showMessage: boolean;
  constructor(private activatedRoute:ActivatedRoute,
    private router:Router,
    private route:ActivatedRoute,
    private dialog:MatDialog,
    private dialogRef: MatDialogRef<AddCustomerComponent>){}
    ngOnInit(){
  }
  close()
  {
    //this.showMessage=false;
    this.dialogRef.close();
    window.close();

  }
    
   
}
