import { Injectable } from '@angular/core';
import { environment } from '../environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DownloadAttachmentService{
  private url = environment.baseUrl+'downloadWorkItemAttachment?';

  constructor(private http:HttpClient){

  }
  downloadFile(id,workItemId,fileName, token){
    return this.http.get(this.url+"crmUserId="+id+"&manualWorkItemId="+workItemId+"&fileName="+fileName+"&sessionToken="+token,{responseType: 'blob'});
  }
}
