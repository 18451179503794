export class CrmUser{
    public name:string;
    public location:string;
    public company:string;
    public userAccessList:string;
    public crmUserId:number;
    public companyId:number;
    public emaiId:string;
    public sessionToken:string;
    public admin:boolean; 
    public agent:boolean;
    public userType:String;
    public sme:boolean;
    public securityQuestionsSet:boolean;
    public accountBlocked:boolean;
    public userRole:string;
    
 
    
 }