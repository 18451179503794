import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { CustomerHistoryInput } from '../models/CustomerHistoryInput.model';
import { CustomerDetails } from '../models/CustomerDetails.model';
import { EmailHistoryInput } from '../models/EmailHistoryInput.model';

@Injectable()
export class EmailHistoryService{
    public StoredData: BehaviorSubject<CustomerDetails> = new BehaviorSubject<CustomerDetails>( new CustomerDetails());  
currentData = this.StoredData.asObservable();

storeddata(data: any) {
  
    this.StoredData.next(data);
  }


    private Url=environment.baseUrl+'getPreviousReplies';

    constructor(private http:HttpClient){
    
    }

    getEmailHistory(emailHistoryInput:EmailHistoryInput){
        
        return this.http.post(this.Url,

            emailHistoryInput ,
              {observe: 'response'});
    }


}