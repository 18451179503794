import { WhatsappMessageFormatModel } from "src/app/models/WhatsappMessageFormat.model";

export class WhatsappWorkitemDetails {
    assignedAgent:Array<{
        name:string;
        location:string;
        assignmentThreshold:number;
        mappedSkills:Array<{
            id:number;
            name:string;
            description:string;
        }>;
        emaiId:string;
    }>;
    customerVO:Array<any>;
    workItemCategory:Array<{
        id:number;
        name:string;
        description:string;
    }>;
    
    callingNumber:string;
    conversationWorkItemId:number;
    whatsAppWorkItemDetailsList:Array<WhatsappMessageFormatModel>;
    whatsAppWorkConversationHistoryList:Array<any>;

    workItemId:number;
    workItemStatus:string;
    workItemType:string;
    workItemComments:Array<any>;
}