import { Component, OnInit, Inject } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AddQueue } from '../models/AddQueue.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AddNewQueueService } from '../services/AddNewQueue.service';
import { WorkitemSavedMessageComponent } from '../messagePopUp/workitem-saved-message/workitem-saved-message.component';
import { QueuePriorityEnum } from '../QueuePriorityenum';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-add-queue',
  templateUrl: './add-queue.component.html',
  styleUrls: ['./add-queue.component.css']
})
export class AddQueueComponent implements OnInit {
  dropdownList = [];
  dropDownListForPriority =[];
  selectedItems = [];
  items = [];
  dropdownSettings:IDropdownSettings = {};
  dropdownSettingsForPriority:IDropdownSettings = {};
  skills:Array<{}>;
  addQueueForm:FormGroup;
  addQueue:AddQueue;
  id:number;
  token:string;
  submitted:boolean=false;
  selectedPriority:number;
  lifo:string;
  fifo:string;
  selectedItem:number;
  selectPriorityCheck:boolean =false;

  constructor(
    private router:Router,
    private loginService:LoginService,
    private dialog:MatDialog,
    private addNewQueueService:AddNewQueueService,
    private formBuilder: FormBuilder,
    private route:ActivatedRoute,
    private dialogRef: MatDialogRef<AddQueueComponent>,
  @Inject(MAT_DIALOG_DATA) data) {
    
    this.id = data['id'];
    this.token = data['token'];
     this.skills = data['skills'];
     console.log("skills are"+this.skills);
     this.dropdownList = this.skills;
     

     this.addQueueForm = this.formBuilder.group({
      queueName:new FormControl('',[Validators.required]),
      queueCode:new FormControl('',[Validators.required]),
      queueSkills:new FormControl('',[Validators.required]),
      queuePriority:new FormControl('',[Validators.required]),
      queueSLA:new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      queueDescription:new FormControl('',[Validators.required]),
      enabled:new FormControl(''),
      
     });
   }

   get formControls() {
     return this.addQueueForm.controls;
   }

  ngOnInit() {
    
    this.dropdownList = this.skills;
    this.dropDownListForPriority = [
     "LIFO",
     "FIFO"
    ];
    this.addQueue = new AddQueue();
    this.items = [
     "LIFO",
     "FIFO"
    ];
    this.selectedItem = 0;
    this.selectedPriority = 0;


    this.selectedItems = [];
    this.dropdownSettingsForPriority = {
      maxHeight:50,
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: false
    }
    this.dropdownSettings = {
      maxHeight:100,
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true
    };

    this.fifo = QueuePriorityEnum.FIFO;
    this.lifo = QueuePriorityEnum.LIFO;
  }
  onItemSelect(item: any) {
    
    this.selectedItems.push(item);
    console.log("selected");
    // for(let i= 0;i<this.selectedItems.length;i++){
    //   console.log("the items on select are>>>"+this.selectedItems[i].name);
    // }
  }

  onItemUnSelect(item:any){
    
    for(let i= 0;i<this.selectedItems.length;i++){
      if(this.selectedItems[i].id==item.id){
        for(let j = i;j<(this.selectedItems.length-1);j++){
          this.selectedItems[j] = this.selectedItems[j+1];
        }
        this.selectedItems.pop();
        break;
      }
    }

    console.log("unselected")        
  }
  onSelectAll(items: any) {
    
    this.selectedItems = [];
    for(let i=0;i<items.length;i++){
      this.selectedItems.push(items[i]);
    }
  }

  onDeSelectAll(items:any){
    
    this.selectedItems = [];
  }
  close()
  {

    this.dialogRef.close();

  }

  onSubmitQueue(){
    
    
    this.submitted = true;
      this.addQueue.crmUserId = this.id;
      this.addQueue.description = this.addQueueForm.value.queueDescription;
      this.addQueue.name = this.addQueueForm.value.queueName;
      this.addQueue.queue_code = this.addQueueForm.value.queueCode;
      this.addQueue.sessionToken = this.token;
      this.addQueue.skillIds = [];
      this.addQueue.queueEnabled = this.addQueueForm.value.enabled;
      this.addQueue.queuePriority= this.addQueueForm.value.queuePriority;
      for(let i=0;i<this.selectedItems.length;i++){
        this.addQueue.skillIds[i] = this.selectedItems[i].id;
      }
      this.addQueue.sla = +this.addQueueForm.value.queueSLA;

      if(this.addQueueForm.valid ){
      //  if(this.selectedPriority==0){
      //     this.selectPriorityCheck = true;
      //     return;
      //  }
        this.addNewQueueService.addNewQueue(this.addQueue).subscribe(
          data=>{
            
            console.log(data);
            if(data.status==200 && data.body["responseType"]=="SUCCESS"){
              const dialogConfig= new MatDialogConfig();
              dialogConfig.disableClose=true;
              dialogConfig.autoFocus=true;
              dialogConfig.width="60%";
              dialogConfig.height="40%";
              dialogConfig.data = {
                popUpTitle:"Add Queue",
                messageToBeDisplayed:"Queue added successfully" 
              };
              this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);
              this.dialogRef.close();

            }
            else if(data.status==200 && data.body["responseType"]=="SERVER_ERROR"){
              alert(data.body['responseMessage']);
            }
            else if(data.body['responseType']== 'SESSION_TIMEOUT'){
              
              alert("Session timeout!Please Login Again");
              this.loginService.storeddata(null);
              this.router.navigate(['']);
            } 
            else{
              alert("Unable to save data");
            }
          }
        );
      }
   
  }

  onClearFormFields(){
    this.addQueueForm = new FormGroup({
      queueName:new FormControl(''),
      queueCode:new FormControl(''),
      queueSkills:new FormControl(''),
      queuePriority:new FormControl(''),
      queueSLA:new FormControl(''),
      queueDescription:new FormControl(''),
      enabled:new FormControl(''),
     
      
    });

    this.addQueueForm = this.formBuilder.group({
      queueName:['',[Validators.required]],
      queueCode:['',[Validators.required]],
      queueSkills:['',[Validators.required]],
      queueSLA:['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      queueDescription:['',[Validators.required]],
      queuePriority:['',[Validators.required]]
    });
  }


//   onChangePriority(data){
// 
// console.log(data);
// if(data.target.value = {0:"0"}){
//   this.selectedPriority = 0;
// }
// else{
//   this.selectedPriority = 1;
// }


//   }

}
