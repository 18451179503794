import { Component, OnInit , ElementRef, ViewChild, OnDestroy} from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import { WorkitemDetails } from '../models/workitemDetails.model';
import { LoginService } from '../services/login.service';
import { ShowDashboardService } from '../services/showDashboard.service';
import { CrmUser } from '../models/crmuser.model';
import {LogoutService} from '../services/logout.service';
import { EmailWorkItemDetailService } from '../services/emailworkitemdetail';
import { IVRWorkItemDetailService } from '../services/ivrworkItemDetail.service';
import { FormBuilder, FormGroup, Validators,FormsModule, AbstractControl, FormControl } from '@angular/forms';
import { WorkitemItemDetailInput } from '../models/workitemDetailinput.model';
import { WorkitemItemDetailOutput } from '../models/workitemDetailOutput.model';
import { IVRWorkitemItemDetailOutput } from '../models/ivrworkitemDetailOutput.model';
import {MatDialog,MatDialogConfig,MatDialogRef} from '@angular/material';
import {SearchCustomerComponent} from '../searchCustomer/searchCustomer.component';
import { CustomerHistoryService } from '../services/CustomerHistory.service';
import { CustomerHistoryInput } from '../models/CustomerHistoryInput.model';
import { CustomerHistoryOutput } from '../models/CustomerHistoryOutput.model';
import { CustomerDetails } from '../models/CustomerDetails.model';
import { EmailreplyComponent } from '../emailreply/emailreply.component';
import { UpdateAgentStatusService } from '../services/updateAgentStatus.service';
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
import { debugOutputAstAsTypeScript, CompileTemplateMetadata } from '@angular/compiler';
import { ShowDashboard } from '../models/showDashboard.model';
import { CompletedWorkItemsService } from '../services/CompletedWorkItems.service';
import {CutomerEnagagementHistoryComponent} from '../cutomer-enagagement-history/cutomer-enagagement-history.component';

import { WorkItemStatusChangeInput } from '../models/workitemStatusChangeInput.model';
import { WorkItemStatusChangeService } from '../services/workitemStatusChange.service';
import { WorkItemStatusEnum } from "../workitemstatusenum";
import { CompletedWorkitemDetailOutput } from '../models/completedWorkitemDetailOutput.model';
import { CompletedIVRWorkitemDetailOutput } from '../models/completedIvrWorkitemDetailOutput.model';
import { Customer } from '../models/customer.model';
import { LinkedWorkItemDetailService } from '../services/linkedworkitemdetails.service';
import { IVRWorkitemRemarksService } from '../services/ivrWorkitemRemarks.service';
import { IVRWorkitemRemarksInput } from '../models/ivrWorkitemRemarksInput.model';
import { IVRWorkitemAddedComments } from '../models/ivrWorkitemAddedComments.model';
import { environment } from "../environment";
import { EventEmitterService } from '../event-emitter.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {EmailReplyService} from '../services/emailReply.service';
import { CompleteWorkitemConfirmationMessageComponent } from '../messagePopUp/complete-workitem-confirmation-message/complete-workitem-confirmation-message.component';
import { EmailData } from '../models/emailData.model';
import { UploadAttachmentComponent } from '../uploadAttachment/uploadAttachment.component';
import { Attachment} from '../models/attachment.model';
import { WorkItemCategory } from '../models/workitemcategory.model';
import { WorkItemSubCategory } from '../models/workitemsubcategory.model';
import { CategoryAndSubCategoryService } from '../services/categoryandsubcategory.service';
import { WorkitemTypeEnum } from '../workitemTypeEnum';
import { WorkitemSavedMessageComponent } from '../messagePopUp/workitem-saved-message/workitem-saved-message.component';
import { EmailHistoryInput } from '../models/EmailHistoryInput.model';
import { EmailHistoryService } from '../services/emailHistory.service';
import { EmailHistoryOutput } from '../models/EmailHistoryOutput.model';
import { EmailReplyHistoryComponent } from '../email-reply-history/email-reply-history.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { EngagementHistoryComponent } from '../engagement-history/engagement-history.component';
import { AgentProfileComponent } from '../agent-profile/agent-profile.component';
import { AgentProfileService } from '../services/AgentProfile.service';
import { AgentProfile } from '../models/AgentProfile.model';
import { ChatWorkItemDetailService } from '../services/GetChatWorkitemDetails.service';
import { ChatWorkitemDetails } from '../models/ChatWorkitemDetials.model';
import { AgentAvailability } from '../AgentAvailability.enum';
import { SearchHistoryComponent } from '../historyTab/searchHistory.component';
import { SearchHistoryService } from '../services/SearchHistoryc.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { UserTypeEnum } from '../UserTypeEnum';
import{AdditionalInfoVOForReassign} from '../models/AdditionalInfoVOForReassign.model';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
import { ReminderPopupComponent } from 'src/app/reminder-popup/reminder-popup.component';
import { GetReminderRequest } from 'src/app/models/GetReminderRequest.model';
import { ReminderConfigurationService } from 'src/app/services/ReminderConfiguration.service';
import { WhatsappWorkitemService } from 'src/app/services/WhatsappWorkitem.service';
import { WhatsappWorkitemDetails } from 'src/app/models/WhatsappWorkitemDetails.model';
import { WhatsappChatHistoryComponent } from 'src/app/whatsapp-chat-history/whatsapp-chat-history.component';
import { WorkitemReminderService } from 'src/app/services/WorkitemReminder.service';
import { WorkItemCustomFieldsComponent } from 'src/app/work-item-custom-fields/work-item-custom-fields.component';
import { CustomFieldsService } from 'src/app/services/CustomFields.service';

@Component({
  selector: 'app-agent-dashboard',
  templateUrl: './agent-dashboard.component.html?v=1',
  styleUrls: ['./agent-dashboard.component.css'],

})
export class AgentDashboardComponent implements OnInit,OnDestroy {
  workitemId:string;
 workitemDetails:Array<WorkitemDetails>=new Array<WorkitemDetails>();
 workitemCategory:Array<WorkItemCategory>=new Array<WorkItemCategory>();
 workitemSubCategory:Array<WorkItemSubCategory>=new Array<WorkItemSubCategory>();
 workitemSubCategoryForChat:Array<WorkItemSubCategory> = new Array<WorkItemSubCategory>();
 completeWorkItemDetails:Array<WorkitemDetails> = new Array<WorkitemDetails>();
 workitemDetail:WorkitemDetails;
 customerVO:Array<Customer>;
   crmUser:CrmUser;
   workItemDetailInput: WorkitemItemDetailInput;
   workItemDetailOutput: WorkitemItemDetailOutput;
   completedWorkitemDetailOutput:WorkitemItemDetailOutput;
   chatWorkitemDetailsoutput:ChatWorkitemDetails;
   linkedWorkItemDetailOutput: WorkitemItemDetailOutput;
   emailHistoryInput:EmailHistoryInput;
   agentProfile:AgentProfile;
   ivrWorkitemItemDetailOutput: IVRWorkitemItemDetailOutput;
   completedIvrWorkitemDetailOutput:IVRWorkitemItemDetailOutput;
   whatsappWorkitemDetails:WhatsappWorkitemDetails;

   workItemStatusChangeInput:WorkItemStatusChangeInput;
   ivrWorkitemRemarksInput:IVRWorkitemRemarksInput;
   serveEmailWorkItem:boolean;
   serveManualWorkItem:boolean;
   serveIVRWorkItem:boolean;
   serveWhatsappWorkitem:boolean;
   serveCompletedWhatsappWorkitem:boolean;
   serveCompletedEmailWorkitem:boolean;
   serveCompletedManualWorkitem:boolean;
   serveCompletedIVRWorkitem:boolean;
   serveCompletedChatWorkitem:boolean;

   remarksNotEntered:boolean = false;
   propertiesNotAvailableFromServer:boolean =false;
    token:string;
     id:number;
     updateAgentStatusModel:UpdateAgentStatusModel;
     customerName:string;
     workItemDetail:WorkitemItemDetailOutput;
     workitemType:string;
     customerHistoryInput:CustomerHistoryInput;
     customerHistoryOutput:CustomerHistoryOutput;
     customerEngagementHistory:Array<CustomerHistoryOutput>;
     customerDetails:CustomerDetails;
     emailHistoryOutput:Array<EmailHistoryOutput>;
     dialogRef: MatDialogRef<EmailreplyComponent> | null;
customer:Customer;
     show:boolean = true;
showDashboard:ShowDashboard;
    getLinkHeader:boolean=false;
    showCompletedWorkItems:boolean=false;
    currentsTasks:boolean = true;
    myTaskActiveDiv:boolean = false;
   icon2:Array<boolean> = new Array<boolean>();
textareaValue:string;
    emailItemId:number;
	  //for icon
   public selectedIcon:number;
   public completedSelectedIcon:number;
  //timer
  play:boolean=false;
   time: number = 0;
   hours:number=0;
   minutes:number=0;
   seconds:number=0;

   completedtime: number = 0;
   completedhours:number=0;
   completedminutes:number=0;
   completedseconds:number=0;

interval;
hh:string;
mm:string;
ss:string;

compltedinterval;
completedhh:string;
completedmm:string;
completedss:string;

form: FormGroup;
fwform:FormGroup;
replyType:string;
workItemIdForCompletion:number;
ivrWorkitemAddedComments:IVRWorkitemAddedComments;
selectedCategory : number;
selectedMailBox:any;
selectedMailBoxFw:any;
selectedSubCategory: number;
// selectedCategoryForEmail:number;
// selectedCategoryForIVR:number;


selectedCategoryForChat:number;
selectedSubCategoryForChat:number;
showReply:boolean=false;
IsHidden= true;
IsHiddenReplyAll=true;
fromMailBoxId:string[]=[];
fwfromMailBoxId:string[]=[];
public emailDataTobeSent:EmailData;
isLoading:boolean=false;
clicked:boolean=false;
mailContent:string;
fwmailContent:string;
  subject:string;
  to:string;
  fwto:string;
  fwsubject:string;
  receiver:string;
  public emailData: any;
icon:Array<Number>=new Array<Number>();
 config2: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  minHeight: '18rem',
  maxHeight: '18rem',
  placeholder: 'Enter text here...',
  translate: 'no',
  sanitize: false,
   toolbarPosition: 'top',
  outline: true,
  defaultFontName: 'Arial',
  defaultFontSize: '3',
   showToolbar: true,

};
files:File[]=[];
fwFiles:File[]=[];
attachmentList:Attachment[]=[];
attachmentFile:Attachment;
fwclicked:boolean=false;
deleteAttachment:Attachment;
categoryForCompletedWorkItem: string;
subCategoryForCompletedWorkItem: string;
submitted:boolean=false;
fwSubmitted:boolean=false;
loading:boolean=false;
propertiesListFromServer:string = "";
activeCondiction:boolean = true;
categoryNotSelectedForEmail:boolean = false;
categoryNotSelectedForIVR:boolean = false;
categoryNotSelectedForChat:boolean = false;
agentAvailabilityCheck:string = AgentAvailability.ONLINE;
searchWorkItemForMyTasks:String = '';
searchWorkItemForCompletedTasks:String = '';

rateRemarks:string;
transactionCount:number;
gdsStatus:string;
rateLoadingStatus:string;

rateMappingStatusList = ['PENDING_INTERNAL', 'CLOSED', 'OTHERS', 'PENDING_EXTERNAL'];
gdsStatusList = ['PENDING_INTERNAL', 'CLOSED', 'OTHERS', 'PENDING_EXTERNAL'];

workitemServed = false;
getReminderRequest:GetReminderRequest;
remindersList = [];
whatsappMessagesList:Array<any> = new Array<any>();
whatsappConversationId:number;
customFieldsList:Array<any> = new Array<any>();
addedCustomFieldsListForWorkitem = [];
constructor(
  private searchHistoryService:SearchHistoryService,
  private chatWorkItemDetailService:ChatWorkItemDetailService,
  private showDashboardServiceService:ShowDashboardService,
  private loginService:LoginService,
  private emailWorkItemDetailService:EmailWorkItemDetailService,
  private ivrWorkItemDetailService: IVRWorkItemDetailService,
private workItemStatusChangeService:WorkItemStatusChangeService,
  private router:Router,
private dialog:MatDialog,
  private customerHistoryService:CustomerHistoryService,
  private updateAgentStatusService:UpdateAgentStatusService,
  private completedWorkItemsService:CompletedWorkItemsService,
  private showDashboardService:ShowDashboardService,
  private logoutService:LogoutService,
  private linkedWorkItemDetailService:LinkedWorkItemDetailService,
  private ivrWorkitemRemarksService:IVRWorkitemRemarksService,
  private eventEmitterService: EventEmitterService,
  private emailReplyService:EmailReplyService,
  private formBuilder: FormBuilder,
   private categoryandsubcategoryService:CategoryAndSubCategoryService,
   private emailHistoryService:EmailHistoryService,
   private agentProfileService:AgentProfileService,
   private reminderConfigurationService:ReminderConfigurationService,
   private whatsappWorkitemService:WhatsappWorkitemService,
   private workitemReminderService:WorkitemReminderService,
   private customFieldsService:CustomFieldsService,
  ) {

  }







    onUpdateAgentAvailability(agentAvailability){
      
      this.updateAgentStatusModel.agentAvailability = agentAvailability;
      this.updateAgentStatusModel.crmUserId = this.crmUser.crmUserId;
      this.updateAgentStatusModel.sessionToken = this.crmUser.sessionToken;
        this.updateAgentStatusService.updateAgentStatus(this.updateAgentStatusModel).
        subscribe(data=>{
          console.log(data);
          if(data.status==200 && data.body["responseType"]=="SUCCESS"){
            
            if(agentAvailability==AgentAvailability.ONLINE){
              this.agentAvailabilityCheck = AgentAvailability.ONLINE;
            }
            else if(agentAvailability==AgentAvailability.AWAY){
              this.agentAvailabilityCheck = AgentAvailability.AWAY;
              // this.pauseTimer();
            }
            else if(agentAvailability==AgentAvailability.OFFLINE){
              this.agentAvailabilityCheck =AgentAvailability.OFFLINE;
              // this.pauseTimer();
            }
            // this.pauseTimer();
          }
                   })
    }


toggleShow(){

  this.show = !this.show;
}


  ngOnInit() {
    this.IsHidden=true;
    this.IsHiddenReplyAll=true;
    this.icon2.push(false);
    this.serveEmailWorkItem=false;
    this.serveManualWorkItem = false;
    this.serveIVRWorkItem=false;
    this.serveWhatsappWorkitem = false;
    this.serveCompletedWhatsappWorkitem = false;
    this.serveCompletedEmailWorkitem = false;
    this.serveCompletedManualWorkitem = false;
    this.serveCompletedIVRWorkitem = false;
    this.serveCompletedChatWorkitem = false;
    this.remarksNotEntered = false;
   this.crmUser=new CrmUser();
   this.getReminderRequest = new GetReminderRequest();
   this.workitemDetail=new WorkitemDetails();
   this.workItemDetailInput=new WorkitemItemDetailInput();
   this.workItemDetailOutput=new WorkitemItemDetailOutput();
   this.workItemDetailOutput.additionalInfoVO = new AdditionalInfoVOForReassign();
   this.completedWorkitemDetailOutput = new  WorkitemItemDetailOutput();
   this.completedWorkitemDetailOutput.additionalInfoVO = new AdditionalInfoVOForReassign();
   this.linkedWorkItemDetailOutput=new WorkitemItemDetailOutput();
   this.chatWorkitemDetailsoutput = new ChatWorkitemDetails();
   this.whatsappWorkitemDetails = new WhatsappWorkitemDetails();

   this.ivrWorkitemItemDetailOutput=new IVRWorkitemItemDetailOutput();
   this.emailHistoryInput = new EmailHistoryInput();

   this.customerHistoryInput = new CustomerHistoryInput();
   this.customerHistoryOutput = new CustomerHistoryOutput();

   this.workItemStatusChangeInput=new WorkItemStatusChangeInput();
   this.ivrWorkitemRemarksInput = new IVRWorkitemRemarksInput();
   this.ivrWorkitemRemarksInput.additionalInfoVO = new AdditionalInfoVOForReassign();

   this.customerEngagementHistory = new Array<CustomerHistoryOutput>();
   this.customerDetails = new CustomerDetails();
   this.customer = new Customer();
   this.updateAgentStatusModel = new UpdateAgentStatusModel();
   this.showDashboard = new ShowDashboard();
   this.emailHistoryOutput = new Array<EmailHistoryOutput>();
   this.agentProfile = new AgentProfile();

   this.activeCondiction = true;

   this.ivrWorkitemAddedComments = new IVRWorkitemAddedComments({},"","");
   this.showDashboardServiceService.currentData.subscribe((data)=>{
     this.workitemDetails=data;

    });

    this.customFieldsService.currentData.subscribe(data=>{
      this.customFieldsList = data;
    });

    this.searchHistoryService.currentData.subscribe((data)=>{
      
      if(!(data===null)){
        this.agentAvailabilityCheck=data;
      }

    });

    this.categoryandsubcategoryService.currentData.subscribe((data)=>{
      
      this.workitemCategory=data;
      console.log(this.workitemCategory);
    })

    this.loginService.currentData.subscribe((data) => {
      
     this.crmUser = data;
    this.token=this.crmUser.sessionToken;
    this.id=this.crmUser.crmUserId;


    if(this.crmUser==null || this.crmUser.sessionToken == null){
        this.router.navigate(['']);
      }

      // const dialogConfig = new MatDialogConfig();
      // dialogConfig.disableClose = true;
      // dialogConfig.autoFocus = true;
      // this.getReminderRequest.crmUserId = this.id;
      // this.getReminderRequest.sessionToken = this.token;
      // this.getReminderRequest.searchByUser = true;
      // this.getReminderRequest.searchCompleted = true;
      // this.getReminderRequest.searchCriteriaId = this.id;
      // this.reminderConfigurationService.getReminder(this.getReminderRequest).subscribe(data=>{
      //   
      //   this.remindersList = data.body["reminderVOList"];
      //   console.log(this.remindersList);
      //   dialogConfig.data={
      //     crmUserId: this.id,
      //     sessionToken: this.token,
      //     remindersList:this.remindersList,
      //   };
      //   this.dialog.open(ReminderPopupComponent,dialogConfig);
      // });
 });
 this.selectedCategory=0;
 this.selectedSubCategory=0;
 this.selectedCategoryForChat = 0;
 this.selectedSubCategoryForChat = 0;
this.form = this.formBuilder.group({
  receiver: ['',[Validators.required, Validators.email]],
   from: [''],
   cc: ['', [Validators.pattern(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/)]],
   bcc:['', [Validators.pattern(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/)]],
   sub: ['',[Validators.required,Validators.minLength(4)]],
   content: ['',[Validators.required,Validators.minLength(4)]]
 });
 this.fwform = this.formBuilder.group({
  fwreceiver: ['',[Validators.required, Validators.email]],
   fwfrom: [''],
   fwcc: ['', [Validators.pattern(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/)]],
   fwbcc:['', [Validators.pattern(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/)]],
   fwsub: ['',[Validators.required,Validators.minLength(4)]],
   fwcontent: ['',[Validators.required,Validators.minLength(4)]]
 });
  }



  get f() { return this.form.controls; }
  get fw(){return this.fwform.controls;}

  currentWorkItemId:number;
  getServedWorkItem(workitemId,workitemType,workitemState){
    
    this.currentWorkItemId = workitemId;
    this.workitemServed = true;
    this.icon2 = [];
    this.icon2.push(false);
    this.propertiesNotAvailableFromServer = false;
    this.loading = true;
    this.categoryNotSelectedForEmail = false;
    this.propertiesListFromServer= "";
    this.categoryNotSelectedForIVR = false;
    this.remarksNotEntered = false;
    this.rateLoadingStatus = null;
    this.transactionCount = null;
    this.rateRemarks = null;
    this.gdsStatus = null;

    // this.serveEmailWorkItem=false;
    // this.serveIVRWorkItem=false;
this.IsHidden=true;
this.IsHiddenReplyAll=true;
    this.serveCompletedEmailWorkitem = false;
    this.serveCompletedIVRWorkitem = false;
    this.serveCompletedChatWorkitem = false;
    this.serveCompletedManualWorkitem = false;
    if(workitemState!=="completed"){
    this.pauseTimer();
    this.selectedIcon=workitemId;
    this.activeCondiction = true;
    }
    else{
      this.completedSelectedIcon = workitemId;
      this.activeCondiction = false;
    }
    this.workItemDetailInput.workItemId=workitemId;
    this.workItemDetailInput.crmUserId=this.crmUser.crmUserId;
    this.workItemDetailInput.sessionToken=this.crmUser.sessionToken;
    if(workitemType==WorkitemTypeEnum.EMAIL){
      this.getEmailWorkItem(workitemState);
  this.workitemType="EMAIL";


    }else if(workitemType==WorkitemTypeEnum.IVR){
      this.getIVRWorkItem(workitemState);
      this.workitemType="IVR";
    }
    else if(workitemType == WorkitemTypeEnum.MANUAL){
      this.getManualWorkItem(workitemState);
    }
    else if(workitemType==WorkitemTypeEnum.CHAT){
    this.getChatWorkItem();
    }
    else if(workitemType==WorkitemTypeEnum.WHATSAPP){
      this.getWhatsappWorkitem(workitemState);
    }
  }

 

  getChatWorkItem(){
      this.chatWorkItemDetailService.getChatWorkItem(this.workItemDetailInput).subscribe(
        data=>{
          console.log(data);
          if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
            this.chatWorkitemDetailsoutput = data.body['liveChatWorkItem'];
            this.serveCompletedChatWorkitem = true;
            this.getSelectedCategoryAndSubCategoryForChat();
            this.loading = false;
          }
        }
      )
  }

  getManualWorkItem(workitemState){
    this.IsHidden=true;
    this.files=[];
    this.fwFiles=[];
    this.emailWorkItemDetailService.getManualWorkitemDetails(this.workItemDetailInput).
    subscribe(data=>{
      
      console.log(data);
      if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
        
        // console.log(data1.body['flowSearchResults']);
        this.workitemReminderService.storeddata(data.body['manualWorkItem']);
        if(workitemState==="completed"){
          this.completedWorkitemDetailOutput = data.body['manualWorkItem'];
          if(this.completedWorkitemDetailOutput.propertyVOS!=null){
          for(let i=0;i<this.completedWorkitemDetailOutput.propertyVOS.length;i++){
            if(i==this.completedWorkitemDetailOutput.propertyVOS.length-1){
              this.propertiesListFromServer = this.propertiesListFromServer+this.completedWorkitemDetailOutput.propertyVOS[i].name;
            }
            else{
              this.propertiesListFromServer = this.propertiesListFromServer+this.completedWorkitemDetailOutput.propertyVOS[i].name+", ";
            }
          }
        }
        else{
          this.propertiesNotAvailableFromServer = true;
        }
          this.serveCompletedManualWorkitem = true;
          if(this.completedWorkitemDetailOutput.workItemTimeSpent==null){
            this.getComplteHandlingTime(0);
          }else{
            this.getComplteHandlingTime(this.completedWorkitemDetailOutput.workItemTimeSpent["ss"]);
          }
          this.categoryForCompletedWorkItem = this.completedWorkitemDetailOutput.workItemCategory['name'];
          this.subCategoryForCompletedWorkItem = this.completedWorkitemDetailOutput.workItemSubCategory['name'];

          //console.log(this.workItemDetailOutput2);
        }else{
          this.workItemDetailOutput=data.body['manualWorkItem'];
          if((this.workItemDetailOutput.additionalInfoVO.statusGDS)!=null)
            this.gdsStatus = this.workItemDetailOutput.additionalInfoVO.statusGDS;
          else
            this.gdsStatus = "Select GDS Status";
          if((this.workItemDetailOutput.additionalInfoVO.rateMappingStatus)!=null)
            this.rateLoadingStatus = this.workItemDetailOutput.additionalInfoVO.rateMappingStatus;
          else
            this.rateLoadingStatus = "Select Rate Loading Status";
          this.rateRemarks = this.workItemDetailOutput.additionalInfoVO.ratePlanText;
          this.transactionCount = this.workItemDetailOutput.additionalInfoVO.transactionCount;
          if(this.workItemDetailOutput.propertyVOS!=null){
          for(let i=0;i<this.workItemDetailOutput.propertyVOS.length;i++){
            if(i==this.workItemDetailOutput.propertyVOS.length-1){
              this.propertiesListFromServer = this.propertiesListFromServer+this.workItemDetailOutput.propertyVOS[i].name;
            }
            else{
              this.propertiesListFromServer = this.propertiesListFromServer+this.workItemDetailOutput.propertyVOS[i].name+", ";
            }
          }
        }
        else{
          this.propertiesNotAvailableFromServer = true;
        }
          this.getSelectedCategoryAndSubCategoryForEmail();

          if(this.workItemDetailOutput.workItemTimeSpent==null){
            this.startTimer(0);
          }
          else{
            this.startTimer( this.workItemDetailOutput.workItemTimeSpent["ss"]);
          }

          this.serveManualWorkItem = true;
          this.serveEmailWorkItem=false;
          this.serveIVRWorkItem=false;
          this.serveWhatsappWorkitem = false;
          this.myTaskActiveDiv = true;
          console.log(this.workItemDetailOutput);
        }
        this.loading = false;
      }
      else if(data.body['responseType']== 'SESSION_TIMEOUT'){
        alert("Session timeout!Please Login Again");
        this.loginService.storeddata(null);
        this.router.navigate(['']);
      }
      else{
        
        console.log(data);
      }
    },



  error => {
    
    console.log(error);
  });


  }
 

  getWhatsappWorkitem(workitemState){
    
        this.whatsappWorkitemService.getWhatsappWorkitemDetails(this.workItemDetailInput).subscribe(
          data=>{
            
            console.log(data);
            if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
              this.loading = false;
              this.workitemReminderService.storeddata(data.body["whatsAppConversationWorkItem"]);


              this.whatsappWorkitemDetails = data.body["whatsAppConversationWorkItem"];
              this.whatsappMessagesList = data.body["whatsAppWorkConversationHistoryList"];
              this.whatsappConversationId = this.whatsappWorkitemDetails.conversationWorkItemId;

              let arrayFromString  = Array.from(this.whatsappWorkitemDetails.callingNumber);
              for(let j=0;j<arrayFromString.length-2;j++){
                arrayFromString[j] = 'x';
              }
              this.whatsappWorkitemDetails.callingNumber = arrayFromString.join("");
              if(workitemState==="completed"){
                // this.whatsappWorkitemDetails = data.body["whatsAppConversationWorkItem"];
                // this.whatsappMessagesList = data.body["whatsAppWorkConversationHistoryList"];
                // this.whatsappConversationId = this.whatsappWorkitemDetails.conversationWorkItemId;
                this.serveCompletedWhatsappWorkitem = true;
    
              }
              else{
    
                // this.whatsappWorkitemDetails = data.body["whatsAppConversationWorkItem"];
                // this.whatsappMessagesList = data.body["whatsAppWorkConversationHistoryList"];
                // this.whatsappConversationId = this.whatsappWorkitemDetails.conversationWorkItemId;
                // for(let i=0;i<this.whatsappWorkitemDetails.whatsAppWorkItemDetailsList.length;i++){
                //   this.whatsappMessageArrayForDisplay.push({'messageBody':this.whatsappWorkitemDetails.whatsAppWorkItemDetailsList[i],'source':"server"});
                //   this.WhatsappMessageArray.push({'destinationNumber':this.whatsappWorkitemDetails.whatsAppWorkItemDetailsList});
                // }
                this.serveWhatsappWorkitem = true;
                this.serveEmailWorkItem = false;
                this.serveManualWorkItem = false;
                this.myTaskActiveDiv = true;
    
                // this.whatsappWorkitemService.getConversationHistoryByNumber(this.crmUser.crmUserId,this.whatsappWorkitemDetails.callingNumber,this.crmUser.sessionToken).subscribe(
                //   data=>{
                //     
                //     console.log(data);
                //   }
                // );
    
                // this.whatsappWorkitemService.sendWhatsappMessage(this.crmUser.crmUserId,this.whatsappWorkitemDetails.callingNumber,this.crmUser.sessionToken,"hello from iqd").subscribe(
                //   data=>{
                //     
                //     console.log(data);
                //   }
                // );
    
    
              }
            }
    
    
          }
        );
      }
    
      getConversationHistoryByNumber(){
    
        this.whatsappWorkitemService.getConversationHistoryByNumber(this.crmUser.crmUserId,this.whatsappWorkitemDetails.callingNumber,this.crmUser.sessionToken).subscribe(
          data=>{
            
            console.log(data);
          }
        );
    
      }
      inputMessageForWhatsapp:string = "";
    
      sendWhatsappMessage(){
        
        this.whatsappWorkitemService.sendWhatsappMessage(this.crmUser.crmUserId,this.whatsappConversationId,this.crmUser.sessionToken,this.inputMessageForWhatsapp).subscribe(
          data=>{
            
            if(data.status ==200 && data.body["responseType"]=="SUCCESS"){

              this.whatsappMessagesList.push({'messageType':"SENT_FROM_IQD",'sentBy':this.crmUser.name,'message':this.inputMessageForWhatsapp,'conversationId':this.whatsappConversationId,'mesageDate':""});
              // this.singlehatsappMessage.destinationNumber = this.whatsappWorkitemDetails.callingNumber;
              // this.singlehatsappMessage.msgBody = this.inputMessageForWhatsapp;
              // this.singlehatsappMessage.messagingNumber = this.whatsappWorkitemDetails.callingNumber;
              
              // this.whatsappMessageArrayForDisplay.push({'messageBody':this.singlehatsappMessage,'source':"user"});
              console.log(data);
              this.inputMessageForWhatsapp = "";
            }
          }
        );
      }


  getEmailWorkItem(workitemState){
    this.IsHidden=true;
    this.files=[];
    this.fwFiles=[];
  this.emailWorkItemDetailService.getEmailWorkitemStateChange(this.workItemDetailInput).
  subscribe(data=>{
    
    console.log(data);
    if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
      
      // console.log(data1.body['flowSearchResults']);
      this.workitemReminderService.storeddata( data.body['emailWorkItem']);
      if(workitemState==="completed"){

        this.completedWorkitemDetailOutput = data.body['emailWorkItem'];
        if(this.completedWorkitemDetailOutput.propertyVOS!=null){
        for(let i=0;i<this.completedWorkitemDetailOutput.propertyVOS.length;i++){
          if(i==this.completedWorkitemDetailOutput.propertyVOS.length-1){
            this.propertiesListFromServer = this.propertiesListFromServer+this.completedWorkitemDetailOutput.propertyVOS[i].name;
          }
          else{
            this.propertiesListFromServer = this.propertiesListFromServer+this.completedWorkitemDetailOutput.propertyVOS[i].name+", ";
          }
        }
      }
      else{
        this.propertiesNotAvailableFromServer = true;
      }

        this.serveCompletedEmailWorkitem = true;
        this.getComplteHandlingTime(this.completedWorkitemDetailOutput.workItemTimeSpent["ss"]);
        this.categoryForCompletedWorkItem = this.completedWorkitemDetailOutput.workItemCategory['name'];
        this.subCategoryForCompletedWorkItem = this.completedWorkitemDetailOutput.workItemSubCategory['name'];

        //console.log(this.workItemDetailOutput2);
      }else{
        this.workItemDetailOutput=data.body['emailWorkItem'];

        if((this.workItemDetailOutput.additionalInfoVO.statusGDS)!=null)
        this.gdsStatus = this.workItemDetailOutput.additionalInfoVO.statusGDS;
      else
        this.gdsStatus = "Select GDS Status";
      if((this.workItemDetailOutput.additionalInfoVO.rateMappingStatus)!=null)
        this.rateLoadingStatus = this.workItemDetailOutput.additionalInfoVO.rateMappingStatus;
      else
        this.rateLoadingStatus = "Select Rate Loading Status";

        this.rateRemarks = this.workItemDetailOutput.additionalInfoVO.ratePlanText;
        this.transactionCount = this.workItemDetailOutput.additionalInfoVO.transactionCount;

        if(this.workItemDetailOutput.propertyVOS!=null){
        for(let i=0;i<this.workItemDetailOutput.propertyVOS.length;i++){
          if(i==this.workItemDetailOutput.propertyVOS.length-1){
            this.propertiesListFromServer = this.propertiesListFromServer+this.workItemDetailOutput.propertyVOS[i].name;
          }
          else{
            this.propertiesListFromServer = this.propertiesListFromServer+this.workItemDetailOutput.propertyVOS[i].name+", ";
          }
        }
      }
      else{
        this.propertiesNotAvailableFromServer = true;
      }
        this.getSelectedCategoryAndSubCategoryForEmail();


        this.startTimer( this.workItemDetailOutput.workItemTimeSpent["ss"]);
        this.serveEmailWorkItem=true;
        this.serveManualWorkItem = false;
        this.serveIVRWorkItem=false;
        this.serveWhatsappWorkitem = false;
        this.myTaskActiveDiv = true;
        console.log(this.workItemDetailOutput);
      }
      this.loading = false;
    }
    else if(data.body['responseType']== 'SESSION_TIMEOUT'){
      alert("Session timeout!Please Login Again");
      this.loginService.storeddata(null);
      this.router.navigate(['']);
    }
    else{
      
      console.log(data);
    }
  },



error => {
  
  console.log(error);
});

}


getIVRWorkItem(workitemState){
  this.ivrWorkItemDetailService.getIVRWorkitemStateChange(this.workItemDetailInput).
  subscribe(data=>{
    console.log(data);
    if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
          
             console.log(data);
             if(workitemState==="completed"){
              this.completedIvrWorkitemDetailOutput = data.body['ivrWorkItem'];
              this.serveCompletedIVRWorkitem = true;
              this.getComplteHandlingTime(this.completedIvrWorkitemDetailOutput.workItemTimeSpent["ss"]);
              this.categoryForCompletedWorkItem = this.completedIvrWorkitemDetailOutput.workItemCategory['name'];
              this.subCategoryForCompletedWorkItem = this.completedIvrWorkitemDetailOutput.workItemSubCategory['name'];
              console.log(this.completedIvrWorkitemDetailOutput);
            }else{
             this.ivrWorkitemItemDetailOutput=data.body['ivrWorkItem'];
             this.getSelectedCategoryAndSubCategoryForIvr();

             this.serveIVRWorkItem=true;
             this.serveManualWorkItem = false;
             this.serveEmailWorkItem=false;
             this.serveWhatsappWorkitem = false;

             this.myTaskActiveDiv = true;
       this.startTimer( this.ivrWorkitemItemDetailOutput.workItemTimeSpent["ss"]);

            }
                 }

                else if(data.body['responseType']== 'SESSION_TIMEOUT'){

                  alert("Session timeout!Please Login Again");
                  this.loginService.storeddata(null);
                 this.router.navigate(['']);
                }  else{
                     
                    console.log(data);
                }
                this.loading = false;

             },

            error => {
              
              console.log(error);

            })

}

getSelectedCategoryAndSubCategoryForChat(){

  
  this.selectedCategoryForChat = this.chatWorkitemDetailsoutput.workItemCategory['id'];
  var list = this.workitemCategory.filter(x => x.id == this.selectedCategoryForChat);
  var temp=list[0];
  if(temp!=undefined){
this.workitemSubCategoryForChat=(temp['subCategories']);
this.selectedSubCategoryForChat=this.chatWorkitemDetailsoutput.workItemSubCategory['id'];
}
else{
  this.workitemSubCategoryForChat=[];
  this.selectedSubCategoryForChat = 0;
}


}
getSelectedCategoryAndSubCategoryForEmail(){
  

  this.selectedCategory=this.workItemDetailOutput.workItemCategory['id'];

var list = this.workitemCategory.filter(x => x.id == this.selectedCategory);

var temp=list[0];
if(temp!=undefined){
this.workitemSubCategory=(temp['subCategories']);
this.selectedSubCategory=this.workItemDetailOutput.workItemSubCategory['id'];
}
else{
  this.workitemSubCategory=[];
  this.selectedSubCategory = 0;
}
//  this.selectedSubCategory=0;

}
getSelectedCategoryAndSubCategoryForIvr(){
  
  this.selectedCategory=this.ivrWorkitemItemDetailOutput.workItemCategory['id'];

var list = this.workitemCategory.filter(x => x.id == this.selectedCategory);
var temp=list[0];
if(temp!=undefined){
this.workitemSubCategory=(temp['subCategories']);
this.selectedSubCategory=this.ivrWorkitemItemDetailOutput.workItemSubCategory['id'];
}
else{
  this.workitemSubCategory = [];
  this.selectedSubCategory = 0;
}
}

onCompleteWorkitemClicked(workItemId){
  
    const dialogConfig= new MatDialogConfig();
  dialogConfig.disableClose=true;
  dialogConfig.autoFocus=true;
  dialogConfig.width="60%";
  dialogConfig.height="35%";
  dialogConfig.id = workItemId;
  console.log("workitemid is"+dialogConfig.id);
  this.workItemIdForCompletion = workItemId;
  this.dialog.open(CompleteWorkitemConfirmationMessageComponent,dialogConfig).afterClosed().
  subscribe(item => {
    if(item===0){}
    // Edited Data sent to App Component from Dialog
    else{
      this.completeWorkItem(item);
    }

  });;

}



completeWorkItem(workitemid){

console.log("workitemid is"+ workitemid);

  





  this.pauseTimer();
  this.workItemStatusChangeInput.crmUserId=this.crmUser.crmUserId;
  this.workItemStatusChangeInput.sessionToken=this.crmUser.sessionToken;
  this.workItemStatusChangeInput.updatedByUserId=this.crmUser.crmUserId;
  this.workItemStatusChangeInput.workItemId= workitemid;
  this.workItemStatusChangeInput.workItemStatus= WorkItemStatusEnum.COMPLETED;



  this.workItemStatusChangeService.getWorkItemStatusChange(this.workItemStatusChangeInput).
  subscribe(data=>{
    console.log(data);
    if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
          
             console.log(data);
             this.serveEmailWorkItem=false;
            this.serveIVRWorkItem=false;
            this.serveManualWorkItem = false;
            this.getWorkItemList();



                 }
                else if(data.body['responseType']== 'SESSION_TIMEOUT'){

                  alert("Session timeout!Please Login Again");
                  this.loginService.storeddata(null);
                  this.router.navigate(['']);
                                                              }  else{
                                                                
                                                                console.log(data);
                                                                                                          }

             },

            error => {
              
              console.log(error);


                })

              // }

}


getComplteHandlingTime(time){

  this.completedhours = Math.floor(time / 3600);
  time %= 3600;
  this.completedminutes = Math.floor(time / 60);
  this.completedseconds = time % 60;
  this.completedhh = this.completedhours < 10 ? "0" + this.completedhours : this.completedhours.toString();
  this.completedmm= this.completedminutes < 10 ? "0" + this.completedminutes : this.completedminutes.toString();
  this.completedss = this.completedseconds < 10 ? "0" + this.completedseconds : this.completedseconds.toString();
}


startTimer(time) {
  console.log(time);
  
  this.play = true;

 console.log(this.interval);
  this.interval = setInterval(() => {
   var timer=time;
    this.hours = Math.floor(timer / 3600);
    timer %= 3600;

this.minutes = Math.floor(timer / 60);
 this.seconds = timer % 60;


this.hh = this.hours < 10 ? "0" + this.hours : this.hours.toString();
this.mm= this.minutes < 10 ? "0" + this.minutes : this.minutes.toString();
this.ss = this.seconds < 10 ? "0" + this.seconds : this.seconds.toString();
// console.log(this.hh + " " + this.mm + " " + this.ss);
    time++;

   },1000)




 }

pauseTimer() {
  
  this.play = false;
  clearInterval(this.interval);
}


getWorkItemList(){
  
  this.searchWorkItemForMyTasks = '';
  this.searchWorkItemForCompletedTasks = '';
  console.log(this.crmUser.crmUserId,this.crmUser.sessionToken)
  this.showDashboard.crmUserId=this.crmUser.crmUserId;
  this.showDashboard.sessionToken=this.crmUser.sessionToken;
  this.showDashboardServiceService.getWorkitemDetails(this.showDashboard).
  subscribe(data=>{

    if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
          
             console.log(data);

             let temp=data.body['agentDashboard'];
             let temp1=temp["agentWorkItemAssignmentList"]
            //  this.workitemDetails=new Array<WorkitemDetails>();
            this.workitemDetails = [];
            console.log(  this.workitemDetails);
             if(null!=temp1){
             for (let i = 0; i < temp1.length; i++) {
               this.workitemDetails.push(temp1[i]);
                                                   }
                                                   console.log(  this.workitemDetails);
              // this.showDashboardService.storeddata(this.workitemDetails);
                                                 }

         }
         if(data.body['responseType']== 'SESSION_TIMEOUT'){

           alert("Session timeout!Please Login Again");
           this.loginService.storeddata(null);
           this.router.navigate(['']);
                                                       }


       },
       error => {
         
         console.log(error);
       });
}


onSearchCust(workItemState){
  
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
  //   dialogConfig.position = {
  //     'top': '130',
  //     left: '130'
  // };
  if(this.workitemType=='EMAIL')
  {
    if(workItemState==="completed")
    {
  dialogConfig.data = {
    id: this.crmUser.crmUserId,
    token:  this.crmUser.sessionToken,
    sender:this.completedWorkitemDetailOutput.sender,
    wiType:this.workitemType,
   // caller:this.ivrWorkitemItemDetailOutput.callingNumber
};}
else{
  dialogConfig.data = {
    id: this.crmUser.crmUserId,
    token:  this.crmUser.sessionToken,
    sender:this.workItemDetailOutput.sender,
    wiType:this.workitemType,
   // caller:this.ivrWorkitemItemDetailOutput.callingNumber
};
}
}
else if(this.workitemType=='IVR'){
  if(workItemState==='completed')
  {
    dialogConfig.data = {
      id: this.crmUser.crmUserId,
      token:  this.crmUser.sessionToken,
      wiType:this.workitemType,
     caller:this.completedIvrWorkitemDetailOutput.callingNumber
  };
  }
  else{
    dialogConfig.data = {
      id: this.crmUser.crmUserId,
      token:  this.crmUser.sessionToken,
      wiType:this.workitemType,
     caller:this.ivrWorkitemItemDetailOutput.callingNumber
  };
  }
}
    this.dialog.open(SearchCustomerComponent,dialogConfig);
    // if(this.workitemType=='EMAIL')
    // {
    //   window.open('/searchCustomer?id='+this.id+'&token='+this.token+'&sender='+this.workItemDetailOutput.sender+'&wiType='+this.workitemType, '_blank', 'location=yes,height=570,width=900,scrollbars=yes,status=yes');
    // }
    // else if(this.workitemType=='IVR')
    // {
    //   window.open('/searchCustomer?id='+this.id+'&token='+this.token+'&caller='+this.ivrWorkitemItemDetailOutput.callingNumber+'&wiType='+this.workitemType, '_blank', 'location=yes,height=570,width=900,scrollbars=yes,status=yes');
    // }
}

openEngHistoryEmail(emailAddress){
  
  this.customerHistoryInput.crmUserId = this.crmUser.crmUserId;
  this.customerHistoryInput.sessionToken = this.crmUser.sessionToken;
  this.customerHistoryInput.emailAddress=emailAddress;
  this.customerHistoryInput.pageNumber = 0;
    this.customerHistoryInput.pageSize = 5;
  if(emailAddress!=null)
  {
this.customerHistoryService.getCustomerEmailHistory(this.customerHistoryInput).subscribe(data=>{
  
  if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
    console.log(data);
     this.customerDetails = data.body;
     this.customerDetails.customerEmailAddress = emailAddress;
       this.customerDetails.crmUserId = this.crmUser.crmUserId;
       this.customerDetails.sessionToken = this.crmUser.sessionToken;
       this.customerHistoryService.storeddata(this.customerDetails);
     const dialogConfig = new MatDialogConfig();
     dialogConfig.disableClose = true;
       dialogConfig.autoFocus = true;
     dialogConfig.data = {
       customerDetails: this.customerDetails
   };
     this.dialog.open(EngagementHistoryComponent,dialogConfig);
    // window.open('/cutomerEngagementHistory','_new','location=yes,height=570,width=1100,scrollbars=yes,status=yes');

 }
 else if(data.body['responseType']== 'SESSION_TIMEOUT'){

  alert("Session timeout!Please Login Again");
  this.loginService.storeddata(null);
  this.router.navigate(['']);
}
});
  }
  else{
    alert("No History Available");
  }
}
openEngHistoryIVR(callingNumber)
{
  
  this.customerHistoryInput.crmUserId = this.crmUser.crmUserId;
  this.customerHistoryInput.sessionToken = this.crmUser.sessionToken;
  this.customerHistoryInput.contactNumber=callingNumber;
  if(callingNumber>0)
  {
this.customerHistoryService.getCustomerIVRHistory(this.customerHistoryInput).subscribe(data=>{
  if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
    console.log(data);
     this.customerDetails = data.body;
     //this.customerHistoryService.storeddata(this.customerDetails);
    // sessionStorage.setItem("One",JSON.stringify(this.customerDetails));
     const dialogConfig = new MatDialogConfig();
     dialogConfig.disableClose = true;
       dialogConfig.autoFocus = true;
     dialogConfig.data = {
       customerDetails: this.customerDetails
   };
     this.dialog.open(EngagementHistoryComponent,dialogConfig);
    // window.open('/cutomerEngagementHistory','_new','location=yes,height=570,width=1100,scrollbars=yes,status=yes');



 }
 else if(data.body['responseType']== 'SESSION_TIMEOUT'){

  alert("Session timeout!Please Login Again");
  this.loginService.storeddata(null);
  this.router.navigate(['']);
}
});
  }
  else{
    alert("No History Available");
  }
}
openHistory(customerId){

   

   //this.customerVO = this.ivrWorkitemItemDetailOutput.customerVO;
  this.customerHistoryInput.crmUserId = this.crmUser.crmUserId;
  this.customerHistoryInput.sessionToken = this.crmUser.sessionToken;
  this.customerHistoryInput.customerId = customerId;

  //this.ivrWorkitemItemDetailOutput.customerVO.customerId;
 // this.customer.customerId;
 if(this.customerHistoryInput.customerId!=null && this.customerHistoryInput.customerId>0){
  this.customerHistoryService.getCustomerHistory(this.customerHistoryInput).
  subscribe(data=>{
    
          if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
             console.log(data);
              this.customerDetails = data.body;
              //this.customerHistoryService.storeddata(this.customerDetails);
             // sessionStorage.setItem("One",JSON.stringify(this.customerDetails));
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
              dialogConfig.data = {
                customerDetails: this.customerDetails
            };
              this.dialog.open(CutomerEnagagementHistoryComponent,dialogConfig);
             // window.open('/cutomerEngagementHistory','_new','location=yes,height=570,width=1100,scrollbars=yes,status=yes');



          }


  });
}
else {
  alert("No history available");
}
}
onHistoryTab(){
this.searchHistoryService.storeddata(this.agentAvailabilityCheck);
this.router.navigate(["searchHistory"]);
 // window.open('/searchHistory?id='+this.id+'&token='+this.token, '_blank', 'location=yes,height=570,width=900,scrollbars=yes,status=yes');
}
onDashboardClick(){

this.router.navigate(["agentDashBoard"]);
}
onLiveMonitorClick(){
  this.searchHistoryService.storeddata(this.agentAvailabilityCheck);
  this.router.navigate(["liveMonitor"]);
}

showCompletedTasks(){
  
  this.myTaskActiveDiv = false;
  this.showDashboard.crmUserId=this.crmUser.crmUserId;
  this.showDashboard.sessionToken=this.crmUser.sessionToken;
  this.completedWorkItemsService.getCompletedWorkItems(this.showDashboard).
  subscribe(data=>{

    if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){

      console.log(data);
      let temp=data.body['agentDashboard'];
      let temp1=temp["agentWorkItemAssignmentList"];
      this.completeWorkItemDetails = [];
      if(null!=temp1){
        for (let i = 0; i < temp1.length; i++) {
          // this.workitemDetails.push(temp1[i]);
          this.completeWorkItemDetails.push(temp1[i]);
        }
      }

    }
    else if(data.body['responseType']== 'SESSION_TIMEOUT'){

      alert("Session timeout!Please Login Again");
      this.loginService.storeddata(null);
      this.router.navigate(['']);
    }
  });
  this.showCompletedWorkItems = true;
  this.currentsTasks = false;

}

getCompletedWorkitems(){
  // this.serveEmailWorkItem = false;
  // this.serveIVRWorkItem = false;
  this.myTaskActiveDiv = false;
  this.showDashboard.crmUserId=this.crmUser.crmUserId;
  this.showDashboard.sessionToken=this.crmUser.sessionToken;
  this.completedWorkItemsService.getCompletedWorkItems(this.showDashboard).
  subscribe(data=>{

    if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){

      console.log(data);
      let temp=data.body['agentDashboard'];
      let temp1=temp["agentWorkItemAssignmentList"];
      this.completeWorkItemDetails = [];
      if(null!=temp1){
        for (let i = 0; i < temp1.length; i++) {
          // this.workitemDetails.push(temp1[i]);
          this.completeWorkItemDetails.push(temp1[i]);
        }
      }

    }
    else if(data.body['responseType']== 'SESSION_TIMEOUT'){

      alert("Session timeout!Please Login Again");
      this.loginService.storeddata(null);
      this.router.navigate(['']);
    }
  });
  this.showCompletedWorkItems = true;
  this.currentsTasks = false;

}

onClickMyTask(){
  // this.serveEmailWorkItem = false;
  // this.serveIVRWorkItem = false;
  this.serveCompletedIVRWorkitem = false;
  this.serveCompletedEmailWorkitem = false;
  this.serveCompletedChatWorkitem = false;
  this.serveCompletedManualWorkitem = false;
  this.serveCompletedWhatsappWorkitem = false;
    this.currentsTasks = true;
    this.showCompletedWorkItems = false;
    this.myTaskActiveDiv = true;
    this.completedSelectedIcon = null;
}

logoutUser(){
  this.loginService.endInterval();
  this.logoutService.logoutUser(this.crmUser.crmUserId, this.crmUser.sessionToken).subscribe(
    data=>{
      console.log(data);
      if(data.status==200){
        this.router.navigate(['']);
      }
      else if(data.body['responseType']== 'SESSION_TIMEOUT'){

        alert("Session timeout!Please Login Again");
        this.loginService.storeddata(null);
        this.router.navigate(['']);
      }
    }
  )

}
downloadAttachment(attachment)
{
  
  window.open(environment.baseUrl+"downloadEmailAttachment?crmUserId="+this.id+"&emailItemId="+this.workItemDetailOutput.emailItemId+"&fileName="+attachment.fileName+"&sessionToken="+this.token);
}

downloadAttachmentForManualWorkitem(attachment){
  
  window.open(environment.baseUrl+"downloadWorkItemAttachment?crmUserId="+this.id+"&manualWorkItemId="+this.workItemDetailOutput.manualWorkItemId+"&fileName="+attachment.fileName+"&sessionToken="+this.token);
}
openIcon(i,workitemId){
 console.log(i);
 console.log(this.icon2);
  this.workItemDetailInput.workItemId=workitemId;
  this.workItemDetailInput.crmUserId=this.crmUser.crmUserId;
  this.workItemDetailInput.sessionToken=this.crmUser.sessionToken;
  this.linkedWorkItemDetailService.getLinkedWorkitemContent(this.workItemDetailInput).
  subscribe(data=>{

    if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
        
      console.log(data);
      this.linkedWorkItemDetailOutput=data.body['emailWorkItem'];

    }
  });

  this.icon2[i] = !this.icon2[i];
}

pushStatus(){
  this.icon2.push(false);
}

closedIcon(i){
  console.log(i);
  this.icon2[i] = !this.icon2[i];
}


// getTextAreaValue(event){
//   this.textareaValue = event.target.value;
// }

onSubmitRemarks(workitemId, assignedAgent, workitemStatus, workitemType){
  
 // console.log(this.textareaValue);

  this.ivrWorkitemRemarksInput.callNotes = this.textareaValue;
  this.ivrWorkitemRemarksInput.crmUserId = this.crmUser.crmUserId;
  this.ivrWorkitemRemarksInput.workItemId = workitemId;

  this.ivrWorkitemRemarksInput.sessionToken = this.crmUser.sessionToken;


  // if(workitemType!=WorkitemTypeEnum.WHATSAPP){
  //   this.ivrWorkitemRemarksInput.workItemId = workitemId;
  //   this.ivrWorkitemRemarksInput.additionalInfoVO.rateMappingStatus = this.rateLoadingStatus;
  //   this.ivrWorkitemRemarksInput.additionalInfoVO.ratePlanText = this.rateRemarks; 
  //   this.ivrWorkitemRemarksInput.additionalInfoVO.statusGDS = this.gdsStatus;
  //   this.ivrWorkitemRemarksInput.additionalInfoVO.transactionCount = this.transactionCount;
  //   this.ivrWorkitemRemarksInput.additionalInfoVO.workItemId = workitemId;
  // }       

  // if(workItemType==WorkitemTypeEnum.CHAT){

  // }

  if((this.textareaValue==null || this.textareaValue==undefined) ){
    this.remarksNotEntered = true;
    return;
  }
    // else{
    //   this.callDialogPopUpMessage("Please enter category or remarks");
    //   return;
    // }

  this.ivrWorkitemRemarksService.updateIVRWorkitemRemarks(this.ivrWorkitemRemarksInput).
  subscribe(data=>{
    this.remarksNotEntered = false;
    this.categoryNotSelectedForEmail = false;
    this.categoryNotSelectedForIVR = false;
    this.categoryNotSelectedForChat = false;
    
        if(data.status==200 &&  data.body["responseType"]=="SUCCESS" ){
          

          let now = new Date();


          if(this.whatsappWorkitemDetails.workItemComments==null){
            this.whatsappWorkitemDetails.workItemComments = [];
          }

          if(this.workItemDetailOutput.workItemComments==null){
            this.workItemDetailOutput.workItemComments = [];
          }
          if((this.textareaValue!=null || this.textareaValue!=undefined) && workitemType==WorkitemTypeEnum.WHATSAPP){
            this.whatsappWorkitemDetails.workItemComments.push(new IVRWorkitemAddedComments(assignedAgent,this.textareaValue,now.toString()));
            console.log(data);
          }
          else
          if(this.textareaValue!=null || this.textareaValue!=undefined){
            this.workItemDetailOutput.workItemComments.push(new IVRWorkitemAddedComments(assignedAgent,this.textareaValue,now.toString()));
            console.log(data);
          }

        }
        //this.callDialogPopUpMessage("Workitem saved successfully","Save Workitem");
        this.textareaValue=null;
        this.gdsStatus = null;
        this.rateRemarks = null;
        this.transactionCount = null;
        this.rateLoadingStatus = null;
        //this.getServedWorkItem(workitemId,workitemType,workitemStatus);
      });
}
openUploadDialogFw()
{
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width="400px";
   dialogConfig.height="250px";
  //   dialogConfig.position = {
  //     'top': '130',
  //     left: '130'
  // };
  dialogConfig.data = {
    id: this.crmUser.crmUserId,
    token:  this.token,
    itemId:this.workItemDetailOutput.emailItemId,
};
    this.dialog.open(UploadAttachmentComponent,dialogConfig).afterClosed().
    subscribe(files => {
      for (var i = 0; i < files.length; i++) {
        this.fwFiles.push(files[i]);
      }

    });
}
openUploadDialog()
{
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width="400px";
   dialogConfig.height="250px";
  //   dialogConfig.position = {
  //     'top': '130',
  //     left: '130'
  // };
  dialogConfig.data = {
    id: this.crmUser.crmUserId,
    token:  this.token,
    itemId:this.workItemDetailOutput.emailItemId,
};
    this.dialog.open(UploadAttachmentComponent,dialogConfig).afterClosed().
    subscribe(files => {
      for (var i = 0; i < files.length; i++) {
        this.files.push(files[i]);
      }

    });
}
deleteFiles(file)
{
  this.deleteAttachment= new Attachment();
  this.deleteAttachment.token=this.token;
  this.deleteAttachment.userId=this.crmUser.crmUserId;
  this.deleteAttachment.fileName=file.name;
  this.deleteAttachment.emailItemId=this.workItemDetailOutput.emailItemId;
  this.emailReplyService.deleteAttachment(this.deleteAttachment).subscribe(data=>{
    console.log(data);
  });
this.files.splice(file,1);
}
deleteFilesFw(file)
{
  this.deleteAttachment= new Attachment();
  this.deleteAttachment.token=this.token;
  this.deleteAttachment.userId=this.crmUser.crmUserId;
  this.deleteAttachment.fileName=file.name;
  this.deleteAttachment.emailItemId=this.workItemDetailOutput.emailItemId;
  this.emailReplyService.deleteAttachment(this.deleteAttachment).subscribe(data=>{
    console.log(data);
  });
this.fwFiles.splice(file,1);
}
newMail()
{
  // let paramModel = {
  //   crmUserId: this.id,
  //   emailItemId: 1836,
  //   sessionToken: this.token,
  //   sender:this.workItemDetailOutput.to,
  //   receiver:this.workItemDetailOutput.sender
  // }
  const dialogConfig = new MatDialogConfig();

  //   dialogConfig. hasBackdrop= false;
     //,hideOnCascade: false};
     dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
    dialogConfig.data={
      crmUserId: this.id,
    sessionToken: this.token,
    };
    //dialogConfig.height="800px";
    dialogConfig.width="800px"

   // window.open('/emailreply?id='+this.id+'&token='+this.token+'&itemId='+this.workItemDetailOutput.emailItemId+'&replyType='+this.replyType, '_blank', 'location=yes,height=570,width=998,scrollbars=yes,status=yes');
  // this.dialogRef.updateSize('80%', '80%');
    this.dialogRef = this.dialog.open(EmailreplyComponent,dialogConfig);
      // this.dialogRef.updatePosition({top: '10%', left: '15%'});



}

onChangeCategoryForCompletedChat(){
  
  if(this.selectedCategoryForChat>0){
   var list = this.workitemCategory.filter(x => x.id == this.selectedCategoryForChat);

 var temp=list[0];
 this.workitemSubCategoryForChat=(temp['subCategories']);


   }else{

     this.workitemSubCategoryForChat=null;
     this.selectedSubCategoryForChat=0;
   }
}
onChangeCategory(){
  
 if(this.selectedCategory>0){
  var list = this.workitemCategory.filter(x => x.id == this.selectedCategory);

var temp=list[0];
this.workitemSubCategory=(temp['subCategories']);


  }else{

    this.workitemSubCategory=null;
    this.selectedSubCategory=0;
  }

}

myFunction1(){
  this.replyType="REPLY";
  this.emailReplyService.populateMailData(this.crmUser.crmUserId,this.workItemDetailOutput.emailItemId,this.token,this.replyType).subscribe(
    data=>{
      
      if(data.status==200){
        this.emailData=data.body;
        console.log("emaildata>>"+this.emailData);
        this.form.get('from').setValue(this.emailData.fromMailboxId);
        this.fromMailBoxId=this.emailData.fromMailboxes;
        this.form.get('receiver').setValue(this.emailData.from);
        this.form.get('sub').setValue("RE:"+this.emailData.subject);
        this.form.get('content').setValue(this.emailData.mailContent);
        this.form.get('cc').setValue(this.emailData.cc);
      }
    }
  );
  this.IsHiddenReplyAll=true;
  this.IsHidden= !this.IsHidden;
}
myFunction2(){
  this.replyType="FORWARD";
  this.emailReplyService.populateMailData(this.crmUser.crmUserId,this.workItemDetailOutput.emailItemId,this.token,this.replyType).subscribe(
    data=>{
      
      if(data.status==200){
        this.emailData=data.body;
        console.log("emaildata>>"+this.emailData.subject);
        this.fwform.get('fwfrom').setValue(this.emailData.fromMailboxId);
        this.fwfromMailBoxId=this.emailData.fromMailboxes;
        this.fwform.get('fwreceiver').setValue(this.emailData.from);
        this.fwform.get('fwcc').setValue(this.emailData.cc);
        this.fwform.get('fwsub').setValue("FW:"+this.emailData.subject);
        this.fwform.get('fwcontent').setValue(this.emailData.mailContent);

      }
    }
  );
  this.IsHidden=true;
  this.IsHiddenReplyAll= !this.IsHiddenReplyAll;
}
callDialogPopUpMessage(  message,title){
  const dialogConfig= new MatDialogConfig();
  dialogConfig.disableClose=true;
  dialogConfig.autoFocus=true;
  dialogConfig.width="60%";
  dialogConfig.height="40%";
  dialogConfig.data = {
   popUpTitle:title,
   messageToBeDisplayed:message
};
  this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);

}


ngOnDestroy(){
this.pauseTimer();
}

openEmailHistory(workitemId){
  this.emailHistoryInput.crmUserId = this.crmUser.crmUserId;
  //this.emailHistoryInput.emailItemId = this.workItemDetailOutput.emailItemId;
  this.emailHistoryInput.emailItemId = workitemId;
  this.emailHistoryInput.sessionToken = this.crmUser.sessionToken;


    this.emailHistoryService.getEmailHistory(this.emailHistoryInput).
    subscribe(data=>{
      
            if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
               console.log(data);
               this.emailHistoryOutput = data.body['previousReplies'];
               if(this.emailHistoryOutput.length==0){
                this.callDialogPopUpMessage("No reply history available", "Reply History");
               }
               else{
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.height = "60%";
                dialogConfig.width = "60%";
                dialogConfig.data = {
                emailHistoryOutput: this.emailHistoryOutput
              };
                 this.dialog.open(EmailReplyHistoryComponent,dialogConfig);


            }

            }


    });
}
onSubmitForward()
{
  
  let formData = this.fwform.value;
  this.fwclicked=true;
  this.submitted=true;
  console.log(formData);
  this.emailDataTobeSent= new EmailData();
  this.emailDataTobeSent.crmUserId= this.crmUser.crmUserId;
  this.emailDataTobeSent.sessionToken = this.token;
  this.emailDataTobeSent.receiver = this.fwform.value.fwreceiver;
  this.emailDataTobeSent.from = this.fwform.value.fwfrom;
  this.emailDataTobeSent.cc=this.fwform.value.fwcc;
  this.emailDataTobeSent.bcc=this.fwform.value.fwbcc;
  this.emailDataTobeSent.mailContent=this.fwform.value.fwcontent;
  this.emailDataTobeSent.subject=this.fwform.value.fwsub;
  this.emailDataTobeSent.emailItemId=this.workItemDetailOutput.emailItemId;
  if (this.fwform.valid) {
    this.emailReplyService.sendReply(this.emailDataTobeSent).subscribe(data=>{
      
            if(data.status==200 && data.body["responseType"]=="SUCCESS"){
              
              // this.isLoading=false;
              // window.close();
              alert("Mail Sent");
              this.fwclicked=false;
            }
             else{
           // this.isLoading=false;
           // window.close();
           alert("Problem In Sending Mail");
           this.fwclicked=false;
             }
             this.IsHiddenReplyAll=!this.IsHiddenReplyAll;
            });

}
else{
  this.fwclicked=false;
}
}
onSubmitReply(){
  
  let formData = this.form.value;
  this.clicked=true;
  this.submitted=true;
  console.log(formData);
  this.emailDataTobeSent= new EmailData();
  this.emailDataTobeSent.crmUserId= this.crmUser.crmUserId;
  this.emailDataTobeSent.sessionToken = this.token;
  this.emailDataTobeSent.receiver = this.form.value.receiver;
  this.emailDataTobeSent.from = this.form.value.from;
  this.emailDataTobeSent.mailContent=this.form.value.content;
  this.emailDataTobeSent.subject=this.form.value.sub;
  this.emailDataTobeSent.emailItemId=this.workItemDetailOutput.emailItemId;
  this.emailDataTobeSent.cc=this.form.value.cc;
  this.emailDataTobeSent.bcc=this.form.value.bcc;
  if (this.form.valid) {
    this.emailReplyService.sendReply(this.emailDataTobeSent).subscribe(data=>{
      
            if(data.status==200 && data.body["responseType"]=="SUCCESS"){
              
              // this.isLoading=false;
              // window.close();
              alert("Mail Sent");
              this.clicked=false;
            }
             else{
           // this.isLoading=false;
           // window.close();
           alert("Problem In Sending Mail");
           this.clicked=false;
             }
             this.IsHidden= !this.IsHidden;
            });

}
else
{
  this.clicked=false;
}
}
close(){
  this.IsHidden= !this.IsHidden;
}
closeFw(){
this.IsHiddenReplyAll=!this.IsHiddenReplyAll;
}
openAgentProfile(){


  this.agentProfileService.getAgentDetails(this.crmUser.crmUserId, this.crmUser.sessionToken).subscribe(
    data=>{
      

      console.log(data);
      if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
        this.agentProfile.name = data.body['name'];
        this.agentProfile.emailId = data.body['emailAddress'];
        this.agentProfile.skills = data.body['skills'];
        this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = "auto";
        dialogConfig.width = "600px";
        dialogConfig.data = {
        agentProfile: this.agentProfile
        };
         this.dialog.open(AgentProfileComponent,dialogConfig);

      }
      else if(data.body['responseType']== 'SESSION_TIMEOUT'){

        alert("Session timeout!Please Login Again");
        this.loginService.storeddata(null);
        this.router.navigate(['']);
                                                    }
    }
  );


}

changePassword(){
  
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data={
      sessionToken:this.token,
      userId:this.id
    };
    // dialogConfig.maxWidth='400px';
  this.dialog.open(ChangePasswordComponent,dialogConfig);
}

clearSearchFilterMyTasks(){
  this.searchWorkItemForMyTasks = '';
}
clearSearchFilterCompletedTasks(){
  this.searchWorkItemForCompletedTasks = '';
}


reminder(workitemType){
  // 
  // this.getReminderRequest.crmUserId = this.id;
  // this.getReminderRequest.sessionToken = this.token;
  // this.getReminderRequest.searchByUser = false;
  // this.getReminderRequest.searchCompleted = true;
  // this.getReminderRequest.searchCriteriaId = 29300;
  // this.reminderConfigurationService.getReminder(this.getReminderRequest).subscribe(data=>{
  //   console.log(data.body);
  // });
   
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  let workitemToBeSent:any;
  if(workitemType==WorkitemTypeEnum.WHATSAPP){
    workitemToBeSent = this.whatsappWorkitemDetails;
  }
  else{
    workitemToBeSent = this.workItemDetailOutput;
  }
  dialogConfig.data={
    crmUserId: this.id,
    sessionToken: this.token,
    detailedWorkitem:this.workitemServed?workitemToBeSent:null,
    isServed:this.workitemServed
  };
  this.dialog.open(ReminderConfigurationComponent,dialogConfig);
 }

 openWhatsappConversationHistory(contactNumber){
  this.whatsappWorkitemService.getConversationHistoryByNumber(this.crmUser.crmUserId,contactNumber,this.crmUser.sessionToken).subscribe(
    data=>{
      
      if(data.status==200 && data.body['responseType']=="SUCCESS"){
        console.log(data);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
          crmUserId: this.id,
          sessionToken: this.token,
          coversationArray:data.body['whatsAppWorkItemDetailsList']
        };
        this.dialog.open(WhatsappChatHistoryComponent,dialogConfig)
      }
    }
  );

 }


//  onAddCustomFields(){
//   const dialogConfig = new MatDialogConfig();
//   dialogConfig.disableClose = true;
//   dialogConfig.autoFocus = true;
//   dialogConfig.data={
//     crmUserId: this.id,
//     sessionToken: this.token,
//   };

//   this.dialog.open(WorkItemCustomFieldsComponent,dialogConfig);
//  }

 onAddCustomFields(){
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data={
    crmUserId: this.id,
    sessionToken: this.token,
    customFieldsList:this.customFieldsList,
    workItemId:this.currentWorkItemId,
    emailItemId:this.workItemDetailOutput.emailItemId,
  };

  this.dialog.open(WorkItemCustomFieldsComponent,dialogConfig);
 }

 getCustomFieldsForWorkItem(){
   
   this.customFieldsService.getCustomFieldsForWorkItem(this.workItemDetailInput).subscribe(data=>{
      
      console.log(data);
      this.addedCustomFieldsListForWorkitem = data.body['workItemCustomFieldVOS'];
   });
 }


}
