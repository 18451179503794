import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomFieldsForWorkitem } from 'src/app/models/CustomFieldsForWorkitem.model';
import { CustomFieldsService } from 'src/app/services/CustomFields.service';
import { WorkitemItemDetailInput } from 'src/app/models/workitemDetailinput.model';

@Component({
  selector: 'app-work-item-custom-fields',
  templateUrl: './work-item-custom-fields.component.html',
  styleUrls: ['./work-item-custom-fields.component.css']
})
export class WorkItemCustomFieldsComponent implements OnInit {

  id:number;
  token:string;
  customFieldsList:Array<any> = new Array<any>();
  customFieldsForm:FormGroup;
  fieldListToSave:Array<any> = new Array<any>();
  workItemId:number;
  customFieldsForWorkitem:CustomFieldsForWorkitem;
  customFieldsActive:boolean = true;
  workItemDetailInput: WorkitemItemDetailInput = new WorkitemItemDetailInput();

  addedCustomFieldsListForWorkitem = [];
  constructor(
    private router:Router,
    private loginService:LoginService,
    private dialog:MatDialog,
    private dialogRef: MatDialogRef<WorkItemCustomFieldsComponent>,
    private formBuilder: FormBuilder,
    private customFieldsService:CustomFieldsService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    
      this.id = data['crmUserId'];
      this.token = data['sessionToken']
      this.customFieldsList = data['customFieldsList'];
      this.workItemId = data['workItemId'];
      this.workItemDetailInput.crmUserId = this.id;
      this.workItemDetailInput.sessionToken = this.token;
      this.workItemDetailInput.workItemId = this.workItemId;
      this.getCustomFieldsForWorkItem();

   }

  ngOnInit() {

    this.setControls();
    this.customFieldsForWorkitem = new CustomFieldsForWorkitem();
    
    
  }

  noFieldsAdded:boolean = false;
  getCustomFieldsForWorkItem(){
    
    this.customFieldsService.getCustomFieldsForWorkItem(this.workItemDetailInput).subscribe(data=>{
       
       console.log(data);
       this.addedCustomFieldsListForWorkitem = data.body['workItemCustomFieldVOS'];
       if(this.addedCustomFieldsListForWorkitem.length<1){
         this.noFieldsAdded = true;
       }
       else{
         this.noFieldsAdded = false;
       }
    });
  }

  close(){
    this.dialogRef.close();
  }
  onSubmitCustomField(){
    
    this.fieldListToSave.push({'field':this.customFieldsForm.value.selectedField,'value':this.customFieldsForm.value.value});
    this.setControls();
  }

  setControls(){
    this.customFieldsForm =this.formBuilder.group({
      value: new FormControl('',[Validators.required]),
      selectedField:new FormControl('Select Type',[Validators.required]),
    });
  }

  onSubmit(){
    
    this.customFieldsForWorkitem.crmUserId = this.id;
    this.customFieldsForWorkitem.sessionToken = this.token;
    this.customFieldsForWorkitem.workItemId = this.workItemId;
    this.customFieldsForWorkitem.workItemCustomFieldVOS = new Array();
    for(let i=0;i<this.fieldListToSave.length;i++){
      this.customFieldsForWorkitem.workItemCustomFieldVOS.push({
        "customFieldId": this.fieldListToSave[i].field.customFieldId,
        "customFieldName":this.fieldListToSave[i].field.customFieldName,
        "customFieldValue":this.fieldListToSave[i].value,
        "workItemId":this.workItemId
      });

      this.customFieldsService.updateCustomFieldsForWorkItem(this.customFieldsForWorkitem).subscribe(data=>{
        
        this.getCustomFieldsForWorkItem();
        console.log(data);
        this.close();
      });
      // this.customFieldsForWorkitem.workItemCustomFieldVOS[i].customFieldId = this.customFieldsList[i].field.customFieldId;
      // this.customFieldsForWorkitem.workItemCustomFieldVOS[i].customFieldName = this.customFieldsList[i].field.customFieldName;
      // this.customFieldsForWorkitem.workItemCustomFieldVOS[i].customFieldValue = this.customFieldsList[i].value;
    }
  }
  onCustomFieldsClick(){
    this.customFieldsActive = true;
  }

  onAddCustomFieldsClick(){
    this.customFieldsActive = false;
  }

}
