import { Injectable } from "@angular/core";
import { environment } from '../environment';
import { HttpClient } from '@angular/common/http';
import { CreateWorkitem } from '../models/CreateWorkitem.model';

@Injectable()
export class CreateManualWorkitemService{
  private url = environment.baseUrl+'submitManualWorkItemDetails';

  constructor(private http:HttpClient){

  }

  createManualWorkitem(createWorkitem:CreateWorkitem){
    
    return this.http.post(this.url,createWorkitem,{observe:'response'});
  }
}
