import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { Customer } from '../models/customer.model';


@Injectable()
export class AddCustomerService{
  
     public StoredData: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);  
     currentData = this.StoredData.asObservable();
       storeddata(data: any) {
         this.StoredData.next(data)
       }
       private Url=environment.baseUrl+'addNewCustomerProfile'
       private searchUrl=environment.baseUrl+'searchCustomerProfile'
       private editUrl=environment.baseUrl+'updateCustomerProfile'
constructor(private http:HttpClient){
    
}
addCustomer(customerVO:Customer){
    
    return this.http.post<Customer>(this.Url, {'crmUserId':customerVO.crmUserId,'sessionToken':customerVO.sessionToken,customerVO}, {observe: 'response'})
   
}  
searchCustomer(customerVO:Customer){
  
  return this.http.post<Customer[]>(this.searchUrl, {'crmUserId':customerVO.crmUserId,'sessionToken':customerVO.sessionToken,customerVO}, {observe: 'response'})
 
} 
editCustomer(customerVO:Customer){
  
  return this.http.post<Customer>(this.editUrl, {'crmUserId':customerVO.crmUserId,'customerId':customerVO.customerId,'sessionToken':customerVO.sessionToken,customerVO}, {observe: 'response'})
 
}
}
