export class CompleteSystemReport{
    public accessedBy:number;
    public crmUserId:number;
    public endDate:string;
    public frequency:string;
    public reportFilterType:string;
    public reportId:number;
    public reportType:string;
    public selectedFilterId:number;
    public sessionToken:string;
    public startDate:string;
    public reportWorkItemType:string;
    public multiselectedFilterIds:Array<any>;
}