
import { Injectable } from "@angular/core";
import { environment } from '../environment';
import { HttpClient } from '@angular/common/http';
import { ShowDashboard } from '../models/showDashboard.model';
import { BehaviorSubject } from 'rxjs';
import { AdminWorkitemModel } from '../models/AdminWorkitem.model';

@Injectable()
export class LoadAdminWorkitemService{


  private urlForOpenWorkitems = environment.baseUrl+'loadAdminDashboard';
  private urlForClosedWorkitems = environment.baseUrl+'loadClosedAdminWorkItemDashboard';

  public StoredData: BehaviorSubject<AdminWorkitemModel[]> = new BehaviorSubject<AdminWorkitemModel[]>([]);
  currentData = this.StoredData.asObservable();
  storeddata(data: any) {
    this.StoredData.next(data)
  }
  constructor(private http:HttpClient){

  }


  getAdminOpenWorkitems(showDashBoard:ShowDashboard){
    
    return  this.http.post(this.urlForOpenWorkitems,showDashBoard,{observe: 'response'});
  }

  getAdminClosedWorkitems(showDashBoard:ShowDashboard){
    return this.http.post(this.urlForClosedWorkitems,showDashBoard,{observe: 'response'});
  }
}
