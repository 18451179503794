import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatDialogRef, MatDialog,MAT_DIALOG_DATA,MatDialogConfig } from '@angular/material/dialog';
import {ActivatedRoute,Router} from '@angular/router';
import { EmailData } from '../models/emailData.model';
import { EmailReplyService } from '../services/emailReply.service';
import { UploadAttachmentComponent } from '../uploadAttachment/uploadAttachment.component';
import {Overlay} from '@angular/cdk/overlay';
import { UploadRequest } from '../UploadRequest.enum';
import { Attachment } from '../models/attachment.model';
@Component({
  selector: 'app-emailreply',
  templateUrl: './emailreply.component.html',
  styleUrls: ['./emailreply.component.css']
})
export class EmailreplyComponent implements OnInit {
  
  public emailData: any;
  public emailDataTobeSent:EmailData;
  form: FormGroup;
  fromMailBoxId:string;
  to:string;
  receiver:string;
  userId:number;
  token:string;
  mailContent:string;
  subject:string;
  submitted:boolean=false;
  emailItemId:number;
  isLoading:boolean=false;
  replyType:string;
  file:File;
  files:File[]=[];
  mailBoxes:any[]=[];
  selectedMailBox:any;  
  clicked:boolean=false;
  randomNumber:any;
  deleteAttachment:Attachment;
  config1: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '18rem',
    maxHeight: '18rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    sanitize: false,
     toolbarPosition: 'top',
    outline: true,
    defaultFontName: 'Arial',
    defaultFontSize: '3',
     showToolbar: true,
  };


  constructor(private formBuilder: FormBuilder,private dialog: MatDialog,
   private route:ActivatedRoute,private emailReplyService:EmailReplyService,
   private dialogRef: MatDialogRef<EmailreplyComponent>,overlay: Overlay,
   @Inject(MAT_DIALOG_DATA) data) {
        this.userId = data.crmUserId;
        this.token = data.sessionToken;


        let range = 100000;
        this.randomNumber = Math.floor(Math.random()*Math.floor(range));
        console.log("random number is"+this.randomNumber);
     }

  ngOnInit() {
 
this.emailReplyService.populateMailData(this.userId,this.emailItemId,this.token,this.replyType).subscribe(
  data=>{
    
    console.log(data);
    if(data.status==200){
      this.emailData=data.body;
      this.mailBoxes=this.emailData.fromMailboxes;
      this.selectedMailBox=this.emailData.fromMailboxes[0].id;
      this.form.get('content').setValue(this.emailData.fromMailboxes[0].templateText);
    //  this.to=this.emailData.from;
     // this.subject="Re:"+this.emailData.subject;
     // this.mailContent=this.emailData.mailContent;

    }
  }
);
this.files=[];
    this.form = this.formBuilder.group({
     receiver: ['',[Validators.required, Validators.email]],
      from: [''],
      cc: ['', [Validators.pattern(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/)]],
      bcc:['', [Validators.pattern(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/)]],
      //bcc:['', [Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/)]],
      sub: ['',[Validators.required,Validators.minLength(4)]],
      content: ['',[Validators.required,Validators.minLength(4)]]
    });
  
    //this.setValue();
  }

  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(',').map(e=>e.trim());
    const forbidden = emails.some(email => Validators.email(new FormControl(email)));
    return forbidden ? { 'cc': { value: control.value } } : null;
  };

  setValue(): void {
    this.form.patchValue(this.emailData);

  }
 
  get f() { return this.form.controls; }
 
  onSubmit() {
   
   this.clicked=true;
    let formData = this.form.value;
    this.submitted=true;
    console.log(formData);
    this.emailDataTobeSent= new EmailData();
    this.emailDataTobeSent.crmUserId= this.userId;
    this.emailDataTobeSent.sessionToken = this.token;
    this.emailDataTobeSent.receiver = this.form.value.receiver;
    this.emailDataTobeSent.from = this.selectedMailBox;
    this.emailDataTobeSent.mailContent=this.form.value.content;
    this.emailDataTobeSent.subject=this.form.value.sub;
    this.emailDataTobeSent.emailItemId=this.emailItemId;
    this.emailDataTobeSent.cc=this.form.value.cc;
    this.emailDataTobeSent.bcc=this.form.value.bcc;
    this.emailDataTobeSent.newMailIdentifier = this.randomNumber;
    if (this.form.valid) {
      this.isLoading=true;
      this.emailReplyService.sendEmail(this.emailDataTobeSent).subscribe(data=>{
        
              if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
                
                 this.isLoading=false;
                 this.dialogRef.close();
                 alert("Mail Sent");
                // window.close();
              }
               else{
              this.isLoading=false;
              this.dialogRef.close();
              alert("Problem in sending mail.");
             // window.close();
               }

               //this.randomNumber=0;
              });

}
}

deleteFiles(file)
{
  this.deleteAttachment= new Attachment();
  this.deleteAttachment.token=this.token;
  this.deleteAttachment.userId=this.userId;
  this.deleteAttachment.fileName=file.name;
  this.deleteAttachment.emailItemId=this.randomNumber;
  this.emailReplyService.deleteAttachment(this.deleteAttachment).subscribe(data=>{
    console.log(data);
  });
this.files.splice(file,1);
}


openUploadDialog()
{
  
 
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.width="300px";
  dialogConfig.height="200px";
 
  //   dialogConfig.position = {
  //     'top': '130',
  //     left: '130'
  // };
  dialogConfig.data = {
    id: this.userId,
    token:  this.token,
    itemId:this.emailItemId,
    requestFrom:UploadRequest.NEWMAIL,
    randomNumber:this.randomNumber
};
    this.dialog.open(UploadAttachmentComponent,dialogConfig).afterClosed().

    subscribe(files => {
      
      for (var i = 0; i < files.length; i++) { 
        this.files.push(files[i]);
      }

    });
}
close()
    {
      this.dialogRef.close();
    }
    onChangeMailBox()
    {
if(this.selectedMailBox>0)
{
  var list = this.mailBoxes.filter(x => x.id == this.selectedMailBox);
  var temp=list[0];
this.form.get('content').setValue("  "+temp['templateText']);
}
}
}