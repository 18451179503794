import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { WorkitemItemDetailOutput } from 'src/app/models/workitemDetailOutput.model';
import { WorkItemCategory } from 'src/app/models/workitemcategory.model';
import { ReassignWorkitemInputModel } from 'src/app/models/ReassignWorkitemInput.model';
import { LiveMontorService } from 'src/app/services/LiveMonitor.service';
import { WorkitemSavedMessageComponent } from '../../messagePopUp/workitem-saved-message/workitem-saved-message.component';
import { WorkitemItemDetailInput } from 'src/app/models/workitemDetailinput.model';
import { LinkedWorkItemDetailService } from 'src/app/services/linkedworkitemdetails.service';
import { EmailReplyHistoryComponent } from 'src/app/email-reply-history/email-reply-history.component';
import { EmailHistoryInput } from 'src/app/models/EmailHistoryInput.model';
import { EmailHistoryService } from 'src/app/services/emailHistory.service';
import { EmailHistoryOutput } from 'src/app/models/EmailHistoryOutput.model';
import { ReopenConfirmationComponent } from './reopen-confirmation/reopen-confirmation.component';
import { LoginService } from 'src/app/services/login.service';
import { environment } from 'src/app/environment';
import { HttpClient } from '@angular/common/http';
import { DownloadAttachmentService } from 'src/app/services/DownloadAttachment.service';
import { WorkitemCompleteHistoryComponent } from 'src/app/workitem-complete-history/workitem-complete-history.component';
import { CompleteWorkitemHistoryService } from 'src/app/services/CompleteWorkitemHistory.service';
import { CompleteWorkitemConfirmationMessageComponent } from 'src/app/messagePopUp/complete-workitem-confirmation-message/complete-workitem-confirmation-message.component';
import { WorkItemStatusEnum } from 'src/app/workitemstatusenum';
import { WorkItemStatusChangeInput } from 'src/app/models/workitemStatusChangeInput.model';
import { WorkItemStatusChangeService } from 'src/app/services/workitemStatusChange.service';
import { CrmUser } from 'src/app/models/crmuser.model';
import { SendWICompletionMailComponent } from 'src/app/sendWICompletionMail/sendWICompletionMail.component';
@Component({
  selector: 'app-reassign-workitem',
  templateUrl: './reassign-workitem.component.html',
  styleUrls: ['./reassign-workitem.component.css']
})
export class ReassignWorkitemComponent implements OnInit {
  id:number;
  token:string;
  usersList:any[];
  workitemFromQueue:WorkitemItemDetailOutput;
  selectedCategory:number;

workItemDetailOutput:any = new Object();
  workitemSubCategory:Array<any>;
  selectedSubCategory:number;
  selectedAgent:number = 0;
  workitemCategory:Array<WorkItemCategory> = new Array<WorkItemCategory>();
  reassignWorkitemModelInput:ReassignWorkitemInputModel;
  previousAgent:Array<any> = new Array<any>();
  icon2:Array<boolean> = new Array<boolean>();
  workItemDetailInput: WorkitemItemDetailInput;
  linkedWorkItemDetailOutput: WorkitemItemDetailOutput;
  showEmailWorkitem:boolean;
  showIVRWorkitem:boolean;
  showChatWorkitem:boolean;
  showManualWorkitem:boolean;
  showWhatsappWorkitem:boolean;
  emailTo = [];
  emailToForDisplay = "";
  emailCc = [];
  emailCcForDisplay = "";
  isAgentSelected = true;
  emailHistoryInput:EmailHistoryInput;
  emailHistoryOutput:Array<EmailHistoryOutput>;
  whatsappMessagesList:Array<any> = new Array<any>();
  workItemIdForCompletion:number;
  workItemStatusChangeInput:WorkItemStatusChangeInput;
  crmUser:CrmUser = new CrmUser();
  dialogRefer: MatDialogRef<SendWICompletionMailComponent> | null;
  constructor(
    private router:Router,
    private dialog:MatDialog,
    private route:ActivatedRoute,
    private dialogRef: MatDialogRef<ReassignWorkitemComponent>,
    private liveMonitorService:LiveMontorService,
    private linkedWorkItemDetailService:LinkedWorkItemDetailService,
    private emailHistoryService:EmailHistoryService,
    private loginService:LoginService,
    private http:HttpClient,
    private downloadAttachmentService:DownloadAttachmentService,
    private completeWorkitemHistoryService:CompleteWorkitemHistoryService,
    private workItemStatusChangeService:WorkItemStatusChangeService,
    @Inject(MAT_DIALOG_DATA) data
  )
  {
    
    this.id = data['id'];
    this.token = data['token'];

    this.usersList = data['usersList'];
    this.workitemCategory = data['workitemCategory'];
    this.workItemDetailOutput = data['workitemDetailOutput'];
    this.workitemFromQueue = data['workitemFromQueue'];
    this.whatsappMessagesList = data['whatsappMessagesList'];
    if(this.workitemFromQueue['workItemType']=="EMAIL"){
      this.showEmailWorkitem = true;
      this.showIVRWorkitem = false;
      this.showManualWorkitem = false;
      this.showChatWorkitem = false;
      this.showWhatsappWorkitem = false;
      this.emailToForDisplay = this.workItemDetailOutput.to;
    this.emailCcForDisplay = this.workItemDetailOutput.cc;
    this.emailTo = this.emailToForDisplay.split(",");
    this.emailCc = this.emailCcForDisplay.split(",");
    this.emailToForDisplay = "";
    this.emailCcForDisplay = "";
    for(let i=0;i<this.emailTo.length;i++){
      if(i<this.emailTo.length-1){
      this.emailToForDisplay = this.emailToForDisplay+this.emailTo[i]+", ";
      }
      else{
        this.emailToForDisplay = this.emailToForDisplay+this.emailTo[i];
      }
    }
    for(let i=0;i<this.emailCc.length;i++){
      if(i<this.emailCc.length-1){
      this.emailCcForDisplay = this.emailCcForDisplay+this.emailCc[i]+", ";
      }
      else{
        this.emailCcForDisplay = this.emailCcForDisplay+this.emailCc[i];
      }
    }
    }
    else if(this.workitemFromQueue['workItemType']=="MANUAL"){
      this.showEmailWorkitem = false;
      this.showIVRWorkitem = false;
      this.showManualWorkitem = true;
      this.showChatWorkitem = false;
      this.showWhatsappWorkitem = false;
    }
    else if(this.workitemFromQueue['workItemType']=="IVR"){
      this.showEmailWorkitem = false;
      this.showManualWorkitem = false;
      this.showIVRWorkitem = true;
      this.showChatWorkitem = false;
      this.showWhatsappWorkitem = false;
    }
    else if(this.workitemFromQueue['workItemType']=="CHAT"){
      this.showEmailWorkitem = false;
      this.showManualWorkitem = false;
      this.showIVRWorkitem = false;
      this.showChatWorkitem = true;
      this.showWhatsappWorkitem = false;
    }

    else if(this.workitemFromQueue['workItemType']=="WHATSAPP"){
      this.showEmailWorkitem = false;
      this.showManualWorkitem = false;
      this.showIVRWorkitem = false;
      this.showChatWorkitem = false;
      this.showWhatsappWorkitem = true;
    }



    console.log("the workitemQueue is >>>>>"+this.workitemFromQueue);
  }

  ngOnInit() {
    this.selectedCategory = 0;
    this.selectedSubCategory = 0;
    this.reassignWorkitemModelInput = new ReassignWorkitemInputModel();
    this.workItemDetailInput = new WorkitemItemDetailInput();
    this.linkedWorkItemDetailOutput = new WorkitemItemDetailOutput();
    this.workItemStatusChangeInput = new WorkItemStatusChangeInput();
    this.previousAgent = this.workItemDetailOutput['assignedAgent'];
    this.getSelectedCategoryAndSubCategoryForEmail();
    this.icon2.push(false);
    this.emailHistoryInput = new EmailHistoryInput();
    this.emailHistoryOutput = new Array<EmailHistoryOutput>();

    this.loginService.currentData.subscribe((data) => {
      this.crmUser = data;
     if(data==null || data.sessionToken == null){
       this.loginService.endInterval();
         this.router.navigate(['']);
       }
    });
  }

  getSelectedCategoryAndSubCategoryForEmail(){
    

    this.selectedCategory=this.workItemDetailOutput.workItemCategory['id'];

    var list = this.workitemCategory.filter(x => x.id == this.selectedCategory);

    var temp=list[0];
    if(temp!=undefined){
      this.workitemSubCategory=(temp['subCategories']);
      this.selectedSubCategory=this.workItemDetailOutput.workItemSubCategory['id'];
    }
    else{
      this.workitemSubCategory=[];
      this.selectedSubCategory = 0;
    }

  }

  openIcon(i,workitemId){
    console.log(i);
    console.log(this.icon2);
     this.workItemDetailInput.workItemId=workitemId;
     this.workItemDetailInput.crmUserId=this.id;
     this.workItemDetailInput.sessionToken=this.token;
     this.linkedWorkItemDetailService.getLinkedWorkitemContent(this.workItemDetailInput).
     subscribe(data=>{

       if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
           
         console.log(data);
         this.linkedWorkItemDetailOutput=data.body['emailWorkItem'];

       }
     });

     this.icon2[i] = !this.icon2[i];
   }

   pushStatus(){
     this.icon2.push(false);
   }

   closedIcon(i){
     console.log(i);
     this.icon2[i] = !this.icon2[i];
   }


  close(){
    this.dialogRef.close();
  }
  onChangeCategory(){
    
   if(this.selectedCategory>0){
    var list = this.workitemCategory.filter(x => x.id == this.selectedCategory);

  var temp=list[0];
  this.workitemSubCategory=(temp['subCategories']);


    }else{

      this.workitemSubCategory=null;
      this.selectedSubCategory=0;
    }

  }

  onReassignClick(){
    

    if(this.selectedAgent ==0){
      this.isAgentSelected = false;
      return;
    }
    this.reassignWorkitemModelInput.crmUserId = this.id;
    this.reassignWorkitemModelInput.existingCrmUserId = this.previousAgent['crmUserId'];
    this.reassignWorkitemModelInput.newCrmUserId = this.selectedAgent;
    this.reassignWorkitemModelInput.previousWorkItemStatus = this.workitemFromQueue['workItemStatus'];
    this.reassignWorkitemModelInput.workItemId = this.workitemFromQueue['workItemId'];
    this.reassignWorkitemModelInput.sessionToken = this.token;
    this.reassignWorkitemModelInput.newWorkItemStatus = "PAUSED";
    this.reassignWorkitemModelInput.reassignmentType = "GENERAL_REASSIGNMENT";
    this.reassignWorkitemModelInput.reassignmentComment = "no comment needed";

    this.liveMonitorService.reassignWorkitem(this.reassignWorkitemModelInput).subscribe(data=>{
      
      console.log(data);
      if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
        const dialogConfig= new MatDialogConfig();
        dialogConfig.disableClose=true;
        dialogConfig.autoFocus=true;
        dialogConfig.width="60%";
        dialogConfig.height="40%";
        dialogConfig.data = {
          popUpTitle:"Reassign Workitem",
          messageToBeDisplayed:"Workitem Reassigned Successfully"
        };
        this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);
        this.dialogRef.close();
      }
    });


  }
  newCrmUserId:number = 0;
  newSelectedAgent(){
    if(this.selectedAgent>0){
      this.isAgentSelected = true;
      var list = this.usersList.filter(x => x.id == this.selectedAgent);
      console.log("the list is >>>>>"+list);
      this.newCrmUserId = list['crmUserId'];
    }
    else{
      this.isAgentSelected = false;
    }

  }

  openEmailHistory(workitemId){
    
    this.emailHistoryInput.crmUserId = this.id;
    //this.emailHistoryInput.emailItemId = this.workItemDetailOutput.emailItemId;
    this.emailHistoryInput.emailItemId = workitemId;
    this.emailHistoryInput.sessionToken = this.token;


      this.emailHistoryService.getEmailHistory(this.emailHistoryInput).
      subscribe(data=>{
        
              if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
                 console.log(data);
                 this.emailHistoryOutput = data.body['previousReplies'];
                 if(this.emailHistoryOutput.length==0){
                  this.callDialogPopUpMessage("No email history available", "Email History");
                 }
                 else{
                  const dialogConfig = new MatDialogConfig();
                  dialogConfig.disableClose = true;
                  dialogConfig.autoFocus = true;
                  dialogConfig.height = "60%";
                  dialogConfig.width = "60%";
                  dialogConfig.data = {
                  emailHistoryOutput: this.emailHistoryOutput
                };
                   this.dialog.open(EmailReplyHistoryComponent,dialogConfig);


              }

              }


      });
  }

  callDialogPopUpMessage(  message,title){
    const dialogConfig= new MatDialogConfig();
    dialogConfig.disableClose=true;
    dialogConfig.autoFocus=true;
    dialogConfig.width="60%";
    dialogConfig.height="40%";
    dialogConfig.data = {
     popUpTitle:title,
     messageToBeDisplayed:message
  };
    this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);

  }


  reopenWorkitem(){


    const dialogConfig= new MatDialogConfig();
    dialogConfig.disableClose=true;
    dialogConfig.autoFocus=true;
    dialogConfig.width="60%";
    dialogConfig.height="35%";
    dialogConfig.id = "1";
    this.dialog.open(ReopenConfirmationComponent,dialogConfig).afterClosed().
    subscribe(item => {
      
      if(item===0){}
      else{
        
        this.liveMonitorService.reopenWorkitem(this.id,this.token,this.workitemFromQueue['workItemId']).subscribe(data=>{
          
          if(data.status==200 && data.body["responseType"]=="SUCCESS"){
            this.callDialogPopUpMessage("Workitem Reopened","Reopen Workitem");
            this.dialogRef.close();
          }

          else if(data.body['responseType']== 'SESSION_TIMEOUT'){

            alert("Session timeout!Please Login Again");
            this.loginService.storeddata(null);
            this.router.navigate(['']);
          }

        },
        error=>{
          console.log(error);
        }


        );
      }

    });;

  }


  downloadAttachment(attachment)
{
  
  window.open(environment.baseUrl+"downloadEmailAttachment?crmUserId="+this.id+"&emailItemId="+this.workItemDetailOutput.emailItemId+"&fileName="+attachment.fileName+"&sessionToken="+this.token);
}

getCompleteWorkitemHistory(){
  
      
      this.workItemDetailInput.workItemId=this.workitemFromQueue.workItemId;
      this.workItemDetailInput.crmUserId=this.id;
      this.workItemDetailInput.sessionToken=this.token;
  
      this.completeWorkitemHistoryService.getCompleteHistory(this.workItemDetailInput).subscribe(data=>{
        
        if(data.status==200 && data.body['responseType']=="SUCCESS"){
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            workItemHistoryVOS:data.body['workItemHistoryVOS']
          };
      
          this.dialog.open(WorkitemCompleteHistoryComponent,dialogConfig);
        }
  
        else{
          alert(data.body['responseMessage']);
        }
      });
      
  
     }


     onCompleteWorkitemClicked(){
      
        const dialogConfig= new MatDialogConfig();
      dialogConfig.disableClose=true;
      dialogConfig.autoFocus=true;
      // dialogConfig.width="60%";
      // dialogConfig.height="35%";
      dialogConfig.width="800px";
      dialogConfig.data={
        crmUserId: this.id,
      sessionToken: this.token,
      receiver:this.workItemDetailOutput.sender,
      cc:this.emailCcForDisplay,
      subject:this.workItemDetailOutput.subject,
      content:this.workItemDetailOutput.content,
       attachments:this.workItemDetailOutput.emailAttachments,
       emailItemId:this.workItemDetailOutput.emailItemId
      };
      dialogConfig.id = String(this.workitemFromQueue.workItemId);
      console.log("workitemid is"+dialogConfig.id);
      this.workItemIdForCompletion = this.workitemFromQueue.workItemId;
      this.dialog.open(CompleteWorkitemConfirmationMessageComponent,dialogConfig).afterClosed().
      subscribe(
      
        item => {
          
          if(item===0){
           
          }
          // Edited Data sent to App Component from Dialog
          else{
            this.dialog.open(SendWICompletionMailComponent,dialogConfig).afterClosed().subscribe(
              item => {
                
                  if(item===0){
                    
                  }
                  // Edited Data sent to App Component from Dialog
                  else{
                    this.completeWorkItem();
                  }
            
                }
             );
          }
    
        }
      );
    
      // this.dialog.open(CompleteWorkitemConfirmationMessageComponent,dialogConfig).afterClosed().
      // subscribe(item => {
      //   if(item===0){}
      //   // Edited Data sent to App Component from Dialog
      //   else{
      //     this.completeWorkItem();
      //   }
  
      // });;
  
    }

    completeWorkItem(){
      
          console.log("workitemid is"+ this.workitemFromQueue.workItemId);
      
            
            this.workItemStatusChangeInput.crmUserId=this.crmUser.crmUserId;
            this.workItemStatusChangeInput.sessionToken=this.crmUser.sessionToken;
            this.workItemStatusChangeInput.updatedByUserId=this.crmUser.crmUserId;
            this.workItemStatusChangeInput.workItemId= this.workitemFromQueue.workItemId;
            this.workItemStatusChangeInput.workItemStatus= WorkItemStatusEnum.COMPLETED;
      
      
      
            this.workItemStatusChangeService.getWorkItemStatusChange(this.workItemStatusChangeInput).
            subscribe(data=>{
              console.log(data);
              if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
                    
                       console.log(data);
                       this.dialogRef.close();
                       alert("WorkItem Completed Successfully");
                           }
                          else if(data.body['responseType']== 'SESSION_TIMEOUT'){
      
                            alert("Session timeout!Please Login Again");
                            this.loginService.storeddata(null);
                            this.router.navigate(['']);
                            alert("Could not complete workitem.Please try again");
                          }
                          else{
                            
                            console.log(data);
                            alert("Could not complete workitem.Please try again");
                          }
      
                       },
      
                      error => {
                        
                        console.log(error);
      
                        alert("Could not complete workitem.Please try again");
                          })
      
                        // }
      
          }
}
