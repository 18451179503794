import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material';
import { EditQueueService } from '../services/EditQueue.service';
import { EditQueue } from '../models/EditQueue.model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { QueuePriorityEnum } from '../QueuePriorityenum';
import { WorkitemSavedMessageComponent } from '../messagePopUp/workitem-saved-message/workitem-saved-message.component';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-edit-queue',
  templateUrl: './edit-queue.component.html',
  styleUrls: ['./edit-queue.component.css']
})
export class EditQueueComponent implements OnInit {
  id:number;
  token:string;
  queue;
  updateQueue:EditQueue;
  editQueueForm:FormGroup;
  mappedSkills:string = "";
  lifo:string;
  fifo:string;
  submitted:boolean=false;
  items = [];
  constructor(
    private router:Router,
    private loginService:LoginService,
    private dialog:MatDialog,
    private dialogRef: MatDialogRef<EditQueueComponent>,
    private formBuilder: FormBuilder,
    private editQueueService:EditQueueService,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    
    this.id = data['id'];
    this.token = data['token'];
      this.queue = data['queue'];
  }

  ngOnInit() {
    
    this.items = [
      "LIFO",
      "FIFO"
     ];
    this.fifo = QueuePriorityEnum.FIFO;
    this.lifo = QueuePriorityEnum.LIFO;
    for(let i=0;i<this.queue.mappedSkills.length;i++){
      this.mappedSkills = this.mappedSkills+this.queue.mappedSkills[i].name+" ";  
    }
    
    this.updateQueue = new EditQueue();
    this.editQueueForm = this.formBuilder.group({
      queueName:new FormControl({value:'',disabled: true},[Validators.required]),
      queueCode:new FormControl({value:'',disabled: true},[Validators.required]),
      queueSkills:new FormControl({value:'',disabled: true},[Validators.required]),
      queueSLA:new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      queueDescription:new FormControl('',[Validators.required]),
      enabled:new FormControl(''),
      queuePriority:new FormControl('', [Validators.required])
     });
  }



  editQueue(){

this.submitted = true;
    this.updateQueue.crmUserId = this.id;
    this.updateQueue.description = this.editQueueForm.value.queueDescription;
    this.updateQueue.queueEnabled = this.editQueueForm.value.enabled;
    this.updateQueue.queueId =this.queue.id;
    this.updateQueue.queuePriority = this.editQueueForm.value.queuePriority;
    this.updateQueue.sessionToken = this.token;
    this.updateQueue.sla = this.editQueueForm.value.queueSLA;
    if(this.editQueueForm.valid ){
    this.editQueueService.editQueue(this.updateQueue).subscribe(
      data=>{
        console.log(data);
        if(data.status==200 && data.body["responseType"]=="SUCCESS"){

          const dialogConfig= new MatDialogConfig();
              dialogConfig.disableClose=true;
              dialogConfig.autoFocus=true;
              dialogConfig.width="60%";
              dialogConfig.height="40%";
              dialogConfig.data = {
                popUpTitle:"Update Queue",
                messageToBeDisplayed:"Queue updated successfully" 
              };
              this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);
              this.dialogRef.close();
       
        }
        else if(data.body['responseType']== 'SESSION_TIMEOUT'){
              
          alert("Session timeout!Please Login Again");
          this.loginService.storeddata(null);
          this.router.navigate(['']);
        }
        else{
          alert("Unable to save data");
        }
      }
    );

}
  }

close()
  {

    this.dialogRef.close();

  }


  onClearFormFields(){
    this.editQueueForm = new FormGroup({
      queuePriority:new FormControl(''),
      queueSLA:new FormControl(''),
      queueDescription:new FormControl(''),
      enabled:new FormControl(''),
     
      
    });

    this.editQueueForm = this.formBuilder.group({
   
      queueSLA:['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      queueDescription:['',[Validators.required]],
      queuePriority:['',[Validators.required]],
    });
  }

  get formControls() {
    return this.editQueueForm.controls;
  }

}
