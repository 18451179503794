import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { EmailData } from '../models/emailData.model';
import { Attachment } from '../models/attachment.model';


@Injectable()
export class EmailReplyService{

       private Url=environment.baseUrl+'sendReplyEmail'
       private UrlPopulateMail=environment.baseUrl+'populateEmailReply'
       private uploadAttachmentUrl=environment.baseUrl+'addAttachment'
       private deleteAttachmnetUrl=environment.baseUrl+'deleteAttachment'
       private uploadAttachmentsUrl=environment.baseUrl+'addAttachments'
       private sendMailUrl = environment.baseUrl+'sendEmail'
       private uploadAttachmentsForNewMailUrl = environment.baseUrl+'addAttachmentsForNewMail';
       private uploadAttachmentsForManualWorkitemUrl = environment.baseUrl+'addAttachmentsForManualWorkItem';
constructor(private http:HttpClient){

}
populateMailData(crmUserId,emailItemId,sessionToken,replyType)
{
  return this.http.post(this.UrlPopulateMail,{'crmUserId':crmUserId,'emailItemId':emailItemId,'sessionToken':sessionToken,'replyType':replyType},{observe: 'response'});
}
sendReply(emailData:EmailData){
    
    return this.http.post(this.Url, {'crmUserId':emailData.crmUserId,'sessionToken':emailData.sessionToken,'fromMailBoxId':emailData.from,'to':emailData.receiver,'subject':emailData.subject,'mailContent':emailData.mailContent,'bcc':emailData.bcc,'cc':emailData.cc,'emailItemId':emailData.emailItemId}, {observe: 'response'})

}
uploadAttachment(data)
{

  return this.http.post(this.uploadAttachmentUrl,data, {observe: 'response'});
}
deleteAttachment(attachment:Attachment)
{
  
  return this.http.post(this.deleteAttachmnetUrl,{'crmUserId':attachment.userId,'sessionToken':attachment.token,'emailItemId':attachment.emailItemId,'fileName':attachment.fileName},{observe:'response'});
}
uploadAttachments(data)
{
  
  return this.http.post(this.uploadAttachmentsUrl,data,{observe:'response'});
}
sendEmail(emailData:EmailData){
  
  return this.http.post(this.sendMailUrl,{'crmUserId':emailData.crmUserId,'sessionToken':emailData.sessionToken,'fromMailBoxId':emailData.from,'to':emailData.receiver,'subject':emailData.subject,'mailContent':emailData.mailContent,'bcc':emailData.bcc,'cc':emailData.cc,'newMailIdentifier':emailData.newMailIdentifier},{observe:'response'});
}

uploadAttachmentsForNewMail(data){
  
  return this.http.post(this.uploadAttachmentsForNewMailUrl,data,{observe:'response'});
}
uploadAttachmentsForManualWorkitem(data){
  
  return this.http.post(this.uploadAttachmentsForManualWorkitemUrl,data,{observe:'response'});
}
}
