import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { WorkItemCompleteHistoryResponse } from 'src/app/models/WorkitemCompleteHistory.model';

@Component({
  selector: 'app-workitem-complete-history',
  templateUrl: './workitem-complete-history.component.html',
  styleUrls: ['./workitem-complete-history.component.css']
})
export class WorkitemCompleteHistoryComponent implements OnInit {
  workItemHistoryVOS:Array<WorkItemCompleteHistoryResponse> = new Array<WorkItemCompleteHistoryResponse>();
  dataAvailable:boolean = false;
  constructor(
    private dialogRef: MatDialogRef<WorkitemCompleteHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    
      this.workItemHistoryVOS = data['workItemHistoryVOS'];
      if(this.workItemHistoryVOS.length>0){
        this.dataAvailable = true;
      }
      else{
        this.dataAvailable = false;
      }
      // this.workItemHistoryVOS.push({'activityName':"false",'activityDoneBy':"abhinandan",'date':"12-12-12"});
      // this.workItemHistoryVOS.push({'activityName':"false",'activityDoneBy':"abhinandan",'date':"12-12-12"});
      // this.workItemHistoryVOS.push({'activityName':"false",'activityDoneBy':"abhinandan",'date':"12-12-12"});
      // this.workItemHistoryVOS.push({'activityName':"false",'activityDoneBy':"abhinandan",'date':"12-12-12"});
      // this.workItemHistoryVOS.push({'activityName':"false",'activityDoneBy':"abhinandan",'date':"12-12-12"});
      // this.workItemHistoryVOS.push({'activityName':"false",'activityDoneBy':"abhinandan",'date':"12-12-12"});
   }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
