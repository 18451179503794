import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { ConfigureEmail } from '../models/configureEmail.model';


@Injectable()
export class ConfigureEmailService{
  
       private Url=environment.baseUrl+'getQueueList'
       private mailBoxUrl=environment.baseUrl+'getMailboxList'
       private addMailBoxUrl=environment.baseUrl+'addMailbox'
       private editMailBoxUrl=environment.baseUrl+'editMailBox'
       private getTemplateListUrl=environment.baseUrl+'getEmailTemplateList'
       public StoredData: BehaviorSubject<ConfigureEmail> = new BehaviorSubject<ConfigureEmail>( new ConfigureEmail());  
currentData = this.StoredData.asObservable();

storeddata(data: any) {
  
    this.StoredData.next(data);
  }
constructor(private http:HttpClient){
    
}
getQueueList(crmUserId,sessionToken){
    
    return this.http.post(this.Url, {'crmUserId':crmUserId,'sessionToken':sessionToken}, {observe: 'response'})
   
} 
getMailboxList(crmUserId,sessionToken){
    return this.http.post(this.mailBoxUrl,{'crmUserId':crmUserId,'sessionToken':sessionToken},{observe: 'response'})
}
addMailBox(emailConfigureData:ConfigureEmail){
  
  return this.http.post(this.addMailBoxUrl,{'crmUserId':emailConfigureData.crmUserId,'email':emailConfigureData.emailId,'folderName':emailConfigureData.folderName,'mailboxEnabled':emailConfigureData.scanEnabled,'mailboxReplyEnabled':emailConfigureData.replyEnabled,'name':emailConfigureData.mailBoxName,'password':emailConfigureData.password,'queue':emailConfigureData.queueName,'receivingPort':emailConfigureData.sendingPort,'receivingUrl':emailConfigureData.sendingUrl,'sendingPort':emailConfigureData.replyPort,'sendingUrl':emailConfigureData.replyUrl,'sessionToken':emailConfigureData.sessionToken,'template':emailConfigureData.emailTemplate},{observe: 'response'})
}
editMailBox(emailConfigureData:ConfigureEmail,token,id){
  
  return this.http.post(this.editMailBoxUrl,{'crmUserId':id,'id':emailConfigureData.mailBoxId, 'mailboxEnabled':emailConfigureData.scanEnabled,'mailboxReplyEnabled':emailConfigureData.replyEnabled,'name':emailConfigureData.mailBoxName,'password':emailConfigureData.password,'sessionToken':token,'templateId':emailConfigureData.emailTemplate},{observe: 'response'})
}
getEmailTemplateList(crmUserId,sessionToken){
  
  return this.http.post(this.getTemplateListUrl, {'crmUserId':crmUserId,'sessionToken':sessionToken}, {observe: 'response'})
 
} 
}
