import { Component, OnInit , ElementRef, ViewChild,Inject} from '@angular/core';
import { Router,NavigationExtras,ActivatedRoute } from '@angular/router';
import {MatDialog,MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material';
import {FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
import {UpdateAgentStatusService} from '../services/updateAgentStatus.service';
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
import {LogoutService} from '../services/logout.service';
import {AddEmailComponent} from '../email-configuration/add-email.component';
import { ConfigureUserService } from '../services/configureUser.service';
import { Users } from '../models/users.model';
import { AddUserComponent } from './add-user.component';
import {EditUserComponent} from './edit-user.component';
import {AgentProfileService} from '../services/AgentProfile.service';
import {AgentProfile} from '../models/AgentProfile.model';
import {AgentProfileComponent} from '../agent-profile/agent-profile.component';
import {LoginService} from '../services/login.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
@Component({
    selector: 'app-userConfiguration',
    templateUrl: './user-configuration.component.html',
    styleUrls:['./user-configuration.component.css']
  })
export class UserConfigurationComponent implements OnInit{
  token:string;
  id:number;
  submitted:boolean=false;
  showRow:boolean=false;
  skillsList:any[];
  userList:any[];
  agentProfile:AgentProfile;
  updateAgentStatusModel:UpdateAgentStatusModel;
  isClient:boolean = false;
    constructor( private adminProfileService:AgentProfileService,private dialog:MatDialog,private formBuilder: FormBuilder,private route:ActivatedRoute,private router:Router,
        private updateAgentStatusService:UpdateAgentStatusService,private loginService:LoginService,private logoutService:LogoutService,private configureUserService:ConfigureUserService){

      }
    ngOnInit() {
      window.scrollTo(0,0);
      this.agentProfile = new AgentProfile();
      this.loginService.currentData.subscribe(
        data=>{
          if(data==null || data.sessionToken == null){
            this.router.navigate(['']);
          }
          else{
           this.id=data.crmUserId;
           this.token=data.sessionToken;
          }
        }
      )
   this.getUserList();
    }
  getUserList(){
    
    this.configureUserService.getUserList(this.id,this.token).subscribe(data=>
        {
          
           this.userList= data.body['users'];
           console.log(this.userList);
        });
  }

  onAdminWorkitemControllerClick(){
    this.router.navigate(['adminWorkitemController']);
  }
  onLiveMonitorClick(){
    this.router.navigate(["liveMonitor"]);
  }
  onDashboardClick(){
    this.router.navigate(["adminDashBoard"]);
  }
  onManualWorkitemClick(){
    this.router.navigate(["manualWorkitemController"]);
  }
  onQueueClick(){
    this.router.navigate(["queueConfiguration"]);
  }
  onEmailClick(){
    this.router.navigate(["emailConfiguration"]);
  }
  onUserClick(){
    //this.router.navigate(["userConfiguration"]);
  }
  onSkillClick(){
    this.router.navigate(["skillConfiguration"]);
  }

  showAgentDashboard()
  {
    this.router.navigate(["dashboard"]);
  }
  showWIDashboard(){
    this.router.navigate(["wiDashboard"]);
  }



      logoutUser(){
        
        this.loginService.endInterval();
        this.logoutService.logoutUser(this.id, this.token).subscribe(
          data=>{

            
            console.log(data);
            if(data.status==200 ){
              this.router.navigate(['/']);
            }
          }
        )

      }




    addUserConfig()
      {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
         // dialogConfig.width="50%";
        //   dialogConfig.position = {
        //     'top': '130',
        //     left: '130'
        // };
        dialogConfig.data = {
          id: this.id,
          token:  this.token,
      };
          this.dialog.open(AddUserComponent,dialogConfig).afterClosed().subscribe(()=>
          {
         this.getUserList();
          });


      }

      updateUserInfo(user){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
         // dialogConfig.width="50%";
        //   dialogConfig.position = {
        //     'top': '130',
        //     left: '130'
        // };
        dialogConfig.data = {
          id: this.id,
          token:  this.token,
          updateUser:user
      };
          this.dialog.open(EditUserComponent,dialogConfig).afterClosed().subscribe(()=>
          {
         this.getUserList();
          });
      }
      openAdminProfile(){

        
          this.adminProfileService.getAgentDetails(this.id ,this.token).subscribe(
            data=>{
              

              console.log(data);
              if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
                this.agentProfile.name = data.body['name'];
                this.agentProfile.emailId = data.body['emailAddress'];
                this.agentProfile.skills = data.body['skills'];
                this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.height = "218px";
                dialogConfig.width = "350px";
                dialogConfig.data = {
                agentProfile: this.agentProfile
                };
                 this.dialog.open(AgentProfileComponent,dialogConfig);

              }
              else if(data.body['responseType']== 'SESSION_TIMEOUT'){

                alert("Session timeout!Please Login Again");
                this.loginService.storeddata(null);
                this.router.navigate(['']);
                                                            }
            }
          );


        }

        changePassword(){
          
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data={
              sessionToken:this.token,
              userId:this.id
            };
            // dialogConfig.maxWidth='400px';
          this.dialog.open(ChangePasswordComponent,dialogConfig);
        }

        reminder(){
          
           
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            crmUserId: this.id,
            sessionToken: this.token,
            detailedWorkitem:null,
            isServed:false
          };
          this.dialog.open(ReminderConfigurationComponent,dialogConfig);
         }
}
