import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
//import { AgentWIStatusService} from '../../services/agentWIStatus.service';
import { CrmUser } from '../../models/crmuser.model';
import { Router} from '@angular/router';
import { LoginService } from '../../services/login.service';
import { AgentReportingDashboardService } from 'src/app/services/AgentReportingDashboard.service';
@Component({
  selector: 'app-agent-wise-completed-items-chart',
  templateUrl: './agent-wise-completed-items-chart.component.html',
  styleUrls: ['./agent-wise-completed-items-chart.component.css']
})
export class AgentWiseCompletedItemsChartComponent implements OnInit {

  crmUser:CrmUser;
  id:number;
  token:string;
  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Assigned' },
    {data:[],label:'Pending Verification'},
    {data:[],label:'Completed (In last 7 days)'}
  ];
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  agentNameList:Array<any>;
  agentWIStatusList:Array<any> = new Array<any>();
  public dataFromService:Array<any>=new Array<any>();
  

  constructor( private router:Router,
    private loginService:LoginService,
    private agentReportingDashboardService:AgentReportingDashboardService
  ) { 
    
      this.agentReportingDashboardService.currentData.subscribe((data)=>{
      if(data!=null){
        
        this.dataFromService = data['agentList'];
        for(let i=0;i<this.dataFromService.length;i++){
          this.agentWIStatusList[i] = {"agent": this.dataFromService[i].agentName,"completed":  this.dataFromService[i].workItemsCompleted,"paused":this.dataFromService[i].workItemsPaused,"assigned":this.dataFromService[i].workItemsAssigned};
        }
      }
      });
      
  }

  ngOnInit() {
    this.loginService.currentData.subscribe((data) => {
      this.crmUser = data;
     this.token=this.crmUser.sessionToken;
     this.id=this.crmUser.crmUserId;
    
     if(this.crmUser==null || this.crmUser.sessionToken == null){
         this.router.navigate(['']);
       }
  });
  console.log("in the AgentWiseCompletedItemsChartComponent");
  console.log("token>>"+this.token);
 

this.agentWIStatusList.forEach(element => {
  (this.barChartData[0].data as number[]).push(element.assigned);
  (this.barChartData[1].data as number[]).push(element.paused);
  (this.barChartData[2].data as number[]).push(element.completed);
this.barChartLabels.push(element.agent);
});
  }

}
