import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoginService } from './services/login.service';
import { Routes,RouterModule } from '@angular/router'

import { ForgotPasswordService } from './services/forgotPassword.service';

import { ShowDashboardService } from './services/showDashboard.service';
import { AgentDashboardComponent } from './agent-dashboard/agent-dashboard.component';


import { SafeHtmlPipe } from './pipe.safehtml';
import { OrderByPipe  } from './order-by.pipe';
import { EmailWorkItemDetailService } from './services/emailworkitemdetail';
import { IVRWorkItemDetailService } from './services/ivrworkItemDetail.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import {SearchCustomerComponent}  from './searchCustomer/searchCustomer.component';
import { AddCustomerService } from './services/addCustomer.service';
import {AddCustomerComponent} from './addCustomer/addCustomer.component';
import {AddCustomerMessageComponent} from './messagePopUp/addCustomerMessage.component';
import {EditCustomerComponent} from './editCustomer/editCustomer.component';
import {UpdateCustomerMessageComponent} from './messagePopUp/updateCustomerMessage.component';
import { CustomerExistsMessageComponent } from './messagePopUp/customerAlreadyExists.component';
import { CustomerHistoryService} from './services/CustomerHistory.service';
import {CutomerEnagagementHistoryComponent} from './cutomer-enagagement-history/cutomer-enagagement-history.component';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {SearchHistoryComponent} from './historyTab/searchHistory.component';
import { EmailreplyComponent } from './emailreply/emailreply.component';
 import { AngularEditorModule } from '@kolkov/angular-editor';
import {EmailReplyService} from './services/emailReply.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {LoaderComponent} from './loader/loader.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {UpdateAgentStatusService} from './services/updateAgentStatus.service';
import {  CompletedWorkItemsService } from './services/CompletedWorkItems.service';
import { WorkItemStatusChangeService } from './services/workitemStatusChange.service';
import { LogoutService } from './services/logout.service';
import {UploadAttachmentComponent} from './uploadAttachment/uploadAttachment.component';
import { LinkedWorkItemDetailService } from './services/linkedworkitemdetails.service';
import {  IVRWorkitemRemarksService } from './services/ivrWorkitemRemarks.service';
import { CompleteWorkitemConfirmationMessageComponent } from './messagePopUp/complete-workitem-confirmation-message/complete-workitem-confirmation-message.component';
import {ReportsService} from './services/reports.service';
import { CategoryAndSubCategoryService } from './services/categoryandsubcategory.service';
import { ErrorComponent } from './error/error.component';
import { WorkitemSavedMessageComponent } from './messagePopUp/workitem-saved-message/workitem-saved-message.component';
import { AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import { AgentWIDetailReportComponent} from './reports-module/agentWIDetailReport.component';
import { MatNativeDateModule, MatSelectModule, MatGridListModule, MatCardModule, MatMenuModule, MatSidenavModule } from '@angular/material';
import { SingleWIReportComponent} from './reports-module/singleWIReport.component';
import { EmailReplyHistoryComponent } from './email-reply-history/email-reply-history.component';
import { EmailHistoryService } from './services/emailHistory.service';
import {EngagementHistoryComponent} from './engagement-history/engagement-history.component';
import {WICountByCategoryReportComponent} from './reports-module/wiCountByCategory.component';
import {WICountByTypeReportComponent} from './reports-module/wiCountByType.component';
import { PasswordResetService } from './services/PasswordReset.service';
import {EmailConfigurationComponent} from './email-configuration/email-configuration.component';
import {AddEmailComponent} from './email-configuration/add-email.component';
import {UpdateEmailComponent} from './email-configuration/update-email.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {ConfigureEmailService} from './services/configureEmail.service';
import {QueueConfigurationComponent} from './queue-configuration/queue-configuration.component';
import {UserConfigurationComponent} from './user-configuration/user-configuration.component';
import {ConfigureUserService} from './services/configureUser.service';
import { AddQueueComponent } from './add-queue/add-queue.component';
import { GetQueueListService } from './services/GetQueueList.service';
import { AddNewQueueService } from './services/AddNewQueue.service';
import { EditQueue } from './models/EditQueue.model';
import { EditQueueService } from './services/EditQueue.service';
import { EditQueueComponent } from './edit-queue/edit-queue.component';
import {AddUserComponent} from './user-configuration/add-user.component';
import {AgentProfileComponent} from './agent-profile/agent-profile.component';
import {AgentProfileService} from './services/AgentProfile.service';
import {GetApplicationsService} from './services/GetApplications.service';
import {GetSkillsService} from './services/GetSkills.service';
import {EditUserComponent} from './user-configuration/edit-user.component';
import { ChatWorkItemDetailService } from './services/GetChatWorkitemDetails.service';
import { WorkItemSLAReportComponent } from './reports-module/work-item-slareport/work-item-slareport.component';
import { SearchHistoryService } from './services/SearchHistoryc.service';
import { ReportByAllComponent } from './reports-module/report-by-all/report-by-all.component';
import { ReportByAgentComponent } from './reports-module/report-by-agent/report-by-agent.component';
import { ReportByCategoryComponent } from './reports-module/report-by-category/report-by-category.component';
import { ReportByMailboxComponent } from './reports-module/report-by-mailbox/report-by-mailbox.component';
import { LiveMonitorComponent } from './live-monitor/live-monitor.component';
import { LiveMontorService } from './services/LiveMonitor.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ReassignWorkitemComponent } from './live-monitor/reassign-workitem/reassign-workitem.component';
import { ListFilterPipe } from './ListFilterPipe';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangePasswordService } from './services/changePassword.service';
import { ReopenConfirmationComponent } from './live-monitor/reassign-workitem/reopen-confirmation/reopen-confirmation.component';
import { DatePipe } from '@angular/common';
import { CreateManualWorkitemService } from './services/CreateManualWorkitem.service';
import { PropertiesList } from './services/PropertiesList.service';
import { ManualWorkitemControllerComponent } from './manual-workitem-controller/manual-workitem-controller.component';
import { AddManualWorkitemComponent } from './manual-workitem-controller/add-manual-workitem/add-manual-workitem.component';
import { DownloadAttachmentService } from './services/DownloadAttachment.service';
import { AdminWorkitemControllerComponent } from './admin-workitem-controller/admin-workitem-controller.component';
import { LoadAdminWorkitemService } from './services/LoadAdminWorkitems.service';
import { SaveAndReassignWorkitemService } from './services/SaveAndReassignWorkitem.service';
import { UploadExcelComponent } from './manual-workitem-controller/uploadExcelFile/uploadExcel.component';
import { ExcelWorkitemControllerComponent } from './manual-workitem-controller/excel-workitem-controller/excel-workitem-controller.component';

import { DashComponent } from './dash/dash.component';
import { CardComponent } from './card/card.component';

import { AgentAvailabilityStatusChartComponent } from './charts/agentAvailabilityStatusChart/agentAvailabilityStatusChart.component';
import { AgentWiseCompletedItemsChartComponent } from './charts/agent-wise-completed-items-chart/agent-wise-completed-items-chart.component';
import { QueueTypeStatusChartComponent } from './charts/queueTypeStatusChart/queueTypeStatusChart.component';
import { StoreSessionsChartComponent } from './charts/store-sessions-chart/store-sessions-chart.component';
import { AgentSkillDistributionChartComponent } from './charts/agent-skill-distribution-chart/agent-skill-distribution-chart.component';
import { WIDashboardComponent } from './widashboard/widashboard.component';
import { QueuewiseWIStatusChartComponent } from './charts/queuewise-wistatus-chart/queuewise-wistatus-chart.component';
import { AverageHandlingTimeByQueueChartComponent } from './charts/average-handling-time-by-queue-chart/average-handling-time-by-queue-chart.component';

import {ChartsModule, ThemeService} from 'ng2-charts';
import { LayoutModule } from '@angular/cdk/layout';
import { AgentReportingDashboardService } from 'src/app/services/AgentReportingDashboard.service';
import { QueueReportingDashboardService } from 'src/app/services/QueueReportingDashboard.service';
import { CustomDateTimePickerComponent } from './custom-date-time-picker/custom-date-time-picker.component';
import { SkillCofigurationComponent } from './skill-cofiguration/skill-cofiguration.component';
import { SkillConfigurationService } from 'src/app/services/SkillConfiguration.service';
import { AddSkillComponent } from './skill-cofiguration/add-skill/add-skill.component';
import { Customdatepicker1r1Component } from './customdatepicker1r1/customdatepicker1r1.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { HourPickerFormat } from 'src/app/HourPickerFormat';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxChartsComponent } from './ngx-charts/ngx-charts.component';
import { ReminderConfigurationComponent } from './reminder-configuration/reminder-configuration.component';
import { ReminderConfigurationService } from 'src/app/services/ReminderConfiguration.service';
import { BaseAdminControllerComponent } from './base-admin-controller/base-admin-controller.component';
import { WebSocketService } from 'src/app/services/WebSocket.service';
import { TestComponentComponent } from './test-component/test-component.component';
import { ModalModule } from 'src/app/_modal';
import { ReminderPopupComponent } from './reminder-popup/reminder-popup.component';
import { WhatsappWorkitemService } from 'src/app/services/WhatsappWorkitem.service';
import { WhatsappChatHistoryComponent } from './whatsapp-chat-history/whatsapp-chat-history.component';
import { WorkitemReminderService } from 'src/app/services/WorkitemReminder.service';
import { LandingDashboardComponent } from './landing-dashboard/landing-dashboard.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { AddCustomFieldsComponent } from './custom-fields/add-custom-fields/add-custom-fields.component';
import { WorkItemCustomFieldsComponent } from './work-item-custom-fields/work-item-custom-fields.component';
import { LoadUserDetailsService } from 'src/app/services/LoadUserDetails.service';
import { CustomFieldsService } from 'src/app/services/CustomFields.service';
import { NotificationConfigurationComponent } from './notification-configuration/notification-configuration.component';
import { NotificationPopUpComponent } from './notification-configuration/notification-pop-up/notification-pop-up.component';
import { NotificationService } from 'src/app/services/Notifications.service';

import {SecurityQuestionsComponent} from './securityQuestions/securityQuestions.component';
import {SecurityQuestionsService} from './services/securityQuestions.service';
import { SecurityQuestionVerificationComponent } from './securityQuestions/security-question-verification/security-question-verification.component';
import { WorkitemCompleteHistoryComponent } from './workitem-complete-history/workitem-complete-history.component';
import { CompleteWorkitemHistoryService } from 'src/app/services/CompleteWorkitemHistory.service';
import { FilterUsersList } from 'src/app/FilterUsersList';
import { QAMonitorComponent } from './qamonitor/qamonitor.component';
import { CompletePropertiesComponent } from './complete-properties/complete-properties.component';
import { CategorywiseWIStatusChartComponent } from './charts/categorywise-wistatus-chart/categorywise-wistatus-chart.component';
import { WorkitemSearchControllerComponent } from './workitem-search-controller/workitem-search-controller.component';
import { SearchWorkitemService } from 'src/app/services/SearchWorkitem.service';
import { NgSelectModule } from "@ng-select/ng-select";
import { CustomerSearchOnSubjectComponent } from './customer-search-on-subject/customer-search-on-subject.component';
import { EngagementHistoryBySubjectService } from './services/EngagementHistoryBySubject.service';
import { SendWICompletionMailComponent } from './sendWICompletionMail/sendWICompletionMail.component';
//import { ListFilterPipe } from 'ng-multiselect-dropdown/list-filter.pipe';
const appRoutes:Routes=[
  {path:'',component:LoginComponent},
 
  {path:'searchCustomer' , component:SearchCustomerComponent},
  {path:'addCustomerMessage',component:AddCustomerMessageComponent},
  {path: 'editCustomer',component:EditCustomerComponent},
  {path:'updateCustomerMessage',component:UpdateCustomerMessageComponent},
  {path:'addCustomer',component:AddCustomerComponent},
  {path:'customerExistsMessage',component:CustomerExistsMessageComponent},
  {path:'cutomerEngagementHistory',component:CutomerEnagagementHistoryComponent},
  
  {path:'emailreply',component:EmailreplyComponent},
  {path:'emailreply/:id/:token/:itemId/:replyType',component:EmailreplyComponent},
  {path:'loader',component:LoaderComponent},
  {path:'uploadAttachment',component:UploadAttachmentComponent},
  {path:'completeWorkitemConfirmation',component:CompleteWorkitemConfirmationMessageComponent},
  
 
  {path:'app-singleWIReport',component:SingleWIReportComponent},
{path:'workitemSavedMessageComponent', component:WorkitemSavedMessageComponent},
  {path:'emailReplyHistoryComponent', component:EmailReplyHistoryComponent},
  {path:'engagementHistory',component:EngagementHistoryComponent},
  {path:'wiCountByCategoryReport' ,component:WICountByCategoryReportComponent},
  {path:'wiCountByTypeReport',component:WICountByTypeReportComponent},
  
  {path:'updateEmailCongiguration',component:UpdateEmailComponent},
 {path:'agentProfileComponent', component:AgentProfileComponent},
  
  
  {path:'addUser',component:AddUserComponent},
   {path:'addQueueComponent',component:AddQueueComponent},
   {path:'editQueueComponent',component:EditQueueComponent},
   {path:'editUser',component:EditUserComponent},
   {path:'wiSLAReport',component:WorkItemSLAReportComponent},
   {path:'reportByAll',component:ReportByAllComponent},
   {path:'reportByMailbox',component:ReportByMailboxComponent},
   {path:'reportByCategory',component:ReportByCategoryComponent},
   {path:'reportByAgent',component:ReportByAgentComponent},
   
   {path:'reassignWorkitem',component:ReassignWorkitemComponent},
   {path:'changePassword',component:ChangePasswordComponent},
   {path:'reopenConfirmationComponent',component:ReopenConfirmationComponent},
  
  
   {path:'addManualWorkitem',component:AddManualWorkitemComponent},
   {path:'uploadExcel',component:UploadExcelComponent},
   {path:'excelWorkitemController',component:ExcelWorkitemControllerComponent},
   
   {path:'cardComponent',component:CardComponent},
   
   
   {path:'addSkill',component:AddSkillComponent},
   {path:'reminder',component:ReminderConfigurationComponent},
   {path:'securityQuestions',component:SecurityQuestionsComponent},
   {path:'securityVerification',component:SecurityQuestionVerificationComponent},
   {path:'base', component:BaseAdminControllerComponent,
  children:[
    {path:'testComponent', component:TestComponentComponent},
    {path:'reportsController',component:AdminDashboardComponent},
    {path:'agentWiDetailReport',component:AgentWIDetailReportComponent},
    {path:'emailConfiguration',component:EmailConfigurationComponent},
    {path:'addEmailConfiguration',component:AddEmailComponent},
    {path:'queueConfiguration',component:QueueConfigurationComponent},
    {path:'userConfiguration',component:UserConfigurationComponent},
    {path:'skillConfiguration',component:SkillCofigurationComponent},
    {path:'adminWorkitemController', component:AdminWorkitemControllerComponent},
    {path:'manualWorkitemController',component:ManualWorkitemControllerComponent},
    {path:'liveMonitor',component:LiveMonitorComponent},
    {path:'workitemSearch',component:WorkitemSearchControllerComponent},
    {path: 'dashboard', component: DashComponent },
    {path:'wiDashboard',component:WIDashboardComponent},
    {path:'agentDashBoard',component:AgentDashboardComponent},
    {path:'searchHistory',component:SearchHistoryComponent},
    {path:'landingDashboard',component:LandingDashboardComponent},
    {path:'customFields',component:CustomFieldsComponent},
    {path:'engagementSearch',component:CustomerSearchOnSubjectComponent},
  ]},
   

   {path:'reminderPopupComponent', component:ReminderPopupComponent},
   {path:'whatsappChatHistoryComponent',component:WhatsappChatHistoryComponent},
   {path:'addCustomField',component:AddCustomFieldsComponent},
   {path:'workitemCustomFields',component:WorkItemCustomFieldsComponent},
   {path:'notificationConfiguration',component:NotificationConfigurationComponent},
   {path:'completeWorkitemHistory',component:WorkitemCompleteHistoryComponent},
   {path:'completeProperties',component:CompletePropertiesComponent},
   {path:'sendWICompletionMail',component:SendWICompletionMailComponent},
   
]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AgentDashboardComponent,
    SafeHtmlPipe,
    OrderByPipe,
    //ListFilterPipe,
    ListFilterPipe,
    FilterUsersList,
    SearchCustomerComponent,
    AddCustomerMessageComponent,
    EditCustomerComponent,
    UpdateCustomerMessageComponent,
    AddCustomerComponent,
    CustomerExistsMessageComponent,
    CutomerEnagagementHistoryComponent,
    SearchHistoryComponent,
    EmailreplyComponent,
    LoaderComponent,
    UploadAttachmentComponent,
    CompleteWorkitemConfirmationMessageComponent,
    ErrorComponent,
    WorkitemSavedMessageComponent,
    EmailReplyHistoryComponent,
    AdminDashboardComponent,
    AgentWIDetailReportComponent,
    SingleWIReportComponent,
    EngagementHistoryComponent,
    WICountByCategoryReportComponent,
    WICountByTypeReportComponent,
    EmailConfigurationComponent,
    AddEmailComponent,
    UpdateEmailComponent,
    AddQueueComponent,
    AgentProfileComponent,
    QueueConfigurationComponent,
    UserConfigurationComponent,
    AddUserComponent,
    EditQueueComponent,
    EditUserComponent,
    WorkItemSLAReportComponent,
    ReportByAllComponent,
    ReportByAgentComponent,
    ReportByCategoryComponent,
    ReportByMailboxComponent,
    LiveMonitorComponent,
    ReassignWorkitemComponent,
    ChangePasswordComponent,
    ReopenConfirmationComponent,
    ManualWorkitemControllerComponent,
    AddManualWorkitemComponent,
    AdminWorkitemControllerComponent,
    UploadExcelComponent,
    ExcelWorkitemControllerComponent,
    DashComponent,
    CardComponent,
    AgentAvailabilityStatusChartComponent,
    AgentWiseCompletedItemsChartComponent,
    QueueTypeStatusChartComponent,
    StoreSessionsChartComponent,
    AgentSkillDistributionChartComponent,
    WIDashboardComponent,
    QueuewiseWIStatusChartComponent,
    AverageHandlingTimeByQueueChartComponent,
    CustomDateTimePickerComponent,
    SkillCofigurationComponent,
    AddSkillComponent,
    Customdatepicker1r1Component,
    NgxChartsComponent,
    ReminderConfigurationComponent,
    BaseAdminControllerComponent,
    TestComponentComponent,
    ReminderPopupComponent,
    WhatsappChatHistoryComponent,
    LandingDashboardComponent,
    CustomFieldsComponent,
    AddCustomFieldsComponent,
    WorkItemCustomFieldsComponent,
    NotificationConfigurationComponent,
    NotificationPopUpComponent,
    SecurityQuestionsComponent,
    SecurityQuestionVerificationComponent,
    WorkitemCompleteHistoryComponent,
    QAMonitorComponent,
    CompletePropertiesComponent,
    CategorywiseWIStatusChartComponent,
    WorkitemSearchControllerComponent,
    CustomerSearchOnSubjectComponent,
    SendWICompletionMailComponent,
  ],
  imports: [
    BrowserModule,
    NgMultiSelectDropDownModule,
    FormsModule,
     ReactiveFormsModule,
     HttpClientModule,
     MatDialogModule,
     MatIconModule,
     MatSidenavModule,
     AngularEditorModule,
     MatProgressSpinnerModule,
     NgxPaginationModule,
     MatNativeDateModule,
     MatSelectModule,
     Ng2SearchPipeModule,
  RouterModule.forRoot(appRoutes),
  BrowserAnimationsModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatButtonToggleModule,
  MatButtonModule,
  
  NgMultiSelectDropDownModule.forRoot(),
  MatGridListModule,
  MatCardModule,
  MatMenuModule,
  ChartsModule,
  LayoutModule,
  NgxMaterialTimepickerModule,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  NgxChartsModule,
  ModalModule,
  NgSelectModule
 // MomentTimezonePickerModule
  ],
  providers: [LoginService,ForgotPasswordService,ShowDashboardService,EmailWorkItemDetailService,IVRWorkItemDetailService,AddCustomerService,CustomerHistoryService,EmailReplyService,UpdateAgentStatusService,CompletedWorkItemsService,  WorkItemStatusChangeService, LogoutService,
 LinkedWorkItemDetailService,  IVRWorkitemRemarksService,ReportsService,CategoryAndSubCategoryService , EmailHistoryService,PasswordResetService,ConfigureEmailService,ConfigureUserService,AgentProfileService,GetQueueListService,AddNewQueueService,EditQueueService,GetApplicationsService,GetSkillsService,ChatWorkItemDetailService, SearchHistoryService
 ,LiveMontorService, ChangePasswordService ,DatePipe, CreateManualWorkitemService,PropertiesList,DownloadAttachmentService,LoadAdminWorkitemService,SaveAndReassignWorkitemService,ThemeService,AgentReportingDashboardService, QueueReportingDashboardService, SkillConfigurationService,{provide: OWL_DATE_TIME_LOCALE, useValue: HourPickerFormat},
 ReminderConfigurationService,WebSocketService,WhatsappWorkitemService,WorkitemReminderService,LoadUserDetailsService,CustomFieldsService,NotificationService,SecurityQuestionsService,CompleteWorkitemHistoryService, SearchWorkitemService, EngagementHistoryBySubjectService] ,
  bootstrap: [AppComponent]
})
export class AppModule { }
