import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { WorkitemItemDetailInput } from '../models/workitemDetailinput.model';
import { CompletedIVRWorkitemDetailOutput } from '../models/completedIvrWorkitemDetailOutput.model';
import { IVRWorkitemItemDetailOutput } from '../models/ivrworkitemDetailOutput.model';



@Injectable()
export class IVRWorkItemDetailService{

// public StoredData: BehaviorSubject<> = new BehaviorSubject<WorkitemDetails[]>([]);  
// currentData = this.StoredData.asObservable();
//   storeddata(data: any) {
//     this.StoredData.next(data)
//   }
  private Url=environment.baseUrl+'getIVRWorkItemDetails';
constructor(private http:HttpClient){
    
}
getIVRWorkitemStateChange(workItemDetailInput:WorkitemItemDetailInput){

// if(workitemState==="completed"){
//   return this.http.post<CompletedIVRWorkitemDetailOutput>(this.Url,workItemDetailInput , {observe: 'response'});
// }
     return this.http.post<IVRWorkitemItemDetailOutput>(this.Url,workItemDetailInput , {observe: 'response'})
   
}  

}
