import { Component, OnInit, Inject  } from '@angular/core';
import { CustomerHistoryService } from '../services/CustomerHistory.service';
import { CustomerHistoryOutput } from '../models/CustomerHistoryOutput.model';
import { CustomerDetails } from '../models/CustomerDetails.model';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-cutomer-enagagement-history',
  templateUrl: './cutomer-enagagement-history.component.html',
  styleUrls: ['./cutomer-enagagement-history.component.css']
})
export class CutomerEnagagementHistoryComponent implements OnInit{
  panelOpenState = false;
  customerHistoryOutput:Array<CustomerHistoryOutput>;
  customerDetails:any;
 icon:Array<Number>=new Array<Number>();
 icon2:Array<boolean> = new Array<boolean>();
  isLoaded: boolean = false;
  newDate:Date;
  p:number;
  showDetails:boolean = false;
 

  constructor(
    private customerHistoryService: CustomerHistoryService,   
    private dialog:MatDialog,
    private dialogRef: MatDialogRef<CutomerEnagagementHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    
    this.customerDetails = data.customerDetails;
    if(this.customerDetails.customerVO !=null){
      this.showDetails = true;
    }
   this.isLoaded = true;
   
   }

  ngOnInit() {
	this.icon2.push(false);   
  }

pushStatus(){
  this.icon2.push(false);
}

  toggleIcon(i){
   this.icon2[i] = !this.icon2[i];
  }
  close(){
    this.dialogRef.close();
  }
}
