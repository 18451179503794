import { Component, OnInit } from '@angular/core';
import { WhatsappWorkitemService } from 'src/app/services/WhatsappWorkitem.service';
import { EmailWorkItemDetailService } from 'src/app/services/emailworkitemdetail';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { WhatsappWorkitemDetails } from 'src/app/models/WhatsappWorkitemDetails.model';
import { CrmUser } from 'src/app/models/crmuser.model';
import { WorkItemsFromQueueInput } from 'src/app/models/WorkItemsFromQueueInput.model';
import { WorkitemsFromQueueOutput } from 'src/app/models/WorkitemsFromQueueOutput.model';
import { WorkitemItemDetailInput } from 'src/app/models/workitemDetailinput.model';
import { Router } from '@angular/router';
import { SearchWorkitemInput } from 'src/app/models/SearchWorkitemInput.model';
import { SearchWorkitemService } from '../services/SearchWorkitem.service';
import { ReassignWorkitemComponent } from 'src/app/live-monitor/reassign-workitem/reassign-workitem.component';
import { ConfigureUserService } from 'src/app/services/configureUser.service';
import { WorkItemCategory } from 'src/app/models/workitemcategory.model';
import { CategoryAndSubCategoryService } from 'src/app/services/categoryandsubcategory.service';
import { PropertiesList } from 'src/app/services/PropertiesList.service';
import { LiveMontorService } from 'src/app/services/LiveMonitor.service';
import { WorkitemSavedMessageComponent } from 'src/app/messagePopUp/workitem-saved-message/workitem-saved-message.component';


@Component({
  selector: 'app-workitem-search-controller',
  templateUrl: './workitem-search-controller.component.html',
  styleUrls: ['./workitem-search-controller.component.css']
})
export class WorkitemSearchControllerComponent implements OnInit {

  token: string;
  id: number;
  workitemSearchForm: FormGroup;
  submitted: boolean;
  crmUser: CrmUser;
  whatsappMessagesList: Array<any> = new Array<any>();
  whatsappWorkitemDetails: WhatsappWorkitemDetails;
  workitemItemDetailInput: WorkitemItemDetailInput;
  workitemsFromQueueOutput: WorkitemsFromQueueOutput;
  workitemsFromQueueInput: WorkItemsFromQueueInput;
  searchWorkitemInput:SearchWorkitemInput;
  showWorkitems:boolean =false;
  iqdWorkItemInfoVOList = [];
  workItemDetailOutput: Array<any> = [];
  userList:any[];
  workitemCategory:Array<WorkItemCategory> = new Array<WorkItemCategory>();
  workitemProperties = [];
  workitemsFromQueueOutputArray=[];
  buttonDisabled:boolean = true;

  constructor(
    private whatsappWorkitemService: WhatsappWorkitemService,
    private emailWorkItemDetailService: EmailWorkItemDetailService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private dialog: MatDialog,
    private router: Router,
    private searchWorkitemService:SearchWorkitemService,
    private configureUserService:ConfigureUserService,
    private categoryandsubcategoryService:CategoryAndSubCategoryService,
    private propertiesList:PropertiesList,
    private liveMontorService:LiveMontorService,
  ) { }

  ngOnInit() {
    this.submitted = false;
    this.crmUser = new CrmUser();
    this.workitemsFromQueueInput = new WorkItemsFromQueueInput();
    this.workitemsFromQueueOutput = new WorkitemsFromQueueOutput();
    this.workitemItemDetailInput = new WorkitemItemDetailInput();
    this.whatsappWorkitemDetails = new WhatsappWorkitemDetails();
    this.searchWorkitemInput = new SearchWorkitemInput();

    this.loginService.currentData.subscribe(data => {
      if (data == null || data.sessionToken == null) {
        this.router.navigate(['']);
      }
      else {
        
        this.id = data.crmUserId;
        this.token = data.sessionToken;
        this.crmUser = data;
        //  this.isSME = data.sme;

      }
    }
    );
    this.configureUserService.getUserList(this.id,this.token).subscribe(data=>
      
            {
              
               this.userList= data.body['users'];
      
            });

            this.categoryandsubcategoryService.currentData.subscribe((data)=>{
              
              this.workitemCategory=data;
        
            });
        
            this.propertiesList.currentData.subscribe(data=>{
              
              this.workitemProperties = data;
        
            });

    this.setFormControls();
  }


  setFormControls() {

    this.workitemSearchForm = this.formBuilder.group({
      workitemId: new FormControl('', [
        // Validators.required
      ]),

      email: new FormControl('', [
        // Validators.required,
        Validators.email
      ]),

    });
  }

  onSubmit(){

    this.submitted = true;
    this.searchWorkitemInput.crmUserId = this.id;
    this.searchWorkitemInput.sessionToken = this.token;
    
    this.searchWorkitemInput.workItemId = this.workitemSearchForm.value.workitemId;
    this.searchWorkitemInput.searchString = this.workitemSearchForm.value.email;
   
    if(this.submitted && this.workitemSearchForm.valid){
      if(( this.workitemSearchForm.value.workitemId=='') && (this.workitemSearchForm.value.email=='')){
        alert("Please enter atleast one value");
        this.iqdWorkItemInfoVOList= [];
        this.showWorkitems = false;
        return;
      }
      this.searchWorkitemService.searchWorkitem(this.searchWorkitemInput).subscribe(data=>{
        
        console.log(data);
        
        if(data.status==200 && data.body["responseType"]=="SUCCESS"){
          this.iqdWorkItemInfoVOList = data.body['iqdWorkItemInfoVOList'];
          if(this.iqdWorkItemInfoVOList.length>0){
            this.showWorkitems = true;
          }else{
            this.showWorkitems = false;
            const dialogConfig= new MatDialogConfig();
            dialogConfig.disableClose=true;
            dialogConfig.autoFocus=true;
            dialogConfig.width="60%";
            dialogConfig.height="40%";
            dialogConfig.data = {
              popUpTitle:"Search WorkItem",
              messageToBeDisplayed:"No WorkItem Found" 
            };
            this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);
          }
        }
      });
    }

  }

  reassignWorkitem(workitemFromQueue){
    
    this.workitemItemDetailInput.crmUserId = this.id;
    this.workitemItemDetailInput.sessionToken = this.token;
    this.workitemItemDetailInput.workItemId = workitemFromQueue.workItemId;

    if(workitemFromQueue.workItemType=="MANUAL"){
      this.emailWorkItemDetailService.getManualWorkitemDetails(this.workitemItemDetailInput).subscribe(data=>{
        
        if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
          
          this.workItemDetailOutput=data.body['manualWorkItem'];
          this.openWorkitemForReassignment(workitemFromQueue,this.workItemDetailOutput);
        }

        else if(data.body['responseType']== 'SESSION_TIMEOUT'){

          alert("Session timeout!Please Login Again");
          this.loginService.storeddata(null);
          this.router.navigate(['']);
        }
        else{
          alert("Something went wrong");
        }

      });
    }


    if(workitemFromQueue.workItemType=="EMAIL"){
    this.emailWorkItemDetailService.getEmailWorkitemStateChange(this.workitemItemDetailInput).subscribe(data=>{
      
      if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
        
        this.workItemDetailOutput=data.body['emailWorkItem'];
        this.openWorkitemForReassignment(workitemFromQueue,this.workItemDetailOutput);
      }

      else if(data.body['responseType']== 'SESSION_TIMEOUT'){

        alert("Session timeout!Please Login Again");
        this.loginService.storeddata(null);
        this.router.navigate(['']);
      }
      else{
        alert("Something went wrong");
      }

    });
  }
  if(workitemFromQueue.workItemType=="WHATSAPP"){
  this.whatsappWorkitemService.getWhatsappWorkitemDetails(this.workitemItemDetailInput).subscribe(data=>{
    if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
      this.whatsappWorkitemDetails = data.body["whatsAppConversationWorkItem"];
      this.whatsappMessagesList = data.body["whatsAppWorkConversationHistoryList"];
      this.openWorkitemForReassignment(workitemFromQueue,this.whatsappWorkitemDetails);
    }
  });
  }

  // else if(workitemFromQueue.workItemType=="IVR"){
  //   this.ivrWorkItemDetailService.getIVRWorkitemStateChange(this.workitemItemDetailInput).subscribe(data=>{
  //     
  //     if(data.status==200 && data.body["responseType"]=="SUCCESS"){
  //       
  //       this.workItemDetailOutput = data.body['ivrWorkItem'];
  //       this.openWorkitemForReassignment(workitemFromQueue,this.workItemDetailOutput);
  //     }

  //     else if(data.body['responseType']== 'SESSION_TIMEOUT'){

  //       alert("Session timeout!Please Login Again");
  //       this.loginService.storeddata(null);
  //       this.router.navigate(['']);
  //     }

  //     else{
  //       alert("Something went wrong");
  //     }
  //   });

  // }
  // else if(workitemFromQueue.workItemType=="CHAT"){
  //   this.chatWorkItemDetailService.getChatWorkItem(this.workitemItemDetailInput).subscribe(
  //     data=>{
  //       
  //     if(data.status==200 && data.body["responseType"]=="SUCCESS"){
  //       
  //       this.workItemDetailOutput = data.body['liveChatWorkItem'];
  //       this.openWorkitemForReassignment(workitemFromQueue,this.workItemDetailOutput);
  //     }

  //     else if(data.body['responseType']== 'SESSION_TIMEOUT'){

  //       alert("Session timeout!Please Login Again");
  //       this.loginService.storeddata(null);
  //       this.router.navigate(['']);
  //     }

  //     else{
  //       alert("Something went wrong");
  //     }
  //     }
  //   );

  // }
  }


  openWorkitemForReassignment(workitemFromQueue,workItemDetailOutputt){
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        //dialogConfig.height = "618px";
        dialogConfig.width = "750px";
        dialogConfig.data = {
          usersList:this.userList,
          workitemFromQueue:workitemFromQueue,
          id:this.id,
          token:this.token,
          workitemCategory:this.workitemCategory,
          workitemDetailOutput:workItemDetailOutputt,
          whatsappMessagesList:this.whatsappMessagesList
        };
         this.dialog.open(ReassignWorkitemComponent,dialogConfig).afterClosed().subscribe(data=>{

          this.searchWorkitemService.searchWorkitem(this.searchWorkitemInput).subscribe(data=>{
            if(data.status==200 && data.body["responseType"]=="SUCCESS"){
              this.iqdWorkItemInfoVOList = data.body['iqdWorkItemInfoVOList'];
            }
          });
        
         });

    }


   


  //   private atLeastOneValidator = () => {
  //     return (controlGroup) => {
  //       
  //         let controls = controlGroup.controls;
  //         if ( controls ) {
  //           let theOne = Object.keys(controls).find(key=> controls[key].valid );
  //             if ( !theOne ) {
  //                 return {
  //                     atLeastOneRequired : {
  //                         text : 'At least one should be selected'
  //                     }
  //                 }
  //             }
  //         }
  //         return null;
  //     };
  // };

}
