import { Component, OnInit,Inject } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material';
import {MatDialog,MatDialogConfig} from '@angular/material';
import { FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import { Customer } from '../models/customer.model';
import { AddCustomerService } from '../services/addCustomer.service';
import {AddCustomerMessageComponent} from '../messagePopUp/addCustomerMessage.component';
import {AddCustomerComponent} from '../addCustomer/addCustomer.component';
import {EditCustomerComponent} from '../editCustomer/editCustomer.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-searchCustomer',
  templateUrl: './searchCustomer.component.html'
  
})
export class SearchCustomerComponent  implements OnInit{
  public name:string;
  public email:string;
  public facebookID:string;
  public   phoneNo:number;
  customer:Customer;
  showSearchResults:boolean=false;
  searchCustomer:Array<Customer>;
  private token:string;
  submitted = false;
  isShowMessage:boolean=false;
  showAddCustomer:boolean=false;
  private userId:number;
  addCustForm : FormGroup;
  searchCustForm:FormGroup;  
  sender:string='';   
  caller:number;   
  workItemType:string;    
  facebookId:string;      
  twitterId:string;
    constructor(private formBuilder: FormBuilder,
      private form:FormBuilder,
        private addCustomerService:AddCustomerService,
        private dialogRef: MatDialogRef<SearchCustomerComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private dialog:MatDialog,private route:ActivatedRoute){
          this.searchCustForm = this.formBuilder.group({  
            name: new FormControl('', [
              Validators.minLength(3),  
              Validators.maxLength(30),  
              Validators.pattern('^[a-zA-Z ]*$')]),
              phoneNo: new FormControl('', [  
               Validators.minLength(8),  
              // Validators.maxLength(12),  
              Validators.pattern('^([0|\+[0-9]{1,5})?([0-9]{12})$')]), 
            email: new FormControl('', [  
              Validators.minLength(5),  
              Validators.maxLength(80),  
              //Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
            ]),  
            facebookID: new FormControl('', [  
              Validators.minLength(5),  
              Validators.maxLength(80),  
              Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
            ]), 
            twitterID: new FormControl('', [  
              Validators.minLength(5),  
              Validators.maxLength(80),  
              Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
            ])
          });  
          //this.route.queryParams.subscribe(params => {
            this.userId = data.id;
            this.token = data.token;
            this.sender=data.sender;
            this.caller=data.caller;
            this.workItemType=data.wiType;
            this.email=this.sender;
            this.phoneNo=this.caller;

       // });
        // this.token = data.token;
        // this.userId=data.id;
    }
        
        

        
  
   
         ngOnInit() {
            
          
                                        this.customer=new Customer();
                                //         this.route.params.forEach((urlParams) => {
                                //           this.token= urlParams['token'];
                                //           this.userId=urlParams['id'];
                                    
                                //  });
                                }
                                     // convenience getter for easy access to form fields
                                     get f() { return this.addCustForm.controls; }
                                   

// onAddSubmit() {
//     
//     this.submitted = true;
//     if(this.workItemType=='EMAIL' && (this.addCustForm.value.email==null || this.addCustForm.value.email==''))
//     {
//       this.addCustForm.invalid;
//     }
//     else if(this.workItemType=='IVR' && (this.addCustForm.value.phoneNo==null || this.addCustForm.value.phoneNo==''))
//     {
//       this.addCustForm.invalid;
//     }
//    if(this.addCustForm.valid){
//       this.customer.customerName= this.addCustForm.value.name;
//       this.customer.customerEmailAddress=this.addCustForm.value.email;
//       this.customer.customerFacebookId=this.addCustForm.value.facebookID;
//       this.customer.customerTwitterId=this.addCustForm.value.twitterID;
//       this.customer.customerContactNumber=this.addCustForm.value.phoneNo;
//      this.customer.sessionToken=this.token;
//      this.customer.crmUserId=this.userId;
//       this.addCustomerService.addCustomer(this.customer)
//       .subscribe(
//       data=>{
//     
//       console.log(data);
//       if(data.status==200){
//       this.isShowMessage=true;
//       const dialogConfig= new MatDialogConfig();
//       dialogConfig.disableClose=true;
//       dialogConfig.autoFocus=true;
//       dialogConfig.width="40%";
//       this.dialog.open(AddCustomerMessageComponent,dialogConfig);
//       }
//         });}
//     //this.dialogRef.close();
//     }

    get searchCust(){return this.searchCustForm.controls};
    onSearch(){
        
        this.submitted = true;
        if(this.searchCustForm.valid){
          console.log("valid");
           this.customer.customerName= this.searchCustForm.value.name;
            this.customer.customerEmailAddress=this.sender;
           this.customer.customerFacebookId=this.facebookId;
           this.customer.customerTwitterId=this.twitterId;
            this.customer.customerContactNumber=this.caller;
          this.customer.sessionToken=this.token;
          this.customer.crmUserId=this.userId;
           this.addCustomerService.searchCustomer(this.customer)
           .subscribe(
           data=>{
         
         if(data.status==200){
           console.log(data);
           if(data.body["searchResults"]!=null && data.body["searchResults"]!='')
           {
           this.searchCustomer =data.body["searchResults"];
           this.showSearchResults=true;
           this.showAddCustomer=false;
         }
         else{
          this.showSearchResults=false;
          this.showAddCustomer=true;
         }
             }});}
    }
    onAdddCust(){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        ///dialogConfig.width="50%";
      //   dialogConfig.position = {
      //     'top': '130',
      //     left: '130'
      // };
      dialogConfig.data = {
        id: this.userId,
        token:  this.token,
        sender: this.sender,
        wiType:this.workItemType,
        caller:this.caller
    };
       this.dialog.open(AddCustomerComponent,dialogConfig);
  //      if(this.sender!=null && this.sender!='')
  //      {
  //      window.open('/addCustomer?id='+this.userId+'&token='+this.token+'&sender='+this.sender+'&wiType='+this.workItemType, '_blank', 'location=yes,height=570,width=900,scrollbars=yes,status=yes');
  //   }
  // else if(this.caller!=null)
  // {
  //   window.open('/addCustomer?id='+this.userId+'&token='+this.token+'&caller='+this.caller+'&wiType='+this.workItemType, '_blank', 'location=yes,height=570,width=900,scrollbars=yes,status=yes');
  // }
  }
    updateCustInfo(customer:Customer){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
       // dialogConfig.width="50%";
      //   dialogConfig.position = {
      //     'top': '130',
      //     left: '130'
      // };
      dialogConfig.data = {
        id: this.userId,
        token:  this.token,
        customerToBeUpdated: customer
    };
        this.dialog.open(EditCustomerComponent,dialogConfig); 
       

    }
    close()
    {
      this.dialogRef.close();
    }
}