import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { LiveMonitorComponent } from '../../live-monitor.component';

@Component({
  selector: 'app-reopen-confirmation',
  templateUrl: './reopen-confirmation.component.html',
  styleUrls: ['./reopen-confirmation.component.css']
})
export class ReopenConfirmationComponent implements OnInit {

  constructor(
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private route:ActivatedRoute,
    private dialog:MatDialog,
    private dialogRef: MatDialogRef<LiveMonitorComponent>
  ) { }

  ngOnInit() {
  }

  confirmComplete(){
    console.log("workitemid in reopen component"+this.dialogRef.id);
    this.dialogRef.close(this.dialogRef.id);
  }

  close(){
    this.dialogRef.close(0);
  }

}
