import { Component, OnInit , ElementRef, ViewChild,Inject} from '@angular/core';
import { Router,NavigationExtras,ActivatedRoute } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA,MatDialogConfig, MatDialog} from '@angular/material';
import {FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
import { EditCustomerComponent } from '../editCustomer/editCustomer.component';
import { ConfigureEmail } from '../models/configureEmail.model';
import { ConfigureEmailService } from '../services/configureEmail.service';
import {UpdateCustomerMessageComponent} from '../messagePopUp/updateCustomerMessage.component';
@Component({
    selector: 'app-updateemail',
    templateUrl: './update-email.component.html',
    styleUrls:['./update-email.component.css']
  })
export class UpdateEmailComponent implements OnInit{
  token:string;
  id:number;
  editEmailForm:FormGroup; 
  submitted:boolean=false;
  configureEmailData:any;
  queueList = [];
  emailTemplateList=[];
  selectedQueue:any;
  selectedTemplate:any;
  updatedDataMailbox:ConfigureEmail;
    constructor(private formBuilder: FormBuilder,private route:ActivatedRoute,private router:Router,
        private dialog:MatDialog,private dialogRef: MatDialogRef<UpdateEmailComponent>,private configureEmailService:ConfigureEmailService, @Inject(MAT_DIALOG_DATA) data){
          this.route.queryParams.subscribe(params => {
            this.id = data.id;
            this.token = data.token;
           this.configureEmailData=data.emailDataToBeUpdated;
        });
        this.editEmailForm=this.formBuilder.group({
          mailBoxName:new FormControl('',[Validators.required]),
      queueName:new FormControl({value:'',disabled:true}, [  
        Validators.required,  
        Validators.minLength(3)]),
        templateName:new FormControl('',[Validators.required]),
        emailId:new FormControl({value:'',disabled:true}, [ 
          Validators.required, 
          Validators.minLength(5),  
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
        ]), 
        password:new FormControl('', [ 
          Validators.required, 
          Validators.minLength(5),  
          Validators.maxLength(80),  
        ]), 
        sendingUrl:new FormControl({value:'',disabled:true}),
        sendingPort:new FormControl({value:'',disabled:true}),
        folderName:new FormControl({value:'',disabled:true}),
        scanEnabled:new FormControl(''),
        receivingUrl:new FormControl({value:'',disabled:true},[Validators.required]),
        receivingPort:new FormControl({value:'',disabled:true},[Validators.required]),
        replyEnabled:new FormControl(''), 
    })
      }
    ngOnInit() {
      window.scrollTo(0,0);
      this.configureEmailService.getQueueList(this.id,this.token).subscribe(
        data=>{
        if(data.status==200)
        {
          this.queueList=data.body['queueList'];
        }
        });
        this.configureEmailService.getEmailTemplateList(this.id,this.token).subscribe(
          data=>{
            if(data.status==200)
            {
              this.emailTemplateList=data.body['emailTemplates'];
            }
          }
        );
        this.selectedQueue=this.configureEmailData.queueId;
        this.selectedTemplate=this.configureEmailData.templateId;
    }
   
    get f() {return this.editEmailForm.controls; }

    onUpdateEmailSubmit(){
      this.updatedDataMailbox=new ConfigureEmail();
      this.submitted=true;
      if(this.editEmailForm.valid)
      {
      this.updatedDataMailbox.mailBoxName=this.configureEmailData.displayName;
      this.updatedDataMailbox.password=this.configureEmailData.password;
      this.updatedDataMailbox.replyEnabled=this.configureEmailData.replyEnabled;
      this.updatedDataMailbox.scanEnabled=this.configureEmailData.enabled;
      this.updatedDataMailbox.mailBoxId=this.configureEmailData.id;
      this.updatedDataMailbox.emailTemplate=this.editEmailForm.value.templateName;
        this.configureEmailService.editMailBox(this.updatedDataMailbox,this.token,this.id).subscribe(
        data=>
        {
          if(data.status==200 && data.body["responseType"]=="SUCCESS")
          {
            const dialogConfig= new MatDialogConfig();
        dialogConfig.disableClose=true;
        dialogConfig.autoFocus=true;
        dialogConfig.width="60%";
        dialogConfig.height="40%";
        this.dialog.open(UpdateCustomerMessageComponent,dialogConfig);
          }
          else if(data.status==200 && data.body["responseType"]=="SERVER_ERROR"){
            alert(data.body['responseMessage']);
          }
          else
          {
            alert("Unable to update information");
          }
          this.dialogRef.close();
        }
      )
        
    }}
 
    close()
    {
        this.dialogRef.close();
    }




 
}