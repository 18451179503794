import { Component, OnInit,Input } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import {MatDialog,MatDialogConfig} from '@angular/material';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material';
import {EditCustomerComponent} from '../editCustomer/editCustomer.component';
@Component({
  selector: 'app-updateCustomerMessage',
  templateUrl: './updateCustomerMessage.component.html'
  
})
export class UpdateCustomerMessageComponent implements OnInit {
    
 // @Input()  showMessage: boolean;
  constructor(private activatedRoute:ActivatedRoute,
    private router:Router,
    private route:ActivatedRoute,
    private dialog:MatDialog,
    private dialogRef: MatDialogRef<EditCustomerComponent>){}
    ngOnInit(){
  }
  close()
  {
    //this.showMessage=false;
    this.dialogRef.close();
  }
    
   
}
