import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepicker } from '@angular/material';
import { ReportsService } from 'src/app/services/reports.service';
import { AgentWIDetailReportComponent } from '../agentWIDetailReport.component';
import { DatePipe } from '@angular/common';
import { saveAs } from "file-saver";
import { CategoryAndSubCategoryService } from 'src/app/services/categoryandsubcategory.service';
import { WorkItemCategory } from 'src/app/models/workitemcategory.model';
import { ShowDashboard } from 'src/app/models/showDashboard.model';
import * as _moment from 'moment';
import {defaultFormat as _rollupMoment, Moment} from 'moment';
import { CompleteSystemReport } from 'src/app/models/CompleteSystemReport.model';
import { ReportFrequencyEnum } from 'src/app/ReportFrequencyEnum';
import { ReportTypeFilterEnum } from 'src/app/ReportTypeFilterEnum';
const moment = _rollupMoment || _moment;
@Component({
    selector: 'app-report-by-category',
    templateUrl: './report-by-category.component.html',
    providers:[DatePipe]
  })
  export class ReportByCategoryComponent implements OnInit{

    token:string;
    id:number;
    startDate:string;
    endDate:string;
    minDate:Date;
    isValidDate:any;
    parts:any;
    submitted:boolean=false;
    maxDate=new Date();
    error:any={isError:false,errorMessage:''};
    ReportByCategoryForm:FormGroup;
    workitemCategory:Array<WorkItemCategory>=new Array<WorkItemCategory>();
    showDashBoard:ShowDashboard;
    // selectedCategory:number;
    pickerDate:string;
    showStartDate:boolean;
    showMonth:boolean;
    completeSystemReport:CompleteSystemReport;
    reportType:string;
    @ViewChild('picker1',{read:undefined,static:false}) datePicker: MatDatepicker<Date>;


    constructor(
      private categoryandsubcategoryService:CategoryAndSubCategoryService,
      private reportService:ReportsService,
       private dialogRef: MatDialogRef<ReportByCategoryComponent>,
        private router:Router,
        @Inject(MAT_DIALOG_DATA) data,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe){
        this.token=data.sessionToken;
        this.id=data.userId;
        this.workitemCategory = data.workitemCategory;
        this.reportType=data.reportType;
        
        this.datePipe.transform(this.maxDate, 'mm/dd/yyyy');
        
  
    }

    ngOnInit() {
        
        this.completeSystemReport = new CompleteSystemReport();
        this.showMonth = false;
        this.showStartDate = true;
        // this.selectedCategory = 0;
        this.showDashBoard = new ShowDashboard();
        this.showDashBoard.crmUserId = this.id;
        this.showDashBoard.sessionToken = this.token;
        this.ReportByCategoryForm = this.formBuilder.group({
          
          frequency:new FormControl('HOURLY',[Validators.required]), 
          selectedCategory:new FormControl('', [Validators.required]),
          startDate: new FormControl('', [  Validators.required]),  
          month: new FormControl('', [Validators.required])
             
        }); 
    }

  clickRadioFilter(option){

    if((option=="HOURLY") || (option=="WEEKLY")){
      this.showMonth = false;
      this.showStartDate = true;
    }
    else {
      this.showStartDate = false;
      this.showMonth = true;
    }
  }

    get f() {return this.ReportByCategoryForm.controls; }
          
    close()
    {
      this.dialogRef.close();
    }

    closeDatePicker(event) {
      
      
      this.pickerDate = this.datePipe.transform(event,"MM-dd-yyyy");
      this.ReportByCategoryForm.get('month').setValue(this.datePipe.transform(event,"dd-MM-yy"));
      console.log(this.pickerDate);
      this.ReportByCategoryForm.get("month").setValue(new Date(this.pickerDate));
      this.datePicker.close();
      this.pickerDate = this.datePipe.transform(event,"dd-MM-yy");
}
    

    downloadReportByCategory(){
      
      if(this.ReportByCategoryForm.value.frequency==ReportFrequencyEnum.MONTHLY){
        this.completeSystemReport.startDate =this.pickerDate;
        this.ReportByCategoryForm.get("startDate").setValue(this.ReportByCategoryForm.value.month);
      }
      else{
        this.completeSystemReport.startDate = this.datePipe.transform(this.ReportByCategoryForm.value.startDate,"dd-MM-yy");
        this.ReportByCategoryForm.get("month").setValue(this.ReportByCategoryForm.value.startDate);
      }
      this.submitted = true;
      this.completeSystemReport.accessedBy = 1;
      this.completeSystemReport.crmUserId = this.id;
      this.completeSystemReport.frequency = this.ReportByCategoryForm.value.frequency;;
      this.completeSystemReport.reportFilterType = ReportTypeFilterEnum.CATEGORY;
      this.completeSystemReport.reportId = 1;
      this.completeSystemReport.reportType = "EXCEL";
      this.completeSystemReport.selectedFilterId = this.ReportByCategoryForm.value.selectedCategory;
      this.completeSystemReport.sessionToken = this.token;
      
      this.completeSystemReport.endDate = "";
      this.completeSystemReport.reportWorkItemType=this.reportType;

      if(this.ReportByCategoryForm.valid) {
        this.reportService.getCompleteSystemReport(this.completeSystemReport).subscribe(
          data => {
            console.log(data);
            if(this.reportType== 'CHAT' ){
              saveAs(data,"ChatReportByCategory");
            }else{
            saveAs(data,"ReportByCategory");
            }
          },
          err => {
            alert("Problem while downloading the file.");
            console.error(err);
          });
          this.dialogRef.close();
          }
    }
  }