import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";

import { WorkitemItemDetailInput } from '../models/workitemDetailinput.model';
import { WorkitemItemDetailOutput } from '../models/workitemDetailOutput.model';


@Injectable()
export class LinkedWorkItemDetailService{



  private Url=environment.baseUrl+'getLinkedEmailWorkItemContents';
constructor(private http:HttpClient){
    
}
getLinkedWorkitemContent(workItemDetailInput:WorkitemItemDetailInput){


     return this.http.post<WorkitemItemDetailOutput>(this.Url,workItemDetailInput , {observe: 'response'});
   
}  

}
