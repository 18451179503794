import { Component, OnInit , ElementRef, ViewChild,Inject} from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import { ReportsService } from '../services/reports.service';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
import { saveAs } from "file-saver";
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-wiCountByCategoryReport',
    templateUrl: './wiCountByCategory.component.html',
    providers:[DatePipe]
  })
export class WICountByCategoryReportComponent implements OnInit{
  token:string;
  id:number;
  wiCountByCategoryForm:FormGroup; 
  startDate:string;
  endDate:string;
  minDate:Date;
  isValidDate:any;
  submitted:boolean=false;
  maxDate=new Date();
  error:any={isError:false,errorMessage:''};
    constructor(private reportService:ReportsService, private dialogRef: MatDialogRef<WICountByCategoryReportComponent>,
      private router:Router,@Inject(MAT_DIALOG_DATA) data,private formBuilder: FormBuilder,private datePipe: DatePipe){
        this.token=data.sessionToken;
        this.id=data.userId;
        this.wiCountByCategoryForm = this.formBuilder.group({  
          endDate: new FormControl('', [  
            Validators.required])
        });  
        this.datePipe.transform(this.maxDate, 'mm/dd/yyyy');
      }
    ngOnInit() {
   
    }
    get f() {return this.wiCountByCategoryForm.controls; }
    close()
  {
    this.dialogRef.close();
  }
 
  downloadWICountByCategoryReport(){
    
    this.submitted=true;
    if(this.wiCountByCategoryForm.valid)
    {
this.endDate=this.datePipe.transform(this.wiCountByCategoryForm.value.endDate,"dd-MM-yy");
this.reportService.wiCountByCategory(1,this.id,this.endDate,1,"EXCEL",this.token).subscribe(
  data => {
    
    saveAs(data,"wiCountByCategoryReport");
  },
  err => {
    alert("Problem while downloading the file.");
    console.error(err);
  });
  this.dialogRef.close();
  }}




 
}