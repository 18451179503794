import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AgentDashboardComponent } from 'src/app/agent-dashboard/agent-dashboard.component';

@Component({
  selector: 'app-complete-workitem-confirmation-message',
  templateUrl: './complete-workitem-confirmation-message.component.html',
  styleUrls: ['./complete-workitem-confirmation-message.component.css']
})
export class CompleteWorkitemConfirmationMessageComponent implements OnInit {
agentDashBoardComponentObject:AgentDashboardComponent;
  constructor(
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private route:ActivatedRoute,
    private dialog:MatDialog,
    private dialogRef: MatDialogRef<AgentDashboardComponent>){

    }


  ngOnInit() {
  }

  confirmComplete(){
    console.log("workitemid is"+this.dialogRef.id);
    this.dialogRef.close(this.dialogRef.id);
  }

  close(){
    this.dialogRef.close(0);
  }

}
