import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AgentProfile } from '../models/AgentProfile.model';

@Component({
  selector: 'app-agent-profile',
  templateUrl: './agent-profile.component.html',
  styleUrls: ['./agent-profile.component.css']
})
export class AgentProfileComponent implements OnInit {
  agentProfile:AgentProfile = new AgentProfile();
  constructor(
    private dialogRef: MatDialogRef<AgentProfileComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      
         this.agentProfile = data.agentProfile;
   }

  ngOnInit() {
    // this.agentProfile = new AgentProfile();
  }
  close()
  {
    this.dialogRef.close();
  }

}
