import { Component, OnInit , ElementRef, ViewChild,Inject} from '@angular/core';
import { Router,NavigationExtras,ActivatedRoute } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA,MatDialogConfig, MatDialog} from '@angular/material';
import {FormGroup,FormBuilder,FormControl,Validators,FormArray} from '@angular/forms';
import {UpdateAgentStatusService} from '../services/updateAgentStatus.service';
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
import {LogoutService} from '../services/logout.service';
import { Users } from '../models/users.model';
import { ConfigureUserService } from '../services/configureUser.service';
import { UpdateCustomerMessageComponent } from '../messagePopUp/updateCustomerMessage.component';
@Component({
    selector: 'app-edituser',
    templateUrl: './edit-user.component.html',
  })
export class EditUserComponent implements OnInit{
  token:string;
  id:number;
  editUserForm:FormGroup; 
  submitted:boolean=false;
  updateAgentStatusModel:UpdateAgentStatusModel;
  skillList = [];
  selectedItemss = [];
  selectedApps=[];
  public userData:Users;
  dropdownSettings = {};
  dropdownSettingsApp={};
  applicationList=[];
  updateUser:any;
  skills:[];
  userStatus:string;
  assignmentThreshold:number;
  extensionNumber:number;
  location:string;
    constructor(private formBuilder: FormBuilder,private route:ActivatedRoute,private router:Router,
        private updateAgentStatusService:UpdateAgentStatusService,private logoutService:LogoutService,
        private dialog:MatDialog,private dialogRef: MatDialogRef<EditUserComponent>,private configureUserService:ConfigureUserService, @Inject(MAT_DIALOG_DATA) data){
          this.route.queryParams.subscribe(params => {
            this.id = data.id;
            this.token = data.token;
            
           this.updateUser=data.updateUser;
        this.selectedApps=this.updateUser.applicationList;
        this.selectedItemss=this.updateUser.mappedSkills;
        this.userStatus=this.updateUser.userStatus;
        this.assignmentThreshold=this.updateUser.assignmentThreshold;
        this.extensionNumber=this.updateUser.extensionNumber;
        this.location=this.updateUser.location;
        });
          this.editUserForm=this.formBuilder.group({
            userName:new FormControl({value:'',disabled:true}),
          emailId:new FormControl({value:'',disabled:true}), 
          assignmentThreshold:new FormControl('',[Validators.required]), 
          applications:new FormControl('',[Validators.required]),
          agentSkills:new FormControl('',[Validators.required]),
          extensionNumber:new FormControl(''),
          location:new FormControl(''),
          loginName:new FormControl({value:'',disabled:true}),
          status:new FormControl(''),
          //userRole:new FormControl('')
          isSME:new FormControl(this.updateUser.sme)
      });
      }
    ngOnInit() {
      
      
      this.configureUserService.getAppList(this.id,this.token).subscribe(
          data=>{
           this.applicationList=data.body['applications'];
          });
      
      this.configureUserService.getSkillList(this.id,this.token).subscribe(
        data=>{
        console.log(data);
        if(data.status==200)
        {
          this.skills=data.body['skills'];
        }
        }
      )
   
      this.dropdownSettings= {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        closeDropDownOnSelection:true,
             
        //itemsShowLimit: 3,
        allowSearchFilter: true
      };
     this.dropdownSettingsApp={
      singleSelection: false,
      idField: 'id',
      textField: 'applicationName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      closeDropDownOnSelection:true,
     };
    }
   
    get f() {return this.editUserForm.controls; }

    onEditUserSubmit(){
      
      this.submitted=true;
      this.userData= new Users();
      if(this.editUserForm.valid)
      {
      this.userData.userSkill = [];
      this.userData.application=[];
      for(let i=0;i<this.editUserForm.value.agentSkills.length;i++){
        this.userData.userSkill[i] = this.editUserForm.value.agentSkills[i].id;
      }
      for(let i=0;i<this.editUserForm.value.applications.length;i++){
        this.userData.application[i] = this.editUserForm.value.applications[i].id;
      }
      this.userData.assignmentThreshold=this.assignmentThreshold;
      this.userData.extensionNumber=this.extensionNumber;
      this.userData.userLocation=this.location;
      this.userData.sessionToken=this.token;
      this.userData.userId=this.id;
      this.userData.editUserId=this.updateUser.crmUserId;
    this.userData.userStatus=this.userStatus;
    this.userData.isSme = this.editUserForm.value.isSME;
        this.configureUserService.editUser(this.userData).subscribe(
          data=>{        

            if(data.status==200 && data.body["responseType"]=="SUCCESS"){
              const dialogConfig= new MatDialogConfig();
              dialogConfig.disableClose=true;
              dialogConfig.autoFocus=true;
              dialogConfig.width="60%";
              dialogConfig.height="40%";
              this.dialog.open(UpdateCustomerMessageComponent,dialogConfig);
              this.dialogRef.close();

            } else if(data.status==200 && data.body["responseType"]=="SERVER_ERROR"){
              alert(data.body['responseMessage']);
            }
            else{
              alert("Unable to save data");
            }
            
          }
        )
       
    }
  }
 
    close()
    {
        this.dialogRef.close();
    }

    onItemSelect(item: any) {
      console.log("item>>"+this.selectedItemss);
    }
    onSelectAll(items: any) {
      console.log("items>>>"+this.selectedItemss);
    }
    OnItemDeSelect(item:any){
      
      console.log("items>>>"+this.selectedItemss);
  }
  onDeSelectAll(items: any){
    console.log("items>>>"+this.selectedItemss);
}


 
}