import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../environment";

@Injectable()
export class AgentProfileService{
     
private Url=environment.baseUrl+'getCrmUserProfile';
constructor(private http:HttpClient){
    
}
getAgentDetails(crmUserId, sessionToken){
    return this.http.post(this.Url, {"crmUserId":crmUserId,"sessionToken" :sessionToken }, {observe: 'response'})
   
}  

}
