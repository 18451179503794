import { Component, OnInit, Inject } from '@angular/core';
import { AgentDashboardComponent } from 'src/app/agent-dashboard/agent-dashboard.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-workitem-saved-message',
  templateUrl: './workitem-saved-message.component.html',
  styleUrls: ['./workitem-saved-message.component.css']
})
export class WorkitemSavedMessageComponent implements OnInit {
  messageToDisplay:string = '';
  popUpTitle:string = '';
  constructor(
    private dialogRef: MatDialogRef<AgentDashboardComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.popUpTitle = data.popUpTitle;
      this.messageToDisplay = data.messageToBeDisplayed;
   }

  ngOnInit() {
  }
  close()
  {

    this.dialogRef.close();

  }
}
