import { Component, OnInit } from '@angular/core';
import { CrmUser } from 'src/app/models/crmuser.model';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { SecurityQuestionsService } from 'src/app/services/securityQuestions.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SecurityQuestions } from 'src/app/models/SecurityQuestions.model';
import { ShowDashboard } from 'src/app/models/showDashboard.model';
import { ShowDashboardService } from 'src/app/services/showDashboard.service';
import { WorkitemDetails } from 'src/app/models/workitemDetails.model';
import { AdminWorkitemModel } from 'src/app/models/AdminWorkitem.model';
import { WorkItemCategory } from 'src/app/models/workitemcategory.model';
import { WorkitemProperty } from 'src/app/models/WorkitemProperty.model';
import { LoadAdminWorkitemService } from 'src/app/services/LoadAdminWorkitems.service';
import { PropertiesList } from 'src/app/services/PropertiesList.service';
import { LoadUserDetailsService } from 'src/app/services/LoadUserDetails.service';
import { CategoryAndSubCategoryService } from 'src/app/services/categoryandsubcategory.service';

@Component({
  selector: 'app-security-question-verification',
  templateUrl: './security-question-verification.component.html',
  styleUrls: ['./security-question-verification.component.css']
})
export class SecurityQuestionVerificationComponent implements OnInit {

  crmUser:CrmUser;
  
  id:number;
  token:string;

  securityQuestionsList:Array<any> = new Array<any>();
  saveQuestionsInfo:FormGroup;
  
  securityAnswerList:Array<SecurityQuestions>;
  listAnswers: any = [];


  showDashboard:ShowDashboard;
  workitemDetails:Array<WorkitemDetails>;
  adminWorkitemList:Array<AdminWorkitemModel>;
  workitemCategory:Array<WorkItemCategory>;
  worktiemProperties:Array<WorkitemProperty>;
  isAdmin:boolean=false;
  constructor(
    private router:Router,
    private loginService:LoginService,
    private securityQuestionsService:SecurityQuestionsService,
    private formBuilder: FormBuilder,
    private showDashboardService:ShowDashboardService,
    private loadAdminWorkitemsService:LoadAdminWorkitemService,
    private propertiesList:PropertiesList,
    private loadUserDetailsService:LoadUserDetailsService,
    private categoryAndSubCategoryService:CategoryAndSubCategoryService,
  ) { }

  ngOnInit() {

    this.crmUser=new CrmUser();
    this.workitemDetails=new Array<WorkitemDetails>();
    this.adminWorkitemList = new Array<AdminWorkitemModel>();
    this.workitemCategory=new Array<WorkItemCategory>();
    this.worktiemProperties = new Array<WorkitemProperty>();
    this.showDashboard=new ShowDashboard();
    this.securityAnswerList = new Array<SecurityQuestions>();
    
    this.loginService.currentData.subscribe((data) => {
      
      
            this.crmUser = data;
            this.showDashboard.crmUserId=this.crmUser.crmUserId;
            this.showDashboard.sessionToken=this.crmUser.sessionToken;
            this.token=this.crmUser.sessionToken;
            this.id=this.crmUser.crmUserId;

            this.securityQuestionsService.getVerificationQuestionsForUser(this.id,this.token).subscribe(data=>{
              
              if(data.status==200 && data.body['responseType']=="SUCCESS"){
                this.securityQuestionsList = data.body['questions'];
                
             
              }
            });

            this.saveQuestionsInfo = this.formBuilder.group({
              answerText:new FormControl('',[Validators.required])
             
             
             });
  });


  }

  get formControls() {
    return this.saveQuestionsInfo.controls;
  }

  saveAnswers(){
    
    console.log(this.listAnswers);
    if(this.saveQuestionsInfo.valid && this.listAnswers!=null){
      for(let i=0;i<this.securityQuestionsList.length;i++)
      {
        let answerArray: SecurityQuestions = {
          questionId: this.securityQuestionsList[i].id,
       answer: (this.listAnswers[i] === null || this.listAnswers[i] === undefined) ? "" : this.listAnswers[i] 
         }
         this.securityAnswerList.push(answerArray);
      }

         this.securityQuestionsService.verifySecureLogin(this.id,this.id,this.securityAnswerList,this.token).subscribe(data=>{
           if(data.status==200 && data.body['responseType']=="SUCCESS"){

            this.tasksAfterLogin();
            

           }
           else{
             alert("Entered data is incorrect");
             this.securityAnswerList = [];
           }
         });
    }
    else{
      alert("Please enter the data to proceed");
  }

  }


  tasksAfterLogin(){
    this.loginService.startoneInterval();
    this.loginService.startInterval();
    if(this.crmUser.admin==true)
    {
      this.isAdmin=true;
      this.categoryAndSubCategory();
      this.propertyTypeAndProperties();
      this.loadAdminWorkitemsService.getAdminOpenWorkitems(this.showDashboard).subscribe(admindata=>{
        
        if(admindata.status==200 && admindata.body['responseType']=="SUCCESS"){
          
          console.log(admindata);
          let temp = admindata.body['adminDashboard'];
          this.loadUserDetailsService.storeddata(temp);
          let temp1 = temp['adminWorkItemAssignmentList'];

          if(temp1!=null){
            for(let i=0;i<temp1.length;i++){
              this.adminWorkitemList.push(temp1[i]);

            }
            this.loadAdminWorkitemsService.storeddata(this.adminWorkitemList);
          }
          //this.router.navigate(['adminWorkitemController']);
          this.router.navigate(['base/landingDashboard']);
        }
      });

    }
    else{
      this.categoryAndSubCategory();
    this.showDashboardService.getWorkitemDetails(this.showDashboard).
   subscribe(data=>{

          if(data.status==200){
           
              console.log(data);

              let temp=data.body['agentDashboard'];
              this.loadUserDetailsService.storeddata(temp);
              let temp1=temp["agentWorkItemAssignmentList"]
              if(null!=temp1){
              for (let i = 0; i < temp1.length; i++) {
                this.workitemDetails.push(temp1[i]);
                                                    }
                 this.showDashboardService.storeddata(this.workitemDetails);
      }
                 this.router.navigate(['base/landingDashboard']);
          }
        });} 
       }


       propertyTypeAndProperties(){
        this.propertiesList.getPropertyTypesAndProperties(this.showDashboard).subscribe(data=>{
          
          console.log(data);
          if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
            var temp=data.body['propertyTypeVOs'];
              for (let i = 0; i < temp.length; i++) {
                this.worktiemProperties.push(temp[i]);
              }
              this.propertiesList.storeddata(this.worktiemProperties);
          }
        });
      }
  
      categoryAndSubCategory(){
  
  
          this.categoryAndSubCategoryService.getCategoryAndSubCategory(this.showDashboard).
          subscribe(data=>{
            
            console.log(data);
            if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
              
              // console.log(data1.body['flowSearchResults']);
  
              var temp=data.body['workItemCategoryVOList'];
              for (let i = 0; i < temp.length; i++) {
                this.workitemCategory.push(temp[i]);
                                                    }
              this.categoryAndSubCategoryService.storeddata(this.workitemCategory);
            }
  
            else{
              
              console.log(data);
            }
          },
  
  
  
        error => {
          
          console.log(error);
        });
  
  
  
  
      }

}


