export class ConfigureEmail{
    public mailBoxName:string;
    public queueName:string;
    public emailId:string;
    public password:string;
    public replyEnabled:any;
    public sendingUrl:string;
    public sendingPort:number;
    public folderName:string;
    public replyUrl:any;
    public replyPort:number;
    public scanEnabled:any;
    public sessionToken:string;
    public crmUserId:number;
    public mailBoxId:number;
    public emailTemplate:string;
    }