import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class WorkitemReminderService{

    public StoredData: BehaviorSubject<any> = new BehaviorSubject<null>(null);  
    currentData = this.StoredData.asObservable();
    
      storeddata(data: any) {
        this.StoredData.next(data);
      }

    constructor(private http:HttpClient,
        ){
          
      }
}