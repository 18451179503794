import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { QueueReportingDashboardService } from 'src/app/services/QueueReportingDashboard.service';

@Component({
  selector: 'app-average-handling-time-by-queue-chart',
  templateUrl: './average-handling-time-by-queue-chart.component.html',
  styleUrls: ['./average-handling-time-by-queue-chart.component.css']
})
export class AverageHandlingTimeByQueueChartComponent implements OnInit {

  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  //public barChartLabels: Label[] = ['ZP ENGLISH MAIL', 'ZP JAPENESE MAIL', 'ZP KOREAN MAIL', 'ZP MANDARIN MAIL', 'ZP THAI MAIL', 'ZP_IVR', 'ZP_MANUAL','ZP_CHAT'];
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public dataFromService:Array<any>=new Array<any>();
  public queueWIStatusList:Array<any> = new Array<any>();

  public barChartData: ChartDataSets[] = [
    //{ data: [15,20,10,0,30,200,12,10], label: 'Average Handling Time(Minutes)' },
    { data: [], label: 'Average Handling Time(seconds)' },
  ];

  constructor(
    private queueReportingDashboardService:QueueReportingDashboardService
  ) {
    
    this.queueReportingDashboardService.currentData.subscribe((data)=>{
    if(data!=null){
      
      this.dataFromService = data['queueList'];
        for(let i=0;i<this.dataFromService.length;i++){
          console.log("Avg handling time>>"+this.dataFromService[i].averageHandlingTime);
          this.queueWIStatusList[i] = {"queueName":this.dataFromService[i].queueName,"avgTime":this.dataFromService[i].averageHandlingTime};
        }

    }
  });
   }

  ngOnInit() {
    this.queueWIStatusList.forEach(element => {
      (this.barChartData[0].data as number[]).push(element.avgTime);
      this.barChartLabels.push(element.queueName);
    });
  }

}
