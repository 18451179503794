import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import { LoginService } from '../services/login.service';
import { Router, Scroll, NavigationStart, NavigationEnd } from '@angular/router';
import { ForgotPasswordService } from '../services/forgotPassword.service';
import { Agent } from '../models/agent.model';
import { CrmUser } from '../models/crmuser.model';
import { ShowDashboard } from '../models/showDashboard.model';
import { ShowDashboardService } from '../services/showDashboard.service';
import { WorkitemDetails } from '../models/workitemDetails.model';
import { CategoryAndSubCategoryService } from '../services/categoryandsubcategory.service';
import { WorkItemCategory } from '../models/workitemcategory.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PasswordResetService } from '../services/PasswordReset.service';
import { LoadAdminWorkitemService } from '../services/LoadAdminWorkitems.service';
import { AdminWorkitemModel } from '../models/AdminWorkitem.model';
import { PropertiesList } from '../services/PropertiesList.service';
import { WorkitemProperty } from '../models/WorkitemProperty.model';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
import { WebSocketService } from 'src/app/services/WebSocket.service';
import { LoadUserDetailsService } from 'src/app/services/LoadUserDetails.service';
import { SecurityQuestions } from 'src/app/models/SecurityQuestions.model';
import { SecurityQuestionsService } from 'src/app/services/securityQuestions.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  loginFailed=false;
  private email:string;
  private password:string;
  errorMessage: string  = '';
  authenticationFlag: boolean = true;
  private agentId:number;
  private companyId:number;
  submitted = false;
  loginCorrect:boolean;
  loginInCorrect:boolean
  isSecurityQuestionsSet:boolean=false;
  
  
  listAnswers: any = [];
  otpAnswer:String = "";
  securityAnswerList:Array<SecurityQuestions>;
  securityQuestionsList:Array<any> = new Array<any>();
  saveQuestionsInfo:FormGroup;

  twoFactorCheck: boolean = false;
  otpVerificationCheck:boolean = false;



  registerForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
                                });

   agent:Agent;
   crmUser:CrmUser;
   showDashboard:ShowDashboard;
   workitemDetails:Array<WorkitemDetails>;
   adminWorkitemList:Array<AdminWorkitemModel>;
   forgotPassword:boolean;
   showLoginPage:boolean;
   workitemCategory:Array<WorkItemCategory>;
   worktiemProperties:Array<WorkitemProperty>;
   isAdmin:boolean=false;
   forgotPasswordEmail:string = '';
   increment:number = 0;
   interval;
   
   
    constructor(private formBuilder: FormBuilder,
     private loginService:LoginService,
     private dialog:MatDialog,
     private showDashboardService:ShowDashboardService,
     private categoryAndSubCategoryService:CategoryAndSubCategoryService,
     private passwordResetService:PasswordResetService,
     private loadAdminWorkitemsService:LoadAdminWorkitemService,
     private propertiesList:PropertiesList,
     private router:Router,
     private webSocketService: WebSocketService,
     private loadUserDetailsService:LoadUserDetailsService,
     private securityQuestionsService:SecurityQuestionsService,
    viewPortScroller:ViewportScroller) { 
    }

    ngOnInit() {

       this.agent=new Agent();
      this.crmUser=new CrmUser();
      this.workitemDetails=new Array<WorkitemDetails>();
      this.adminWorkitemList = new Array<AdminWorkitemModel>();
      this.workitemCategory=new Array<WorkItemCategory>();
      this.worktiemProperties = new Array<WorkitemProperty>();
      this.showDashboard=new ShowDashboard();
      this.securityAnswerList = new Array<SecurityQuestions>();
      this.forgotPassword=false;
      this.loginCorrect=false;
      this.loginInCorrect=false;
      this.loginFailed=false;
      this.showLoginPage=true;

      this.saveQuestionsInfo = this.formBuilder.group({
        answerText:new FormControl('',[Validators.required])
       
       
       });


      this.registerForm = this.formBuilder.group({

        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(10),



        ]]
    });

}
    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    onSubmit() {
      
        this.submitted = true;
        if(this.registerForm.valid) {
          this.email= this.registerForm.value.email;
          this.password=this.registerForm.value.password;


          this.loginService.getUserDetails(this.email,this.password)
          .subscribe(
          data=>{
            

          console.log(data);

                if(data.body["crmUser"]!=null){
                  

                  this.crmUser =data.body["crmUser"];
                  this.loginService.storeddata(this.crmUser);
                  this.showDashboard.crmUserId=this.crmUser.crmUserId;
                  this.showDashboard.sessionToken=this.crmUser.sessionToken;

                  //changes for security questions
                  // if(this.crmUser.accountBlocked){
                  //   this.otpVerificationCheck = true;
                  // }

                  // else if(!this.crmUser.securityQuestionsSet)
                  // {
                  //   this.router.navigate(['securityQuestions']);
                  // }
                  // else{

                  //   this.securityQuestionsService.getVerificationQuestionsForUser(this.crmUser.crmUserId,this.crmUser.sessionToken).subscribe(data=>{
                  //     
                  //     if(data.status==200 && data.body['responseType']=="SUCCESS"){
                  //       this.securityQuestionsList = data.body['questions'];
                  //       this.twoFactorCheck = true;
                     
                  //     }
                  //   });
                  // }

                  //security qustion changes end here

                  // setTimeout(()=>{
                  //   this.loginService.startoneInterval();
                  // },5000);
                  // this.loginService.startoneInterval();
                  // this.loginService.startInterval();
                 
                  if(this.crmUser.admin==true)
                  {
                    this.isAdmin=true;
                    this.categoryAndSubCategory();
                    this.propertyTypeAndProperties();
                    this.loadAdminWorkitemsService.getAdminOpenWorkitems(this.showDashboard).subscribe(admindata=>{
                      
                      if(admindata.status==200 && admindata.body['responseType']=="SUCCESS"){
                        
                        console.log(admindata);
                        let temp = admindata.body['adminDashboard'];
                        this.loadUserDetailsService.storeddata(temp);
                        let temp1 = temp['adminWorkItemAssignmentList'];

                        if(temp1!=null){
                          for(let i=0;i<temp1.length;i++){
                            this.adminWorkitemList.push(temp1[i]);

                          }
                          this.loadAdminWorkitemsService.storeddata(this.adminWorkitemList);
                        }
                        //this.router.navigate(['adminWorkitemController']);
                        this.router.navigate(['base/landingDashboard']);
                      }
                    });

                  }
                  else{
                    this.categoryAndSubCategory();
                  this.showDashboardService.getWorkitemDetails(this.showDashboard).
                 subscribe(data=>{

                        if(data.status==200){
                         
                            console.log(data);

                            let temp=data.body['agentDashboard'];
                            this.loadUserDetailsService.storeddata(temp);
                            let temp1=temp["agentWorkItemAssignmentList"]
                            if(null!=temp1){
                            for (let i = 0; i < temp1.length; i++) {
                              this.workitemDetails.push(temp1[i]);
                                                                  }
                               this.showDashboardService.storeddata(this.workitemDetails);
                    }
                               this.router.navigate(['base/landingDashboard']);
                        }
                      });
                    
                    }
                    
                    

              }
                else {

                  this.loginFailed=true;
             }

            });



          }
        }


        reminderWebSocket(userId){
          
          let stompClient = this.webSocketService.connect();
          stompClient.connect({}, frame => {
  
        // Subscribe to notification topic
              stompClient.subscribe('/'+userId+'/notification', notifications => {
                console.log("the websocket result is "+ notifications);
  
          // Update notifications attribute with the recent messsage sent from the server
                  //this.notifications = JSON.parse(notifications.body).count;
              })
          });
        }
    clearFormFields(){
      this.registerForm = new FormGroup({
        email: new FormControl(''),
        password: new FormControl(''),
                                    });
                                  this.registerForm = this.formBuilder.group({

                                      email: ['', [Validators.required, Validators.email]],
                                      password: ['', [Validators.required, Validators.minLength(10),



                                      ]]
                                  });
                                  this.forgotPasswordEmail = '';


    }

    propertyTypeAndProperties(){
      this.propertiesList.getPropertyTypesAndProperties(this.showDashboard).subscribe(data=>{
        
        console.log(data);
        if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
          var temp=data.body['propertyTypeVOs'];
            for (let i = 0; i < temp.length; i++) {
              this.worktiemProperties.push(temp[i]);
            }
            this.propertiesList.storeddata(this.worktiemProperties);
        }
      });
    }

    categoryAndSubCategory(){


        this.categoryAndSubCategoryService.getCategoryAndSubCategory(this.showDashboard).
        subscribe(data=>{
          
          console.log(data);
          if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
            
            // console.log(data1.body['flowSearchResults']);

            var temp=data.body['workItemCategoryVOList'];
            for (let i = 0; i < temp.length; i++) {
              this.workitemCategory.push(temp[i]);
                                                  }
            this.categoryAndSubCategoryService.storeddata(this.workitemCategory);
          }

          else{
            
            console.log(data);
          }
        },



      error => {
        
        console.log(error);
      });




    }
    onForgotPassword(){
    this.showLoginPage=false;
     this.forgotPassword=true;
     this.forgotPasswordEmail = this.registerForm.value.email;
    }


    onClose(){

      this.loginCorrect=false;
      this.loginInCorrect=false;
      this.forgotPassword=false;
      this.email="";
      this.showLoginPage=true;
    }


    onResetPassword(emailAddress:string){

      this.loginInCorrect=false;
      this.loginCorrect=false;
      this.email=emailAddress;
      this.passwordResetService.ResetPassword(this.email,'').
subscribe(data=>{
  
 console.log(data);
 if( data.body["responseType"]=='SUCCESS' && data.status==200){
     
     this.loginCorrect=true;
   }
 else {

this.loginInCorrect=true;
      }


 });
    }


    saveAnswers(){
      
      console.log(this.listAnswers);
      if(this.saveQuestionsInfo.valid && this.listAnswers!=null){
        for(let i=0;i<this.securityQuestionsList.length;i++)
        {
          let answerArray: SecurityQuestions = {
            questionId: this.securityQuestionsList[i].id,
         answer: (this.listAnswers[i] === null || this.listAnswers[i] === undefined) ? "" : this.listAnswers[i] 
           }
           this.securityAnswerList.push(answerArray);
        }
  
           this.securityQuestionsService.verifySecureLogin(this.crmUser.crmUserId,this.crmUser.crmUserId,this.securityAnswerList,this.crmUser.sessionToken).subscribe(data=>{
             if(data.status==200 && data.body['responseType']=="SUCCESS"){
  
              this.tasksAfterLogin();
              
  
             }
             else if(data.body['responseType']=="BLOCKED"){
               this.securityQuestionsService.resetQuestionAndSendOtp(this.crmUser.crmUserId,this.crmUser.sessionToken).subscribe(
                 data=>{
                  if(data.status==200 && data.body['responseType']=="SUCCESS"){
                    this.otpVerificationCheck = true;
                  }
                  else{
                    alert(data.body['responseMessage']);
                  }
                 }
               );
                
             }
             else{
               alert("Entered data is incorrect");
               this.securityAnswerList = [];
               this.saveQuestionsInfo = this.formBuilder.group({
                answerText:new FormControl('',[Validators.required])
               
               
               });
             }
           });
      }
      else{
        alert("Please enter the data to proceed");
    }
  
    }



    tasksAfterLogin(){
      if(this.crmUser.admin==true)
      {
        this.isAdmin=true;
        this.categoryAndSubCategory();
        this.propertyTypeAndProperties();
        this.loadAdminWorkitemsService.getAdminOpenWorkitems(this.showDashboard).subscribe(admindata=>{
          
          if(admindata.status==200 && admindata.body['responseType']=="SUCCESS"){
            
            console.log(admindata);
            let temp = admindata.body['adminDashboard'];
            this.loadUserDetailsService.storeddata(temp);
            let temp1 = temp['adminWorkItemAssignmentList'];
  
            if(temp1!=null){
              for(let i=0;i<temp1.length;i++){
                this.adminWorkitemList.push(temp1[i]);
  
              }
              this.loadAdminWorkitemsService.storeddata(this.adminWorkitemList);
            }
            //this.router.navigate(['adminWorkitemController']);
            this.router.navigate(['base/landingDashboard']);
          }
        });
  
      }
      else{
        this.categoryAndSubCategory();
      this.showDashboardService.getWorkitemDetails(this.showDashboard).
     subscribe(data=>{
  
            if(data.status==200){
             
                console.log(data);
  
                let temp=data.body['agentDashboard'];
                this.loadUserDetailsService.storeddata(temp);
                let temp1=temp["agentWorkItemAssignmentList"]
                if(null!=temp1){
                for (let i = 0; i < temp1.length; i++) {
                  this.workitemDetails.push(temp1[i]);
                                                      }
                   this.showDashboardService.storeddata(this.workitemDetails);
        }
                   this.router.navigate(['base/landingDashboard']);
            }
          });} 
         }



  sendOtpForVerification(){
    this.securityQuestionsService.verifyOtp(this.crmUser.crmUserId, this.crmUser.sessionToken, this.otpAnswer).subscribe(
      data=>{
        if(data.status==200 && data.body['responseType']=="SUCCESS"){
          alert("OTP verification successful, Kindly login again");
          this.otpAnswer = null;
          this.otpVerificationCheck = false;
          this.twoFactorCheck = false;
        }
        else{
          alert("Invalid OTP");
        }
      }
    );
  }




  }

