import { Component, OnInit,Inject } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material';
import {MatDialog,MatDialogConfig} from '@angular/material';
import { FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import { Customer } from '../models/customer.model';
import { AddCustomerService } from '../services/addCustomer.service';
import { UpdateCustomerMessageComponent } from '../messagePopUp/updateCustomerMessage.component';
import {CustomerExistsMessageComponent} from '../messagePopUp/customerAlreadyExists.component';
@Component({
  selector: 'app-editCustomer',
  templateUrl: './editCustomer.component.html'
  
})
export class EditCustomerComponent  implements OnInit{
  public name:string;
  public email:string;
  public facebookID:string;
  public twitterID:string;
  public phoneNo:number;
  customer:Customer;
  public token:string;
  submitted = false;
  isShowMessage:boolean=false;
  public userId:number;
  public customerToBeUpdated:Customer;
  public customerId:number;
  editCustForm :FormGroup;
  public searchName="";
    constructor(private formBuilder: FormBuilder,
        private addCustomerService:AddCustomerService,
        private dialog:MatDialog,
        private dialogRef: MatDialogRef<EditCustomerComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
          this.editCustForm = this.formBuilder.group({  
            editName: new FormControl('', [
              Validators.minLength(3),  
              Validators.maxLength(30),  
              Validators.pattern('^[a-zA-Z ]*$')]),
            editPhoneNo: new FormControl('', [  
              Validators.minLength(8),  
              Validators.maxLength(15),  
              //Validators.pattern('^[0-9]*$')]),
              Validators.pattern('^([0|\+[0-9]{1,5})?([0-9]{12})$')]),
            editEmail: new FormControl('', [  
              Validators.minLength(5),  
              Validators.maxLength(80),  
              Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
            ]),  
            editFacebookId: new FormControl('', [  
              Validators.minLength(5),  
              Validators.maxLength(80),  
              Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
            ]), 
            editTwitterId: new FormControl('', [  
              Validators.minLength(5),  
              Validators.maxLength(80),  
              Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
            ])
          });  
        this.token = data.token;
        this.userId=data.id;
        this.customerToBeUpdated=data.customerToBeUpdated;
        this.customerId=this.customerToBeUpdated.customerId;
    }
        
         ngOnInit() {
          this.customer=new Customer();           
                                 }
                                     // convenience getter for easy access to form fields
                                     
                                     get editCustFields() { return this.editCustForm.controls; }
                                     close()
                                     {
                                       this.dialogRef.close();
                                     }
                                     editCustomer(customer:Customer)
                                     {
                                      
                                      this.submitted = true;
                                      if(this.editCustForm.valid){
                                        this.customer.customerName=this.editCustForm.value.editName;
                                        this.customer.customerEmailAddress=this.editCustForm.value.editEmail;
                                        this.customer.customerFacebookId=this.editCustForm.value.editFacebookId;
                                        this.customer.customerTwitterId=this.editCustForm.value.editTwitterId;
                                        this.customer.customerContactNumber=this.editCustForm.value.editPhoneNo;
                                       this.customer.sessionToken=this.token;
                                       this.customer.crmUserId=this.userId;
                                       this.customer.customerId=this.customerId;
                                        this.addCustomerService.editCustomer(this.customer)
                                        .subscribe(
                                        data=>{
                                      
                                        console.log(data);
                                        if(data.status==200 && data.body.responseType=='SUCCESS'){
                                        this.isShowMessage=true;
                                        const dialogConfig= new MatDialogConfig();
                                        dialogConfig.disableClose=true;
                                        dialogConfig.autoFocus=true;
                                         dialogConfig.width="35%";
                                        // dialogConfig.height="40%";
                                        this.dialog.open(UpdateCustomerMessageComponent,dialogConfig);
                                        }
                                        else {
                                          
                                          const dialogConfig= new MatDialogConfig();
                                      dialogConfig.disableClose=true;
                                      dialogConfig.autoFocus=true;
                                      dialogConfig.width="60%";
                                      dialogConfig.height="40%";
                                      this.dialog.open(CustomerExistsMessageComponent,dialogConfig);
                                        }
                                          });
                                       this.dialogRef.close();
                                        }
                                      }
                                     
                                     
}