import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { PropertiesList } from '../services/PropertiesList.service';
import { LoginService } from '../services/login.service';
import { CrmUser } from 'src/app/models/crmuser.model';
import { Router } from '@angular/router';
import { WorkitemSubPropertiesModel } from '../models/WorktiemSubProperties.model';
import { EngagementHistoryBySubject } from '../models/EngagementHistoryBySubject.model';
import { EngagementHistoryBySubjectService } from '../services/EngagementHistoryBySubject.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CustomerDetails } from 'src/app/models/CustomerDetails.model';
import { CutomerEnagagementHistoryComponent } from 'src/app/cutomer-enagagement-history/cutomer-enagagement-history.component';

@Component({
  selector: 'app-customer-search-on-subject',
  templateUrl: './customer-search-on-subject.component.html',
  styleUrls: ['./customer-search-on-subject.component.css']
})
export class CustomerSearchOnSubjectComponent implements OnInit {

  workitemSubProperties = [];
  workitemProperties = [];
  crmUser:CrmUser;
  private token:string;
  userId:number;
  searchCustForm:FormGroup;
  workitemSubPropertiesArray:Array<WorkitemSubPropertiesModel> = [];
  selectedSubProperties = [];
  selectedItems:Array<any>=[];
  submitted:boolean = false;
  engagementHistoryBySubject:EngagementHistoryBySubject;
  showSearchResults:boolean = false;
  dropdownSettings:IDropdownSettings = {};
  customerDetails:any;
 
  constructor(
    private formBuilder: FormBuilder,
    private dialog:MatDialog,
    private engagementHistoryBySubjectService:EngagementHistoryBySubjectService,
    private propertiesList:PropertiesList,
    private loginService:LoginService,
    private router:Router,
    
  ) { 

    
  }

  ngOnInit() {

    this.searchCustForm = this.formBuilder.group({  
      name: new FormControl('', [
        Validators.minLength(3),  
        Validators.maxLength(30),  
        Validators.pattern('^[a-zA-Z ]*$')]),
      email: new FormControl('', [  
        Validators.minLength(5),  
        Validators.maxLength(80),  
        //Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
      ]),  
      subject: new FormControl('', [  

      ]), 
      propertyType:new FormControl(0),
      properties: new FormControl('', [  
      ])
    });  
    this.dropdownSettings = {
      maxHeight:100,
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit:2
    };
    this.engagementHistoryBySubject = new EngagementHistoryBySubject();
    //this.customerDetails = new CustomerDetails();
    this.propertiesList.currentData.subscribe(data=>{
      
      this.workitemProperties = data;

    });


    this.loginService.currentData.subscribe((data) => {
      this.crmUser = data;
     if(this.crmUser==null || this.crmUser.sessionToken == null){
         this.router.navigate(['']);
       }
       else{
         
         this.userId=this.crmUser.crmUserId;
         this.token=this.crmUser.sessionToken;

         
       }
  });
  }


  onChangeProperty(){
    
    if(this.searchCustForm.value.propertyType>0){
      var list = this.workitemProperties.filter(x => x.id == (this.searchCustForm.value.propertyType));
      var temp=list[0];
      this.workitemSubPropertiesArray=(temp['properties']);
    }
    else{
      this.workitemSubProperties = null;
      this.workitemSubPropertiesArray = [];

      this.selectedSubProperties = [];
    }
    this.selectedItems = [];
  }

  get searchCust(){return this.searchCustForm.controls};

  onSubmit(){
    
    this.submitted = true;
    if(this.searchCustForm.valid){

      if((this.searchCustForm.value.name=='') && (this.searchCustForm.value.email=='') && (this.searchCustForm.value.subject=='') && (this.selectedItems.length==0)){
        alert('Please enter atleast one field');
        return;

      }
      this.engagementHistoryBySubject.crmUserId = this.userId;
      this.engagementHistoryBySubject.sessionToken = this.token;
      this.engagementHistoryBySubject.subject = this.searchCustForm.value.subject;
      this.engagementHistoryBySubject.propertyIds = [];
      for(let i=0;i<this.selectedItems.length;i++){
        this.engagementHistoryBySubject.propertyIds.push(this.selectedItems[i].id);
      }

      this.engagementHistoryBySubjectService.searchCustomer(this.engagementHistoryBySubject).subscribe(
        data=>{
          
          console.log(data);
          if(data.status==200){
            this.customerDetails = data.body;
            if(this.customerDetails.customerEngagementHistory==null || this.customerDetails.customerEngagementHistory.length==0){
              alert('No data available');
              return;
            }
           // this.customerHistoryService.storeddata(this.customerDetails);
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
            dialogConfig.data = {
              customerDetails: this.customerDetails
          };
            this.dialog.open(CutomerEnagagementHistoryComponent,dialogConfig);
          



        }
        }
      );
    }
  }

  onItemSelect(event){}

  onItemUnSelect(event){}

  onSelectAll(event){}

  onDeSelectAll(event){}

}
