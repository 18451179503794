import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';
import { WhatsappWorkitemService } from 'src/app/services/WhatsappWorkitem.service';
import { EmailWorkItemDetailService } from 'src/app/services/emailworkitemdetail';
import { GetQueueListService } from 'src/app/services/GetQueueList.service';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { CrmUser } from 'src/app/models/crmuser.model';
import { WorkItemsFromQueueInput } from 'src/app/models/WorkItemsFromQueueInput.model';
import { WorkitemsFromQueueOutput } from 'src/app/models/WorkitemsFromQueueOutput.model';
import { WorkitemItemDetailInput } from 'src/app/models/workitemDetailinput.model';
import { WhatsappWorkitemDetails } from 'src/app/models/WhatsappWorkitemDetails.model';
import { ConfigureUserService } from 'src/app/services/configureUser.service';

@Component({
  selector: 'app-qamonitor',
  templateUrl: './qamonitor.component.html',
  styleUrls: ['./qamonitor.component.css']
})
export class QAMonitorComponent implements OnInit {

  QAMonitorForm: FormGroup;
  token: string;
  id: number;
  crmUser: CrmUser;
  submitted: boolean;
  isValidDate: boolean;
  workitemsFromQueueInput: WorkItemsFromQueueInput;
  workitemsFromQueueOutput: WorkitemsFromQueueOutput;
  workitemItemDetailInput: WorkitemItemDetailInput;
  maxDate = new Date();
  whatsappMessagesList: Array<any> = new Array<any>();

  whatsappWorkitemDetails: WhatsappWorkitemDetails;

  dropdownListForQueues = [];
  dropdownListForStatus = ['PAUSED', 'REOPEN', 'PRENEW', 'NEW', 'INPROGRESS',
    'ASSIGNED', 'ESCALATED', 'PENDING',
    'CLOSED', 'DELAYED', 'AUTOPAUSED', 'COMPLETED'];
  userList: any[];
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private whatsappWorkitemService: WhatsappWorkitemService,
    private emailWorkItemDetailService: EmailWorkItemDetailService,
    private getQueueListService: GetQueueListService,
    private loginService: LoginService,
    private router: Router,
    private configureUserService: ConfigureUserService,
  ) { }

  ngOnInit() {

    this.crmUser = new CrmUser();
    this.workitemsFromQueueInput = new WorkItemsFromQueueInput();
    this.workitemsFromQueueOutput = new WorkitemsFromQueueOutput();
    this.workitemItemDetailInput = new WorkitemItemDetailInput();
    this.whatsappWorkitemDetails = new WhatsappWorkitemDetails();
    this.loginService.currentData.subscribe(data => {
      if (data == null || data.sessionToken == null) {
        this.router.navigate(['']);
      }
      else {
        
        this.id = data.crmUserId;
        this.token = data.sessionToken;
        this.crmUser = data;
        //  this.isSME = data.sme;

      }
    });

    this.getQueueListService.getQueueList(this.id, this.token).subscribe(
      data => {
        
        if (data.status == 200 && data.body["responseType"] == "SUCCESS") {

          this.dropdownListForQueues = data.body['queueList'];
        }
        else if (data.body['responseType'] == 'SESSION_TIMEOUT') {

          alert("Session timeout!Please Login Again");
          this.loginService.storeddata(null);
          this.router.navigate(['']);
        }
      }
    );

    this.configureUserService.getUserList(this.id, this.token).subscribe(data => {
      
      this.userList = data.body['users'];

    });

  }

}
