import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd,Event } from '@angular/router';
import { filter, scan, observeOn } from 'rxjs/operators';
import { asyncScheduler } from 'rxjs';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';

interface ScrollPositionRestore {
  event: Event;
  positions: { [K: number]: number };
  trigger: 'imperative' | 'popstate';
  idToRestore: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{
  increment:number = 0;

  @ViewChild('contentArea',{static:false}) private contentArea: ElementRef<any>;
  constructor(private router: Router,private dialog:MatDialog,) {
    // const schedule = require('node-schedule');
    // const job = schedule.scheduleJob('42 * * * * *', function(){
    //   console.log('The answer to life, the universe, and everything!'+this.increment);
      // const dialogConfig = new MatDialogConfig();

      // dialogConfig.disableClose = true;
      // dialogConfig.autoFocus = true;
      // alert("hello world");
      // this.dialog.open(ReminderConfigurationComponent,dialogConfig);
      //this.increment++;
    // });

  }

  function1(){
    // const dialogConfig = new MatDialogConfig();
    
    //       dialogConfig.disableClose = true;
    //       dialogConfig.autoFocus = true;
    //       this.dialog.open(ReminderConfigurationComponent,dialogConfig);
  
  }
 

  // ngOnInit() {
  //   this.router.events
  //     .pipe(
  //       filter(
  //         event =>
  //           event instanceof NavigationStart || event instanceof NavigationEnd,
  //       ),
  //       scan<Event, ScrollPositionRestore>((acc, event,0) => ({
  //         event,
  //         positions: {
  //           ...acc.positions,
  //           ...(event instanceof NavigationStart
  //             ? {
  //                 [event.id]: this.contentArea.nativeElement.scrollTop,
  //               }
  //             : {}),
  //         },
  //         trigger:
  //           event instanceof NavigationStart
  //             ? event.navigationTrigger
  //             : acc.trigger,
  //         idToRestore:
  //           (event instanceof NavigationStart &&
  //             event.restoredState &&
  //             event.restoredState.navigationId + 1) ||
  //           acc.idToRestore,
  //       })),
  //       filter(
  //         ({ event, trigger }) => event instanceof NavigationEnd && !!trigger,
  //       ),
  //       observeOn(asyncScheduler),
  //     )
  //     .subscribe(({ trigger, positions, idToRestore }) => {
  //       if (trigger === 'imperative') {
  //         this.contentArea.nativeElement.scrollTop = 0;
  //       }

  //       if (trigger === 'popstate') {
  //         this.contentArea.nativeElement.scrollTop = positions[idToRestore];
  //       }
  //     });
  // }
  title = 'iqd-app';
  arrs= [
    {id: 1, text: 'Sentence 1'},
    {id: 2, text: 'Sentence 2'},
    {id: 3, text: 'Sentence 3'},
    {id: 4, text: 'Sentenc4 '},
];
selectedIndex: number;
daysInAWeek: string[] = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']; 
toggle = {};
public SelectedDay={};
  changeStyle(id){
    
    
    this.SelectedDay=id;
  }
  
}
