import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ReportsService } from 'src/app/services/reports.service';
import { AgentWIDetailReportComponent } from '../agentWIDetailReport.component';
import { DatePipe } from '@angular/common';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-work-item-slareport',
  templateUrl: './work-item-slareport.component.html',
  styleUrls: ['./work-item-slareport.component.css'],
  providers:[DatePipe]
})
export class WorkItemSLAReportComponent implements OnInit {

  token:string;
  id:number;
  WISLAReportForm:FormGroup; 
  startDate:string;
  endDate:string;
  minDate:Date;
  isValidDate:any;
  parts:any;
  submitted:boolean=false;
  maxDate=new Date();
  type:string;
  isSLA:boolean;
  error:any={isError:false,errorMessage:''};
    constructor(private reportService:ReportsService, private dialogRef: MatDialogRef<AgentWIDetailReportComponent>,
      private router:Router,@Inject(MAT_DIALOG_DATA) data,private formBuilder: FormBuilder,private datePipe: DatePipe){
        this.token=data.sessionToken;
        this.id=data.userId;
        this.isSLA = data.isSLA;
   this.datePipe.transform(this.maxDate, 'mm/dd/yyyy');

      }
    ngOnInit() {
      
      this.WISLAReportForm = this.formBuilder.group({  
        startDate: new FormControl('', [  
          Validators.required
        ]),  
        endDate: new FormControl('', [  
          Validators.required  
        ])
       
      }); 
    }
    get f() {return this.WISLAReportForm.controls; }
    close()
  {
    this.dialogRef.close();
  }
 
  downloadWISLAReport(){
    
    // console.log(this.datePipe.transform(this.WISLAReportForm.value.startDate, 'yyyy-MM-dd') < this.datePipe.transform(this.WISLAReportForm.value.endDate, 'yyyy-MM-dd') ? true : false);
    this.submitted=true;
this.startDate=this.datePipe.transform(this.WISLAReportForm.value.startDate,"dd-MM-yy");
this.endDate=this.datePipe.transform(this.WISLAReportForm.value.endDate,"dd-MM-yy");
this.isValidDate = this.validateDates(this.process(this.startDate), this.process(this.endDate));
if(this.isValidDate && this.WISLAReportForm.valid && this.isSLA) {
this.reportService.wiSLAReport(1,this.id,1,"EXCEL",this.token,this.startDate,this.endDate).subscribe(
  data => {
    saveAs(data,"workItemSLAReport");
  },
  err => {
    alert("Problem while downloading the file.");
    console.error(err);
  });
  this.dialogRef.close();
  }
  else if(this.isValidDate && this.WISLAReportForm.valid && !this.isSLA){
    this.reportService.wiDetailReport(1,this.id,1,"EXCEL",this.token,this.startDate,this.endDate).subscribe(
      data => {
        saveAs(data,"workItemDetailReport");
      },
      err => {
        alert("Problem while downloading the file.");
        console.error(err);
      });
      this.dialogRef.close();
  }

}

   


  validateDates(sDate: Date, eDate: Date){
    
    this.isValidDate = true;
   

    if((eDate) < (sDate)){
     // this.error={isError:true,errorMessage:'Start date should be less than End date.'};
     alert("Start date should be less than End date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  process(date:string){
     this.parts = date.split("-");
    return new Date(this.parts[2], this.parts[1] - 1, this.parts[0]);
 }
}