import { Injectable } from "@angular/core";
import { environment } from "src/app/environment";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class QueueReportingDashboardService{
    private Url = environment.baseUrl+'getQueueReportingDashboard';

    public StoredData: BehaviorSubject<any> = new BehaviorSubject<any>(null);  
    currentData = this.StoredData.asObservable();
      storeddata(data: any) {
          
        this.StoredData.next(data);
      }

      public storedDataForCategoryChart: BehaviorSubject<any> = new BehaviorSubject<any>(null); 
      
      currentDataForCategoryChart = this.storedDataForCategoryChart.asObservable();
      storeddataForCategoryChart(data: any) {
          
        this.storedDataForCategoryChart.next(data);
      }
    constructor(private http:HttpClient){
        
    }

    getQueueReportingDashboard(crmUserId, sessiontoken,startDate,endDate){
        
        return this.http.post(this.Url,{"crmUserId":crmUserId,"sessionToken":sessiontoken,"startDate":startDate,"endDate":endDate},{observe: 'response'});
    }
}