import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/app/environment";
import { SendNotificationModel } from "src/app/models/SendNotification.model";



@Injectable()
export class NotificationService{
    

    private urlForSendNotification = environment.baseUrl+'sendNotification';
    private urlToGetActiveNotifications = environment.baseUrl+'getActiveNotification';
    constructor(private http:HttpClient){
        
    }


    sendNotification(sendNotificationModel:SendNotificationModel){
        return this.http.post(this.urlForSendNotification,sendNotificationModel,{observe:'response'});
    }

    getActiveNotifications(crmUserId,sessionToken){
        return this.http.post(this.urlToGetActiveNotifications,{"crmUserId":crmUserId,"sessionToken":sessionToken},{observe:'response'});
    }

}