import { CustomerHistoryOutput } from './CustomerHistoryOutput.model';

export class CustomerDetails{
    crmUserId:number;
    sessionToken:string;
    customerContactNumber:number;
    customerEmailAddress:string;
    customerFacebookId:string;
    customerName:string;
    customerTwitterId:string;
    engagementHistory:Array<CustomerHistoryOutput>;
    pageNumber:number;
    pageSize:number;
    totalElements:number;
    totalPages:number;
}