import { Component, OnInit, ViewChild,Inject } from '@angular/core'
import { MatDialogRef,MAT_DIALOG_DATA }from '@angular/material'
import {EmailReplyService} from '../services/emailReply.service';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import {FormGroup} from '@angular/forms';
import { Attachment } from '../models/attachment.model';
import { UploadRequest } from '../UploadRequest.enum';
@Component({
  selector: 'uploadAttachment',
  templateUrl: './uploadAttachment.component.html',
})
export class UploadAttachmentComponent {
  private userId;
  private token;
  private emailItemId;
  private requestFrom;
  private randomNumber;
  myFiles:File [] = [];
  sMsg:string = '';

    constructor(private httpService: HttpClient,public dialogRef: MatDialogRef<UploadAttachmentComponent>, private emailReplyService:EmailReplyService, @Inject(MAT_DIALOG_DATA) data
    ) {
      
      this.userId=data.id;
      this.token=data.token;
      this.emailItemId=data.itemId;
      this.requestFrom=data.requestFrom;
      this.randomNumber = data.randomNumber;
    }
    fileToUpload: File = null;

    close()
    {
      this.dialogRef.close(this.myFiles);
    }


  getFileDetails (e) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
  }

  uploadFiles () {
    
    for (var i = 0; i < this.myFiles.length; i++) {
      
      this.fileToUpload=this.myFiles[i];
      let data = new FormData();
          let fileItem = this.myFiles[i];
          console.log(fileItem.name);
          //data.append('file', fileItem);
          data.append('fileName',fileItem.name);
           //data.append('fileSize',fileItem.size.toString());
           data.append('sessionToken',this.token);
           data.append('crmUserId',this.userId);
           //data.append('emailItemId',this.emailItemId);
           if(this.requestFrom==UploadRequest.NEWMAIL){
            data.append('files', fileItem);
            data.append('newMailIdentifier',this.randomNumber);
            this.emailReplyService.uploadAttachmentsForNewMail(data).subscribe(data=>console.log(data));
           }
           else if(this.requestFrom==UploadRequest.MANUALWORKITEMCONTROLLER){
             data.append('files',fileItem);
             data.append('manualWorkItemIdentifier',this.randomNumber);
             this.emailReplyService.uploadAttachmentsForManualWorkitem(data).subscribe(data=>{
               console.log(data);
             });

           }
           else{
            data.append('emailItemId',this.emailItemId);
            data.append('file', fileItem);
            this.emailReplyService.uploadAttachment(data).subscribe(data =>console.log(data));
           }

    }
    this.dialogRef.close(this.myFiles);

  }
}
