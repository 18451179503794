import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { ShowDashboard } from '../models/showDashboard.model';
import { WorkitemDetails } from '../models/workitemDetails.model';
import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class CompletedWorkItemsService{
    public StoredData: BehaviorSubject<WorkitemDetails[]> = new BehaviorSubject<WorkitemDetails[]>([]);
    currentData = this.StoredData.asObservable();
    storeddata(data: any) {
        this.StoredData.next(data)
      }
      private Url=environment.baseUrl+'loadClosedWorkItemDashboard';
      constructor(private http:HttpClient){
          
      }
      getCompletedWorkItems(showDashboard:ShowDashboard){

        return this.http.post<WorkitemDetails[]>(this.Url, showDashboard, {observe: 'response'});
      
   }  
}