import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'filterUsersList',
    pure: true
  })
  export class FilterUsersList implements PipeTransform {
  
    transform(list: any[]): any {
        
        
      return list ? list.filter(item => item.userRole!="CLIENT") : list;
    }
  
  }