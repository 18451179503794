import { Injectable } from '@angular/core';
import { environment } from "../environment";
import { HttpClient } from '@angular/common/http';
import { ShowDashboard } from '../models/showDashboard.model';
import { BehaviorSubject } from 'rxjs';
import { WorkItemCategory } from '../models/workitemcategory.model';

@Injectable()
 export class CategoryAndSubCategoryService{
    public StoredData: BehaviorSubject<WorkItemCategory[]> = new BehaviorSubject<WorkItemCategory[]>(null);  
    currentData = this.StoredData.asObservable();
      storeddata(data: any) {
        this.StoredData.next(data)
      }

private url=environment.baseUrl+"getWorkItemCategories";

constructor(private http:HttpClient){

}
getCategoryAndSubCategory(showDashboard:ShowDashboard){

    return this.http.post<WorkItemCategory[]>(this.url, showDashboard, {observe: 'response'});
  
}  
 }