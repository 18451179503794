import { Injectable } from '@angular/core';
import { environment } from '../environment';
import { HttpClient } from '@angular/common/http';
import { ShowDashboard } from '../models/showDashboard.model';
import { BehaviorSubject } from 'rxjs';
import { WorkitemProperty } from '../models/WorkitemProperty.model';

@Injectable()
export class PropertiesList{

  private url = environment.baseUrl+'getProperties';
  private urlForPropertyTypes = environment.baseUrl+'getPropertyTypesAndProperties';

  public StoredData: BehaviorSubject<WorkitemProperty[]> = new BehaviorSubject<WorkitemProperty[]>(null);
    currentData = this.StoredData.asObservable();
      storeddata(data: any) {
        this.StoredData.next(data)
      }
  constructor(private http:HttpClient){}
  getPropertiesList(crmUserId:number,sessionToken:string){
   return  this.http.post(this.url, {"crmUserId":crmUserId,"sessionToken":sessionToken}, {observe:'response'});
  }

  getPropertyTypesAndProperties(showDashboard:ShowDashboard){
    
    return  this.http.post(this.urlForPropertyTypes, showDashboard, {observe:'response'});
  }
}
