import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { Users } from '../models/users.model';


@Injectable()
export class ConfigureUserService{
  
       private Url=environment.baseUrl+'getUserList'
       private addUserUrl=environment.baseUrl+'addAgent'
       private appListUrl=environment.baseUrl+'getApplications'
       private skillListUrl=environment.baseUrl+'getSkills'
       private editUserUrl=environment.baseUrl+'editAgent'
       public StoredData: BehaviorSubject<Users> = new BehaviorSubject<Users>( new Users());  
currentData = this.StoredData.asObservable();

storeddata(data: any) {
  
    this.StoredData.next(data);
  }
constructor(private http:HttpClient){
    
}
getUserList(crmUserId,sessionToken){
    
    return this.http.post(this.Url, {'crmUserId':crmUserId,'sessionToken':sessionToken}, {observe: 'response'});
   
} 
addUser(userData:Users, isSme:boolean){
    
    return this.http.post(this.addUserUrl, {'crmUserId':userData.userId,'sessionToken':userData.sessionToken,'agentSkills':userData.userSkill,'assignmentThreshold':userData.assignmentThreshold,'applications':userData.application,'email':userData.emailAddress,'extensionNumber':userData.extensionNumber,'location':userData.userLocation,'name':userData.userName,'loginName':userData.loginName,"sme":isSme}, {observe: 'response'});
}
getAppList(crmUserId,sessionToken)
{
    return this.http.post(this.appListUrl,{'crmUserId':crmUserId,'sessionToken':sessionToken}, {observe: 'response'})
}
getSkillList(crmUserId,sessionToken)
{
    return this.http.post(this.skillListUrl,{'crmUserId':crmUserId,'sessionToken':sessionToken}, {observe: 'response'})
}
editUser(userData:Users)
{
   return this.http.post(this.editUserUrl,{'crmUserId':userData.userId,'sessionToken':userData.sessionToken,'userStatus':userData.userStatus,'location':userData.userLocation,'extensionNumber':userData.extensionNumber,'agentSkills':userData.userSkill,'applications':userData.application,'editCrmUserId':userData.editUserId,'assignmentThreshold':userData.assignmentThreshold,'sme':userData.isSme},{observe:'response'});
}
}
