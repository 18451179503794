import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { SkillConfigurationService } from 'src/app/services/SkillConfiguration.service';
import { AddSkillModel } from 'src/app/models/AddSkill.model';
import { WorkitemSavedMessageComponent } from 'src/app/messagePopUp/workitem-saved-message/workitem-saved-message.component';

@Component({
  selector: 'app-add-skill',
  templateUrl: './add-skill.component.html',
  styleUrls: ['./add-skill.component.css']
})
export class AddSkillComponent implements OnInit {

  id:number;
  token:string;
  addSkillForm:FormGroup;
  submitted:boolean=false;
  addSkillModel:AddSkillModel;
  isUpdateRequest:boolean = false;
  selectedSkill:any;
  constructor(
    private router:Router,
    private loginService:LoginService,
    private dialog:MatDialog,
    private skillConfigurationService:SkillConfigurationService,
    private formBuilder: FormBuilder,
    private route:ActivatedRoute,
    private dialogRef: MatDialogRef<AddSkillComponent>,
  @Inject(MAT_DIALOG_DATA) data
  ) { 
    
    this.id = data['id'];
    this.token = data['token'];
    this.isUpdateRequest = data['isUpdateRequest'];
    if(this.isUpdateRequest){
      this.selectedSkill = data['selectedSkill'];
    }

    this.addSkillForm = this.formBuilder.group({
      name:this.isUpdateRequest?new FormControl({value:this.selectedSkill.name,disabled:this.isUpdateRequest},[
        Validators.required 
      ]):new FormControl('',[
        Validators.required  
      ]),
      description:this.isUpdateRequest?new FormControl(this.selectedSkill.description,[
        Validators.required
      ]):new FormControl('',[
        Validators.required  
      ]),
    });
  }

  get formControls() {
    return this.addSkillForm.controls;
  }

  ngOnInit() {
    this.addSkillModel = new AddSkillModel();
  }

  onSubmitSkill(){
    
    this.submitted = true;
    this.addSkillModel.crmUserId = this.id;
    this.addSkillModel.name = this.addSkillForm.value.name;
    this.addSkillModel.description = this.addSkillForm.value.description;
    this.addSkillModel.sessionToken = this.token;

    if(this.submitted && this.addSkillForm.valid && !this.isUpdateRequest){
      this.skillConfigurationService.addSkill(this.addSkillModel).subscribe(data=>{
        
        if(data.status==200 && data.body["responseType"]=="SUCCESS"){
          const dialogConfig= new MatDialogConfig();
          dialogConfig.disableClose=true;
          dialogConfig.autoFocus=true;
          dialogConfig.width="60%";
          dialogConfig.height="40%";
          dialogConfig.data = {
            popUpTitle:"Add Skill",
            messageToBeDisplayed:"Skill added successfully" 
          };
          this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);
          this.dialogRef.close();
        }

        else if(data.status==200 && data.body["responseType"]=="SERVER_ERROR"){
          alert(data.body['responseMessage']);
        }
        else if(data.body['responseType']== 'SESSION_TIMEOUT'){
          
          alert("Session timeout!Please Login Again");
          this.loginService.storeddata(null);
          this.router.navigate(['']);
        } 
        else{
          alert("Unable to save data");
        }
      });
    }
    else if(this.submitted && this.addSkillForm.valid && this.isUpdateRequest){
      
      this.addSkillModel.name = this.selectedSkill.name;
      this.addSkillModel.skillId = this.selectedSkill.id;
      this.skillConfigurationService.editSkill(this.addSkillModel).subscribe(data=>{
        if(data.status==200 && data.body["responseType"]=="SUCCESS"){
          const dialogConfig= new MatDialogConfig();
          dialogConfig.disableClose=true;
          dialogConfig.autoFocus=true;
          dialogConfig.width="60%";
          dialogConfig.height="40%";
          dialogConfig.data = {
            popUpTitle:"Edit Skill",
            messageToBeDisplayed:"Skill Updated successfully" 
          };
          this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);
          this.dialogRef.close();
        }
        else if(data.status==200 && data.body["responseType"]=="SERVER_ERROR"){
          alert(data.body['responseMessage']);
        }
        else if(data.body['responseType']== 'SESSION_TIMEOUT'){
          
          alert("Session timeout!Please Login Again");
          this.loginService.storeddata(null);
          this.router.navigate(['']);
        } 
        else{
          alert("Unable to save data");
        }
      });
    }
  }

  onClearFormFields(){
    
    if(this.isUpdateRequest){
      this.addSkillForm = new FormGroup({
       
        description:new FormControl(''),
        
       
        
      });
  
      this.addSkillForm = this.formBuilder.group({
        description:['',[Validators.required]],
        
      });
    }
    else{ 
    this.addSkillForm = new FormGroup({
      name:new FormControl(''),
      description:new FormControl(''),
      
     
      
    });

    this.addSkillForm = this.formBuilder.group({
      name:['',[Validators.required]],
      description:['',[Validators.required]],
      
    });
    }
  }

  close(){
    this.dialogRef.close();
  }

}
