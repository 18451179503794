import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environment';
import { WorkItemsFromQueueInput } from '../models/WorkItemsFromQueueInput.model';
import { ReassignWorkitemInputModel } from '../models/ReassignWorkitemInput.model';

@Injectable()
export class LiveMontorService{
    private UrlForWorkitems=environment.baseUrl+'getOpenWorkItemsForQueue';
    private UrlForReassignment = environment.baseUrl+'reassignWorkItem';
    private UrlForReopen = environment.baseUrl+'reopenWorkItem';
    constructor(private http:HttpClient){
    
    }

    getWorkitemsFromQueues(workitemsFromQueueInput:WorkItemsFromQueueInput){
        
        return this.http.post(this.UrlForWorkitems,workitemsFromQueueInput , {observe: 'response'});
    }

    reassignWorkitem(reassignWorkitemInputModel:ReassignWorkitemInputModel){
        return this.http.post(this.UrlForReassignment,reassignWorkitemInputModel,{observe:'response'});
    }

    reopenWorkitem(crmUserId, sessionToken, workitemId){
        
        return this.http.post(this.UrlForReopen,{"crmUserId":crmUserId,"sessionToken":sessionToken,"workItemId":workitemId},{observe:'response'});
    }
}