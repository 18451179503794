import { Injectable } from "@angular/core";
import { environment } from "../../app/environment";
import { HttpClient } from "@angular/common/http";
import { SearchWorkitemInput } from "../models/SearchWorkitemInput.model";

@Injectable()
export class SearchWorkitemService{
    private urlForSearchWorkitemUsingPost = environment.baseUrl+'searchWorkItem';

    constructor(private http:HttpClient){
        
    }

    searchWorkitem(searchWorkitemInput:SearchWorkitemInput){
        return this.http.post(this.urlForSearchWorkitemUsingPost,searchWorkitemInput,{observe:'response'});
    }
}