import{ Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
 import { environment } from "../environment";
import { AddQueue } from '../models/AddQueue.model';



@Injectable()
export class AddNewQueueService{
     private Url=environment.baseUrl+'addQueue';
constructor(private http:HttpClient){
    
}
addNewQueue(addQueue:AddQueue){
     return this.http.post<any>(this.Url, addQueue, {observe: 'response'});
   
}  

}