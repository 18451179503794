import { Injectable } from "@angular/core";
import { environment } from "../environment";
import { HttpClient } from "@angular/common/http";
import { WorkitemItemDetailInput } from "../models/workitemDetailinput.model";

@Injectable()
export class CompleteWorkitemHistoryService{

    public urlForHistory = environment.baseUrl+'getWorkItemHistory';

    constructor(
        private http:HttpClient
    ){

    }


    getCompleteHistory(workitemDetailInput:WorkitemItemDetailInput){

        return this.http.post(this.urlForHistory,workitemDetailInput,{observe:'response'});
    }
}