import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
@Injectable()
export class UpdateAgentStatusService{


    private Url=environment.baseUrl+'updateAgentAvailability';
    constructor(private http:HttpClient){
        
    }

    updateAgentStatus(updateAgentStatusModel:UpdateAgentStatusModel){
        return this.http.post(this.Url,updateAgentStatusModel , {observe: 'response'})
    }
}