import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";

import { WorkitemItemDetailInput } from '../models/workitemDetailinput.model';
import { WorkitemItemDetailOutput } from '../models/workitemDetailOutput.model';
import { CompletedWorkitemDetailOutput } from '../models/completedWorkitemDetailOutput.model';

@Injectable()
export class EmailWorkItemDetailService{

public StoredData: BehaviorSubject<WorkitemItemDetailOutput> = new BehaviorSubject<WorkitemItemDetailOutput>(null);

currentData = this.StoredData.asObservable();
  storeddata(data: any) {
    this.StoredData.next(data)
  }
  private Url=environment.baseUrl+'getEmailWorkItemDetails';
  private urlForManual = environment.baseUrl+'getManualWorkItemDetails';
constructor(private http:HttpClient){

}
getEmailWorkitemStateChange(workItemDetailInput:WorkitemItemDetailInput){

// if(workitemState==="completed"){
//   return this.http.post<CompletedWorkitemDetailOutput>(this.Url,workItemDetailInput , {observe: 'response'});
// }
     return this.http.post<WorkitemItemDetailOutput>(this.Url,workItemDetailInput , {observe: 'response'});

}

getManualWorkitemDetails(workItemDetailInput:WorkitemItemDetailInput){
  return this.http.post(this.urlForManual, workItemDetailInput,{observe:'response'});
}
}
