import {AdditionalInfoVOForReassign} from './AdditionalInfoVOForReassign.model'; 

export class IVRWorkitemRemarksInput{

  public additionalInfoVO:AdditionalInfoVOForReassign;
  public callNotes:string;
  public crmUserId:number;
  public sessionToken:string;
  public workItemId:number;
  public categoryId:number;
  public subCategoryId:number;

}