import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { WorkItemCategory } from 'src/app/models/workitemcategory.model';
import { CategoryAndSubCategoryService } from 'src/app/services/categoryandsubcategory.service';
import { ExcelWorkitem } from 'src/app/models/ExcelWorkitem.model';
import { PropertiesList } from 'src/app/services/PropertiesList.service';
import { AddManualWorkitemComponent } from '../add-manual-workitem/add-manual-workitem.component';

@Component({
  selector: 'app-excel-workitem-controller',
  templateUrl: './excel-workitem-controller.component.html',
  styleUrls: ['./excel-workitem-controller.component.css']
})
export class ExcelWorkitemControllerComponent implements OnInit {

  workitemsList:Array<ExcelWorkitem> = new Array<ExcelWorkitem>();

  workitemCategory:Array<WorkItemCategory>=new Array<WorkItemCategory>();
  workitemProperties = [];
  constructor(
      public dialogRef: MatDialogRef<ExcelWorkitemControllerComponent>,
      private categoryandsubcategoryService:CategoryAndSubCategoryService,
      private propertiesList:PropertiesList,
      private dialog:MatDialog,
      @Inject(MAT_DIALOG_DATA) data
    ) {
      
      this.workitemsList = data.Sheet1;


      for(let i=0;i<this.workitemsList.length;i++){
        console.log(this.workitemsList[i]);
      }
  }

  ngOnInit() {

    this.categoryandsubcategoryService.currentData.subscribe((data)=>{
      
      this.workitemCategory=data;
      console.log(this.workitemCategory);
    });

    this.propertiesList.currentData.subscribe(data=>{
      
      this.workitemProperties = data;

    });
  }
  close(){
    this.dialogRef.close();
  }

  onChangeCategory(workitem){
    
    if(workitem.category>0){
      var list = this.workitemCategory.filter(x => x.id == workitem.category);

  var temp=list[0];
  workitem.subCategoryList=(temp['subCategories']);
    }

  }

  onChangeProperty(workitem){
    if(workitem.property>0){
        var list = this.workitemProperties.filter(x => x.id == (workitem.property));
        var temp=list[0];
        workitem.subPropertiesList=(temp['properties']);

      }
      else{
        workitem.subPropertiesList = null;
      }

    }

    onUpdate(workitem){
      
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = workitem
      this.dialog.open(AddManualWorkitemComponent,dialogConfig).afterClosed().subscribe(result=>{

        if(result==1){
          this.workitemsList.splice(this.workitemsList.indexOf(workitem),1);
        }
      });
    }


}
