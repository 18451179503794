import { Customer } from './customer.model';
import { WorkItemCategory } from './workitemcategory.model';
import { WorkItemSubCategory } from './workitemsubcategory.model';
import { IVRWorkitemAddedComments } from './ivrWorkitemAddedComments.model';
import {AdditionalInfoVOForReassign} from './AdditionalInfoVOForReassign.model';
export class WorkitemItemDetailOutput{
  public additionalInfoVO:AdditionalInfoVOForReassign;
    public attachmentCount: number;
    public completedTms: string;
    public to: string;
    public cc: string;
    public content: string;
    public createTms: string;
    public formattedCallDateTime:string;

    public propertyVOS:Array<{
      description:string
      name:string
      id:number
    }>;
    workItemDescription:string;
    workItemTitle:string;
    manualWorkItemPriority:string;

    public customerVO:Array<Customer>;
    public sender: string;
    public slaThreshold: string;
    public subject: string;
    public workItemStatus: string;
    public workItemType: string;
    public workItemId:number;
    public emailItemId:number;
    public workItemTimeSpent:Array<{
        hh:number
    mm:number
    ss: number
    }>

    public workItemCategory:Array<WorkItemCategory>;
    public workItemSubCategory:Array<WorkItemSubCategory>;

    public workItemComments:Array<IVRWorkitemAddedComments>;
    public createdBy:Array<{
      name:string;
    }>;
    public formattedDueDate:string;
    public manualWorkItemId:number;
    public workItemAttachments:any;
    }
