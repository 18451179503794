import { Component, OnInit } from '@angular/core';
import { LoadUserDetailsService } from 'src/app/services/LoadUserDetails.service';
import { QueueReportingDashboardService } from 'src/app/services/QueueReportingDashboard.service';
import { LoginService } from 'src/app/services/login.service';
import { CrmUser } from 'src/app/models/crmuser.model';
import { AgentProfile } from 'src/app/models/AgentProfile.model';
import { DatePipe } from '@angular/common';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { ReminderConfigurationService } from 'src/app/services/ReminderConfiguration.service';
import { ReminderVO } from 'src/app/models/ReminderVO.model';
import { GetReminderRequest } from 'src/app/models/GetReminderRequest.model';
import { ReminderStatus } from 'src/app/Enums/ReminderStatus.enum';
import { EditReminderModel } from 'src/app/models/EditReminder.model';
import { NotificationService } from 'src/app/services/Notifications.service';
import { WebSocketService } from 'src/app/services/WebSocket.service';

@Component({
  selector: 'app-landing-dashboard',
  templateUrl: './landing-dashboard.component.html',
  styleUrls: ['./landing-dashboard.component.css']
})
export class LandingDashboardComponent implements OnInit {

  userDetails:any;

  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Queues';
  showYAxisLabel = true;
  yAxisLabel = 'No of work items';
  crmUser:CrmUser;
  agentProfile:AgentProfile;
  isAdmin:boolean;
  id:number;
  token:string;
  startDate:string;
  endDate:string;
  chartData = new Array();
  icon2:Array<boolean> = new Array<boolean>();
  iconForNotification:Array<boolean> = new Array<boolean>();
  reminderVO:Array<ReminderVO> = new Array<ReminderVO>();
  getReminderRequest:GetReminderRequest = new GetReminderRequest();
  editReminderModel:EditReminderModel;
  notificationVOS = [];
  showNotificationsTab :boolean = false;
  showRemindersTab:boolean = false;


  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Assigned' },
    {data:[],label: 'Unassigned'},
    {data:[],label: 'Completed'}
  ];
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  colorScheme = {
    domain: ['#9370DB', '#87CEFA', '#FA8072', '#FF7F50', '#90EE90', '#9370DB']
  };


  public multi = [
    {
      "name": "China",
      "series": [
        {
          "name": "2018",
          "value": 2243772
        },
        {
          "name": "2017",
          "value": 1227770
        }
      ]
    },
    {
      "name": "USA",
      "series": [
        {
          "name": "2018",
          "value": 1126000
        },
        {
          "name": "2017",
          "value": 764666
        }
      ]
    },
    {
      "name": "Norway",
      "series": [
        {
          "name": "2018",
          "value": 296215
        },
        {
          "name": "2017",
          "value": 209122
        }
      ]
    },
    {
      "name": "Japan",
      "series": [
        {
          "name": "2018",
          "value": 257363
        },
        {
          "name": "2017",
          "value": 205350
        }
      ]
    },
    {
      "name": "Germany",
      "series": [
        {
          "name": "2018",
          "value": 196750
        },
        {
          "name": "2017",
          "value": 129246
        }
      ]
    },
    {
      "name": "France",
      "series": [
        {
          "name": "2018",
          "value": 204617
        },
        {
          "name": "2017",
          "value": 149797
        }
      ]
    }
  ];
  constructor(

    private loadUserDetailsService:LoadUserDetailsService,
    private queueReportingDashboardService:QueueReportingDashboardService,
    private loginService:LoginService,
    private datePipe:DatePipe,
    private reminderConfigurationService:ReminderConfigurationService,
    private notificationService:NotificationService,
    private webSocketService: WebSocketService,
  ) { 

    this.loadUserDetailsService.currentData.subscribe(data=>{
      
      if(data!=null){
        this.userDetails = data;
      }


    });


    let stompClient = this.webSocketService.connect();
    
        stompClient.connect({}, frame => {
          
                
          stompClient.subscribe('/topic/notification', notifications => {
            
            console.log("the result of web socket is "+notifications);
           
            console.log(notifications.body);
            this.getNotifications();
                         
          })
        });
   
  }

  ngOnInit() {

        this.agentProfile = new AgentProfile();
        this.editReminderModel = new EditReminderModel();

        this.loginService.currentData.subscribe((data) => {
          
          
                this.crmUser = data;
                this.token=this.crmUser.sessionToken;
                this.id=this.crmUser.crmUserId;
                this.isAdmin = this.crmUser.admin;
                let systemDate = new Date();
                var firstDate = new Date(systemDate.getFullYear(),systemDate.getMonth(),systemDate.getDate()-7);
                this.startDate = this.datePipe.transform(firstDate,"dd-MM-yy");
                this.endDate = this.datePipe.transform(systemDate,"dd-MM-yy");
                this.getNotifications();
    
                console.log("the dates from landing dashboard"+this.startDate+" "+this.endDate);
                this.queueReportingDashboardService.getQueueReportingDashboard(this.id, this.token,this.startDate, this.endDate).subscribe(
                  data=>{
                    
                    var response = data.body['queueReportingDashboardDataVO'];
                    var dataList = response['queueList'];
    
                    dataList.forEach(element => {
                      
                      (this.barChartData[0].data as number[]).push(element.assigned);
                      (this.barChartData[1].data as number[]).push(element.unassigned);
                      (this.barChartData[2].data as number[]).push(element.completed);
                    this.barChartLabels.push(element.queueName);
                    });
                    for(let i=0;i<dataList.length;i++){
                      
                      this.chartData.push({"name": dataList[i].queueName,
                      "series": [
                        {
                          "name": "completed",
                          "value": dataList[i].completed
                        },
                        {
                          "name": "unassigned",
                          "value": dataList[i].unassigned
                        }
                        // {
                        //   "name": "assigned",
                        //   "value": dataList[i].assigned
                        // }
                      ]});
                    }
    
                    console.log("chart data is ?????????"+this.chartData);
                  });
                 
                  this.getReminders();
                  
                  this.reminderConfigurationService.currentData.subscribe(data=>{
                    
                    if(data==true){
                      this.getReminders();
                      this.reminderConfigurationService.storeddata(false);
                    }
                  });
    
                  
    
                  
                  

                  
                
          
              }
            );
  }

  getReminders(){
    
    this.getReminderRequest.crmUserId = this.id;
    this.getReminderRequest.searchByUser = true;
    this.getReminderRequest.sessionToken = this.token;
    this.getReminderRequest.searchCompleted = true;
    this.getReminderRequest.searchCriteriaId = this.id;
    this.reminderConfigurationService.getReminder(this.getReminderRequest).subscribe(data=>{
      
      if(data.status==200 && data.body["responseType"]=="SUCCESS"){
        if(data.body["reminderVOList"]==null ||data.body["reminderVOList"]==[] ||data.body["reminderVOList"].length==0){
          this.reminderVO = [];
          this.showRemindersTab = false;
        }
        else{
          this.reminderVO = data.body["reminderVOList"];
          for(let i=0;i<this.reminderVO.length;i++){
            this.reminderVO[i].dueDate = this.reminderVO[i].dueDateTime.substring(0,10);
            this.reminderVO[i].dueTime = this.reminderVO[i].dueDateTime.substring(11,this.reminderVO[i].dueDateTime.length);
            this.showRemindersTab = true;
          }
        }
      }
    });

  }

  getNotifications(){
    this.notificationService.getActiveNotifications(this.id,this.token).subscribe(data=>{
        
      console.log(data);
      if(data.status==200){
        if(data.body['notificationVOS'].length>0){
          this.showNotificationsTab = true;
          this.notificationVOS = data.body['notificationVOS'];
        }
        else{
          this.showNotificationsTab = false;
        }
      }
    });

  }


  pushStatus(){
    this.icon2.push(false);
  }
  
    toggleIcon(i){
      
     this.icon2[i] = !this.icon2[i];
    }


    pushStatusForNotifcationIcon(){
      this.iconForNotification.push(false);
    }
    
      toggleIconForNOtification(i){
        
       this.iconForNotification[i] = !this.iconForNotification[i];
      }


    markReminderComplete(reminder,option){
      
      this.editReminderModel.crmUserId = this.id;
      this.editReminderModel.description = reminder.decription;
      this.editReminderModel.dueDateTime = reminder.dueDateTime;
      this.editReminderModel.id = reminder.id;
      this.editReminderModel.markCompleted = true;
      if(option=="COMPLETE")
      this.editReminderModel.reminderStatus = ReminderStatus.COMPLETED;
      else
      this.editReminderModel.reminderStatus = ReminderStatus.CANCELLED;
      this.editReminderModel.sessionToken = this.token;
      this.editReminderModel.title = reminder.title;
      this.reminderConfigurationService.editReminder(this.editReminderModel).subscribe(data=>{
        console.log(data);
        
        if(data.status==200 && data.body["responseType"]=="SUCCESS"){
          this.reminderVO.forEach((element,index) => {
            
            if(element.id==reminder.id){
              this.reminderVO.splice(index,1);
              this.icon2[index] = !this.icon2[index];
            }
          });
        }
      });
    }
  
}
