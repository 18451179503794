import{ Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
 import { environment } from "../environment";
import { ChangePasswordInput } from '../models/changePassword.model';



@Injectable()
export class ChangePasswordService{
     private Url=environment.baseUrl+'changeUserPassword';
constructor(private http:HttpClient){
    
}
changePassword(changePasswordInput:ChangePasswordInput){
    
     return this.http.post<any>(this.Url, changePasswordInput, {observe: 'response'})
   
}  

}