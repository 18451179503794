import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'listFilter',
    pure: true
  })
  export class ListFilterPipe implements PipeTransform {
  
    transform(list: any[], filterText: string): any {
        
        if(filterText==""){
            return list;
        }
      return list ? list.filter(item => item.workItemId==filterText) : list;
    }
  
  }