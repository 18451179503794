import { Component,OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { CrmUser } from '../models/crmuser.model';
import { AgentProfileService } from '../services/AgentProfile.service';
import { AgentProfile } from '../models/AgentProfile.model';
import {MatDialogConfig,MatDialog,MAT_DIALOG_DATA} from '@angular/material';
import { AgentProfileComponent } from '../agent-profile/agent-profile.component';
import { LoginService } from '../services/login.service';
import { LogoutService } from '../services/logout.service';
import { Router} from '@angular/router';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import {FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AgentReportingDashboardService } from 'src/app/services/AgentReportingDashboard.service';
import { ConfigureUserService } from 'src/app/services/configureUser.service';
import { WorkitemSubPropertiesModel } from 'src/app/models/WorktiemSubProperties.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
@Component({
  selector: 'dashboard',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css'],
  providers:[DatePipe]

})
export class DashComponent implements OnInit{
  /** Based on the screen size, switch from standard to one column per row */
  crmUser:CrmUser;
  id:number;
  token:string;
  agentProfile:AgentProfile;
  agentWIReportForm:FormGroup; 
  maxDate=new Date();
  startDate:string;
  endDate:string;
  minDate:Date;
  isValidDate:any;
  submitted:boolean=false;
  error:any={isError:false,errorMessage:''};
  parts:any;
  showDashboard:boolean=false;
  loading:boolean = false;
  usersList = [];
  usersListForDropdown:Array<any> = new Array<any>();
  selectedUsers= [];
  dropdownSettings:IDropdownSettings = {};
  cardLayout  = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return {
          columns: 1,
          miniCard: { cols: 1, rows: 1 },
          chart: { cols: 1, rows: 2 },
          table: { cols: 1, rows: 4 },
        };
      }
 
     return {
        columns: 4,
        miniCard: { cols: 2, rows: 2 },
        chart: { cols: 4, rows: 4},
        table: { cols: 4, rows: 4 },
      };
    })
  );
  constructor(private breakpointObserver: BreakpointObserver,
    private adminProfileService:AgentProfileService,
    private dialog:MatDialog,
    private loginService:LoginService,
    private router:Router,
    private logoutService:LogoutService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private agentReportingDashboardService:AgentReportingDashboardService,
    private configureUserService:ConfigureUserService
  ) {
      this.datePipe.transform(this.maxDate, 'mm/dd/yyyy');

      
    }
  
    ngOnInit() {
      window.scrollTo(0,0);
      this.dropdownSettings = {
        maxHeight:100,
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true,
        itemsShowLimit:3,
  
      };
      this.agentProfile = new AgentProfile();
      this.loginService.currentData.subscribe(
        data=>{
          if(data==null || data.sessionToken == null){
            this.router.navigate(['']);
          }
          else{
           this.id=data.crmUserId;
           this.token=data.sessionToken;
           

           this.agentReportingDashboardService.getAgentReportingDashboard(this.id,this.token).subscribe(
            data=>{
              
              if(data.status==200){
                
                this.agentReportingDashboardService.storeddata(data.body['agentReportingDashboardDataVO']);
                console.log("agent reporting data"+data.body['agentReportingDashboardDataVO']);
                this.showDashboard=true;
              }

              
            }
          );

          this.configureUserService.getUserList(this.id,this.token).subscribe(data=>{
            if(data.status==200){
              
              this.usersList= data.body['users'];
              // for(let i=0;i<5;i++){
              //   this.usersListForDropdown[i] = {id:i,'name':'some'};
              // }
              for(let i=0;i<this.usersList.length;i++){
                // let obj = new WorkitemSubPropertiesModel();
                // // thi
                // obj.id = i;
                // obj.name = this.usersList[i].name;
                
                this.usersListForDropdown.push({id:this.usersList[i].crmUserId,name:this.usersList[i].name});
              }
              this.usersList = this.usersListForDropdown;

            }
          });
          }
        }
      )
      this.agentWIReportForm = this.formBuilder.group({  
        // startDate: new FormControl('', [  
        //   Validators.required
        // ]),  
        // endDate: new FormControl('', [  
        //   Validators.required  
        // ])
        selectedUsers: new FormControl('',[
          Validators.required
        ])
       
      }); 

      
    }
    get f() {return this.agentWIReportForm.controls; }

    onAdminWorkitemControllerClick(){
      this.router.navigate(['adminWorkitemController']);
    }
    onDashboardClick(){
      this.router.navigate(["adminDashBoard"]);
    }
    onEmailClick(){
      this.router.navigate(["emailConfiguration"]);
    }
    onQueueClick(){
      this.router.navigate(["queueConfiguration"]);
    }
    onUserClick(){
      this.router.navigate(["userConfiguration"]);
    }

    onSkillClick(){
      this.router.navigate(["skillConfiguration"]);
    }

    onLiveMonitorClick(){
      this.router.navigate(["liveMonitor"]);
    }
    onManualWorkitemClick(){
      this.router.navigate(["manualWorkitemController"]);
    }
    showAgentDashboard()
    {
      this.router.navigate(["dashboard"]);
    }
    showWIDashboard(){
      this.router.navigate(["wiDashboard"]);
    }
    changePassword(){
      
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
          sessionToken:this.token,
          userId:this.id
        };
        // dialogConfig.maxWidth='400px';
      this.dialog.open(ChangePasswordComponent,dialogConfig);
    }
    logoutUser(){
      this.loginService.endInterval();
      this.logoutService.logoutUser(this.id, this.token).subscribe(
        data=>{
    
          console.log(data);
          if(data.status==200 ){
            this.router.navigate(['/']);
          }
        }
      )
     
    }
  openAdminProfile(){

      this.adminProfileService.getAgentDetails(this.id, this.token).subscribe(
        data=>{
    
          console.log(data);
          if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
            this.agentProfile.name = data.body['name'];
            this.agentProfile.emailId = data.body['emailAddress'];
            this.agentProfile.skills = data.body['skills'];
            this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.height = "auto";
            dialogConfig.width = "600px";  ;
            dialogConfig.data = {
            agentProfile: this.agentProfile
            };
             this.dialog.open(AgentProfileComponent,dialogConfig);
    
          }
          else if(data.body['responseType']== 'SESSION_TIMEOUT'){
                  
            alert("Session timeout!Please Login Again");
            this.loginService.storeddata(null);
            this.router.navigate(['']);
                                                        } 
        }
      );
     
    
    }
    validateDates(sDate: Date, eDate: Date){
      this.isValidDate = true;
     
  
      if((eDate) < (sDate)){
       // this.error={isError:true,errorMessage:'Start date should be less than End date.'};
       alert("Start date should be less than End date.");
        this.isValidDate = false;
      }
      return this.isValidDate;
    }
    process(date:string){
      this.parts = date.split("-");
     return new Date(this.parts[2], this.parts[1] - 1, this.parts[0]);
  }
  viewDashboard()
  {
    
    this.submitted=true;
    // this.startDate=this.datePipe.transform(this.agentWIReportForm.value.startDate,"dd-MM-yy");
    // this.endDate=this.datePipe.transform(this.agentWIReportForm.value.endDate,"dd-MM-yy");
    // this.isValidDate = this.validateDates(this.process(this.startDate), this.process(this.endDate));
   // if( this.agentWIReportForm.valid) {
      this.loading = true;
      this.agentReportingDashboardService.getAgentReportingDashboard(this.id,this.token).subscribe(
        data=>{
          
          if(data.status==200){
            
            this.agentReportingDashboardService.storeddata(data.body['agentReportingDashboardDataVO']);
            console.log("agent reporting data"+data.body['agentReportingDashboardDataVO']);
            this.showDashboard=true;
            this.loading = false;
          }
        }
      );
  //}
 // else{
   // this.showDashboard=false;
 // }
}


onItemSelect(item: any) {
  
  console.log(item)
}
onSelectAll(items: any) {
  
}
OnItemDeSelect(item:any){
  
  
}
onDeSelectAll(items: any){

}


reminder(){
  
   
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data={
    crmUserId: this.id,
    sessionToken: this.token,
    detailedWorkitem:null,
    isServed:false
  };
  this.dialog.open(ReminderConfigurationComponent,dialogConfig);
 }
}
