import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EngagementHistoryBySubject } from "../models/EngagementHistoryBySubject.model";
import { environment } from "../environment";

@Injectable()
export class EngagementHistoryBySubjectService{


    private url = environment.baseUrl+'getEngagementHistoryByEmailOrProperty';;
    constructor(private http:HttpClient){
    }

    searchCustomer(engagementHistoryBySubject:EngagementHistoryBySubject){
        return this.http.post(this.url,engagementHistoryBySubject,{observe:'response'});
    }
}