import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepicker } from '@angular/material';
import { ReportsService } from 'src/app/services/reports.service';
import { AgentWIDetailReportComponent } from '../agentWIDetailReport.component';
import { DatePipe } from '@angular/common';
import { saveAs } from "file-saver";
import { LoginService } from 'src/app/services/login.service';
import { ConfigureUserService } from 'src/app/services/configureUser.service';
import { CompleteSystemReport } from 'src/app/models/CompleteSystemReport.model';
import { ReportFrequencyEnum } from 'src/app/ReportFrequencyEnum';
import { ReportTypeFilterEnum } from 'src/app/ReportTypeFilterEnum';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
    selector: 'app-report-by-agent',
    templateUrl: './report-by-agent.component.html',
    providers:[DatePipe]
  })
  export class ReportByAgentComponent implements OnInit{
    reportType:string;
    token:string;
    id:number;
    startDate:string;
    endDate:string;
    minDate:Date;
    isValidDate:any;
    parts:any;
    submitted:boolean=false;
    maxDate=new Date();
    error:any={isError:false,errorMessage:''};
    ReportByAgentForm:FormGroup;
    showStartDate:boolean;
    showMonth:boolean;
    pickerDate:string;
    agentList:Array<any> = new Array();
    completeSystemReport:CompleteSystemReport;
    // dropdownList = [];
    dropdownSettings:IDropdownSettings = {};
    selectedItems = [];
    @ViewChild('picker1',{read:undefined,static:false}) datePicker: MatDatepicker<Date>;
    constructor(
      private configureUserService: ConfigureUserService,
      private loginService: LoginService,
      private reportService:ReportsService,
      private dialogRef: MatDialogRef<ReportByAgentComponent>,
      private router:Router,
      @Inject(MAT_DIALOG_DATA) data,
      private formBuilder: FormBuilder,
      private datePipe: DatePipe) {
        this.token=data.sessionToken;
        this.id=data.userId;
        
        this.reportType=data.reportType;
        //this.agentList = ['one','two','three'];
        for(let i=0;i<data.agentList.length;i++){
          this.agentList.push({id:data.agentList[i].crmUserId,name:data.agentList[i].name});
        }
        //this.agentList = data.agentList;
        
        this.datePipe.transform(this.maxDate, 'mm/dd/yyyy');
  
    }

    ngOnInit() {
        
        this.showMonth = false;
        this.showStartDate = true;
        this.completeSystemReport = new CompleteSystemReport();
        this.dropdownSettings = {
          maxHeight:90,
          singleSelection: false,
          idField: 'id',
          textField: 'name',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          allowSearchFilter: true,
          itemsShowLimit:3
        };
        this.ReportByAgentForm = this.formBuilder.group({ 
            
            frequency: new FormControl('HOURLY', [Validators.required]),
            selectedAgent: new FormControl('',[Validators.required]),
            startDate: new FormControl('', [Validators.required]),  
            month: new FormControl('', [Validators.required])
             
            }); 
    }

    get f() {return this.ReportByAgentForm.controls; }
          
    close()
    {
      this.dialogRef.close();
    }
    


    clickRadioFilter(option){

      if((option=="HOURLY") || (option=="WEEKLY")){
        this.showMonth = false;
        this.showStartDate = true;
      }
      else {
        this.showStartDate = false;
        this.showMonth = true;
      }
    }

    closeDatePicker(event) {
      
      
      this.pickerDate = this.datePipe.transform(event,"MM-dd-yyyy");
      this.ReportByAgentForm.get('month').setValue(this.datePipe.transform(event,"dd-MM-yy"));
      console.log(this.pickerDate);
      this.ReportByAgentForm.get("month").setValue(new Date(this.pickerDate));
      this.datePicker.close();
      this.pickerDate = this.datePipe.transform(event,"dd-MM-yy");
    }

    downloadReportByAgent(){
      
      if(this.ReportByAgentForm.value.frequency==ReportFrequencyEnum.MONTHLY){
        this.completeSystemReport.startDate =this.pickerDate;
        this.ReportByAgentForm.get("startDate").setValue(this.ReportByAgentForm.value.month);
      }
      else{
        this.completeSystemReport.startDate = this.datePipe.transform(this.ReportByAgentForm.value.startDate,"dd-MM-yy");
        this.ReportByAgentForm.get("month").setValue(this.ReportByAgentForm.value.startDate);
      }
      this.submitted = true;
      this.completeSystemReport.accessedBy = 1;
      this.completeSystemReport.crmUserId = this.id;
      this.completeSystemReport.frequency = this.ReportByAgentForm.value.frequency;;
      this.completeSystemReport.reportFilterType = ReportTypeFilterEnum.AGENT;
      this.completeSystemReport.reportId = 1;
      this.completeSystemReport.reportType = "EXCEL";
      //this.completeSystemReport.selectedFilterId = this.ReportByAgentForm.value.selectedAgent;
      this.completeSystemReport.sessionToken = this.token;
      this.completeSystemReport.endDate = "";
      this.completeSystemReport.reportWorkItemType=this.reportType;
      this.completeSystemReport.multiselectedFilterIds = [];
      for(let i=0;i<this.ReportByAgentForm.value.selectedAgent.length;i++){
        this.completeSystemReport.multiselectedFilterIds.push(this.ReportByAgentForm.value.selectedAgent[i].id);
      }

      if(this.ReportByAgentForm.valid) {
        this.reportService.getCompleteSystemReport(this.completeSystemReport).subscribe(
          data => {
            
            console.log(data);
            if(this.reportType== 'CHAT' ){
            
            saveAs(data,"ChatReportByAgent");
            }else{
              saveAs(data,"ReportByAgent"); 
             }
          },
          err => {
            alert("Problem while downloading the file.");
            console.error(err);
          });
          this.dialogRef.close();
          }

    }



    onItemSelect(item: any) {
      
      //this.selectedItems.push(item);
    }
  
    onItemUnSelect(item:any){
      
      for(let i= 0;i<this.selectedItems.length;i++){
        if(this.selectedItems[i].id==item.id){
          for(let j = i;j<(this.selectedItems.length-1);j++){
            this.selectedItems[j] = this.selectedItems[j+1];
          }
          this.selectedItems.pop();
          break;
        }
      }
  
      console.log("unselected")        
    }
    onSelectAll(items: any) {
      
      this.selectedItems = [];
      for(let i=0;i<items.length;i++){
        this.selectedItems.push(items[i]);
      }
    }
  
    onDeSelectAll(items:any){
      
      this.selectedItems = [];
    }
  }