import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepicker } from '@angular/material';
import { ReportsService } from 'src/app/services/reports.service';
import { DatePipe } from '@angular/common';
import { saveAs } from "file-saver";
import { CompleteSystemReport } from 'src/app/models/CompleteSystemReport.model';
import { ReportTypeFilterEnum } from 'src/app/ReportTypeFilterEnum';
import { ReportFrequencyEnum } from 'src/app/ReportFrequencyEnum';
@Component({
    selector: 'app-report-by-all',
    templateUrl: './report-by-all.component.html',
    providers:[DatePipe]
  })
  export class ReportByAllComponent implements OnInit{

    token:string;
    id:number;
    frequency:string;
    startDate:string;
    endDate:string;
    minDate:Date;
    isValidDate:any;
    parts:any;
    submitted:boolean=false;
    maxDate=new Date();
    error:any={isError:false,errorMessage:''};
    ReportByAllForm:FormGroup;
    selectedFilterId:number;
    reportFilterType:String;
    completeSystemReport:CompleteSystemReport;
    pickerDate:string;
    showStartDate:boolean;
    showMonth:boolean;
    reportType:string;
    @ViewChild('picker1',{read:undefined,static:false}) datePicker: MatDatepicker<Date>;

 
    constructor(private reportService:ReportsService, private dialogRef: MatDialogRef<ReportByAllComponent>,
        private router:Router,@Inject(MAT_DIALOG_DATA) data,private formBuilder: FormBuilder,private datePipe: DatePipe){
        
        
          this.token=data.sessionToken;
        this.id=data.userId;
        this.reportType=data.reportType;
        this.datePipe.transform(this.maxDate, 'mm/dd/yyyy');
  
    }

    ngOnInit() {
        
        this.showStartDate = true;
        this.showMonth = false;
        this.ReportByAllForm = this.formBuilder.group({
          
            frequency: new FormControl(ReportFrequencyEnum.HOURLY,[Validators.required]),
            startDate: new FormControl('', [Validators.required]),  
            month: new FormControl('', [Validators.required ])
        }); 
            this.completeSystemReport = new CompleteSystemReport();
    }

    get f() {return this.ReportByAllForm.controls; }
          
    close()
    {
      this.dialogRef.close();
    }

    clickRadioFilter(option){

      if((option==ReportFrequencyEnum.HOURLY) || (option==ReportFrequencyEnum.WEEKLY)){
        this.showMonth = false;
        this.showStartDate = true;
      }
      else {
        this.showStartDate = false;
        this.showMonth = true;
      }

    }

    closeDatePicker(event) {
      
      
      this.pickerDate = this.datePipe.transform(event,"MM-dd-yyyy");
      this.ReportByAllForm.get('month').setValue(this.datePipe.transform(event,"dd-MM-yy"));
      console.log(this.pickerDate);
      this.ReportByAllForm.get("month").setValue(new Date(this.pickerDate));
      this.datePicker.close();
      this.pickerDate = this.datePipe.transform(event,"dd-MM-yy");
}
    
    downloadReportByAll(){
      
      if(this.ReportByAllForm.value.frequency==ReportFrequencyEnum.MONTHLY){
        this.completeSystemReport.startDate =this.pickerDate;
        this.ReportByAllForm.get("startDate").setValue(this.ReportByAllForm.value.month);
      }
      else{
        this.completeSystemReport.startDate = this.datePipe.transform(this.ReportByAllForm.value.startDate,"dd-MM-yy");
        this.ReportByAllForm.get("month").setValue(this.ReportByAllForm.value.startDate);
      }
      this.submitted = true; 
      this.completeSystemReport.accessedBy = 1;
      this.completeSystemReport.crmUserId = this.id;
      this.completeSystemReport.frequency = this.ReportByAllForm.value.frequency;;
      this.completeSystemReport.reportFilterType = ReportTypeFilterEnum.ALL;
      this.completeSystemReport.reportId = 1;
      this.completeSystemReport.reportType = "EXCEL";
      this.completeSystemReport.selectedFilterId = 0;
      this.completeSystemReport.sessionToken = this.token;
      this.completeSystemReport.endDate = "";
      this.completeSystemReport.reportWorkItemType=this.reportType;
      if(this.ReportByAllForm.valid) {
        this.reportService.getCompleteSystemReport(this.completeSystemReport).subscribe(
          data => {
            console.log(data);
            if(this.reportType== 'CHAT' ){
              
            saveAs(data,"completeSystemChatReportByAll");
            }else{
            saveAs(data,"completeSystemReportByAll");
            }
          },
          err => {
            alert("Problem while downloading the file.");
            console.error(err);
          });
          this.dialogRef.close();
          }

    }
  }