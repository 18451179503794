import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";

@Injectable()
export class IVRWorkitemRemarksService{
    private Url=environment.baseUrl+'saveWorkItem';
    constructor(private http:HttpClient){
        
    }

    updateIVRWorkitemRemarks(ivrWorkitemRemarksInput){
        
        console.log(ivrWorkitemRemarksInput);
        return this.http.post(this.Url ,ivrWorkitemRemarksInput, {observe: 'response'});
    }
}