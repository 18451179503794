import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CustomFieldModel } from 'src/app/models/AddCustomField.model';
import { CustomFieldsService } from 'src/app/services/CustomFields.service';

@Component({
  selector: 'app-add-custom-fields',
  templateUrl: './add-custom-fields.component.html',
  styleUrls: ['./add-custom-fields.component.css']
})
export class AddCustomFieldsComponent implements OnInit {

  id:number;
  token:string;
  addCustomFieldForm:FormGroup;
  submitted:boolean=false;
  //addSkillModel:AddSkillModel;
  isUpdateRequest:boolean = false;
  customFieldModel:CustomFieldModel;
  customField:any;
  
  constructor(
    private router:Router,
    private loginService:LoginService,
    private dialog:MatDialog,
    private formBuilder: FormBuilder,
    private route:ActivatedRoute,
    private dialogRef: MatDialogRef<AddCustomFieldsComponent>,
    private customFieldsService:CustomFieldsService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    
    
    this.id = data['id'];
    this.token = data['token'];
    this.isUpdateRequest = data['isUpdateRequest'];
    this.customField = data['customField'];
    this.setFormControls();
    
   }

  ngOnInit() {
    this.customFieldModel = new CustomFieldModel();
  }

  setFormControls(){
    this.addCustomFieldForm = this.formBuilder.group({
      name:this.isUpdateRequest?new FormControl({value:this.customField.customFieldName,disabled:true},[
        Validators.required 
      ]):new FormControl('',[
        Validators.required  
      ]),
      description:this.isUpdateRequest?new FormControl(this.customField.customFieldDescription,[
        Validators.required
      ]):new FormControl('',[
        Validators.required  
      ]),
      defaultValue:this.isUpdateRequest?new FormControl():new FormControl('',[
        Validators.required  
      ]),
    });
  }

  get formControls() {
    return this.addCustomFieldForm.controls;
  }

  onSubmitCustomField(){

    
    this.submitted = true;
    this.customFieldModel.crmUserId= this.id;
    this.customFieldModel.sessionToken= this.token;
    if(this.isUpdateRequest){
      this.customFieldModel.fieldId = this.customField.customFieldId;
      this.customFieldModel.newDescription = this.addCustomFieldForm.value.description;
      if(this.submitted && this.addCustomFieldForm.valid){
        this.customFieldsService.editCustomField(this.customFieldModel).subscribe(data=>{
          console.log(data);
          this.close();
          if(data.status==200 && data.body["responseType"]=="SUCCESS"){
            
          }
        });
      }
    }
    else{
      this.customFieldModel.name= this.addCustomFieldForm.value.name;
      this.customFieldModel.description= this.addCustomFieldForm.value.description;
      this.customFieldModel.defaultValue= this.addCustomFieldForm.value.defaultValue;
    
      if(this.submitted && this.addCustomFieldForm.valid){
        this.customFieldsService.addCustomField(this.customFieldModel).subscribe(data=>{
          console.log(data);
          this.close();
          if(data.status==200 && data.body["responseType"]=="SUCCESS"){
            
          }
        });
      }
    }


  }

  close(){
    this.dialogRef.close();
  }

}
