import{ Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
 import { environment } from "../environment";
import { EditQueue } from '../models/EditQueue.model';




@Injectable()
export class EditQueueService{
     private Url=environment.baseUrl+'editQueue';
constructor(private http:HttpClient){
    
}
editQueue(editQueue:EditQueue){
     return this.http.post<any>(this.Url, editQueue, {observe: 'response'});
   
}  

}