import { Injectable } from '@angular/core';
import { environment } from '../environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PasswordResetService{
    private Url=environment.baseUrl+'forgotPassword';

    constructor(private http:HttpClient){
    
    }


    ResetPassword(emailAddress, terminalId){
        
        return this.http.post(this.Url, {"emailAddress":emailAddress, "terminalId":terminalId}, {observe: 'response'})
      
   }
}