import { Component, OnInit , ElementRef, ViewChild,Inject} from '@angular/core';
import { Router,NavigationExtras,ActivatedRoute } from '@angular/router';
import {MatDialog,MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material';
import {FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
import {UpdateAgentStatusService} from '../services/updateAgentStatus.service';
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
import {LogoutService} from '../services/logout.service';
import {AddEmailComponent} from '../email-configuration/add-email.component';
import { ConfigureEmailService } from '../services/configureEmail.service';
import {ConfigureEmail} from '../models/configureEmail.model';
import { AddQueueComponent } from '../add-queue/add-queue.component';
import { GetApplicationsService } from '../services/GetApplications.service';
import { GetSkillsService } from '../services/GetSkills.service';
import { GetQueueListService } from '../services/GetQueueList.service';
import { AddQueue } from '../models/AddQueue.model';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { EditQueue } from '../models/EditQueue.model';
import { EditQueueService } from '../services/EditQueue.service';
import { EditQueueComponent } from '../edit-queue/edit-queue.component';
import { LoginService } from '../services/login.service';
import {AgentProfileService} from '../services/AgentProfile.service';
import {AgentProfile} from '../models/AgentProfile.model';
import {AgentProfileComponent} from '../agent-profile/agent-profile.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
@Component({
    selector: 'app-queueConfiguration',
    templateUrl: './queue-configuration.component.html',
    styleUrls:['./queue-configuration.component.css']
  })
export class QueueConfigurationComponent implements OnInit{
  token:string;
  id:number;
  submitted:boolean=false;
  showRow:boolean=false;
  queueList:Array<any> = [];
  editQueue:EditQueue;
  agentProfile:AgentProfile;
  updateAgentStatusModel:UpdateAgentStatusModel;
    constructor( private adminProfileService:AgentProfileService,
      private loginService:LoginService,
      private dialog:MatDialog,
      private formBuilder: FormBuilder,
      private route:ActivatedRoute,
      private router:Router,
        private logoutService:LogoutService,
        private getSkillsService:GetSkillsService,
        private getQueueListService:GetQueueListService,
        private editQueueService:EditQueueService
        ){
      }
    ngOnInit() {
      window.scrollTo(0,0);
      this.agentProfile = new AgentProfile();
     this.editQueue = new EditQueue();
     this.loginService.currentData.subscribe(data=>{
       
      if(data==null || data.sessionToken == null){
        this.router.navigate(['']);
      }
      else{
       this.id=data.crmUserId;
       this.token=data.sessionToken;
      }
     });
   this.getQueueListService.getQueueList(this.id, this.token).subscribe(
     data=>{
       
      if(data.status==200 && data.body["responseType"]=="SUCCESS"){
      console.log(data);
      this.queueList = data.body['queueList'];
      }
      else if(data.body['responseType']== 'SESSION_TIMEOUT'){

        alert("Session timeout!Please Login Again");
        this.loginService.storeddata(null);
        this.router.navigate(['']);
      }
     }
   );
    }

    onAdminWorkitemControllerClick(){
      this.router.navigate(['adminWorkitemController']);
    }
      onDashboardClick(){
        this.router.navigate(["adminDashBoard"]);
      }

      onLiveMonitorClick(){
        this.router.navigate(["liveMonitor"]);
      }
      onQueueClick(){
        //this.router.navigate(["queueConfiguration"]);
      }
      onEmailClick(){
        this.router.navigate(["emailConfiguration"]);
      }
      onManualWorkitemClick(){
        this.router.navigate(["manualWorkitemController"]);
      }
      onUserClick(){
        this.router.navigate(["userConfiguration"]);
      }

      onSkillClick(){
        this.router.navigate(["skillConfiguration"]);
      }
      showAgentDashboard()
      {
        this.router.navigate(["dashboard"]);
      }
      showWIDashboard(){
        this.router.navigate(["wiDashboard"]);
      }

      logoutUser(){
        
        this.loginService.endInterval();
        this.logoutService.logoutUser(this.id, this.token).subscribe(
          data=>{

            
            console.log(data);
            if(data.status==200 ){
              this.router.navigate(['/']);
            }
          }
        )

      }





    addQueueConfig(){
      
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = "auto";
      dialogConfig.width = "60%";

      this.getSkillsService.getSkills(this.id,this.token).subscribe(

        data=>{
          
          console.log(data);
          if(data.status==200 && data.body["responseType"]=="SUCCESS"){
          dialogConfig.data = {
            'skills':data.body['skills'],
            'id': this.id,
            'token':  this.token,
          };

          this.dialog.open(AddQueueComponent,dialogConfig).afterClosed().subscribe(
            result=>{
              this.refreshQueueList();
            }
          );
        }
        else if(data.body['responseType']== 'SESSION_TIMEOUT'){

          alert("Session timeout!Please Login Again");
          this.loginService.storeddata(null);
          this.router.navigate(['']);
        }
        }
      );




    }

    updateQueue(queue){
      


      if(queue.id>0){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = "60%";
        dialogConfig.width = "60%";
        dialogConfig.data = {
          "id" : this.id,
          "token": this.token,
          "queue":queue
        }
        this.dialog.open(EditQueueComponent,dialogConfig);
      }
    }

    refreshQueueList(){
      this.getQueueListService.getQueueList(this.id, this.token).subscribe(
        data=>{
         console.log(data);
         if(data.status==200 && data.body["responseType"]=="SUCCESS"){
         this.queueList = data.body['queueList'];
        }
        else if(data.body['responseType']== 'SESSION_TIMEOUT'){

          alert("Session timeout!Please Login Again");
          this.loginService.storeddata(null);
          this.router.navigate(['']);
        }
      }
      );
    }

    openAdminProfile(){

      
        this.adminProfileService.getAgentDetails(this.id ,this.token).subscribe(
          data=>{
            

            console.log(data);
            if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
              this.agentProfile.name = data.body['name'];
              this.agentProfile.emailId = data.body['emailAddress'];
              this.agentProfile.skills = data.body['skills'];
              this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              dialogConfig.height = "218px";
              dialogConfig.width = "350px";
              dialogConfig.data = {
              agentProfile: this.agentProfile
              };
               this.dialog.open(AgentProfileComponent,dialogConfig);

            }
            else if(data.body['responseType']== 'SESSION_TIMEOUT'){

              alert("Session timeout!Please Login Again");
              this.loginService.storeddata(null);
              this.router.navigate(['']);
                                                          }
          }
        );


      }

      changePassword(){
        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            sessionToken:this.token,
            userId:this.id
          };
          // dialogConfig.maxWidth='400px';
        this.dialog.open(ChangePasswordComponent,dialogConfig);
      }

      reminder(){
        
         
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
          crmUserId: this.id,
          sessionToken: this.token,
          detailedWorkitem:null,
          isServed:false
        };
        this.dialog.open(ReminderConfigurationComponent,dialogConfig);
       }
}
