export class IVRWorkitemAddedComments{

    id: number;
    commentingAgent:{
        name: string,
        emailId:string,
        loginName:string
    };
    iqdWorkItem:number;
    comment:string;
    commentTms:string;
    commentType:string;
    constructor(commentingAgent,comment, commentTms){
      
      this.commentingAgent = {name:"",emailId:"",loginName:""};
        this.comment = comment;
        this.commentTms = commentTms;
        this.commentingAgent.loginName = commentingAgent;
    }
}
