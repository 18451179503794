import { Component, OnInit , ElementRef, ViewChild,Inject} from '@angular/core';
import { Router,NavigationExtras,ActivatedRoute } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA,MatDialogConfig, MatDialog} from '@angular/material';
import {FormGroup,FormBuilder,FormControl,Validators,FormArray} from '@angular/forms';
import {UpdateAgentStatusService} from '../services/updateAgentStatus.service';
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
import {LogoutService} from '../services/logout.service';
import {AddCustomerMessageComponent} from '../messagePopUp/addCustomerMessage.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {ConfigureEmail} from '../models/configureEmail.model';
import { ConfigureEmailService } from '../services/configureEmail.service';
import { Users } from '../models/users.model';
import { ConfigureUserService } from '../services/configureUser.service';
@Component({
    selector: 'app-adduser',
    templateUrl: './add-user.component.html',
  })
export class AddUserComponent implements OnInit{
  token:string;
  id:number;
  addUserForm:FormGroup; 
  submitted:boolean=false;
  updateAgentStatusModel:UpdateAgentStatusModel;
  skillList = [];
  selectedItemss = [];
  selectedApps=[];
  // userTypes = [];
  isSme:boolean = false;
  public userData:Users;
  dropdownSettings = {};
  dropdownSettingsApp={};
  applicationList=[];
  skills:[];
    constructor(private formBuilder: FormBuilder,private route:ActivatedRoute,private router:Router,
        private updateAgentStatusService:UpdateAgentStatusService,private logoutService:LogoutService,
        private dialog:MatDialog,private dialogRef: MatDialogRef<AddUserComponent>,private configureUserService:ConfigureUserService, @Inject(MAT_DIALOG_DATA) data){
          this.route.queryParams.subscribe(params => {
            this.id = data.id;
            this.token = data.token;
           
        
        });
          this.addUserForm=this.formBuilder.group({
            userName:new FormControl('',[Validators.required]),
          emailId:new FormControl('', [ 
            Validators.required, 
            Validators.minLength(5),  
            Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
          ]), 
          assignmentThreshold:new FormControl('',[Validators.required]), 
          applications:new FormControl('',[Validators.required]),
          agentSkills:new FormControl('',[Validators.required]),
          extensionNumber:new FormControl(''),
          location:new FormControl(''),
          loginName:new FormControl(''),
          isSME:new FormControl('')
      })
      }
    ngOnInit() {
      
      this.configureUserService.getAppList(this.id,this.token).subscribe(
          data=>{
           this.applicationList=data.body['applications'];
          });
      
      this.configureUserService.getSkillList(this.id,this.token).subscribe(
        data=>{
        console.log(data);
        if(data.status==200)
        {
          this.skills=data.body['skills'];
        }
        }
      )
   
      this.dropdownSettings= {
        maxHeight:50 ,     
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        closeDropDownOnSelection:true,
        //itemsShowLimit: 3,
        allowSearchFilter: true
      };
     this.dropdownSettingsApp={
      singleSelection: false,
      idField: 'id',
      textField: 'applicationName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      closeDropDownOnSelection:true,
     };

     //this.userTypes = [{id:1, value:"ADMIN"},{id:2,value:"SME"},{id:3,value:"AGENT"}];
    }
   
    get f() {return this.addUserForm.controls; }

    onAddUserSubmit(){
      
      this.submitted=true;
      this.userData= new Users();
      if(this.addUserForm.valid)
      {
      this.userData.userSkill = [];
      this.userData.application=[];
      this.userData.userName=this.addUserForm.value.userName;
      this.userData.emailAddress=this.addUserForm.value.emailId;
      for(let i=0;i<this.addUserForm.value.agentSkills.length;i++){
        this.userData.userSkill[i] = this.addUserForm.value.agentSkills[i].id;
      }
      for(let i=0;i<this.addUserForm.value.applications.length;i++){
        this.userData.application[i] = this.addUserForm.value.applications[i].id;
      }
      this.userData.assignmentThreshold=this.addUserForm.value.assignmentThreshold;
      this.userData.extensionNumber=this.addUserForm.value.extensionNumber;
      this.userData.userLocation=this.addUserForm.value.location;
      this.userData.sessionToken=this.token;
      this.userData.userId=this.id;
      this.userData.loginName=this.addUserForm.value.loginName;
      //this.userData.userType = this.addUserForm.value.userType;
      this.isSme = this.addUserForm.value.isSME;
      
        this.configureUserService.addUser(this.userData, this.isSme).subscribe(
          data=>{        

            if(data.status==200 && data.body["responseType"]=="SUCCESS"){
              const dialogConfig= new MatDialogConfig();
              dialogConfig.disableClose=true;
              dialogConfig.autoFocus=true;
              dialogConfig.width="60%";
              dialogConfig.height="40%";
              this.dialog.open(AddCustomerMessageComponent,dialogConfig);
              this.dialogRef.close();
            }
            else{
              
              alert("Unable to save data");
            }
            
          }
        )
       
    }
  }
 
    close()
    {
        this.dialogRef.close();
    }

    onItemSelect(item: any) {
      console.log("item>>"+this.selectedItemss);
    }
    onSelectAll(items: any) {
      console.log("items>>>"+this.selectedItemss);
    }
    OnItemDeSelect(item:any){
      
      console.log("items>>>"+this.selectedItemss);
  }
  onDeSelectAll(items: any){
    console.log("items>>>"+this.selectedItemss);
}


 
}