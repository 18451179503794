import { IVRWorkitemAddedComments } from './ivrWorkitemAddedComments.model';
import { WorkItemCategory } from './workitemcategory.model';
import { WorkItemSubCategory } from './workitemsubcategory.model';

export class IVRWorkitemItemDetailOutput{
  
    
  
   
    public agentId: number;
    public callText: string;
    public callDateTime: string;
    public callUniqueId: string;
    public callingNumber: number;
    public completedTms: string;
    public createTms: string;
    public customerVO:Array<{
    customerContactNumber: string,
    customerEmailAddress:string,
    customerFacebookId: string,
    customerHomeAddress: string,
    customerId: number,
    customerLocation: string,
    customerName:string,
    customerTwitterId: string
    }>;
    public assignedAgent:Array<{
        name:string;
    }>;
    public dateTimeFormat: string;
    public ivrAddress: string;
    public ivrAppId: string;
    public slaThreshold: string;
    public workItemStatus: string;
    public workItemType:  string;
    public workItemTimeSpent:Array<{
        hh:number
    mm:number
    ss: number
    }>;
    public workItemComments:Array<IVRWorkitemAddedComments>;
    public workItemCategory:Array<WorkItemCategory>;
    public workItemSubCategory:Array<WorkItemSubCategory>;
    
    }