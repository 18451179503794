import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SearchHistoryService{
     public StoredData: BehaviorSubject<string> = new BehaviorSubject<string>(null);  
     currentData = this.StoredData.asObservable();
       storeddata(data: any) {
         this.StoredData.next(data);
       }

}