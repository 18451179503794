import { Component, OnInit } from '@angular/core';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material';
import { AgentProfileComponent } from '../agent-profile/agent-profile.component';
import { AgentProfileService } from '../services/AgentProfile.service';
import { LoginService } from '../services/login.service';
import { LogoutService } from '../services/logout.service';
import { Router } from '@angular/router';
import { AgentProfile } from '../models/AgentProfile.model';
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
import { AddManualWorkitemComponent } from './add-manual-workitem/add-manual-workitem.component';
import * as XLSX from 'xlsx';
import { UploadExcelComponent } from './uploadExcelFile/uploadExcel.component';
import { ExcelWorkitemControllerComponent } from './excel-workitem-controller/excel-workitem-controller.component';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';

@Component({
  selector: 'app-manual-workitem-controller',
  templateUrl: './manual-workitem-controller.component.html',
  styleUrls: ['./manual-workitem-controller.component.css']
})
export class ManualWorkitemControllerComponent implements OnInit {

  token:string;
  id:number;
  submitted:boolean=false;
  agentProfile:AgentProfile;
  updateAgentStatusModel:UpdateAgentStatusModel;

  constructor(
    private adminProfileService:AgentProfileService,
      private loginService:LoginService,
      private dialog:MatDialog,
      private router:Router,
        private logoutService:LogoutService,
  ) {

  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.agentProfile = new AgentProfile();
    this.loginService.currentData.subscribe(data=>{
      if(data==null || data.sessionToken == null){
        this.router.navigate(['']);
      }
      else{
       this.id=data.crmUserId;
       this.token=data.sessionToken;
      }
     });

  }


  onAdminWorkitemControllerClick(){
    this.router.navigate(['adminWorkitemController']);
  }
  onDashboardClick(){
    this.router.navigate(["adminDashBoard"]);
    }

  onLiveMonitorClick(){
    this.router.navigate(["liveMonitor"]);
  }

  onQueueClick(){
    this.router.navigate(["queueConfiguration"]);
  }

  onEmailClick(){
    this.router.navigate(["emailConfiguration"]);
  }

  onUserClick(){
    this.router.navigate(["userConfiguration"]);
  }
  onSkillClick(){
    this.router.navigate(["skillConfiguration"]);
  }

  onManualWorkitemClick(){
    //this.router.navigate(["manualWorkitemController"]);
  }

  showAgentDashboard()
  {
    this.router.navigate(["dashboard"]);
  }
  showWIDashboard(){
    this.router.navigate(["wiDashboard"]);
  }
  openAdminProfile(){

    
      this.adminProfileService.getAgentDetails(this.id ,this.token).subscribe(
        data=>{
          

          console.log(data);
          if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
            this.agentProfile.name = data.body['name'];
            this.agentProfile.emailId = data.body['emailAddress'];
            this.agentProfile.skills = data.body['skills'];
            this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.height = "218px";
            dialogConfig.width = "350px";
            dialogConfig.data = {
            agentProfile: this.agentProfile
            };
             this.dialog.open(AgentProfileComponent,dialogConfig);

          }
          else if(data.body['responseType']== 'SESSION_TIMEOUT'){

            alert("Session timeout!Please Login Again");
            this.loginService.storeddata(null);
            this.router.navigate(['']);
                                                        }
        }
      );


    }

    changePassword(){
      
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
          sessionToken:this.token,
          userId:this.id
        };
        // dialogConfig.maxWidth='400px';
      this.dialog.open(ChangePasswordComponent,dialogConfig);
    }

    logoutUser(){
      
      this.loginService.endInterval();
      this.logoutService.logoutUser(this.id, this.token).subscribe(
        data=>{

          
          console.log(data);
          if(data.status==200 ){
            this.router.navigate(['/']);
          }
        }
      )

    }

    addNewManualWorkitem(){

      
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = "auto";
      dialogConfig.width = "80%";

      this.dialog.open(AddManualWorkitemComponent,dialogConfig).afterClosed().subscribe(
        result=>{
        }
      );
    }

    filename = "ExcelSheet.xlsx";

    addWorkitemsFromExcel(event){
      const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width="300px";
    dialogConfig.height="200px";

      this.dialog.open(UploadExcelComponent,dialogConfig).afterClosed().
      subscribe(file => {
        

        if(file!=null && file!= undefined){
          
          console.log(file);
          const dialogConfigForExcel = new MatDialogConfig();
          dialogConfigForExcel.disableClose = true;
          dialogConfigForExcel.autoFocus = true;
          dialogConfigForExcel.data = file;
          this.dialog.open(ExcelWorkitemControllerComponent,dialogConfigForExcel);
        }

      });

      // 
      // let workBook = null;
      // let jsonData = null;
      // const reader = new FileReader();
      // const file = event.target.files[0];
      // reader.onload = (event) => {
      //   
      //   const data = reader.result;
      //   workBook = XLSX.read(data, { type: 'binary' });
      //   jsonData = workBook.SheetNames.reduce((initial, name) => {
      //     
      //     const sheet = workBook.Sheets[name];
      //     initial[name] = XLSX.utils.sheet_to_json(sheet);
      //     return initial;
      //   },{});

      //   
      //   const dataString = JSON.stringify(jsonData);

      //   document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
      // }

      // reader.readAsBinaryString(file);
    }

    reminder(){
      
       
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data={
        crmUserId: this.id,
        sessionToken: this.token,
        detailedWorkitem:null,
        isServed:false
      };
      this.dialog.open(ReminderConfigurationComponent,dialogConfig);
     }

}
