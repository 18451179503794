import{ Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
 import { environment } from "../environment";



@Injectable()
export class GetApplicationsService{
     private Url=environment.baseUrl+'getApplications';
constructor(private http:HttpClient){
    
}
getApplications(crmUserId,sessionToken){
     return this.http.post<any>(this.Url, {"crmUserId" : crmUserId,"sessionToken":sessionToken }, {observe: 'response'})
   
}  

}