export class Customer{
    public customerName:string;
    public customerEmailAddress:string;
    public customerFacebookId:string;
    public customerTwitterId:string;
    public customerContactNumber:number;
    public sessionToken:string;
    public customerId:number;
 public crmUserId:number;
 public responseType:string;
 public numberForScreen:string;
    
 }