import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { MatDialogRef } from '@angular/material';
import { DatePipe } from '@angular/common';
import { CrmUser } from 'src/app/models/crmuser.model';
import { SendNotificationModel } from 'src/app/models/SendNotification.model';
import { NotificationService } from 'src/app/services/Notifications.service';

@Component({
  selector: 'app-notification-configuration',
  templateUrl: './notification-configuration.component.html',
  styleUrls: ['./notification-configuration.component.css']
})
export class NotificationConfigurationComponent implements OnInit {

  token:string;
  id:number;
  crmUser:CrmUser = new CrmUser();
  sendNotificationModel:SendNotificationModel;
  submitted:boolean = false;

  notificationForm:FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private loginService:LoginService,
    private dialogRef: MatDialogRef<NotificationConfigurationComponent>,
    private datePipe: DatePipe,
    private notificationService:NotificationService
  ) { 

    this.loginService.currentData.subscribe(data=>{
      
     this.crmUser = data;
     this.id = this.crmUser.crmUserId;
     this.token = this.crmUser.sessionToken;
    });
   
  }

  ngOnInit() {
    this.sendNotificationModel = new SendNotificationModel();
    this.setFormControls();
  }

  setFormControls(){
    
    this.notificationForm =this.formBuilder.group({
      name: new FormControl('',[Validators.required]),
      description:new FormControl('',[Validators.required]),
      date: new FormControl('',[Validators.required]),

    });
  }

  onSubmit(){
    
    this.submitted = true;
    this.sendNotificationModel.crmUserId = this.id;
    this.sendNotificationModel.sessionToken = this.token;
    this.sendNotificationModel.title = this.notificationForm.value.name;
    this.sendNotificationModel.description = this.notificationForm.value.description;
    this.sendNotificationModel.date = this.datePipe.transform(this.notificationForm.value.date,'dd-MM-yy');

    if(this.submitted = true && this.notificationForm.valid){
    this.notificationService.sendNotification(this.sendNotificationModel).subscribe(data=>{
      
      console.log(data);
      if(data.status==200){
        this.close();
      }
    });
  }
  }

  close(){
    
    this.dialogRef.close();
  }
}
