import { Component, OnInit } from '@angular/core';
import { QueueReportingDashboardService } from 'src/app/services/QueueReportingDashboard.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { CrmUser } from 'src/app/models/crmuser.model';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-categorywise-wistatus-chart',
  templateUrl: './categorywise-wistatus-chart.component.html',
  styleUrls: ['./categorywise-wistatus-chart.component.css']
})
export class CategorywiseWIStatusChartComponent implements OnInit {


  crmUser:CrmUser;
  id:number;
  token:string;
  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Assigned' },
    {data:[],label: 'Unassigned'},
    {data:[],label: 'Completed'}
  ];
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public dataFromService:Array<any> = new Array<any>();
  categoryWIList:Array<any>=new Array<any>();
  constructor(
    private router:Router,
    private queueReportingDashboardService:QueueReportingDashboardService,
    private loginService:LoginService,
  ) { 

    this.queueReportingDashboardService.currentData.subscribe((data)=>{
      if(data!=null){
        
        this.dataFromService = data['categoryList'];
        for(let i=0;i<this.dataFromService.length;i++){
          this.categoryWIList[i] = {"categoryName":this.dataFromService[i].categoryName,"completed":  this.dataFromService[i].completed,"unassigned":this.dataFromService[i].unassigned,"assigned":this.dataFromService[i].assigned};
        }

      }
    });
  }

  ngOnInit() {

    this.loginService.currentData.subscribe((data) => {
      this.crmUser = data;
     this.token=this.crmUser.sessionToken;
     this.id=this.crmUser.crmUserId;
    
     if(this.crmUser==null || this.crmUser.sessionToken == null){
         this.router.navigate(['']);
       }
  });


  this.categoryWIList.forEach(element => {
    
    (this.barChartData[0].data as number[]).push(element.assigned);
    (this.barChartData[1].data as number[]).push(element.unassigned);
    (this.barChartData[2].data as number[]).push(element.completed);
  this.barChartLabels.push(element.categoryName);
  });
  }

}
