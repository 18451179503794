import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-complete-properties',
  templateUrl: './complete-properties.component.html',
  styleUrls: ['./complete-properties.component.css']
})
export class CompletePropertiesComponent implements OnInit {
  propertiesList:any;
  constructor(
    private dialogRef: MatDialogRef<CompletePropertiesComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    
      this.propertiesList = data['propertyVOS'];

   }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
