import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfigureUserService } from 'src/app/services/configureUser.service';
import { ReminderType } from 'src/app/ReminderType.enum';
import { ReminderRequestModel } from 'src/app/models/ReminderRequest.model';
import { ReminderConfigurationService } from 'src/app/services/ReminderConfiguration.service';
import { DatePipe } from '@angular/common';
import { WorkitemItemDetailOutput } from 'src/app/models/workitemDetailOutput.model';
import { AdminWorkitemModel } from 'src/app/models/AdminWorkitem.model';
import { LoadAdminWorkitemService } from 'src/app/services/LoadAdminWorkitems.service';
import { WorkitemSavedMessageComponent } from 'src/app/messagePopUp/workitem-saved-message/workitem-saved-message.component';
import { LoginService } from 'src/app/services/login.service';
import { CrmUser } from 'src/app/models/crmuser.model';
import { ShowDashboardService } from 'src/app/services/showDashboard.service';
import { WorkitemReminderService } from 'src/app/services/WorkitemReminder.service';
import { GetReminderRequest } from 'src/app/models/GetReminderRequest.model';
import { ReminderStatus } from 'src/app/Enums/ReminderStatus.enum';
import { EditReminderModel } from 'src/app/models/EditReminder.model';
import { ReminderVO } from 'src/app/models/ReminderVO.model';

@Component({
  selector: 'app-reminder-configuration',
  templateUrl: './reminder-configuration.component.html',
  styleUrls: ['./reminder-configuration.component.css']
})
export class ReminderConfigurationComponent implements OnInit {
  reminderForm:FormGroup;
  token:string;
  id:number;
  submitted:boolean=false;
  userList:any[];
  remindersList = [ReminderType.WORKITEM,ReminderType.GENERIC];
  workItemIdsList = [];
  newWorkitemsList = [];
  reminderRequestModel:ReminderRequestModel;
  workitemServed:boolean;
  disableWorkitem:boolean;
  workitemDetailOutput:WorkitemItemDetailOutput = new WorkitemItemDetailOutput();
  adminWorkitemsList = [];
  reminderVO:Array<ReminderVO> = new Array<ReminderVO>();
  // :Array<AdminWorkitemModel> = new Array<AdminWorkitemModel>();
  minDate:Date = new  Date();
  crmUser:CrmUser = new CrmUser();
  isAdmin:boolean;

  createReminderActive:boolean = true;
  reminderListActive:boolean = false;
  thirdTabActive:boolean = false;
  getReminderRequest:GetReminderRequest = new GetReminderRequest();
  listOfAllReminders = [];
  currentPage=0;
  icon2:Array<boolean> = new Array<boolean>();
  editReminderModel:EditReminderModel;

  constructor(
    private formBuilder: FormBuilder,
    private loginService:LoginService,
    private dialogRef: MatDialogRef<ReminderConfigurationComponent>,
    private configureUserService:ConfigureUserService,
    private datePipe: DatePipe,
    private reminderConfigurationService:ReminderConfigurationService,
    private loadAdminWorkitemServie:LoadAdminWorkitemService,
    private showDashboardService:ShowDashboardService,
    private workitemReminderService:WorkitemReminderService,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
     this.loginService.currentData.subscribe(data=>{
       
      this.crmUser = data;
      this.id = this.crmUser.crmUserId;
      this.token = this.crmUser.sessionToken;
      this.getReminderRequest.crmUserId = this.id;
      this.getReminderRequest.searchByUser = true;
      this.getReminderRequest.sessionToken = this.token;
      this.getReminderRequest.searchCompleted = true;
      this.getReminderRequest.searchCriteriaId = this.id;
      this.isAdmin = this.crmUser.admin;
     });
     this.workitemReminderService.currentData.subscribe(data=>{
       
       this.workitemDetailOutput = data;
       this.workitemServed = true;
     });
      
      // this.id = data['crmUserId'];
      // this.token = data['sessionToken'];
      // this.workItemIdsList = data['workitemsList'];
      // this.workitemServed = data['isServed'];
      // if(this.workitemServed){
      //   this.workitemDetailOutput = data['detailedWorkitem'];
      //   console.log("data is "+this.workitemDetailOutput);
      // }
      // else{
      //   this.workitemDetailOutput=null;
      // }


      this.reminderConfigurationService.getAllReminders(this.getReminderRequest).subscribe(data=>{
        
        if(data.status==200 && data.body["responseType"]=="SUCCESS"){
          if(data.body["reminderVOList"]==null ||data.body["reminderVOList"]==[] ||data.body["reminderVOList"].length==0){
            this.reminderVO = [];
          }
          else{
            this.reminderVO = data.body["reminderVOList"];
            for(let i=0;i<this.reminderVO.length;i++){
              this.reminderVO[i].dueDate = this.reminderVO[i].dueDateTime.substring(0,10);
              this.reminderVO[i].dueTime = this.reminderVO[i].dueDateTime.substring(11,this.reminderVO[i].dueDateTime.length);
            }
          }
        }
      });
      
      if(this.workitemDetailOutput!=null){
        this.reminderForm =this.formBuilder.group({
          name: new FormControl(this.workitemDetailOutput.workItemTitle,[Validators.required]),
          reminderType:new FormControl(ReminderType.WORKITEM,[Validators.required]),
          createdForUser:new FormControl('Select User'),
          description:new FormControl('',[Validators.required]),
          dueDateTime: new FormControl(this.workitemDetailOutput.formattedDueDate,[Validators.required]),
          workItemIds:new FormControl(this.workitemDetailOutput.workItemId),
          timezone:new FormControl('')
    
        });

      }else{
        this.setFormControls();
      }
      // for(let i=0;i<this.workItemIdsList.length;i++){
      //   // this.
      // }
     
      this.getUserList();
   }

  ngOnInit() {
  
    
    this.disableWorkitem = false;
    this.reminderRequestModel = new ReminderRequestModel();
    this.editReminderModel = new EditReminderModel();
if(this.isAdmin){

    this.loadAdminWorkitemServie.currentData.subscribe(data=>{
      this.adminWorkitemsList = data;
    });
  }
  else{
    this.showDashboardService.currentData.subscribe(data=>{
      this.adminWorkitemsList = data;
    });
  }
    
     
  }

  setFormControls(){
    
    this.reminderForm =this.formBuilder.group({
      name: new FormControl('',[Validators.required]),
      reminderType:new FormControl('Select Type',[Validators.required]),
      createdForUser:new FormControl('Select User'),
      description:new FormControl('',[Validators.required]),
      dueDateTime: new FormControl('',[Validators.required]),
      workItemIds:new FormControl(0),
      timezone:new FormControl('')

    });
  }

  getUserList(){
    
    this.configureUserService.getUserList(this.id,this.token).subscribe(data=>
        {
          
           this.userList= data.body['users'];
           console.log(this.userList);
        });
  }

  pushStatus(){
    this.icon2.push(false);
  }
  
    toggleIcon(i){
      
     this.icon2[(this.currentPage*5)+i] = !this.icon2[(this.currentPage*5)+i];
    }

    onPageChange(currentPage){
    
    this.currentPage = currentPage-1;
    console.log(currentPage);
    for(let i=0;i<5;i++){
      this.icon2[(this.currentPage*5)+i] = false;
    }
    }


  close(){
    //this.workitemReminderService.storeddata(null);
    this.dialogRef.close();
  }

  createReminder(){
    
    this.createReminderActive=true;
    this.reminderListActive=false;
    this.thirdTabActive=false;
  }

  reminderList(){
    
    this.reminderListActive=true;
    this.createReminderActive=false;
    this.thirdTabActive=false;
  }

  thirdTab(){
    
    this.reminderListActive=false;
    this.createReminderActive=false;
    this.thirdTabActive=true;
  }

  markReminderComplete(reminder,option){
    
    this.editReminderModel.crmUserId = this.id;
    this.editReminderModel.description = reminder.decription;
    this.editReminderModel.dueDateTime = reminder.dueDateTime;
    this.editReminderModel.id = reminder.id;
    this.editReminderModel.markCompleted = true;
    if(option=="COMPLETE")
    this.editReminderModel.reminderStatus = ReminderStatus.COMPLETED;
    else
    this.editReminderModel.reminderStatus = ReminderStatus.CANCELLED;
    this.editReminderModel.sessionToken = this.token;
    this.editReminderModel.title = reminder.title;
    this.reminderConfigurationService.editReminder(this.editReminderModel).subscribe(data=>{
      console.log(data);
      
      if(data.status==200 && data.body["responseType"]=="SUCCESS"){
        this.reminderConfigurationService.storeddata(true);
        this.reminderVO.forEach((element,index) => {
          
          if(element.id==reminder.id){
            this.reminderVO.splice(index,1);
            this.icon2[index] = !this.icon2[index];
          }
        });
      }
    });
  }

  onSubmit(){
    
    this.submitted = true;
    if(this.isAdmin){
    this.reminderRequestModel.createdForUser = this.reminderForm.value.createdForUser;
    }
    else{
      this.reminderRequestModel.createdForUser = this.id;
    }
    this.reminderRequestModel.crmUserId = this.id;
    this.reminderRequestModel.description = this.reminderForm.value.description;
    this.reminderRequestModel.dueDateTime = this.datePipe.transform(this.reminderForm.value.dueDateTime,"dd-MM-yy hh:mm a");
    this.reminderRequestModel.reminderType = this.reminderForm.value.reminderType;
    this.reminderRequestModel.sessionToken = this.token;
    this.reminderRequestModel.title = this.reminderForm.value.name;
    if(this.reminderRequestModel.reminderType==ReminderType.WORKITEM){
      this.reminderRequestModel.workItemId = this.reminderForm.value.workItemIds;
    }
    // if(this.reminderForm.value.reminderType==ReminderType.GENERIC){

    // }

    if(this.reminderForm.valid && this.submitted){
      this.reminderConfigurationService.setReminder(this.reminderRequestModel).subscribe(data=>{
        
        console.log(data.body);
        if(data.status==200 && data.body["responseType"]=="SUCCESS"){
          this.reminderConfigurationService.storeddata(true);
            this.callDialogPopUpMessage("Reminder created successfully","Create Reminder");
            
          this.dialogRef.close();
        }
      });

    }


  }

  onChangeReminderType(){
    
    if(this.reminderForm.value.reminderType=='GENERIC'){
      this.disableWorkitem = true;
    }
    else{
      this.disableWorkitem = false;
    }
  }

  callDialogPopUpMessage(  message,title){
    const dialogConfig= new MatDialogConfig();
    dialogConfig.disableClose=true;
    dialogConfig.autoFocus=true;
    dialogConfig.width="60%";
    dialogConfig.height="40%";
    dialogConfig.data = {
     popUpTitle:title,
     messageToBeDisplayed:message
  };
    this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);

  }

}
