import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";

import { WorkitemItemDetailInput } from '../models/workitemDetailinput.model';


@Injectable()
export class ChatWorkItemDetailService{

// public StoredData: BehaviorSubject<WorkitemItemDetailOutput> = new BehaviorSubject<WorkitemItemDetailOutput>(null);  
// 
// currentData = this.StoredData.asObservable();
//   storeddata(data: any) {
//     this.StoredData.next(data)
//   }
  private Url=environment.baseUrl+'getChatWorkItemDetails';
constructor(private http:HttpClient){
    
}
getChatWorkItem(workItemDetailInput:WorkitemItemDetailInput){


     return this.http.post(this.Url,workItemDetailInput , {observe: 'response'});
   
}  
}
