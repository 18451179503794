import { Component, OnInit } from '@angular/core';
import { AgentProfileService } from 'src/app/services/AgentProfile.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { UpdateAgentStatusService } from 'src/app/services/updateAgentStatus.service';
import { LoginService } from 'src/app/services/login.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LogoutService } from 'src/app/services/logout.service';
import { ConfigureUserService } from 'src/app/services/configureUser.service';
import { SkillConfigurationService } from 'src/app/services/SkillConfiguration.service';
import { AgentProfile } from 'src/app/models/AgentProfile.model';
import { AgentProfileComponent } from 'src/app/agent-profile/agent-profile.component';
import { ChangePasswordComponent } from 'src/app/change-password/change-password.component';
import { AddSkillComponent } from 'src/app/skill-cofiguration/add-skill/add-skill.component';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';

@Component({
  selector: 'app-skill-cofiguration',
  templateUrl: './skill-cofiguration.component.html',
  styleUrls: ['./skill-cofiguration.component.css']
})
export class SkillCofigurationComponent implements OnInit {

  token: string;
  id: number;
  agentProfile: AgentProfile;
  skillsList = [];
  constructor(private adminProfileService: AgentProfileService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private updateAgentStatusService: UpdateAgentStatusService,
    private loginService: LoginService,
    private logoutService: LogoutService,
    private configureUserService: ConfigureUserService,
    private skillConfigurationService: SkillConfigurationService
  ) {

  }

  ngOnInit() {
    window.scrollTo(0,0);
    this.agentProfile = new AgentProfile();
    this.loginService.currentData.subscribe(
      data => {
        if (data == null || data.sessionToken == null) {
          this.router.navigate(['']);
        }
        else {
          this.id = data.crmUserId;
          this.token = data.sessionToken;

          this.getSkillsList();
        }
      }
    );

  }
  getSkillsList(){
    this.skillConfigurationService.skillList(this.id, this.token).subscribe(data => {
      this.skillsList = data.body['skills'];
    });
  }

  onAdminWorkitemControllerClick() {
    this.router.navigate(['adminWorkitemController']);
  }
  onLiveMonitorClick() {
    this.router.navigate(["liveMonitor"]);
  }
  onDashboardClick() {
    this.router.navigate(["adminDashBoard"]);
  }
  onManualWorkitemClick() {
    this.router.navigate(["manualWorkitemController"]);
  }
  onQueueClick() {
    this.router.navigate(["queueConfiguration"]);
  }
  onEmailClick() {
    this.router.navigate(["emailConfiguration"]);
  }
  onUserClick() {
    this.router.navigate(["userConfiguration"]);
  }
  onSkillClick(){
    //this.router.navigate(["skillConfiguration"]);
  }

  showAgentDashboard() {
    this.router.navigate(["dashboard"]);
  }
  showWIDashboard() {
    this.router.navigate(["wiDashboard"]);
  }


  logoutUser() {
    
    this.loginService.endInterval();
    this.logoutService.logoutUser(this.id, this.token).subscribe(
      data => {

        
        console.log(data);
        if (data.status == 200) {
          this.router.navigate(['/']);
        }
      }
    )

  }
  openAdminProfile() {

    
    this.adminProfileService.getAgentDetails(this.id, this.token).subscribe(
      data => {
        

        console.log(data);
        if (data.status == 200 && data.body["responseType"] == "SUCCESS") {
          this.agentProfile.name = data.body['name'];
          this.agentProfile.emailId = data.body['emailAddress'];
          this.agentProfile.skills = data.body['skills'];
          this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.height = "218px";
          dialogConfig.width = "350px";
          dialogConfig.data = {
            agentProfile: this.agentProfile
          };
          this.dialog.open(AgentProfileComponent, dialogConfig);

        }
        else if (data.body['responseType'] == 'SESSION_TIMEOUT') {

          alert("Session timeout!Please Login Again");
          this.loginService.storeddata(null);
          this.router.navigate(['']);
        }
      }
    );


  }

  changePassword(){
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data={
        sessionToken:this.token,
        userId:this.id
      };
      // dialogConfig.maxWidth='400px';
    this.dialog.open(ChangePasswordComponent,dialogConfig);
  }

  addSkill(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: this.id,
      token:  this.token,
      isUpdateRequest:false
    };
    this.dialog.open(AddSkillComponent,dialogConfig).afterClosed().subscribe(()=>
    {
      this.getSkillsList();
    });

  }

  editSkill(skill){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: this.id,
      token:  this.token,
      isUpdateRequest:true,
      selectedSkill:skill
    };
    this.dialog.open(AddSkillComponent,dialogConfig).afterClosed().subscribe(()=>
    {
      this.getSkillsList();
    });
  }

  reminder(){
    
     
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data={
      crmUserId: this.id,
      sessionToken: this.token,
      detailedWorkitem:null,
      isServed:false
    };
    this.dialog.open(ReminderConfigurationComponent,dialogConfig);
   }

}
