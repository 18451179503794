import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { CustomerHistoryInput } from '../models/CustomerHistoryInput.model';
import { WorkItemReplayInput } from '../models/WorkItemReplayInput.model';
import { CustomerHistoryOutput } from '../models/CustomerHistoryOutput.model';
import { CustomerDetails } from '../models/CustomerDetails.model';

@Injectable()
export class CustomerHistoryService{
    public StoredData: BehaviorSubject<CustomerDetails> = new BehaviorSubject<CustomerDetails>( new CustomerDetails());  
currentData = this.StoredData.asObservable();

storeddata(data: any) {
  
    this.StoredData.next(data);
  }


    private Url=environment.baseUrl+'getCustomerEngagementHistory';
    private urlIvr=environment.baseUrl+'getEngagementHistoryByContactNumber';
    private urlMail=environment.baseUrl+'getEngagementHistoryByEmail';
    private urlWorkItem=environment.baseUrl+'getWorkItemReplies';
    

    constructor(private http:HttpClient){
    
    }

    getCustomerHistory(customerHistoryInput:CustomerHistoryInput){
        
        return this.http.post<CustomerDetails>(this.Url,

          customerHistoryInput ,
              {observe: 'response'});
    }

getCustomerIVRHistory(customerHistoryInput:CustomerHistoryInput){
return this.http.post<CustomerDetails>(this.urlIvr,customerHistoryInput,{observe:'response'});
}
getCustomerEmailHistory(customerHistoryInput:CustomerHistoryInput){
  return this.http.post<CustomerDetails>(this.urlMail,customerHistoryInput,{observe:'response'});
  }


getWorkItemHistory(workItemReplayInput:WorkItemReplayInput){
  return this.http.post<CustomerDetails>(this.urlWorkItem,workItemReplayInput, {observe:'response'});
  }
}
