import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label,SingleDataSet } from 'ng2-charts';

@Component({
  selector: 'queueTypeStatusChart',
  templateUrl: './queueTypeStatusChart.component.html',
})
export class QueueTypeStatusChartComponent implements OnInit {

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = ['ZP ENGLISH MAIL', 'ZP JAPENESE MAIL', 'ZP KOREAN MAIL','	ZP MANDARIN MAIL','	ZP THAI MAIL','ZP_IVR','ZP_CHAT'];
  public pieChartData: SingleDataSet = [10,40,30,90,20,60,44];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];

  constructor() { }

  ngOnInit() {
  }}