import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { CrmUser } from '../../models/crmuser.model';
import { Router} from '@angular/router';
import { LoginService } from '../../services/login.service';
import { QueueReportingDashboardService } from 'src/app/services/QueueReportingDashboard.service';
@Component({
  selector: 'app-queuewise-wistatus-chart',
  templateUrl: './queuewise-wistatus-chart.component.html',
  styleUrls: ['./queuewise-wistatus-chart.component.css']
})
export class QueuewiseWIStatusChartComponent implements OnInit {

  crmUser:CrmUser;
  id:number;
  token:string;
  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Assigned' },
    {data:[],label: 'Unassigned'},
    {data:[],label: 'Completed'}
  ];
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  agentNameList:Array<any>;
  queueWIStatusList:Array<any>=new Array<any>();
  public dataFromService:Array<any> = new Array<any>();
  

  constructor( 
    private router:Router,
    private loginService:LoginService,
    private queueReportingDashboardService:QueueReportingDashboardService) {
      
      this.queueReportingDashboardService.currentData.subscribe((data)=>{
      if(data!=null){
        
        this.dataFromService = data['queueList'];
        for(let i=0;i<this.dataFromService.length;i++){
          this.queueWIStatusList[i] = {"queueName":this.dataFromService[i].queueName,"completed":  this.dataFromService[i].completed,"unassigned":this.dataFromService[i].unassigned,"assigned":this.dataFromService[i].assigned};
        }

      }
    });

    }

  ngOnInit() {
    this.loginService.currentData.subscribe((data) => {
      this.crmUser = data;
     this.token=this.crmUser.sessionToken;
     this.id=this.crmUser.crmUserId;
    
     if(this.crmUser==null || this.crmUser.sessionToken == null){
         this.router.navigate(['']);
       }
  });
  console.log("token>>"+this.token);
    //Subscribe service to get data
  //   this.queueWIStatusList=[
  //     {"queue": "ZP ENGLISH MAIL","completed": 5 ,"paused":4,"assigned":9},
  //     {"queue": "ZP JAPENESE MAIL","completed": 10,"assigned":20,"paused":10},
  //     {"queue": "ZP KOREAN MAIL","completed": 30,"assigned":45,"paused":15 },
  //     {"queue": "ZP MANDARIN MAIL","completed": 40,"assigned":56,"paused":16 },
  //     {"queue": "ZP THAI MAIL","completed": 7 ,"assigned":10,"paused":3},
  //     {"queue": "ZP_IVR","completed": 7 ,"assigned":20,"paused":3},
  //     {"queue": "ZP_MANUAL","completed": 7 ,"assigned":10,"paused":3},
  //     {"queue": "ZP_CHAT","completed": 7 ,"assigned":10,"paused":3}

     
     
  // ]
  //  this.barChartData: ChartDataSets[] = [
  //   { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
  //   { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  // ];

this.queueWIStatusList.forEach(element => {
  
  (this.barChartData[0].data as number[]).push(element.assigned);
  (this.barChartData[1].data as number[]).push(element.unassigned);
  (this.barChartData[2].data as number[]).push(element.completed);
this.barChartLabels.push(element.queueName);
});
  }

}
