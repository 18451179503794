import { IVRWorkitemAddedComments } from './IVRWorkitemAddedComments.model';

export class CustomerHistoryOutput{
engagementContent:string;
engagementDate:string;
engagementId:number;
engagementType:string;
workItemComments:Array<IVRWorkitemAddedComments>;
category:string;
subCategory:string;
}
