export class CustomerHistoryInput{
   
    public crmUserId:number;
   
    public sessionToken:string;

    public customerId:number;

    public contactNumber:number;

    public emailAddress:string;

    public pageNumber:number;
    
    public pageSize:number;
 }