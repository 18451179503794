export class EmailData{
    public from:string;
    public cc:string;
    public bcc:string;
    public receiver:string;
    public crmUserId:number;
    public mailContent:string;
    public sessionToken:string;
    public subject:string; 
    public emailItemId:number;
    public newMailIdentifier:number;

 }