import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepicker, MatDialogConfig, MatDialog } from '@angular/material';
import { ChangePasswordInput } from '../models/changePassword.model';
import { ChangePasswordService } from '../services/changePassword.service';
import { LoginService } from '../services/login.service';
import { WorkitemSavedMessageComponent } from '../messagePopUp/workitem-saved-message/workitem-saved-message.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
 
})
export class ChangePasswordComponent implements OnInit {
  token:string;
  id:number;
  submitted = false;
  matchConfirmPassword=false;
  changePasswordInput:ChangePasswordInput;
  passwordChanged=false;
  changePasswordForm= new FormGroup({
   
    password: new FormControl(''),
    confirmpassword: new FormControl(''),
                                });
 



  constructor( private dialogRef: MatDialogRef<ChangePasswordComponent>,
      private router:Router,
      @Inject(MAT_DIALOG_DATA) data,
      private formBuilder: FormBuilder,
      private dialog:MatDialog,
      private changePaswordService:ChangePasswordService,
      private loginService:LoginService){
        
        this.token=data.sessionToken;
        this.id=data.userId;
     }

  ngOnInit() {
    this.changePasswordInput=new ChangePasswordInput;
      this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(10)]],
        confirmpassword: ['', [Validators.required]]
     });
    
}
    // convenience getter for easy access to form fields
    get f() { return this.changePasswordForm.controls; }
 
      
  close()
  {
    this.dialogRef.close();
  }

  onSubmit(){
    
   this.submitted=true;
   if(this.changePasswordForm.valid){
       if(this.changePasswordForm.value.password!=this.changePasswordForm.value.confirmpassword){
        this.matchConfirmPassword=true;
        return;
       }else{
         
        this.matchConfirmPassword=false;
        this.changePasswordInput.crmUserId=this.id;
        this.changePasswordInput.sessionToken=this.token;
        this.changePasswordInput.password=this.changePasswordForm.value.password;
        this.changePasswordInput.confirmPassword=this.changePasswordForm.value.confirmpassword;
        this.changePaswordService.changePassword(this.changePasswordInput)
        .subscribe(
        data=>{
     
    if( data.status==200 &&  data.body["responseType"]=="SUCCESS"){
       this.passwordChanged=true;

       const dialogConfig= new MatDialogConfig();
              dialogConfig.disableClose=true;
              dialogConfig.autoFocus=true;
              dialogConfig.width="60%";
              dialogConfig.height="40%";
              dialogConfig.data = {
                popUpTitle:"Change Password",
                messageToBeDisplayed:"Password Changed Successfully" 
              };
              this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);
              this.dialogRef.close();
    }
    else if(data.body['responseType']== 'SESSION_TIMEOUT'){
              
      alert("Session timeout!Please Login Again");
      this.loginService.storeddata(null);
      this.router.navigate(['']);
                                                  } 
              
                
                
               
                
               
       })
   }
  }
  }
}

