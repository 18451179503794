import { Injectable } from "@angular/core";
import { environment } from "src/app/environment";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { AgentReportingDashboardVO } from "src/app/models/AgentReportingDashboardVO.model";

@Injectable()
export class AgentReportingDashboardService{
    private Url = environment.baseUrl+'getAgentReportingDashboard';

    public StoredData: BehaviorSubject<AgentReportingDashboardVO> = new BehaviorSubject<AgentReportingDashboardVO>(null);  
    currentData = this.StoredData.asObservable();
      storeddata(data: any) {
          
        this.StoredData.next(data);
      }
    constructor(private http:HttpClient){
        
    }

    getAgentReportingDashboard(crmUserId,sessionToken){
      return   this.http.post(this.Url,{"crmUserId":crmUserId,"sessionToken":sessionToken},{observe: 'response'});
    }
}