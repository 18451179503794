import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { AgentReportingDashboardService } from 'src/app/services/AgentReportingDashboard.service';

@Component({
  selector: 'agentAvailabilityStatusChart',
  templateUrl: './agentAvailabilityStatusChart.component.html',
})
export class AgentAvailabilityStatusChartComponent implements OnInit {

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = ['Offline', 'On Break', 'Online'];
  public pieChartData: SingleDataSet;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];

  constructor(
    private agentReportingDashboardService:AgentReportingDashboardService
  ) { 
    this.agentReportingDashboardService.currentData.subscribe((data)=>{
      if(data!=null){
        
        console.log(data);
        this.pieChartData = [data['agentsOffline'],data['agentsOnBreak'],data['agentsOnline']];
      }
    });
  }

  ngOnInit() {
    console.log("in the AgentAvailabilityStatusChartComponent");
  }

}
