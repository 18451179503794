import { Component } from '@angular/core';
import * as XLSX from 'xlsx';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'uploadExcel',
  templateUrl: './uploadExcel.component.html',
})
export class UploadExcelComponent {

  dataToBeSent:any;

  constructor(
    public dialogRef: MatDialogRef<UploadExcelComponent>
  ){

  }

  ngOnInit(){

  }
  addWorkitemsFromExcel(event){
    
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onload = (event) => {
      
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      },{});

      
      this.dataToBeSent = jsonData;

      //document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
    }

    reader.readAsBinaryString(file);
  }

  uploadFile(){
    this.dialogRef.close(this.dataToBeSent);
  }
  close(){
    this.dialogRef.close();
  }
}
