export class ChatWorkitemDetails{
    workItemId:number;
    workItemStatus:string;
    assignedAgent:Array<{
        name:string;
        location:string;
        assignmentThreshold:number;
        mappedSkills:Array<{
            id:number;
            name:string;
            description:string;
        }>;
        emaiId:string;
    }>;
    customerVO:Array<any>;
    workItemCategory:Array<{
        id:number;
        name:string;
        description:string;
    }>;
    workItemSubCategory:Array<{
        id:number;
        name:string;
        description:string;
    }>;
    workItemType:string;
    formattedCreateTms:string;
    formattedChatDateTime:string;
    chatDateTime:string;
    chatItemId:number;
    content:string;
}