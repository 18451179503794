import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepicker } from '@angular/material';
import { ReportsService } from 'src/app/services/reports.service';
import { DatePipe } from '@angular/common';
import { saveAs } from "file-saver";
import { ConfigureEmailService } from 'src/app/services/configureEmail.service';
import { LoginService } from 'src/app/services/login.service';
import { CompleteSystemReport } from 'src/app/models/CompleteSystemReport.model';
import { ReportFrequencyEnum } from 'src/app/ReportFrequencyEnum';
import { ReportTypeFilterEnum } from 'src/app/ReportTypeFilterEnum';
@Component({
    selector: 'app-report-by-mailbox',
    templateUrl: './report-by-mailbox.component.html',
    providers:[DatePipe]
  })
  export class ReportByMailboxComponent implements OnInit{

    token:string;
    id:number;
    startDate:string;
    endDate:string;
    minDate:Date;
    isValidDate:any;
    parts:any;
    submitted:boolean=false;
    maxDate=new Date();
    error:any={isError:false,errorMessage:''};
    ReportByMailboxForm:FormGroup;
    showStartDate:boolean;
    showMonth:boolean;
    pickerDate:string;
    mailBoxesList:Array<any>;
    completeSystemReport:CompleteSystemReport;
    @ViewChild('picker1',{read:undefined,static:false}) datePicker: MatDatepicker<Date>;
    constructor(
      private loginService: LoginService,
      private configureEmailService:ConfigureEmailService,
      private reportService:ReportsService,
       private dialogRef: MatDialogRef<ReportByMailboxComponent>,
        private router:Router,
        @Inject(MAT_DIALOG_DATA) data,
        private formBuilder: FormBuilder,
        private datePipe: DatePipe) {
        this.token=data.sessionToken;
        this.id=data.userId;
        this.mailBoxesList = data.mailBoxesList;
        this.datePipe.transform(this.maxDate, 'mm/dd/yyyy');
  
    }

    ngOnInit() {
        
        this.showMonth = false;
        this.showStartDate = true;
        this.completeSystemReport = new CompleteSystemReport();


        this.ReportByMailboxForm = this.formBuilder.group({ 
          frequency:new FormControl('HOURLY', [Validators.required]), 

          selectedMailbox: new FormControl('',[Validators.required]),
          
          startDate: new FormControl('', [Validators.required]),  
          
          month: new FormControl('', [Validators.required])   
        }); 
    }

    get f() {return this.ReportByMailboxForm.controls; }
          
    close()
    {
      this.dialogRef.close();
    }
    

    clickRadioFilter(option){

      if((option=="HOURLY") || (option=="WEEKLY")){
        this.showMonth = false;
        this.showStartDate = true;
      }
      else {
        this.showStartDate = false;
        this.showMonth = true;
      }
    }

    closeDatePicker(event) {
      
      
      this.pickerDate = this.datePipe.transform(event,"MM-dd-yyyy");
      this.ReportByMailboxForm.get('month').setValue(this.datePipe.transform(event,"dd-MM-yy"));
      console.log(this.pickerDate);
      this.ReportByMailboxForm.get("month").setValue(new Date(this.pickerDate));
      this.datePicker.close();
      this.pickerDate = this.datePipe.transform(event,"dd-MM-yy");
}

    downloadReportByMailbox(){
      
      if(this.ReportByMailboxForm.value.frequency==ReportFrequencyEnum.MONTHLY){
        this.completeSystemReport.startDate =this.pickerDate;
        this.ReportByMailboxForm.get("startDate").setValue(this.ReportByMailboxForm.value.month);
      }
      else{
        this.completeSystemReport.startDate = this.datePipe.transform(this.ReportByMailboxForm.value.startDate,"dd-MM-yy");
        this.ReportByMailboxForm.get("month").setValue(this.ReportByMailboxForm.value.startDate);
      }
      this.submitted = true;
      this.completeSystemReport.accessedBy = 1;
      this.completeSystemReport.crmUserId = this.id;
      this.completeSystemReport.frequency = this.ReportByMailboxForm.value.frequency;;
      this.completeSystemReport.reportFilterType = ReportTypeFilterEnum.MAILBOX;
      this.completeSystemReport.reportId = 1;
      this.completeSystemReport.reportType = "EXCEL";
      this.completeSystemReport.selectedFilterId = this.ReportByMailboxForm.value.selectedMailbox;
      this.completeSystemReport.sessionToken = this.token;
      this.completeSystemReport.endDate = "";

      if(this.ReportByMailboxForm.valid) {
        this.reportService.getCompleteSystemReport(this.completeSystemReport).subscribe(
          data => {
            console.log(data);
            saveAs(data,"ReportByMailbox");
          },
          err => {
            alert("Problem while downloading the file.");
            console.error(err);
          });
          this.dialogRef.close();
          }
    }
  }