import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from "../environment";
import {HttpHeaders} from '@angular/common/http';
import { CompleteSystemReport } from '../models/CompleteSystemReport.model';
import { WIByPropertiesRequest } from 'src/app/models/WIByPropertiesReportRequest.model';


@Injectable()
export class ReportsService{
       private Url=environment.baseUrl+'downloadReport'
       private agentWIUrl=environment.baseUrl+'getAgentWorkItemDetailReport'
       private singleWIUrl=environment.baseUrl+'getSingleWorkItemDetailReport'
       private wiCountByCategoryUrl=environment.baseUrl+'getWorkItemCountByCategoryReport'
       private wiCountByTypeUrl=environment.baseUrl+'getWorkItemCountByTypeReport'
       private wiByPropertiesUrl=environment.baseUrl+'WorkItemByPropertyReport';
       private wiSLAUrl = environment.baseUrl+'getWorkItemSLAReport';
       private completeSystemReportUrl = environment.baseUrl+'getCompleteSystemReport';
       private wiDetailReportUrl = environment.baseUrl+'getWorkItemDetailReport';
constructor(private http:HttpClient){
    
}
downloadReports(accessedBy,crmUserId,reportId,reportType,sessionToken)
{
  return this.http.post(this.Url,{'accessedBy':accessedBy,'crmUserId':crmUserId,'reportId':reportId,'sessionToken':sessionToken,'reportType':reportType}, {
    responseType: "blob",
    headers: new HttpHeaders().append("Content-Type", "application/json")
  });
}
agentWorkItemDetailReport(accessedBy,crmUserId,reportId,reportType,sessionToken,startDate,endDate)
{
  
  return this.http.post(this.agentWIUrl,{'accessedBy':accessedBy,'crmUserId':crmUserId,'reportId':reportId,'sessionToken':sessionToken,'reportType':reportType,'startDate':startDate,'endDate':endDate}, {
    responseType: "blob",
    headers: new HttpHeaders().append("Content-Type", "application/json")
  });
}
singleWIReport(accessedBy,crmUserId,reportId,reportType,sessionToken,workItemId)
{
  return this.http.post(this.singleWIUrl,{'accessedBy':accessedBy,'crmUserId':crmUserId,'reportId':reportId,'sessionToken':sessionToken,'reportType':reportType,'workItemId':workItemId}, {
    responseType: "blob",
    headers: new HttpHeaders().append("Content-Type", "application/json")
  });
}
wiCountByCategory(accessedBy,crmUserId,date,reportId,reportType,sessionToken){
  
  return this.http.post(this.wiCountByCategoryUrl,{'accessedBy':1,'crmUserId':crmUserId,'reportId':1,'sessionToken':sessionToken,'reportType':reportType,'date':date}, {
    responseType: "blob",
    headers: new HttpHeaders().append("Content-Type", "application/json")
  });
}
wiCountByType(accessedBy,crmUserId,date,reportId,reportType,sessionToken,workItemType){
  
  return this.http.post(this.wiCountByTypeUrl,{'accessedBy':1,'crmUserId':crmUserId,'reportId':1,'sessionToken':sessionToken,'reportType':'EXCEL','date':date,'workItemType':workItemType}, {
    responseType: "blob",
    headers: new HttpHeaders().append("Content-Type", "application/json")
  });
}

wiByProperties( wIByPropertiesRequest:WIByPropertiesRequest){
  
  return this.http.post(this.wiByPropertiesUrl,wIByPropertiesRequest, {
    responseType: "blob",
    headers: new HttpHeaders().append("Content-Type", "application/json")
  });
}

wiSLAReport(accessedBy,crmUserId,reportId,reportType,sessionToken,startDate,endDate)
{
  
  return this.http.post(this.wiSLAUrl,{'accessedBy':accessedBy,'crmUserId':crmUserId,'reportId':reportId,'sessionToken':sessionToken,'reportType':reportType,'startDate':startDate,'endDate':endDate}, {
    responseType: "blob",
    headers: new HttpHeaders().append("Content-Type", "application/json")
  });
}


wiDetailReport(accessedBy,crmUserId,reportId,reportType,sessionToken,startDate,endDate){
  
  return this.http.post(this.wiDetailReportUrl,{'accessedBy':accessedBy,'crmUserId':crmUserId,'reportId':reportId,'sessionToken':sessionToken,'reportType':reportType,'startDate':startDate,'endDate':endDate},
    {responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
}



getCompleteSystemReport(completeSystemReport:CompleteSystemReport)
{
  
  return this.http.post(this.completeSystemReportUrl,completeSystemReport,{
    responseType: "blob",
    headers: new HttpHeaders().append("Content-Type", "application/json")
  }
  );
 

}


}
