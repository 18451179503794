import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ReminderConfigurationService } from 'src/app/services/ReminderConfiguration.service';
import { EditReminderModel } from 'src/app/models/EditReminder.model';
import { ReminderStatus } from 'src/app/Enums/ReminderStatus.enum';
import { ReminderVO } from 'src/app/models/ReminderVO.model';

@Component({
  selector: 'app-reminder-popup',
  templateUrl: './reminder-popup.component.html',
  styleUrls: ['./reminder-popup.component.css']
})
export class ReminderPopupComponent implements OnInit {
  crmUserId:number;
  sessionToken:string;
  remindersList:any[] = [];
  icon:Array<Number>=new Array<Number>();
  icon2:Array<boolean> = new Array<boolean>();
  substringArrayForDate:Array<any>;
  substringArrayForTime:Array<any>;
  editReminderModel:EditReminderModel;
  reminderVO:Array<ReminderVO> = new Array<ReminderVO>();
  currentPage=0;
  constructor(
    private dialogRef: MatDialogRef<ReminderPopupComponent>,
    private reminderConfigurationService:ReminderConfigurationService,
    private dialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    
      this.reminderVO = data['remindersList'];
      this.crmUserId = data['crmUserId'];
      this.sessionToken = data['sessionToken'];
      this.substringArrayForDate = new Array(this.remindersList.length);
      for(let i=0;i<this.reminderVO.length;i++){
        this.reminderVO[i].dueDate = this.reminderVO[i].dueDateTime.substring(0,10);
        this.reminderVO[i].dueTime = this.reminderVO[i].dueDateTime.substring(11,this.reminderVO[i].dueDateTime.length);
      }

      this.icon2.push(false); 

   }

  ngOnInit() {
    
    this.editReminderModel = new EditReminderModel();
  }
  pushStatus(){
    this.icon2.push(false);
  }
  
    toggleIcon(i){
      
     this.icon2[(this.currentPage*5)+i] = !this.icon2[(this.currentPage*5)+i];
    }

    onPageChange(currentPage){
    
    this.currentPage = currentPage-1;
    console.log(currentPage);
    for(let i=0;i<5;i++){
      this.icon2[(this.currentPage*5)+i] = false;
    }
    }

  close(){
    this.dialogRef.close();
  }

  markReminderComplete(reminder,option){
    
    this.editReminderModel.crmUserId = this.crmUserId;
    this.editReminderModel.description = reminder.decription;
    this.editReminderModel.dueDateTime = reminder.dueDateTime;
    this.editReminderModel.id = reminder.id;
    this.editReminderModel.markCompleted = true;
    if(option=="COMPLETE")
    this.editReminderModel.reminderStatus = ReminderStatus.COMPLETED;
    else
    this.editReminderModel.reminderStatus = ReminderStatus.CANCELLED;
    this.editReminderModel.sessionToken = this.sessionToken;
    this.editReminderModel.title = reminder.title;
    this.reminderConfigurationService.editReminder(this.editReminderModel).subscribe(data=>{
      console.log(data);
      
      if(data.status==200 && data.body["responseType"]=="SUCCESS"){
        this.reminderConfigurationService.storeddata(true);
        this.reminderVO.forEach((element,index) => {
          
          if(element.id==reminder.id){
            this.reminderVO.splice(index,1);
            this.icon2[index] = !this.icon2[index];
          }
        });
      }
    });
  }

}
