import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { environment } from "../environment";

import { WorkItemStatusChangeInput } from '../models/workitemStatusChangeInput.model';



@Injectable()
export class WorkItemStatusChangeService{

  private Url=environment.baseUrl+'updateWorkItemStatus';
constructor(private http:HttpClient){
    
}
getWorkItemStatusChange(workItemStatusChangeInput:WorkItemStatusChangeInput){

     return this.http.post(this.Url,workItemStatusChangeInput, {observe: 'response'})
   
}  

}
