import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/app/environment";
import { WorkitemItemDetailInput } from "src/app/models/workitemDetailinput.model";

@Injectable()
export class WhatsappWorkitemService{

    private urlForWhatsappWorkitemDetails = environment.baseUrl+'getWhatsAppWorkItemDetails';
    private urlForConversationHistoryByNumber = environment.baseUrl+'getConversationHistoryByNumber';
    private urlForSendingMessage = environment.baseUrl+'sendConversationReply';
    constructor(private http:HttpClient){
        
    }

    getWhatsappWorkitemDetails(workitemDetailsInput:WorkitemItemDetailInput){
        
        return this.http.post(this.urlForWhatsappWorkitemDetails,workitemDetailsInput,{observe:'response'});
    }

    getConversationHistoryByNumber(crmUserId,messagingNumber,sessionToken){
        return this.http.post(this.urlForConversationHistoryByNumber,{'crmUserId':crmUserId,'messagingNumber':messagingNumber,'sessionToken':sessionToken},{observe:'response'});
    }

    sendWhatsappMessage(crmUserId,conversationWorkItemId,sessionToken,message){
        
        return this.http.post(this.urlForSendingMessage,{'crmUserId':crmUserId,'conversationWorkItemId':conversationWorkItemId,'message':message,'sessionToken':sessionToken},{observe:'response'});
    }
}