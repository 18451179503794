import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { AgentReportingDashboardService } from 'src/app/services/AgentReportingDashboard.service';

@Component({
  selector: 'app-agent-skill-distribution-chart',
  templateUrl: './agent-skill-distribution-chart.component.html',
  styleUrls: ['./agent-skill-distribution-chart.component.css']
})
export class AgentSkillDistributionChartComponent implements OnInit {

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels = new Array<Label>();
  public pieChartData= new Array<SingleDataSet>();
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public skillList:Array<any> = new Array<any>();
  constructor(
    private agentReportingDashboardService:AgentReportingDashboardService
  ) {
    this.agentReportingDashboardService.currentData.subscribe((data)=>{
      
      if(data!=null){
        
        this.skillList = data['skills'];
        for(let i=0;i<this.skillList.length;i++){
          this.pieChartLabels[i] = this.skillList[i].skillName;
          this.pieChartData[i] = this.skillList[i].totalAgents;
        }
      }
    });
   }

  ngOnInit() {
    console.log("in the AgentSkillDistributionChartComponent");
  }

}
