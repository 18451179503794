import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/app/environment";
import { ReminderRequestModel } from "src/app/models/ReminderRequest.model";
import { GetReminderRequest } from "src/app/models/GetReminderRequest.model";
import { EditReminderModel } from "src/app/models/EditReminder.model";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ReminderConfigurationService{

    private url = environment.baseUrl+"addReminder";
    private getReminderUrl = environment.baseUrl+"getActiveReminder";
    private editReminderUrl = environment.baseUrl+"editReminder";
    private getAllRemindersUrl = environment.baseUrl+"getReminder";
    constructor(private http:HttpClient){
        
    }


    public StoredData = new BehaviorSubject<boolean>(false);  
    currentData = this.StoredData.asObservable();
      storeddata(data: any) {
        this.StoredData.next(data)
      }

    setReminder(reminderRequestModel:ReminderRequestModel){
        
        return this.http.post(this.url,reminderRequestModel,{observe:'response'});
    }


    getReminder(getReminderRequest:GetReminderRequest){
        
        return this.http.post(this.getReminderUrl,getReminderRequest,{observe:"response"});
    }

    editReminder(editReminder:EditReminderModel){
        
        return this.http.post(this.editReminderUrl,editReminder,{observe:"response"});
    }

    getAllReminders(getReminderRequest:GetReminderRequest){
        return this.http.post(this.getAllRemindersUrl,getReminderRequest,{observe:'response'});
    }
}   