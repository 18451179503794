import { Injectable } from '@angular/core';
import { environment } from '../environment';
import { HttpClient } from '@angular/common/http';
import { SaveAndReassignRequest } from '../models/SaveAndReassignRequest.model';

@Injectable()
export class SaveAndReassignWorkitemService{

  private url = environment.baseUrl+'saveAndReassignWorkItemByAdmin';
  constructor(private http:HttpClient){}

  saveAndReassign(saveAndReassignRequest:SaveAndReassignRequest){
    
    return this.http.post(this.url, saveAndReassignRequest,{observe:'response'});
  }
}
