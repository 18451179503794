import { Component, OnInit , ElementRef, ViewChild,Inject} from '@angular/core';
import { Router,NavigationExtras,ActivatedRoute } from '@angular/router';
import {MatDialog,MAT_DIALOG_DATA,MatDialogConfig} from '@angular/material';
import {FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
import {UpdateAgentStatusService} from '../services/updateAgentStatus.service';
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
import {LogoutService} from '../services/logout.service';
import {AddEmailComponent} from '../email-configuration/add-email.component';
import { UpdateEmailComponent } from './update-email.component';
import { ConfigureEmailService } from '../services/configureEmail.service';
import {ConfigureEmail} from '../models/configureEmail.model';
import {LoginService} from '../services/login.service';
import {AgentProfileService} from '../services/AgentProfile.service';
import {AgentProfile} from '../models/AgentProfile.model';
import {AgentProfileComponent} from '../agent-profile/agent-profile.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
@Component({
    selector: 'app-emailConfiguration',
    templateUrl: './email-configuration.component.html',
    styleUrls:['./email-configuration.component.css']
  })
export class EmailConfigurationComponent implements OnInit{
  token:string;
  id:number;
  emailConfigurationForm:FormGroup;
  submitted:boolean=false;
  showRow:boolean=false;
  mailBoxesList=[];
  agentProfile:AgentProfile;
  updateAgentStatusModel:UpdateAgentStatusModel;
    constructor(  private adminProfileService:AgentProfileService, private loginService:LoginService,private dialog:MatDialog,private formBuilder: FormBuilder,private route:ActivatedRoute,private router:Router,
        private updateAgentStatusService:UpdateAgentStatusService,private logoutService:LogoutService,private configureEmailService:ConfigureEmailService){
      }
    ngOnInit() {
      this.agentProfile = new AgentProfile();

      this.loginService.currentData.subscribe((data) => {
        if(data==null || data.sessionToken == null){
          this.router.navigate(['']);
        }
        else{
         this.id=data.crmUserId;
         this.token=data.sessionToken;
        }
    });
    this.configureEmailService.getMailboxList(this.id,this.token).subscribe(
      data=>{
        if(data.status==200)
        {
          this.mailBoxesList=data.body['mailboxes'];
        }else if(data.body['responseType']== 'SESSION_TIMEOUT'){
          alert("Session timeout!Please Login Again");
          this.loginService.storeddata(null);
          this.router.navigate(['']);
        }
      }
    )
    }
    getMailBoxlist(){
      this.configureEmailService.getMailboxList(this.id,this.token).subscribe(
        data=>{
          if(data.status==200)
          {
            this.mailBoxesList=data.body['mailboxes'];
          }
        }
      )
    }

    onAdminWorkitemControllerClick(){
      this.router.navigate(['adminWorkitemController']);
    }
    onDashboardClick(){
      this.router.navigate(["adminDashBoard"]);
      }
      onSkillClick(){
        this.router.navigate(["skillConfiguration"]);
      }

      onLiveMonitorClick(){
        this.router.navigate(["liveMonitor"]);
      }

      onManualWorkitemClick(){
        this.router.navigate(["manualWorkitemController"]);
      }

      showAgentDashboard()
      {
        this.router.navigate(["dashboard"]);
      }
      showWIDashboard(){
        this.router.navigate(["wiDashboard"]);
      }

    get f() {return this.emailConfigurationForm.controls; }



      logoutUser(){
        this.loginService.endInterval();
        this.logoutService.logoutUser(this.id, this.token).subscribe(
          data=>{
            if(data.status==200 ){
              this.router.navigate(['/']);
            }
          }
        )

      }

      addEmailConfig()
      {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
         // dialogConfig.width="50%";
        //   dialogConfig.position = {
        //     'top': '130',
        //     left: '130'
        // };
        dialogConfig.data = {
          id: this.id,
          token:  this.token,
      };
          this.dialog.open(AddEmailComponent,dialogConfig).afterClosed().subscribe(()=>
          {
         this.getMailBoxlist();
          });


      }

      updateEmailInfo(configureEmail){
      const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
            dialogConfig.data = {
              id: this.id,
              token:  this.token,
              emailDataToBeUpdated:configureEmail
          };
              this.dialog.open(UpdateEmailComponent,dialogConfig).afterClosed().subscribe(()=>{
                this.getMailBoxlist();
              });
      }

      onQueueClick(){
      this.router.navigate(["queueConfiguration"]);
      }
      onEmailClick(){
      this.router.navigate(["emailConfiguration"]);
      }
      onUserClick(){
      this.router.navigate(["userConfiguration"]);
      }
      openAdminProfile(){

          this.adminProfileService.getAgentDetails(this.id ,this.token).subscribe(
            data=>{
              if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
                this.agentProfile.name = data.body['name'];
                this.agentProfile.emailId = data.body['emailAddress'];
                this.agentProfile.skills = data.body['skills'];
                this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;
                dialogConfig.height = "218px";
                dialogConfig.width = "350px";
                dialogConfig.data = {
                agentProfile: this.agentProfile
                };
                 this.dialog.open(AgentProfileComponent,dialogConfig);

              }
              else if(data.body['responseType']== 'SESSION_TIMEOUT'){

                alert("Session timeout!Please Login Again");
                this.loginService.storeddata(null);
                this.router.navigate(['']);
                                                            }
            }
          );


        }


        changePassword(){
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.data={
              sessionToken:this.token,
              userId:this.id
            };
            // dialogConfig.maxWidth='400px';
          this.dialog.open(ChangePasswordComponent,dialogConfig);
        }
        reminder(){
          
           
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            crmUserId: this.id,
            sessionToken: this.token,
            detailedWorkitem:null,
            isServed:false
          };
          this.dialog.open(ReminderConfigurationComponent,dialogConfig);
         }
}
