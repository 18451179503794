export class WhatsappMessageFormatModel{
    destinationNumber:string;
    formattedMessageDateTime:string;
    locationLatitude:string;
    locationLongitude:string;
    messagingNumber:string;
    msgBody:string;
    msgId:string;
    msgReceivedDate:string;
    updateTms:string;
    updatedBy:string;
    whatsAppConversationWorkItemId:string;
    whatsAppWorkItemDetailsId:string;
    source:string;
}