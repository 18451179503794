import {AdditionalInfoVOForReassign} from './AdditionalInfoVOForReassign.model'; 
export class SaveAndReassignRequest{
  public additionalInfoVO:AdditionalInfoVOForReassign;
  public callNotes:String;
  public categoryId:number;
  public crmUserId:number;
  public newAssignedUser:number;
  public propertyIds:Array<number>;
  public queueId:number;
  public sessionToken:string;
  public subCategoryId:number;
  public workItemId:number;
}
