import { Component, OnInit , ElementRef, ViewChild,Inject} from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import { ReportsService } from '../services/reports.service';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
import { saveAs } from "file-saver";
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-singleWIReport',
    templateUrl: './singleWIReport.component.html',
    providers:[DatePipe]
  })
export class SingleWIReportComponent implements OnInit{
  token:string;
  id:number;
  singleWIReportForm:FormGroup; 
  submitted:boolean=false;
  workItemId:number;
    constructor(private reportService:ReportsService, private dialogRef: MatDialogRef<SingleWIReportComponent>,
      private router:Router,@Inject(MAT_DIALOG_DATA) data,private formBuilder: FormBuilder,private datePipe: DatePipe){
        this.token=data.sessionToken;
        this.id=data.userId;
        this.singleWIReportForm = this.formBuilder.group({  
          workItemId: new FormControl('', [  
            Validators.required ,Validators.pattern('^[0-9]*$')
          ])
         
        });  
      }
    ngOnInit() {
   
    }
    get f() {return this.singleWIReportForm.controls; }
    close()
  {
    this.dialogRef.close();
  }
  checkErr(startDate,endDate)
  {
    
    if(startDate < endDate){
      alert('End Date should be greate than start date');
      return false;
    }
  }
  downloadSingleWIReport(){
    
    this.submitted=true;
    if(this.singleWIReportForm.valid)
    {
this.workItemId=this.singleWIReportForm.value.workItemId;
this.reportService.singleWIReport(1,this.id,1,"EXCEL",this.token,this.workItemId).subscribe(
  data => {
    saveAs(data,"singleWorkItemDetailReport");
  },
  err => {
    alert("Problem while downloading the file.");
    console.error(err);
  });
  this.dialogRef.close();
  }}
}