export class Agent{
   public agentId:number;
   public company:string;
   public companyId:number;
   public emailId:string;
   public location:string;
   public name:string;
   public roles:string; 
   public sessionToken:string;

   
}