import { Component, OnInit, ElementRef, ViewChild, Inject, ViewEncapsulation } from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import { ReportsService } from '../services/reports.service';
import {MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import {FormGroup,FormBuilder,FormControl,Validators} from '@angular/forms';
import { saveAs } from "file-saver";
import { DatePipe } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PropertiesList } from 'src/app/services/PropertiesList.service';
import { WorkitemSubPropertiesModel } from 'src/app/models/WorktiemSubProperties.model';
import { WIByPropertiesRequest } from '../models/WIByPropertiesReportRequest.model';

@Component({
    selector: 'app-wiCountByTypeReport',
    templateUrl: './wiCountByType.component.html',
    styleUrls: ['./wiCountByType.component.css'],
    providers:[DatePipe],

  })
export class WICountByTypeReportComponent implements OnInit{
  token:string;
  id:number;
  wiCountByTypeForm:FormGroup; 
  endDate:string;
  startDate:string;
  minDate:Date;
  parts:any;
  isValidDate:any;
  selectedType:string="";
  submitted:boolean=false;
  maxDate=new Date();
  workitemProperties = [];
  dropdownSettings:IDropdownSettings = {};
  selectedProperty:number=0;
  workitemSubPropertiesArray:Array<WorkitemSubPropertiesModel> = [];
  workitemSubProperties = [];
  selectedSubProperties = [];
  selectedItems:Array<any>=[];
  wIByPropertiesRequest:WIByPropertiesRequest = new WIByPropertiesRequest();
//   allTypes = [{
//     type_id: 'EMAIL',
//     name: 'Email Items'
// }, {
//     type_id: 'IVR',
//     name: 'Call Items'
// }]
  error:any={isError:false,errorMessage:''};
    constructor(private reportService:ReportsService, private dialogRef: MatDialogRef<WICountByTypeReportComponent>,private propertiesList:PropertiesList,
      private router:Router,@Inject(MAT_DIALOG_DATA) data,private formBuilder: FormBuilder,private datePipe: DatePipe){
        this.token=data.sessionToken;
        this.id=data.userId;
        this.wiCountByTypeForm = this.formBuilder.group({  
          startDate: new FormControl('', [  
            Validators.required]),
            endDate: new FormControl('', [  
              Validators.required]),
              propertyType:new FormControl(0,[
                Validators.required
              ]),
              properties:new FormControl('',[
                Validators.required
              ]),
         
         
        });  
        this.datePipe.transform(this.maxDate, 'mm/dd/yyyy');
      }
    ngOnInit() {

      this.dropdownSettings = {
        maxHeight:90,
        
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        allowSearchFilter: true,
        itemsShowLimit:1
      };
  
      this.propertiesList.currentData.subscribe(data=>{
        
        this.workitemProperties = data;
  
      });

      //this.wIByPropertiesRequest = new WIByPropertiesRequest();
   
    }
    get f() {return this.wiCountByTypeForm.controls; }
    close()
  {
    this.dialogRef.close();
  }
 
  downloadWICountByTypeReport(){
    
    this.submitted=true;
    this.wIByPropertiesRequest.accessedBy = 1;
    this.wIByPropertiesRequest.crmUserId = this.id;
    this.wIByPropertiesRequest.reportId = 1;
    this.wIByPropertiesRequest.reportType = 'EXCEL';
    this.wIByPropertiesRequest.sessionToken = this.token;
    
    this.wIByPropertiesRequest.propertyIds =[];
    for(let i=0;i<this.selectedItems.length;i++){
      this.wIByPropertiesRequest.propertyIds.push(this.selectedItems[i].id);
    }
    
    if(this.wiCountByTypeForm.valid){
      this.startDate=this.datePipe.transform(this.wiCountByTypeForm.value.startDate,"dd-MM-yy");
      this.endDate=this.datePipe.transform(this.wiCountByTypeForm.value.endDate,"dd-MM-yy");
      this.isValidDate = this.validateDates(this.process(this.startDate), this.process(this.endDate));
      this.wIByPropertiesRequest.startDate = this.startDate;
      this.wIByPropertiesRequest.endDate = this.endDate;
// this.endDate=this.datePipe.transform(this.wiCountByTypeForm.value.endDate,"dd-MM-yy");
// this.selectedType="EMAIL";
console.log(this.selectedItems);
this.reportService.wiByProperties(this.wIByPropertiesRequest).subscribe(
  data => {
    saveAs(data,"wiByPropertiesReport");
  },
  err => {
    alert("Problem while downloading the file.");
    console.error(err);
  });
  this.dialogRef.close();
  }

}




  onChangeProperty(){
    
    if(this.wiCountByTypeForm.value.propertyType>0){
      var list = this.workitemProperties.filter(x => x.id == (this.wiCountByTypeForm.value.propertyType));
      var temp=list[0];
      this.workitemSubPropertiesArray=(temp['properties']);
    }
    else{
      this.workitemSubProperties = null;
      this.workitemSubPropertiesArray = [];

      this.selectedSubProperties = [];
    }
    this.selectedItems = [];
  }


  validateDates(sDate: Date, eDate: Date){
    
    this.isValidDate = true;
   

    if((eDate) < (sDate)){
     // this.error={isError:true,errorMessage:'Start date should be less than End date.'};
     alert("Start date should be less than End date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }


  process(date:string){
    this.parts = date.split("-");
   return new Date(this.parts[2], this.parts[1] - 1, this.parts[0]);
}

onItemSelect(event){

}
onItemUnSelect(event){
  
}
onSelectAll(event){
  
}
onDeSelectAll(event){
  
}



 
}