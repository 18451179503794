import { Component, OnInit, Inject } from '@angular/core';
import { EmailHistoryOutput } from '../models/EmailHistoryOutput.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from '../environment';
import { LoginService } from '../services/login.service';
import { CrmUser } from '../models/crmuser.model';

@Component({
  selector: 'app-email-reply-history',
  templateUrl: './email-reply-history.component.html',
  styleUrls: ['./email-reply-history.component.css']
})
export class EmailReplyHistoryComponent implements OnInit {

  emailHistoryOutput:Array<EmailHistoryOutput>;
  icon:Array<boolean> = new Array<boolean>();
  crmUser:CrmUser;
  id:number;
  token:string;
p:number;
  constructor(
    private loginService:LoginService,
    private dialogRef: MatDialogRef<EmailReplyHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.emailHistoryOutput = data.emailHistoryOutput
  }

  ngOnInit() {
    this.icon.push(false);
    this.crmUser = new CrmUser();
    
    this.loginService.currentData.subscribe((data) => {
      this.crmUser = data;
     this.token=this.crmUser.sessionToken;
     this.id=this.crmUser.crmUserId;
  });
    }
  
  pushStatus(){
    this.icon.push(false);
  }
  
    toggleIcon(i){
     this.icon[i] = !this.icon[i];
    }
    close(){
      this.dialogRef.close();
    }

    downloadAttachment(attachment,replyId){

      
      
      console.log("session token is"+this.token);
      console.log("crm userid is"+this.id);
      console.log("reply id is"+replyId);
      console.log("file name is"+attachment.fileName);

      window.open(
        environment.baseUrl+"downloadReplyAttachment?crmUserId="+this.id+"&replyId="+replyId+"&fileName="+attachment.fileName+"&sessionToken="+this.token
      );
    }

}
