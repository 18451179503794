import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AgentProfileService } from '../services/AgentProfile.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AgentProfileComponent } from '../agent-profile/agent-profile.component';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { AgentProfile } from '../models/AgentProfile.model';
import { CrmUser } from '../models/crmuser.model';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { LogoutService } from '../services/logout.service';
import { AdminWorkitemModel } from '../models/AdminWorkitem.model';
import { LoadAdminWorkitemService } from '../services/LoadAdminWorkitems.service';
import { CompleteWorkitemConfirmationMessageComponent } from '../messagePopUp/complete-workitem-confirmation-message/complete-workitem-confirmation-message.component';
import { WorkItemStatusEnum } from '../workitemstatusenum';
import { WorkItemStatusChangeInput } from '../models/workitemStatusChangeInput.model';
import { WorkItemStatusChangeService } from '../services/workitemStatusChange.service';
import { WorkitemItemDetailOutput } from '../models/workitemDetailOutput.model';
import { CategoryAndSubCategoryService } from '../services/categoryandsubcategory.service';
import { WorkItemCategory } from '../models/workitemcategory.model';
import { WorkItemSubCategory } from '../models/workitemsubcategory.model';
import { environment } from '../environment';
import { WorkitemTypeEnum } from '../workitemTypeEnum';
import { IVRWorkitemAddedComments } from '../models/ivrWorkitemAddedComments.model';
import { IVRWorkitemRemarksInput } from '../models/ivrWorkitemRemarksInput.model';
import { WorkitemSavedMessageComponent } from '../messagePopUp/workitem-saved-message/workitem-saved-message.component';
import { IVRWorkitemRemarksService } from '../services/ivrWorkitemRemarks.service';
import { CustomerHistoryInput } from '../models/CustomerHistoryInput.model';
import { CustomerHistoryService } from '../services/CustomerHistory.service';
import { CutomerEnagagementHistoryComponent } from '../cutomer-enagagement-history/cutomer-enagagement-history.component';
import { CustomerDetails } from '../models/CustomerDetails.model';
import { EngagementHistoryComponent } from '../engagement-history/engagement-history.component';
import { EmailReplyHistoryComponent } from '../email-reply-history/email-reply-history.component';
import { EmailHistoryInput } from '../models/EmailHistoryInput.model';
import { EmailHistoryOutput } from '../models/EmailHistoryOutput.model';
import { EmailHistoryService } from '../services/emailHistory.service';
import { SearchCustomerComponent } from '../searchCustomer/searchCustomer.component';
import { FormGroup, Validators, FormBuilder,FormControl } from '@angular/forms';
import { EmailReplyService } from '../services/emailReply.service';
import { EmailData } from '../models/emailData.model';
import { UploadAttachmentComponent } from '../uploadAttachment/uploadAttachment.component';
import { Attachment } from '../models/attachment.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { WorkitemItemDetailInput } from '../models/workitemDetailinput.model';
import { LinkedWorkItemDetailService } from '../services/linkedworkitemdetails.service';
import { ShowDashboard } from '../models/showDashboard.model';
import { WorkitemDetails } from '../models/workitemDetails.model';
import { EmailWorkItemDetailService } from '../services/emailworkitemdetail';
import { ConfigureEmailService } from '../services/configureEmail.service';
import { ConfigureUserService } from '../services/configureUser.service';
import { SaveAndReassignRequest } from '../models/SaveAndReassignRequest.model';
import { SaveAndReassignWorkitemService } from '../services/SaveAndReassignWorkitem.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { WorkitemProperty } from '../models/WorkitemProperty.model';
import{AdditionalInfoVOForReassign} from '../models/AdditionalInfoVOForReassign.model'
import { PropertiesList } from '../services/PropertiesList.service';
import { WorkitemSubPropertiesModel } from '../models/WorktiemSubProperties.model';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
import { GetReminderRequest } from 'src/app/models/GetReminderRequest.model';
import { ReminderConfigurationService } from 'src/app/services/ReminderConfiguration.service';
import { ModalService } from 'src/app/_modal';
import { ReminderPopupComponent } from 'src/app/reminder-popup/reminder-popup.component';
import { WhatsappWorkitemService } from 'src/app/services/WhatsappWorkitem.service';
import { WhatsappWorkitemDetails } from 'src/app/models/WhatsappWorkitemDetails.model';
import { WhatsappMessageFormatModel } from 'src/app/models/WhatsappMessageFormat.model';
import { WhatsappMessageArrayForDisplay } from 'src/app/models/WhatsappMessageArrayForDisplay.model';
import { WhatsappChatHistoryComponent } from 'src/app/whatsapp-chat-history/whatsapp-chat-history.component';
import { WorkitemReminderService } from 'src/app/services/WorkitemReminder.service';
import { WorkItemCustomFieldsComponent } from 'src/app/work-item-custom-fields/work-item-custom-fields.component';
import { CustomFieldsService } from 'src/app/services/CustomFields.service';
import { WorkitemCompleteHistoryComponent } from 'src/app/workitem-complete-history/workitem-complete-history.component';
import { CompleteWorkitemHistoryService } from 'src/app/services/CompleteWorkitemHistory.service';
import { CompletePropertiesComponent } from 'src/app/complete-properties/complete-properties.component';
import { WorkItemReplayInput } from '../models/WorkItemReplayInput.model';
import { CustomerHistoryOutput } from '../models/CustomerHistoryOutput.model';

@Component({
  selector: 'app-admin-workitem-controller',
  templateUrl: './admin-workitem-controller.component.html?v=1',
  styleUrls: ['./admin-workitem-controller.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class AdminWorkitemControllerComponent implements OnInit {


  agentProfile:AgentProfile;
  crmUser:CrmUser;
  id:number;
  token:string;
  myTaskActiveDiv:boolean = false;

  currentsTasks:boolean = true;
  showCompletedWorkItems:boolean=false;
  activeCondiction:boolean = true;
  searchWorkItemForMyTasks:String = '';
  searchWorkItemForCompletedTasks:String = '';
  loading:boolean=false;
  //for icon
  selectedIcon:number;
  completedSelectedIcon:number;
  serveManualWorkItem:boolean;
  serveEmailWorkItem:boolean;
  serveCompletedEmailWorkitem:boolean;
  serveCompletedManualWorkitem:boolean;
  workItemIdForCompletion:number;
  selectedCategory : number;
  selectedSubCategory: number;
  categoryForCompletedWorkItem: string;
  subCategoryForCompletedWorkItem: string;
  textareaValue:string;
  workitemType:string;
  form: FormGroup;
  fwform:FormGroup;
  replyType:string;
  emailData: any;
  fromMailBoxId:string[]=[];
  propertiesListFromServer:string = "";
  fwfromMailBoxId:string[]=[];
  IsHidden= true;
  IsHiddenReplyAll=true;
  clicked:boolean=false;
  fwclicked:boolean=false;
  submitted:boolean=false;
  fwSubmitted:boolean=false;
  emailDataTobeSent:EmailData;
  categoryNotSelected:boolean = false;
  propertyNotSelected:boolean = false;
  subPropertiesNotSelected:boolean = false;
  queueNotSelected:boolean = false;
  files:File[]=[];
  fwFiles:File[]=[];
  queueList = [];
  userList:any[];
  completeUserList:any[];
  selectedQueue:number = 0;
  selectedAgent:number = 0;
  propertiesNotAvailableFromServer:boolean =true;
  selectedProperty:number = 0;
  selectedSubProperties:Array<any> = [];
  workItemStatusChangeInput:WorkItemStatusChangeInput;
  workItemDetailOutput:WorkitemItemDetailOutput;
  ivrWorkitemRemarksInput:IVRWorkitemRemarksInput;
  customerHistoryInput:CustomerHistoryInput;
  workItemReplayInput:WorkItemReplayInput;
  customerDetails:CustomerDetails;
  emailHistoryInput:EmailHistoryInput;
  emailHistoryOutput:Array<EmailHistoryOutput>;
  completedWorkitemDetailOutput:WorkitemItemDetailOutput;
  workItemDetailInput:WorkitemItemDetailInput;
  getReminderRequest:GetReminderRequest;
  categoryNotSelectedForEmail:boolean = false;
  remarksNotEntered:boolean = false;
  deleteAttachment:Attachment;
  linkedWorkItemDetailOutput:WorkitemItemDetailOutput;
  saveAndReassignRequest:SaveAndReassignRequest;
  showDashboard:ShowDashboard;
  dropdownSettings:IDropdownSettings = {};
  icon2:Array<boolean> = new Array<boolean>();
  adminWorkitemsList:Array<AdminWorkitemModel> = new Array<AdminWorkitemModel>();
  adminCompleteWorkitemsList:Array<AdminWorkitemModel> = new Array<AdminWorkitemModel>();
  workitemCategory:Array<WorkItemCategory>=new Array<WorkItemCategory>();
  workitemProperties:Array<WorkitemProperty> = new Array<WorkitemProperty>();
  workitemSubProperties:Array<any> = [];
  workitemSubCategory:Array<WorkItemSubCategory>=new Array<WorkItemSubCategory>();
  completeWorkItemDetails:Array<WorkitemDetails> = new Array<WorkitemDetails>();


  play:boolean=false;
  time: number = 0;
  hours:number=0;
  minutes:number=0;
  seconds:number=0;

  completedtime: number = 0;
  completedhours:number=0;
  completedminutes:number=0;
  completedseconds:number=0;

interval;
hh:string;
mm:string;
ss:string;

compltedinterval;
completedhh:string;
completedmm:string;
completedss:string;

  rateRemarks:string;
  transactionCount:number;
  gdsStatus:string = "Select Status";
  rateLoadingStatus:string = "Select Rate Loading Status";

  rateMappingStatusList = ['PENDING_INTERNAL', 'CLOSED', 'OTHERS', 'PENDING_EXTERNAL'];
  gdsStatusList = ['PENDING_INTERNAL', 'CLOSED', 'OTHERS', 'PENDING_EXTERNAL'];
  //formForSaveAndReassign:FormGroup;
  config2: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '18rem',
    maxHeight: '18rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    sanitize: false,
     toolbarPosition: 'top',
    outline: true,
    defaultFontName: 'Arial',
    defaultFontSize: '3',
     showToolbar: true,

  };

  remindersList = [];
  bodyText: string;
  whatsappWorkitemDetails:WhatsappWorkitemDetails;
  singlehatsappMessage:WhatsappMessageFormatModel;
  WhatsappMessageArray:Array<WhatsappMessageFormatModel>;
  serveWhatsappWorkitem:boolean;
  serveCompletedWhatsappWorkitem:boolean;
  whatsappMessageArrayForDisplay:Array<WhatsappMessageArrayForDisplay>;
  customFieldsList:Array<any> = new Array<any>();
  addedCustomFieldsListForWorkitem = [];

  constructor(
    private adminProfileService:AgentProfileService,
    private loginService:LoginService,
    private router:Router,
    private dialog:MatDialog,
    private logoutService:LogoutService,
    private loadAdminWorkitemServie:LoadAdminWorkitemService,
    private workItemStatusChangeService:WorkItemStatusChangeService,
    private categoryandsubcategoryService:CategoryAndSubCategoryService,
    private ivrWorkitemRemarksService:IVRWorkitemRemarksService,
    private customerHistoryService:CustomerHistoryService,
    private emailHistoryService:EmailHistoryService,
    private emailReplyService:EmailReplyService,
    private linkedWorkItemDetailService:LinkedWorkItemDetailService,
    private emailWorkItemDetailService:EmailWorkItemDetailService,
    private formBuilder:FormBuilder,
    private configureEmailService:ConfigureEmailService,
    private configureUserService:ConfigureUserService,
    private saveAndReassignWorkitemService:SaveAndReassignWorkitemService,
    private propertiesList:PropertiesList,
    private reminderConfigurationService:ReminderConfigurationService,
    private modalService: ModalService,
    private whatsappWorkitemService:WhatsappWorkitemService,
    private workitemReminderService:WorkitemReminderService,
    private customFieldsService:CustomFieldsService,
    private completeWorkitemHistoryService:CompleteWorkitemHistoryService,
  ) {
      
   }

  ngOnInit() {

    this.bodyText = 'This text can be updated in modal 1';
    this.categoryNotSelected= false;
    this.propertyNotSelected= false;
    this.subPropertiesNotSelected = false;
    this.queueNotSelected = false;
    this.icon2.push(false);
    this.IsHidden=true;
    this.IsHiddenReplyAll=true;
    this.serveEmailWorkItem=false;
    this.serveManualWorkItem = false;
    this.serveCompletedEmailWorkitem = false;
    this.serveCompletedManualWorkitem = false;
    this.agentProfile = new AgentProfile();
    this.workItemStatusChangeInput = new WorkItemStatusChangeInput();
    this.workItemDetailInput=new WorkitemItemDetailInput();
    this.workItemDetailOutput=new WorkitemItemDetailOutput();
    this.ivrWorkitemRemarksInput = new IVRWorkitemRemarksInput();
    this.customerHistoryInput = new CustomerHistoryInput();
    this.workItemReplayInput = new WorkItemReplayInput();
    this.customerDetails = new CustomerDetails();
    this.emailHistoryInput = new EmailHistoryInput();
    this.emailHistoryOutput = new Array<EmailHistoryOutput>();
    this.completedWorkitemDetailOutput = new  WorkitemItemDetailOutput();
    this.linkedWorkItemDetailOutput=new WorkitemItemDetailOutput();
    this.showDashboard = new ShowDashboard();
    this.saveAndReassignRequest = new SaveAndReassignRequest();
    this.saveAndReassignRequest.additionalInfoVO = new AdditionalInfoVOForReassign();
    this.getReminderRequest = new GetReminderRequest();
    this.whatsappWorkitemDetails = new WhatsappWorkitemDetails();
    this.WhatsappMessageArray = new Array<WhatsappMessageFormatModel>();
    this.whatsappMessageArrayForDisplay = new Array<WhatsappMessageArrayForDisplay>();
    this.singlehatsappMessage = new WhatsappMessageFormatModel();
    this.loginService.currentData.subscribe((data) => {
      this.crmUser = data;
     this.token=this.crmUser.sessionToken;
     this.id=this.crmUser.crmUserId;
     if(data==null || data.sessionToken == null){
       this.loginService.endInterval();
         this.router.navigate(['']);
       }

      this.loadAdminWorkitemServie.currentData.subscribe(data=>{
        this.adminWorkitemsList = data;
      });

 
    });

    this.configureEmailService.getQueueList(this.id,this.token).subscribe(
      data=>{
      console.log(data);
      if(data.status==200)
      {
        this.queueList=data.body['queueList'];
      }
      else{
        this.loginService.endInterval();
          this.router.navigate(['']);
        }
      }
      
    );

    this.configureUserService.getUserList(this.id,this.token).subscribe(data=>

      {
        
        console.log(data);
        if(data.status==200){
          this.userList= data.body['users'];


        }
         
         else{
          this.loginService.endInterval();
            this.router.navigate(['']);
          }

      }
    );
    this.categoryandsubcategoryService.currentData.subscribe((data)=>{
      
      this.workitemCategory=data;
      console.log(this.workitemCategory);
    });
    this.propertiesList.currentData.subscribe(data=>{
      
      this.workitemProperties = data;

    });

    this.customFieldsService.currentData.subscribe(data=>{
      this.customFieldsList = data;
    });

    this.configureUserService.getUserList(this.id,this.token).subscribe(data=>
      
            {
              
              console.log(data);
              if(data.status==200){
               this.userList= data.body['users'];
               
              }
            }
          );

    this.selectedCategory=0;
    this.selectedSubCategory=0;

    this.form = this.formBuilder.group({
      receiver: ['',[Validators.required, Validators.email]],
       from: [''],
       cc: ['', [Validators.pattern(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/)]],
       bcc:['', [Validators.pattern(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/)]],
       sub: ['',[Validators.required,Validators.minLength(4)]],
       content: ['',[Validators.required,Validators.minLength(4)]]
     });
     this.fwform = this.formBuilder.group({
      fwreceiver: ['',[Validators.required, Validators.email]],
       fwfrom: [''],
       fwcc: ['', [Validators.pattern(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/)]],
       fwbcc:['', [Validators.pattern(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/)]],
       fwsub: ['',[Validators.required,Validators.minLength(4)]],
       fwcontent: ['',[Validators.required,Validators.minLength(4)]]
     });

     this.dropdownSettings = {

      maxHeight:100,
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit:1,

    };

    

  }

 // get saveAndReassignFormControls(){return this.formForSaveAndReassign.controls};


 openModal(id: string) {
   
  this.modalService.open("custom-modal-1");
}

closeModal(id: string) {
  this.modalService.close("custom-modal-1");
}

  openAdminProfile(){

    
      this.adminProfileService.getAgentDetails(this.crmUser.crmUserId, this.crmUser.sessionToken).subscribe(
        data=>{
          

          console.log(data);
          if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
            this.agentProfile.name = data.body['name'];
            this.agentProfile.emailId = data.body['emailAddress'];
            this.agentProfile.skills = data.body['skills'];
            this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.height = "auto";
            dialogConfig.width = "600px";  ;
            dialogConfig.data = {
            agentProfile: this.agentProfile
            };
             this.dialog.open(AgentProfileComponent,dialogConfig);

          }
          else if(data.body['responseType']== 'SESSION_TIMEOUT'){

            alert("Session timeout!Please Login Again");
            this.loginService.storeddata(null);
            this.router.navigate(['']);
                                                        }
        }
      );


  }

  changePassword(){
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data={
        sessionToken:this.token,
        userId:this.id
      };
      // dialogConfig.maxWidth='400px';
    this.dialog.open(ChangePasswordComponent,dialogConfig);
  }

  logoutUser(){
    
    this.loginService.endInterval();
    this.logoutService.logoutUser(this.crmUser.crmUserId, this.crmUser.sessionToken).subscribe(
      data=>{

        
        console.log(data);
        if(data.status==200 ){
          this.router.navigate(['/']);
        }
      }
    )

  }

  // onAdminWorkitemControllerClick(){

  // }

  // onDashboardClick(){
  //   this.router.navigate(["adminDashBoard"]);
  // }
  // onEmailClick(){
  //   this.router.navigate(["emailConfiguration"]);
  // }
  // onQueueClick(){
  //   this.router.navigate(["queueConfiguration"]);
  // }
  // onUserClick(){
  //   this.router.navigate(["userConfiguration"]);
  // }
  // onSkillClick(){
  //   this.router.navigate(["skillConfiguration"]);
  // }

  // onLiveMonitorClick(){
  //   this.router.navigate(["liveMonitor"]);
  // }
  // onManualWorkitemClick(){
  //   this.router.navigate(["manualWorkitemController"]);
  // }
  // showAgentDashboard()
  // {
  //   this.router.navigate(["dashboard"]);
  // }
  // showWIDashboard(){
  //   this.router.navigate(["wiDashboard"]);
  // }

  showOpenTasks(){
    this.showCompletedWorkItems = false;
    this.serveCompletedEmailWorkitem = false;
    this.serveCompletedManualWorkitem = false;
    this.myTaskActiveDiv = true;
    this.currentsTasks = true;
    this.completedSelectedIcon = null;
  }
  showCompletedTasks(){
    this.myTaskActiveDiv = false;
    this.showDashboard.crmUserId=this.crmUser.crmUserId;
    this.showDashboard.sessionToken=this.crmUser.sessionToken;
    this.loadAdminWorkitemServie.getAdminClosedWorkitems(this.showDashboard).subscribe(data=>{
      console.log(data);
      
      if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
        let temp=data.body['agentDashboard'];
        let temp1=temp["agentWorkItemAssignmentList"];
        this.adminCompleteWorkitemsList = [];
        if(null!=temp1){
          for (let i = 0; i < temp1.length; i++) {
            // this.workitemDetails.push(temp1[i]);
            this.adminCompleteWorkitemsList.push(temp1[i]);
          }
        }
      }
      else if(data.body['responseType']== 'SESSION_TIMEOUT'){

        alert("Session timeout!Please Login Again");
        this.loginService.storeddata(null);
        this.router.navigate(['']);
      }
    });
    this.showCompletedWorkItems = true;
    this.currentsTasks = false;
  }

  getWorkItemList(){
    
     this.searchWorkItemForMyTasks = '';
     this.searchWorkItemForCompletedTasks = '';
     console.log(this.crmUser.crmUserId,this.crmUser.sessionToken)
     this.showDashboard.crmUserId=this.crmUser.crmUserId;
     this.showDashboard.sessionToken=this.crmUser.sessionToken;
     this.loadAdminWorkitemServie.getAdminOpenWorkitems(this.showDashboard).
     subscribe(data=>{

       if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
        
        console.log(data);

        let temp=data.body['adminDashboard'];
        let temp1=temp["adminWorkItemAssignmentList"];
        this.adminWorkitemsList = [];
        if(temp1!=null){
          this.adminWorkitemsList = temp1;
        }
        this.loadAdminWorkitemServie.storeddata(this.adminWorkitemsList);
        //this.workitemDetails=new Array<WorkitemDetails>();
        //      this.workitemDetails = [];
        //      console.log(  this.workitemDetails);
        //       if(null!=temp1){
        //       for (let i = 0; i < temp1.length; i++) {
        //         this.workitemDetails.push(temp1[i]);
        //                                             }
        //                                             console.log(  this.workitemDetails);
                // this.showDashboardService.storeddata(this.workitemDetails);
      }
           if(data.body['responseType']== 'SESSION_TIMEOUT'){

             alert("Session timeout!Please Login Again");
             this.loginService.storeddata(null);
             this.router.navigate(['']);
                                                         }


         },
         error => {
           
           console.log(error);
         });
  }

  clearSearchFilterMyTasks(){
    this.searchWorkItemForMyTasks = '';
  }
  clearSearchFilterCompletedTasks(){
    this.searchWorkItemForCompletedTasks = '';
  }

  workitemServed = false;
  currentWorkItemId:number;

  getServedWorkItem(workitemId, workitemType,workitemState){
    
    this.currentWorkItemId = workitemId;
    this.workitemServed = true;
    this.icon2 = [];
    this.icon2.push(false);
    this.loading = true;
    this.categoryNotSelectedForEmail = false;
    this.categoryNotSelected = false;
    this.propertyNotSelected = false;
    this.queueNotSelected = false;
    this.IsHidden=true;
    this.IsHiddenReplyAll=true;
    this.selectedAgent = 0;
    this.selectedQueue = 0;
    this.selectedProperty = 0;
    this.selectedItems = [];
    this.remarksNotEntered = false;
    this.propertiesNotAvailableFromServer = true;
    this.serveCompletedEmailWorkitem = false;
    this.propertiesListFromServer= "";
    this.remarksNotEntered = false;
    this.rateLoadingStatus = null;
    this.transactionCount = null;
    this.rateRemarks = null;
    this.gdsStatus = null;
    if(workitemState!=="completed"){

      //this.pauseTimer();
      this.selectedIcon=workitemId;
      this.activeCondiction = true;
    }
    else{
      this.completedSelectedIcon = workitemId;
      this.activeCondiction = false;
    }
    this.workItemDetailInput.workItemId=workitemId;
    this.workItemDetailInput.crmUserId=this.crmUser.crmUserId;
    this.workItemDetailInput.sessionToken=this.crmUser.sessionToken;
    this.getCustomFieldsForWorkItem();
    if(workitemType==WorkitemTypeEnum.EMAIL){
      this.getEmailWorkItem(workitemState);
      this.workitemType="EMAIL";
    }
    else if(workitemType == WorkitemTypeEnum.MANUAL){
      this.getManualWorkItem(workitemState);
    }
    else if(workitemType==WorkitemTypeEnum.WHATSAPP){
      this.getWhatsappWorkitem(workitemState);
    }

  }

  whatsappMessagesList:Array<any> = new Array<any>();
  whatsappConversationId:number;

  getWhatsappWorkitem(workitemState){
    
        this.whatsappWorkitemService.getWhatsappWorkitemDetails(this.workItemDetailInput).subscribe(
          data=>{
            
            console.log(data);
            
            if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
              this.loading = false;
              this.workitemReminderService.storeddata(data.body["whatsAppConversationWorkItem"]);
              this.whatsappWorkitemDetails = data.body["whatsAppConversationWorkItem"];
              this.whatsappMessagesList = data.body["whatsAppWorkConversationHistoryList"];
              this.whatsappConversationId = this.whatsappWorkitemDetails.conversationWorkItemId;
              let arrayFromString  = Array.from(this.whatsappWorkitemDetails.callingNumber);
              for(let j=0;j<arrayFromString.length-2;j++){
                arrayFromString[j] = 'x';
              }
              this.whatsappWorkitemDetails.callingNumber = arrayFromString.join("");

              if(workitemState==="completed"){
                
                // this.whatsappWorkitemDetails = data.body["whatsAppConversationWorkItem"];
                // this.whatsappMessagesList = data.body["whatsAppWorkConversationHistoryList"];
                // this.whatsappConversationId = this.whatsappWorkitemDetails.conversationWorkItemId;
    
                this.serveCompletedManualWorkitem = false;
                this.serveCompletedEmailWorkitem = false;
                this.serveCompletedWhatsappWorkitem = true;
              }
              else{
    
                // this.whatsappWorkitemDetails = data.body["whatsAppConversationWorkItem"];
                // this.whatsappMessagesList = data.body["whatsAppWorkConversationHistoryList"];
                // this.whatsappConversationId = this.whatsappWorkitemDetails.conversationWorkItemId;
            
                this.serveWhatsappWorkitem = true;
                this.serveEmailWorkItem = false;
                this.serveManualWorkItem = false;
                this.myTaskActiveDiv = true;
    
               
    
              }
            }
    
    
          }
        );
      }
    
      getConversationHistoryByNumber(){
    
        this.whatsappWorkitemService.getConversationHistoryByNumber(this.crmUser.crmUserId,this.whatsappWorkitemDetails.callingNumber,this.crmUser.sessionToken).subscribe(
          data=>{
            
            console.log(data);
          }
        );
    
      }
      inputMessageForWhatsapp:string = "";
    
      sendWhatsappMessage(){
        
        this.whatsappWorkitemService.sendWhatsappMessage(this.crmUser.crmUserId,this.whatsappConversationId,this.crmUser.sessionToken,this.inputMessageForWhatsapp).subscribe(
          data=>{
            
            if(data.status ==200 && data.body["responseType"]=="SUCCESS"){

              this.whatsappMessagesList.push({'messageType':"SENT_FROM_IQD",'sentBy':"agent",'message':this.inputMessageForWhatsapp,'conversationId':this.whatsappConversationId,'mesageDate':""});
              // this.singlehatsappMessage.destinationNumber = this.whatsappWorkitemDetails.callingNumber;
              // this.singlehatsappMessage.msgBody = this.inputMessageForWhatsapp;
              // this.singlehatsappMessage.messagingNumber = this.whatsappWorkitemDetails.callingNumber;
              
              // this.whatsappMessageArrayForDisplay.push({'messageBody':this.singlehatsappMessage,'source':"user"});
              console.log(data);
              this.inputMessageForWhatsapp = "";
            }
          }
        );
      }

  getEmailWorkItem(workitemState){
    this.IsHidden=true;
    this.files=[];
    this.fwFiles=[];
  this.emailWorkItemDetailService.getEmailWorkitemStateChange(this.workItemDetailInput).
  subscribe(data=>{
    
    console.log(data);
    if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
      
      // console.log(data1.body['flowSearchResults']);
      this.workitemReminderService.storeddata(data.body["emailWorkItem"]);
      if(workitemState==="completed"){
        this.completedWorkitemDetailOutput = data.body['emailWorkItem'];
        this.serveCompletedManualWorkitem = false;
        this.serveCompletedWhatsappWorkitem = false;
        
        this.serveCompletedEmailWorkitem = true;
       
        //this.getComplteHandlingTime(this.completedWorkitemDetailOutput.workItemTimeSpent["ss"]);
        this.categoryForCompletedWorkItem = this.completedWorkitemDetailOutput.workItemCategory['name'];
        this.subCategoryForCompletedWorkItem = this.completedWorkitemDetailOutput.workItemSubCategory['name'];

        //console.log(this.workItemDetailOutput2);
      }else{
        this.workItemDetailOutput=data.body['emailWorkItem'];
        if((this.workItemDetailOutput.additionalInfoVO.statusGDS)!=null)
        this.gdsStatus = this.workItemDetailOutput.additionalInfoVO.statusGDS;
      else
        this.gdsStatus = "Select GDS Status";
      if((this.workItemDetailOutput.additionalInfoVO.rateMappingStatus)!=null)
        this.rateLoadingStatus = this.workItemDetailOutput.additionalInfoVO.rateMappingStatus;
        else
          this.rateLoadingStatus = "Select Rate Loading Status";

        this.rateRemarks = this.workItemDetailOutput.additionalInfoVO.ratePlanText;
        this.transactionCount = this.workItemDetailOutput.additionalInfoVO.transactionCount;
        this.getSelectedCategoryAndSubCategoryForEmail();



        //this.startTimer( this.workItemDetailOutput.workItemTimeSpent["ss"]);
        this.serveEmailWorkItem=true;
        this.serveWhatsappWorkitem = false;
        this.serveManualWorkItem = false;
        //this.serveIVRWorkItem=false;
        this.myTaskActiveDiv = true;
        console.log(this.workItemDetailOutput);
      }


      if(this.workItemDetailOutput.propertyVOS!=null){
        for(let i=0;i<this.workItemDetailOutput.propertyVOS.length-1;i++){
          
          
            this.propertiesListFromServer = this.propertiesListFromServer+this.workItemDetailOutput.propertyVOS[i].name+", ";
    
        }

        this.propertiesListFromServer = this.propertiesListFromServer+this.workItemDetailOutput.propertyVOS[this.workItemDetailOutput.propertyVOS.length-1].name;
        this.propertiesNotAvailableFromServer = false;
      }
      else{
        this.propertiesNotAvailableFromServer = true;
      }



      this.loading = false;
    }
    else if(data.body['responseType']== 'SESSION_TIMEOUT'){
      alert("Session timeout!Please Login Again");
      this.loginService.storeddata(null);
      this.router.navigate(['']);
    }
    else{
      
      console.log(data);
    }
  },



error => {
  
  console.log(error);
});

}

viewAllProperites(){
 
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data={
    propertyVOS:this.workItemDetailOutput.propertyVOS
  };

  this.dialog.open(CompletePropertiesComponent,dialogConfig);
}

getComplteHandlingTime(time){
  
    this.completedhours = Math.floor(time / 3600);
    time %= 3600;
    this.completedminutes = Math.floor(time / 60);
    this.completedseconds = time % 60;
    this.completedhh = this.completedhours < 10 ? "0" + this.completedhours : this.completedhours.toString();
    this.completedmm= this.completedminutes < 10 ? "0" + this.completedminutes : this.completedminutes.toString();
    this.completedss = this.completedseconds < 10 ? "0" + this.completedseconds : this.completedseconds.toString();
  }

  startTimer(time) {
    console.log(time);
    
    this.play = true;
  
   console.log(this.interval);
    this.interval = setInterval(() => {
     var timer=time;
      this.hours = Math.floor(timer / 3600);
      timer %= 3600;
  
  this.minutes = Math.floor(timer / 60);
   this.seconds = timer % 60;
  
  
  this.hh = this.hours < 10 ? "0" + this.hours : this.hours.toString();
  this.mm= this.minutes < 10 ? "0" + this.minutes : this.minutes.toString();
  this.ss = this.seconds < 10 ? "0" + this.seconds : this.seconds.toString();
  // console.log(this.hh + " " + this.mm + " " + this.ss);
      time++;
  
     },1000)
  
  
  
  
   }
  
  pauseTimer() {
    
    this.play = false;
    clearInterval(this.interval);
  }

  

  getManualWorkItem(workitemState){
    this.IsHidden=true;
    this.files=[];
    this.fwFiles=[];
    this.emailWorkItemDetailService.getManualWorkitemDetails(this.workItemDetailInput).
    subscribe(data=>{
      
      console.log(data);
      if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
        
        this.workitemReminderService.storeddata(data.body["manualWorkItem"]);
        // console.log(data1.body['flowSearchResults']);
        if(workitemState==="completed"){
          this.completedWorkitemDetailOutput = data.body['manualWorkItem'];
          if(this.completedWorkitemDetailOutput.propertyVOS!=null){
          for(let i=0;i<this.completedWorkitemDetailOutput.propertyVOS.length;i++){
            if(i==this.completedWorkitemDetailOutput.propertyVOS.length-1){
              this.propertiesListFromServer = this.propertiesListFromServer+this.completedWorkitemDetailOutput.propertyVOS[i].name;
            }
            else{
              this.propertiesListFromServer = this.propertiesListFromServer+this.completedWorkitemDetailOutput.propertyVOS[i].name+", ";
            }
          }
          this.propertiesNotAvailableFromServer = false;
        }
        else{
          this.propertiesNotAvailableFromServer = true;
        }
          this.serveCompletedEmailWorkitem = false;
          this.serveCompletedWhatsappWorkitem = false;
          this.serveCompletedManualWorkitem = true;
          
          // if(this.completedWorkitemDetailOutput.workItemTimeSpent==null){
          //   this.getComplteHandlingTime(0);
          // }
          // else{
          //   this.getComplteHandlingTime(this.completedWorkitemDetailOutput.workItemTimeSpent["ss"]);
          // }
          this.categoryForCompletedWorkItem = this.completedWorkitemDetailOutput.workItemCategory['name'];
          this.subCategoryForCompletedWorkItem = this.completedWorkitemDetailOutput.workItemSubCategory['name'];

          //console.log(this.workItemDetailOutput2);
        }else{
          this.workItemDetailOutput=data.body['manualWorkItem'];
          if((this.workItemDetailOutput.additionalInfoVO.statusGDS)!=null)
          this.gdsStatus = this.workItemDetailOutput.additionalInfoVO.statusGDS;
        else
          this.gdsStatus = "Select GDS Status";
        if((this.workItemDetailOutput.additionalInfoVO.rateMappingStatus)!=null)
          this.rateLoadingStatus = this.workItemDetailOutput.additionalInfoVO.rateMappingStatus;
          else
            this.rateLoadingStatus = "Select Rate Loading Status";

          this.rateRemarks = this.workItemDetailOutput.additionalInfoVO.ratePlanText;
          this.transactionCount = this.workItemDetailOutput.additionalInfoVO.transactionCount;
          if(this.workItemDetailOutput.propertyVOS!=null){
          for(let i=0;i<this.workItemDetailOutput.propertyVOS.length;i++){
            if(i==this.workItemDetailOutput.propertyVOS.length-1){
              this.propertiesListFromServer = this.propertiesListFromServer+this.workItemDetailOutput.propertyVOS[i].name;
            }
            else{
              this.propertiesListFromServer = this.propertiesListFromServer+this.workItemDetailOutput.propertyVOS[i].name+", ";
            }
          }
        }
        else{
          this.propertiesNotAvailableFromServer = true;
        }
          this.getSelectedCategoryAndSubCategoryForEmail();

          // if(this.workItemDetailOutput.workItemTimeSpent==null){
          //   this.startTimer(0);
          // }
          // else{
          //   this.startTimer( this.workItemDetailOutput.workItemTimeSpent["ss"]);
          // }

          this.serveManualWorkItem = true;
          this.serveEmailWorkItem=false;
          this.serveWhatsappWorkitem = false;
          this.myTaskActiveDiv = true;
          console.log(this.workItemDetailOutput);
        }

        this.loading = false;
      }
      else if(data.body['responseType']== 'SESSION_TIMEOUT'){
        alert("Session timeout!Please Login Again");
        this.loginService.storeddata(null);
        this.router.navigate(['']);
      }
      else{
        
        console.log(data);
      }
    },



  error => {
    
    console.log(error);
  });


  }


  getSelectedPropertyAndSubProperty(){
    this.selectedProperty = 0;
    this.selectedSubProperties = [];
    this.workitemSubPropertiesArray = [];
  }
  getSelectedCategoryAndSubCategoryForEmail(){
    

    this.selectedCategory=this.workItemDetailOutput.workItemCategory['id'];

  var list = this.workitemCategory.filter(x => x.id == this.selectedCategory);

  var temp=list[0];
  if(temp!=undefined){
  this.workitemSubCategory=(temp['subCategories']);
  this.selectedSubCategory=this.workItemDetailOutput.workItemSubCategory['id'];
  }
  else{
    this.workitemSubCategory=[];
    this.selectedSubCategory = 0;
  }
  //  this.selectedSubCategory=0;

  }

  onCompleteWorkitemClicked(workItemId){
    
      const dialogConfig= new MatDialogConfig();
    dialogConfig.disableClose=true;
    dialogConfig.autoFocus=true;
    dialogConfig.width="60%";
    dialogConfig.height="35%";
    dialogConfig.id = workItemId;
    console.log("workitemid is"+dialogConfig.id);
    this.workItemIdForCompletion = workItemId;
    this.dialog.open(CompleteWorkitemConfirmationMessageComponent,dialogConfig).afterClosed().
    subscribe(item => {
      if(item===0){}
      // Edited Data sent to App Component from Dialog
      else{
        this.completeWorkItem(item);
      }

    });;

  }

  completeWorkItem(workitemid){

    console.log("workitemid is"+ workitemid);

      
      this.workItemStatusChangeInput.crmUserId=this.crmUser.crmUserId;
      this.workItemStatusChangeInput.sessionToken=this.crmUser.sessionToken;
      this.workItemStatusChangeInput.updatedByUserId=this.crmUser.crmUserId;
      this.workItemStatusChangeInput.workItemId= workitemid;
      this.workItemStatusChangeInput.workItemStatus= WorkItemStatusEnum.COMPLETED;



      this.workItemStatusChangeService.getWorkItemStatusChange(this.workItemStatusChangeInput).
      subscribe(data=>{
        console.log(data);
        if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
              
                 console.log(data);
                 this.serveEmailWorkItem=false;
                this.serveManualWorkItem = false;
                this.getWorkItemList();



                     }
                    else if(data.body['responseType']== 'SESSION_TIMEOUT'){

                      alert("Session timeout!Please Login Again");
                      this.loginService.storeddata(null);
                      this.router.navigate(['']);
                    }
                    else{
                      
                      console.log(data);
                    }

                 },

                error => {
                  
                  console.log(error);


                    })

                  // }

    }


  onChangeCategory(){
    
    if(this.selectedCategory>0){
      this.categoryNotSelected = false;
      var list = this.workitemCategory.filter(x => x.id == this.selectedCategory);

      var temp=list[0];
      this.workitemSubCategory=(temp['subCategories']);


    }else{
      this.workitemSubCategory=null;
      this.selectedSubCategory=0;
    }

  }

  workitemSubPropertiesArray:Array<WorkitemSubPropertiesModel> = [];
  onChangeProperty(){
    
    if(this.selectedProperty>0){
      //this.propertyNotSelected = false;
      var list = this.workitemProperties.filter(x => x.id == this.selectedProperty);
      var temp=list[0];
      this.workitemSubPropertiesArray=(temp['properties']);
      //this.workitemSubPropertiesArray = [];
      // for(let i=0;i<this.workitemSubProperties.length;i++){
      //   this.workitemSubPropertiesArray.push({id:0,name:"asd",description:"asd"});
      //   this.workitemSubPropertiesArray[i].id = this.workitemSubProperties[i].propertyId;
      //   this.workitemSubPropertiesArray[i].name = this.workitemSubProperties[i].name;
      //   this.workitemSubPropertiesArray[i].description = this.workitemSubProperties[i].description;
      // }
    }
    else{
      this.workitemSubProperties = null;
      this.workitemSubPropertiesArray = [];
      this.selectedSubProperties = [];
      this.selectedItems = [];
    }
  }

  downloadAttachment(attachment)
  {
    
    window.open(environment.baseUrl+"downloadEmailAttachment?crmUserId="+this.id+"&emailItemId="+this.workItemDetailOutput.emailItemId+"&fileName="+attachment.fileName+"&sessionToken="+this.token);
  }

  downloadAttachmentForManualWorkitem(attachment){
    
    window.open(environment.baseUrl+"downloadWorkItemAttachment?crmUserId="+this.id+"&manualWorkItemId="+this.workItemDetailOutput.manualWorkItemId+"&fileName="+attachment.fileName+"&sessionToken="+this.token);
  }

  onSubmitRemarks(workitemId, assignedAgent, workitemType){
    
   // console.log(this.textareaValue);

    this.ivrWorkitemRemarksInput.callNotes = this.textareaValue;
    this.ivrWorkitemRemarksInput.crmUserId = this.crmUser.crmUserId;

    this.ivrWorkitemRemarksInput.sessionToken = this.crmUser.sessionToken;

    this.ivrWorkitemRemarksInput.workItemId = workitemId;



      if((this.textareaValue==null || this.textareaValue==undefined) ){
        this.remarksNotEntered = true;
        return;
      }


    this.ivrWorkitemRemarksService.updateIVRWorkitemRemarks(this.ivrWorkitemRemarksInput).
    subscribe(data=>{

      this.remarksNotEntered = false;
      
          if(data.status==200 &&  data.body["responseType"]=="SUCCESS" ){
              

              let now = new Date();


              if(this.whatsappWorkitemDetails.workItemComments==null){
                this.whatsappWorkitemDetails.workItemComments = [];
              }
              if(this.workItemDetailOutput.workItemComments==null){
                this.workItemDetailOutput.workItemComments = [];
              }
              if((this.textareaValue!=null || this.textareaValue!=undefined) && workitemType==WorkitemTypeEnum.WHATSAPP){
                this.whatsappWorkitemDetails.workItemComments.push(new IVRWorkitemAddedComments(assignedAgent,this.textareaValue,now.toString()));
                console.log(data);
              }
              else
              if(this.textareaValue!=null || this.textareaValue!=undefined){
                this.workItemDetailOutput.workItemComments.push(new IVRWorkitemAddedComments(assignedAgent,this.textareaValue,now.toString()));
                console.log(data);
              }


          }
          //this.callDialogPopUpMessage("Workitem saved successfully","Save Workitem");
          this.remarksNotEntered = false;
          this.rateLoadingStatus = null;
          this.transactionCount = null;
          this.rateRemarks = null;
          this.gdsStatus = null;
          this.textareaValue=null;
        });
  }

  callDialogPopUpMessage(  message,title){
    const dialogConfig= new MatDialogConfig();
    dialogConfig.disableClose=true;
    dialogConfig.autoFocus=true;
    dialogConfig.width="60%";
    dialogConfig.height="40%";
    dialogConfig.data = {
     popUpTitle:title,
     messageToBeDisplayed:message
  };
    this.dialog.open(WorkitemSavedMessageComponent,dialogConfig);

  }

  openHistory(customerId){

    
    this.customerHistoryInput.crmUserId = this.crmUser.crmUserId;
    this.customerHistoryInput.sessionToken = this.crmUser.sessionToken;
    this.customerHistoryInput.customerId = customerId;
    if(this.customerHistoryInput.customerId!=null && this.customerHistoryInput.customerId>0){
      this.customerHistoryService.getCustomerHistory(this.customerHistoryInput).
      subscribe(data=>{
        
        if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
          console.log(data);
          this.customerDetails = data.body;
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data = {
            customerDetails: this.customerDetails
          };
          this.dialog.open(CutomerEnagagementHistoryComponent,dialogConfig);
        }


      });
    }
    else {
      alert("No history available");
    }
  }

  openEngHistoryEmail(emailAddress){
     
    this.customerHistoryInput.crmUserId = this.crmUser.crmUserId;
    this.customerHistoryInput.sessionToken = this.crmUser.sessionToken;
    this.customerHistoryInput.emailAddress=emailAddress;
    this.customerHistoryInput.pageNumber = 0;
    this.customerHistoryInput.pageSize = 5;
    console.log(this.customerHistoryInput);
    if(emailAddress!=null)
    {
  this.customerHistoryService.getCustomerEmailHistory(this.customerHistoryInput).subscribe(data=>{
     
    if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
      console.log(data);
       this.customerDetails = data.body;
       this.customerDetails.customerEmailAddress = emailAddress;
       this.customerDetails.crmUserId = this.crmUser.crmUserId;
       this.customerDetails.sessionToken = this.crmUser.sessionToken;
       this.customerHistoryService.storeddata(this.customerDetails);
       const dialogConfig = new MatDialogConfig();
       dialogConfig.disableClose = true;
         dialogConfig.autoFocus = true;
       dialogConfig.data = {
         customerDetails: this.customerDetails
     };
       this.dialog.open(EngagementHistoryComponent,dialogConfig);
      // window.open('/cutomerEngagementHistory','_new','location=yes,height=570,width=1100,scrollbars=yes,status=yes');

   }
   else if(data.body['responseType']== 'SESSION_TIMEOUT'){

    alert("Session timeout!Please Login Again");
    this.loginService.storeddata(null);
    this.router.navigate(['']);
  }
  });
    }
    else{
      alert("No History Available");
    }
  }

  openWorkItemHistoryEmail(emailAddress, workItemId){
    
    this.customerHistoryInput.crmUserId = this.crmUser.crmUserId;
    this.customerHistoryInput.sessionToken = this.crmUser.sessionToken;
    this.customerHistoryInput.emailAddress=emailAddress;
    if(emailAddress!=null)
    {
  this.customerHistoryService.getCustomerEmailHistory(this.customerHistoryInput).subscribe(data=>{
    
    if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
      console.log(data);
       this.customerDetails = data.body;

       //For Sonesta view replies requiremnt
       let customerEngagementHistory:[] = this.customerDetails["customerEngagementHistory"];
       let customerEngagementHistory1:[] = [];
       for(let i=0; i<customerEngagementHistory.length; i++){
        let engmtSubject:string = customerEngagementHistory[i]["engagementSubject"];
        if(engmtSubject.indexOf(workItemId)!=-1){ 
          customerEngagementHistory1.push(customerEngagementHistory[i]);
        }
       }
       this.customerDetails["customerEngagementHistory"] = customerEngagementHistory1;
       //---------------------------------------

       
       const dialogConfig = new MatDialogConfig();
       dialogConfig.disableClose = true;
         dialogConfig.autoFocus = true;
       dialogConfig.data = {
         customerDetails: this.customerDetails
     };
       this.dialog.open(EngagementHistoryComponent,dialogConfig);
      // window.open('/cutomerEngagementHistory','_new','location=yes,height=570,width=1100,scrollbars=yes,status=yes');

   }
   else if(data.body['responseType']== 'SESSION_TIMEOUT'){

    alert("Session timeout!Please Login Again");
    this.loginService.storeddata(null);
    this.router.navigate(['']);
  }
  });
    }
    else{
      alert("No History Available");
    }
  }




  openEmailHistory(workitemId){
    this.emailHistoryInput.crmUserId = this.crmUser.crmUserId;
    //this.emailHistoryInput.emailItemId = this.workItemDetailOutput.emailItemId;
    this.emailHistoryInput.emailItemId = workitemId;
    this.emailHistoryInput.sessionToken = this.crmUser.sessionToken;


    this.emailHistoryService.getEmailHistory(this.emailHistoryInput).
    subscribe(data=>{
      
      if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
        console.log(data);
        this.emailHistoryOutput = data.body['previousReplies'];
        if(this.emailHistoryOutput.length==0){
          this.callDialogPopUpMessage("No reply history available", "Reply History");
        }
        else{
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.height = "60%";
          dialogConfig.width = "60%";
          dialogConfig.data = {
            emailHistoryOutput: this.emailHistoryOutput
          };
          this.dialog.open(EmailReplyHistoryComponent,dialogConfig);
        }

      }
    });
  }

  onSearchCust(workItemState){
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if(this.workitemType=='EMAIL')
    {
      if(workItemState==="completed")
      {
          dialogConfig.data = {
          id: this.crmUser.crmUserId,
          token:  this.crmUser.sessionToken,
          sender:this.completedWorkitemDetailOutput.sender,
          wiType:this.workitemType,
        };
      }
      else{
        dialogConfig.data = {
          id: this.crmUser.crmUserId,
          token:  this.crmUser.sessionToken,
          sender:this.workItemDetailOutput.sender,
          wiType:this.workitemType,
        };
      }
    }
    // else if(this.workitemType=='IVR'){
    //   if(workItemState==='completed')
    //   {
    //     dialogConfig.data = {
    //       id: this.crmUser.crmUserId,
    //       token:  this.crmUser.sessionToken,
    //       wiType:this.workitemType,
    //       caller:this.completedIvrWorkitemDetailOutput.callingNumber
    //     };
    //   }
    //   else{
    //     dialogConfig.data = {
    //       id: this.crmUser.crmUserId,
    //       token:  this.crmUser.sessionToken,
    //       wiType:this.workitemType,
    //       caller:this.ivrWorkitemItemDetailOutput.callingNumber
    //     };
    //   }
    // }
    this.dialog.open(SearchCustomerComponent,dialogConfig);
  }

  replyMailFunction1(){
    
    this.replyType="REPLY";
    this.emailReplyService.populateMailData(this.crmUser.crmUserId,this.workItemDetailOutput.emailItemId,this.token,this.replyType).subscribe(
      data=>{
        
        if(data.status==200){
          this.emailData=data.body;
          console.log("emaildata>>"+this.emailData);
          this.form.get('from').setValue(this.emailData.fromMailboxId);
          this.fromMailBoxId=this.emailData.fromMailboxes;
          this.form.get('receiver').setValue(this.emailData.from);
          this.form.get('sub').setValue("RE:"+this.emailData.subject);
          this.form.get('content').setValue(this.emailData.mailContent);
          this.form.get('cc').setValue(this.emailData.cc);
        }
      }
    );
    this.IsHiddenReplyAll=true;
    this.IsHidden= !this.IsHidden;
  }

  forwardMailFunction2(){
    this.replyType="FORWARD";
    this.emailReplyService.populateMailData(this.crmUser.crmUserId,this.workItemDetailOutput.emailItemId,this.token,this.replyType).subscribe(
      data=>{
        
        if(data.status==200){
          this.emailData=data.body;
          console.log("emaildata>>"+this.emailData.subject);
          this.fwform.get('fwfrom').setValue(this.emailData.fromMailboxId);
          this.fwfromMailBoxId=this.emailData.fromMailboxes;
          this.fwform.get('fwreceiver').setValue(this.emailData.from);
          this.fwform.get('fwcc').setValue(this.emailData.cc);
          this.fwform.get('fwsub').setValue("FW:"+this.emailData.subject);
          this.fwform.get('fwcontent').setValue(this.emailData.mailContent);

        }
      }
    );
    this.IsHidden=true;
    this.IsHiddenReplyAll= !this.IsHiddenReplyAll;
  }

  close(){
    this.IsHidden= !this.IsHidden;
  }
  closeFw(){
    this.IsHiddenReplyAll=!this.IsHiddenReplyAll;
  }

  onSubmitForward()
  {
    
    let formData = this.fwform.value;
    this.fwclicked=true;
    this.submitted=true;
    console.log(formData);
    this.emailDataTobeSent= new EmailData();
    this.emailDataTobeSent.crmUserId= this.crmUser.crmUserId;
    this.emailDataTobeSent.sessionToken = this.token;
    this.emailDataTobeSent.receiver = this.fwform.value.fwreceiver;
    this.emailDataTobeSent.from = this.fwform.value.fwfrom;
    this.emailDataTobeSent.cc=this.fwform.value.fwcc;
    this.emailDataTobeSent.bcc=this.fwform.value.fwbcc;
    this.emailDataTobeSent.mailContent=this.fwform.value.fwcontent;
    this.emailDataTobeSent.subject=this.fwform.value.fwsub;
    this.emailDataTobeSent.emailItemId=this.workItemDetailOutput.emailItemId;
    if (this.fwform.valid) {
      this.emailReplyService.sendReply(this.emailDataTobeSent).subscribe(data=>{
      
            if(data.status==200 && data.body["responseType"]=="SUCCESS"){
              
              // this.isLoading=false;
              // window.close();
              alert("Mail Sent");
              this.fwclicked=false;
            }
             else{
           // this.isLoading=false;
           // window.close();
           alert("Problem In Sending Mail");
           this.fwclicked=false;
             }
             this.IsHiddenReplyAll=!this.IsHiddenReplyAll;
            });

    }
    else{
      this.fwclicked=false;
    }
  }
onSubmitReply(){
  
  let formData = this.form.value;
  this.clicked=true;
  this.submitted=true;
  console.log(formData);
  this.emailDataTobeSent= new EmailData();
  this.emailDataTobeSent.crmUserId= this.crmUser.crmUserId;
  this.emailDataTobeSent.sessionToken = this.token;
  this.emailDataTobeSent.receiver = this.form.value.receiver;
  this.emailDataTobeSent.from = this.form.value.from;
  this.emailDataTobeSent.mailContent=this.form.value.content;
  this.emailDataTobeSent.subject=this.form.value.sub;
  this.emailDataTobeSent.emailItemId=this.workItemDetailOutput.emailItemId;
  this.emailDataTobeSent.cc=this.form.value.cc;
  this.emailDataTobeSent.bcc=this.form.value.bcc;
  if (this.form.valid) {
    this.emailReplyService.sendReply(this.emailDataTobeSent).subscribe(data=>{
      
            if(data.status==200 && data.body["responseType"]=="SUCCESS"){
              
              // this.isLoading=false;
              // window.close();
              alert("Mail Sent");
              this.clicked=false;
            }
             else{
           // this.isLoading=false;
           // window.close();
           alert("Problem In Sending Mail");
           this.clicked=false;
             }
             this.IsHidden= !this.IsHidden;
            });

    }
    else
    {
      this.clicked=false;
    }
  }

  get f() { return this.form.controls; }
  get fw(){return this.fwform.controls;}

  openUploadDialogFw()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width="400px";
    dialogConfig.height="250px";
    dialogConfig.data = {
      id: this.crmUser.crmUserId,
      token:  this.token,
      itemId:this.workItemDetailOutput.emailItemId,
    };
    this.dialog.open(UploadAttachmentComponent,dialogConfig).afterClosed().
    subscribe(files => {
      for (var i = 0; i < files.length; i++) {
        this.fwFiles.push(files[i]);
      }

    });
  }
  openUploadDialog()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width="400px";
    dialogConfig.height="250px";

    dialogConfig.data = {
      id: this.crmUser.crmUserId,
      token:  this.token,
      itemId:this.workItemDetailOutput.emailItemId,
    };
    this.dialog.open(UploadAttachmentComponent,dialogConfig).afterClosed().
    subscribe(files => {
      for (var i = 0; i < files.length; i++) {
        this.files.push(files[i]);
      }

    });
  }

  deleteFiles(file)
  {
    this.deleteAttachment= new Attachment();
    this.deleteAttachment.token=this.token;
    this.deleteAttachment.userId=this.crmUser.crmUserId;
    this.deleteAttachment.fileName=file.name;
    this.deleteAttachment.emailItemId=this.workItemDetailOutput.emailItemId;
    this.emailReplyService.deleteAttachment(this.deleteAttachment).subscribe(data=>{
      console.log(data);
    });
    this.files.splice(file,1);
  }
  deleteFilesFw(file)
  {
    this.deleteAttachment= new Attachment();
    this.deleteAttachment.token=this.token;
    this.deleteAttachment.userId=this.crmUser.crmUserId;
    this.deleteAttachment.fileName=file.name;
    this.deleteAttachment.emailItemId=this.workItemDetailOutput.emailItemId;
    this.emailReplyService.deleteAttachment(this.deleteAttachment).subscribe(data=>{
      console.log(data);
    });
  this.fwFiles.splice(file,1);
  }

  pushStatus(){
    this.icon2.push(false);
  }

  openIcon(i,workitemId){
    console.log(i);
    console.log(this.icon2);
     this.workItemDetailInput.workItemId=workitemId;
     this.workItemDetailInput.crmUserId=this.crmUser.crmUserId;
     this.workItemDetailInput.sessionToken=this.crmUser.sessionToken;
     this.linkedWorkItemDetailService.getLinkedWorkitemContent(this.workItemDetailInput).
     subscribe(data=>{

       if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
           
         console.log(data);
         this.linkedWorkItemDetailOutput=data.body['emailWorkItem'];

       }
     });

     this.icon2[i] = !this.icon2[i];
   }

   onSaveAndReassign(workItemId,assignedAgent,requestFrom){
     

    if(this.selectedItems.length>0){
      this.saveAndReassignRequest.propertyIds = [];
      for(let i=0;i<this.selectedItems.length;i++){
        this.saveAndReassignRequest.propertyIds.push(this.selectedItems[i].id);
      }
    }
      if(this.selectedCategory<1){
        this.categoryNotSelected = true;
        return;
      }
     
    if(this.selectedQueue<1){
      this.queueNotSelected = true;
      return;
    }
     this.saveAndReassignRequest.callNotes = this.textareaValue;
     this.saveAndReassignRequest.categoryId = this.selectedCategory;
     this.saveAndReassignRequest.subCategoryId = this.selectedSubCategory;
     this.saveAndReassignRequest.crmUserId = this.id;
     this.saveAndReassignRequest.newAssignedUser = this.selectedAgent;
     this.saveAndReassignRequest.queueId = this.selectedQueue;
     this.saveAndReassignRequest.workItemId = workItemId;
     this.saveAndReassignRequest.sessionToken = this.token;
     this.saveAndReassignWorkitemService.saveAndReassign(this.saveAndReassignRequest).subscribe(data=>{
      
      if(data.status==200 && data.body['responseType']=="SUCCESS"){

        let now = new Date();


              if(this.workItemDetailOutput.workItemComments==null){
                this.workItemDetailOutput.workItemComments = [];
              }
              if(this.textareaValue!=null || this.textareaValue!=undefined){
                this.workItemDetailOutput.workItemComments.push(new IVRWorkitemAddedComments(assignedAgent,this.textareaValue,now.toString()));
                console.log(data);
              }
              this.serveEmailWorkItem=false;
        this.serveManualWorkItem = false;
        this.getWorkItemList();
        this.callDialogPopUpMessage("Workitem saved successfully","Save Workitem");
        this.textareaValue=null;
      }
     });
   }
   selectedItems:Array<any>=[];

   onItemSelect(item:any){
    //  
    //  this.propertyNotSelected = false;
    // this.selectedItems.push(item);

   }
   onItemUnSelect(item:any){
     
    // for(let i= 0;i<this.selectedItems.length;i++){
    //   if(this.selectedItems[i].id==item.id){
    //     for(let j = i;j<(this.selectedItems.length-1);j++){
    //       this.selectedItems[j] = this.selectedItems[j+1];
    //     }
    //     this.selectedItems.pop();
    //     break;
    //   }

    // }
  }
   onSelectAll(items:any){
    //  
    // this.selectedItems = [];
    // for(let i=0;i<items.length;i++){
    //   this.selectedItems.push(items[i]);
    // }

   }
   onDeSelectAll(items:any){
    //  
    // this.selectedItems = [];
   }

   onChangeQueue(){
     this.queueNotSelected = false;
   }

   reminder(){

     
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data={
      crmUserId: this.id,
      sessionToken: this.token,
      detailedWorkitem:this.workitemServed?this.workItemDetailOutput:null,
      isServed:this.workitemServed
    };
    this.dialog.open(ReminderConfigurationComponent,dialogConfig);
   }


   openWhatsappConversationHistory(contactNumber){
    this.whatsappWorkitemService.getConversationHistoryByNumber(this.crmUser.crmUserId,contactNumber,this.crmUser.sessionToken).subscribe(
      data=>{
        
        if(data.status==200 && data.body['responseType']=="SUCCESS"){
          console.log(data);
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            crmUserId: this.id,
            sessionToken: this.token,
            coversationArray:data.body['whatsAppWorkItemDetailsList']
          };
          this.dialog.open(WhatsappChatHistoryComponent,dialogConfig)
        }
      }
    );

   }


   onAddCustomFields(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data={
      crmUserId: this.id,
      sessionToken: this.token,
      customFieldsList:this.customFieldsList,
      workItemId:this.currentWorkItemId,
    };

    this.dialog.open(WorkItemCustomFieldsComponent,dialogConfig);
   }

   
   getCustomFieldsForWorkItem(){
     
     this.customFieldsService.getCustomFieldsForWorkItem(this.workItemDetailInput).subscribe(data=>{
        
        console.log(data);
        this.addedCustomFieldsListForWorkitem = data.body['workItemCustomFieldVOS'];
     });
   }


   getCompleteWorkitemHistory(){

    

    this.completeWorkitemHistoryService.getCompleteHistory(this.workItemDetailInput).subscribe(data=>{
      
      if(data.status==200 && data.body['responseType']=="SUCCESS"){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
          workItemHistoryVOS:data.body['workItemHistoryVOS']
        };
    
        this.dialog.open(WorkitemCompleteHistoryComponent,dialogConfig);
      }

      else{
        alert(data.body['responseMessage']);
      }
    });
    

   }

  //  getImagesFromEmailContent(){
  //   let regex = new RegExp('<img.*?src=\\"(.*?)\\"[^\>]+>',"g");
    
  //   let inlineImages:Array<any> = this.workItemDetailOutput.content.match(regex);
  //   inlineImages = inlineImages.filter(inlineImage => src.startsWith('cid'));
  //   for(let i=0;i<inlineImages.length;i++){
  //     let blob = this.b64toBlob(yh\\inlineImages[i]);
  //     let url = URL.createObjectURL(blob);
  //     // let newImageTag = imgTag.replace(/src=".*?"/,`src="${url}"`);
  //     // this.workItemDetailOutput.content = this.workItemDetailOutput.content.replace(imgTag,newImageTag);
  //   }

  //  }


    // b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    
    //     // decode a base64 encode string
    //     const byteCharacters = atob(b64Data);
    //     const byteArrays = [];
        
    //     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    //       const slice = byteCharacters.slice(offset, offset + sliceSize);
    
    //       const byteNumbers = new Array(slice.length);
    //       for (let i = 0; i < slice.length; i++) {
    //         byteNumbers[i] = slice.charCodeAt(i);
    //       }
    
    //       const byteArray = new Uint8Array(byteNumbers);
    //       byteArrays.push(byteArray);
    //     }
    
    //     const blob = new Blob(byteArrays, { type: contentType });
    //     return blob;
    //   }

   

   

}
