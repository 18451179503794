import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class LoadUserDetailsService{
    public StoredData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    currentData = this.StoredData.asObservable();
    storeddata(data: any) {
      this.StoredData.next(data)
    }
}