export class Users{
    
    public userId: number;
    public sessionToken: string;
    public userName:string;
    public userSkill:Array<number>;
    public userLocation:string;
    public userType:string;
    public emailAddress:string;
    public application:Array<number>;
    public assignmentThreshold:number;
    public extensionNumber:number;
    public loginName:string;
    public userStatus:string;
    public editUserId:number;
    public isSme:boolean;
    }