import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AgentProfileService } from '../services/AgentProfile.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { LoginService } from '../services/login.service';
import { LogoutService } from '../services/logout.service';
import { AgentProfile } from '../models/AgentProfile.model';
import { AgentProfileComponent } from '../agent-profile/agent-profile.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GetQueueListService } from '../services/GetQueueList.service';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { WorkItemsFromQueueInput } from '../models/WorkItemsFromQueueInput.model';
import { DatePipe } from '@angular/common';
import { LiveMontorService } from '../services/LiveMonitor.service';
import { WorkitemsFromQueueOutput } from '../models/WorkitemsFromQueueOutput.model';
import { ConfigureUserService } from '../services/configureUser.service';
import { ReassignWorkitemComponent } from './reassign-workitem/reassign-workitem.component';
import { EmailWorkItemDetailService } from '../services/emailworkitemdetail';
import { WorkitemItemDetailInput } from '../models/workitemDetailinput.model';
import { WorkitemItemDetailOutput } from '../models/workitemDetailOutput.model';
import { CategoryAndSubCategoryService } from '../services/categoryandsubcategory.service';
import { WorkItemCategory } from '../models/workitemcategory.model';
import { IVRWorkItemDetailService } from '../services/ivrworkItemDetail.service';
import { ChatWorkItemDetailService } from '../services/GetChatWorkitemDetails.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { CrmUser } from '../models/crmuser.model';
import { UserTypeEnum } from '../UserTypeEnum';
import { EmailreplyComponent } from '../emailreply/emailreply.component';
import { SearchHistoryService } from '../services/SearchHistoryc.service';
import { AgentAvailability } from '../AgentAvailability.enum';
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
import { UpdateAgentStatusService } from '../services/updateAgentStatus.service';
import { PropertiesList } from '../services/PropertiesList.service';
import { WorkitemSubPropertiesModel } from '../models/WorktiemSubProperties.model';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
import { WhatsappWorkitemService } from 'src/app/services/WhatsappWorkitem.service';
import { WhatsappWorkitemDetails } from 'src/app/models/WhatsappWorkitemDetails.model';

@Component({
  selector: 'app-live-monitor',
  templateUrl: './live-monitor.component.html',
  styleUrls: ['./live-monitor.component.css'],
  providers:[DatePipe],
  encapsulation: ViewEncapsulation.None
})
export class LiveMonitorComponent implements OnInit {

  token:string;
  id:number;
  agentProfile:AgentProfile;
  dropdownSettings:IDropdownSettings = {};
  dropdownSettingsForStatus:IDropdownSettings = {};
  dropdownListForQueues = [];
  dropdownListForStatus = 
  [{item_id:1,item_text:'PAUSED'},
  {item_id:2,item_text:'REOPEN'},
  {item_id:3,item_text:'NEW'},
  {item_id:4,item_text:'INPROGRESS'},
  {item_id:5,item_text:'ASSIGNED'},
  {item_id:6,item_text:'PENDING_VERIFICATION'},
  {item_id:7,item_text:'AUTOPAUSED'},
  {item_id:8,item_text:'COMPLETED'},];
  selectedItemsForQueue = [];
  selectedItemsForStatus =  ["PAUSED", "REOPEN", "NEW", "INPROGRESS", "ASSIGNED", "PENDING_VERIFICATION", "AUTOPAUSED", "COMPLETED"];
  liveMonitorForm:FormGroup;
  submitted:boolean;
  isValidDate:boolean;
  workitemsFromQueueInput:WorkItemsFromQueueInput;
  parts:any;
  workitemsFromQueueOutput:WorkitemsFromQueueOutput;
  workitemsFromQueueOutputArray=[];
  completedCount:number = 0;
  inProgressCount:number = 0;
  showWorkitems:boolean =false;
  textareaValue:string = '';
  workitemsFromQueueOutputArrayForDisplay = [];
  workitemItemDetailInput:WorkitemItemDetailInput;
  workItemDetailOutput: Array<any> = [];
  userList:any[];
  workitemCategory:Array<WorkItemCategory> = new Array<WorkItemCategory>();
  loading:boolean = false;
  maxDate=new Date();
  crmUser:CrmUser;
  userTypeCheck:boolean;
  isSME:boolean = false;
  workitemProperties = [];
  dialogRef: MatDialogRef<EmailreplyComponent> | null;
  agentAvailabilityCheck:string = AgentAvailability.ONLINE;
  updateAgentStatusModel:UpdateAgentStatusModel;
  workitemSubPropertiesArray:Array<WorkitemSubPropertiesModel> = [];
  workitemSubProperties = [];
  selectedSubProperties = [];
  selectedItems:Array<any>=[];
  whatsappMessagesList:Array<any> = new Array<any>();

  whatsappWorkitemDetails:WhatsappWorkitemDetails;

  constructor(
    private searchHistoryService:SearchHistoryService,
    private router:Router,
    private adminProfileService:AgentProfileService,
    private loginService:LoginService,
    private dialog:MatDialog,
    private logoutService:LogoutService,
    private getQueueListService:GetQueueListService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private liveMontorService:LiveMontorService,
    private configureUserService:ConfigureUserService,
    private emailWorkItemDetailService:EmailWorkItemDetailService,
    private categoryandsubcategoryService:CategoryAndSubCategoryService,
    private ivrWorkItemDetailService: IVRWorkItemDetailService,
    private chatWorkItemDetailService:ChatWorkItemDetailService,
    private updateAgentStatusService:UpdateAgentStatusService,
    private agentProfileService:AgentProfileService,
    private propertiesList:PropertiesList,
    private whatsappWorkitemService:WhatsappWorkitemService
  ) {


    this.getQueueListService.currentData.subscribe(
      data=>{
        
       if(data!=null){

       this.dropdownListForQueues = data;
       
       }
       else if(data.body['responseType']== 'SESSION_TIMEOUT'){

         alert("Session timeout!Please Login Again");
         this.loginService.storeddata(null);
         this.router.navigate(['']);
       }
      }
    );
   }

  ngOnInit() {
    window.scrollTo(0,0);
    this.submitted = false;
    this.isValidDate = false;
    this.workitemsFromQueueInput = new WorkItemsFromQueueInput();
    this.workitemsFromQueueOutput = new WorkitemsFromQueueOutput();
    this.workitemItemDetailInput = new WorkitemItemDetailInput();
    this.whatsappWorkitemDetails = new WhatsappWorkitemDetails();
    this.workitemCategory = new Array<WorkItemCategory>();
    this.agentProfile = new AgentProfile();
    this.crmUser = new CrmUser();
    this.updateAgentStatusModel = new UpdateAgentStatusModel();

    this.searchHistoryService.currentData.subscribe((data)=>{
      
      if(!(data===null)){
        this.agentAvailabilityCheck=data;
      }

    });

    this.loginService.currentData.subscribe(data=>{
      if(data==null || data.sessionToken == null){
        this.router.navigate(['']);
      }
      else{
        
       this.id=data.crmUserId;
       this.token=data.sessionToken;
       this.crmUser = data;
       this.isSME = data.sme;

      }
      }
    );


    // this.getQueueListService.getQueueList(this.id, this.token).subscribe(
    //   data=>{
    //     
    //    if(data.status==200 && data.body["responseType"]=="SUCCESS"){

    //    this.dropdownListForQueues = data.body['queueList'];
       
    //    }
    //    else if(data.body['responseType']== 'SESSION_TIMEOUT'){

    //      alert("Session timeout!Please Login Again");
    //      this.loginService.storeddata(null);
    //      this.router.navigate(['']);
    //    }
    //   }
    // );

    this.categoryandsubcategoryService.currentData.subscribe((data)=>{
      
      this.workitemCategory=data;

    });

    this.propertiesList.currentData.subscribe(data=>{
      
      this.workitemProperties = data;

    });
    this.configureUserService.getUserList(this.id,this.token).subscribe(data=>

      {
        
         this.userList= data.body['users'];

      });


    this.dropdownSettings = {
      maxHeight:100,
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit:3,

    };

    this.dropdownSettingsForStatus = {
      maxHeight:100,
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      allowSearchFilter: true,
      itemsShowLimit:3,
    };
    this.setFormControls();
    
  }

  setFormControls(){

    this.liveMonitorForm = this.formBuilder.group({
      queue: new FormControl(this.dropdownListForQueues,[
        Validators.required
      ]),
      startDate: new FormControl(new Date(),[
        Validators.required
      ]),
      endDate: new FormControl(new Date(),[
        Validators.required
      ]),
      status: new FormControl( [{item_id:1,item_text:'PAUSED'},
      {item_id:2,item_text:'REOPEN'},
      {item_id:3,item_text:'NEW'},
      {item_id:4,item_text:'INPROGRESS'},
      {item_id:5,item_text:'ASSIGNED'},
      {item_id:6,item_text:'PENDING_VERIFICATION'},
      {item_id:7,item_text:'AUTOPAUSED'},
      {item_id:8,item_text:'COMPLETED'},],[
        Validators.required
      ]),
     

    });
  }

  get f() {
    return this.liveMonitorForm.controls;
  }

  onChangeProperty(){
    
    if(this.liveMonitorForm.value.propertyType>0){
      var list = this.workitemProperties.filter(x => x.id == (this.liveMonitorForm.value.propertyType));
      var temp=list[0];
      this.workitemSubPropertiesArray=(temp['properties']);


    }
    else{
      this.workitemSubProperties = null;
      this.workitemSubPropertiesArray = [];

      this.selectedSubProperties = [];
    }
    this.selectedItems = [];
  }

  

 
 

  

  onItemSelect(item: any) {
 


  }

  onItemSelectForStatus(item: any) {
    
   this.selectedItemsForStatus.push(item);

  }


  onItemUnSelect(item:any){
    // 
    // for(let i= 0;i<this.selectedItemsForQueue.length;i++){
    //   if(this.selectedItemsForQueue[i].id==item.id){
    //     for(let j = i;j<(this.selectedItemsForQueue.length-1);j++){
    //       this.selectedItemsForQueue[j] = this.selectedItemsForQueue[j+1];
    //     }
    //     this.selectedItemsForQueue.pop();
    //     break;
    //   }
    // }


  }

  onItemUnSelectForStatus(item:any){
    
    for(let i= 0;i<this.selectedItemsForStatus.length;i++){
      // if(this.selectedItemsForStatus[i].id==item.id){
      //   for(let j = i;j<(this.selectedItemsForStatus.length-1);j++){
      //     this.selectedItemsForStatus[j] = this.selectedItemsForStatus[j+1];
      //   }
       
      // }
      if(this.selectedItemsForStatus[i]==item){
        for(let j = i;j<(this.selectedItemsForStatus.length-1);j++){
          this.selectedItemsForStatus[j] = this.selectedItemsForStatus[j+1];
        }
        this.selectedItemsForStatus.pop();
        break;
      }
    }


  }

  

  onSelectAll(items: any) {
    // 
    // this.selectedItemsForQueue = [];
    // for(let i=0;i<items.length;i++){
    //   this.selectedItemsForQueue.push(items[i]);
    // }
  }

  onSelectAllForStatus(items: any) {
    
    this.selectedItemsForStatus = [];
    for(let i=0;i<items.length;i++){
      this.selectedItemsForStatus.push(items[i]);
    }
  }

  

  onDeSelectAll(items:any){
    // 
    // this.selectedItemsForQueue = [];
  }

  onDeSelectAllForStatus(items:any){
    
    this.selectedItemsForStatus = [];
  }
  num1:number;


  onSubmit(){
    
    // this.loading = true;
    this.submitted = true;
    this.workitemsFromQueueInput.crmUserId = this.id;
    this.workitemsFromQueueInput.queueIds = [];
    for(let i=0;i<this.liveMonitorForm.value.queue.length;i++){
      this.workitemsFromQueueInput.queueIds[i] = this.liveMonitorForm.value.queue[i].id;

    }
    this.workitemsFromQueueInput.sessionToken = this.token;
    this.workitemsFromQueueInput.startDate=this.datePipe.transform(this.liveMonitorForm.value.startDate,"dd-MM-yy");
    this.workitemsFromQueueInput.endDate=this.datePipe.transform(this.liveMonitorForm.value.endDate,"dd-MM-yy");
    this.workitemsFromQueueInput.workItemStatusList = [];
    for(let i=0;i<this.liveMonitorForm.value.status.length;i++){
      this.workitemsFromQueueInput.workItemStatusList.push(this.liveMonitorForm.value.status[i].item_text);  
    }
    //this.workitemsFromQueueInput.workItemStatusList = this.liveMonitorForm.value.status;
if(this.submitted && this.liveMonitorForm.valid){
    this.isValidDate = this.validateDates(this.process(this.workitemsFromQueueInput.startDate), this.process(this.workitemsFromQueueInput.endDate));
    
    if(this.isValidDate  ) {
      
      this.loading = true;
      this.liveMontorService.getWorkitemsFromQueues(this.workitemsFromQueueInput).subscribe(data=>{
        

        if(data.status==200 && data.body["responseType"]=="SUCCESS"){
          this.num1 = 0
          this.completedCount = 0;
            this.inProgressCount = 0;
          
          this.showWorkitems = true;
          this.workitemsFromQueueOutputArray = data.body['iqdWorkItemInfoVOList'];
          this.workitemsFromQueueOutputArrayForDisplay = this.workitemsFromQueueOutputArray;
          for(let i=0;i<this.workitemsFromQueueOutputArrayForDisplay.length;i++){
            if(this.workitemsFromQueueOutputArrayForDisplay[i].workItemStatus=="COMPLETED"){
              this.completedCount++;
            }
            else {
              this.inProgressCount++;
            }
          }
          this.loading = false;

        }
        else{
          alert("Something went wrong");
          this.loading = false;

        }
    

      });

    }
  }
    else{
      this.loading = false;
    }
  }

  //  resetForm(formGroup: FormGroup) {
  //   let control: AbstractControl = null;
  //   this.selectedItemsForQueue = [];
  //   this.selectedItemsForStatus = [];
  //   formGroup.reset();
  //   formGroup.markAsUntouched();
  //   Object.keys(formGroup.controls).forEach((name) => {
  //     control = formGroup.controls[name];
  //     control.setErrors(null);
  //   });
  // }

  validateDates(sDate: Date, eDate: Date){
    this.isValidDate = true;


    if((eDate) < (sDate)){
     // this.error={isError:true,errorMessage:'Start date should be less than End date.'};
     alert("Start date should be less than End date.");
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  process(date:string){
    this.parts = date.split("-");
   return new Date(this.parts[2], this.parts[1] - 1, this.parts[0]);
  }

  onSearchClick(){
    
    let searchValue = parseInt(this.textareaValue);
    let index = 0;
    for(let i=0;i<this.workitemsFromQueueOutputArray.length;i++){
      if(searchValue==this.workitemsFromQueueOutputArray[i].workItemId){
        this.workitemsFromQueueOutputArrayForDisplay = [];
        this.workitemsFromQueueOutputArrayForDisplay[index] = this.workitemsFromQueueOutputArray[i];
        break;

      }
    }

  }

  reassignWorkitem(workitemFromQueue){
    
    this.workitemItemDetailInput.crmUserId = this.id;
    this.workitemItemDetailInput.sessionToken = this.token;
    this.workitemItemDetailInput.workItemId = workitemFromQueue.workItemId;

    if(workitemFromQueue.workItemType=="MANUAL"){
      this.emailWorkItemDetailService.getManualWorkitemDetails(this.workitemItemDetailInput).subscribe(data=>{
        
        if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
          
          this.workItemDetailOutput=data.body['manualWorkItem'];
          this.openWorkitemForReassignment(workitemFromQueue,this.workItemDetailOutput);
        }

        else if(data.body['responseType']== 'SESSION_TIMEOUT'){

          alert("Session timeout!Please Login Again");
          this.loginService.storeddata(null);
          this.router.navigate(['']);
        }
        else{
          alert("Something went wrong");
        }

      });
    }


    if(workitemFromQueue.workItemType=="EMAIL"){
    this.emailWorkItemDetailService.getEmailWorkitemStateChange(this.workitemItemDetailInput).subscribe(data=>{
      
      if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
        
        this.workItemDetailOutput=data.body['emailWorkItem'];
        this.openWorkitemForReassignment(workitemFromQueue,this.workItemDetailOutput);
      }

      else if(data.body['responseType']== 'SESSION_TIMEOUT'){

        alert("Session timeout!Please Login Again");
        this.loginService.storeddata(null);
        this.router.navigate(['']);
      }
      else{
        alert("Something went wrong");
      }

    });
  }
  if(workitemFromQueue.workItemType=="WHATSAPP"){
  this.whatsappWorkitemService.getWhatsappWorkitemDetails(this.workitemItemDetailInput).subscribe(data=>{
    if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
      this.whatsappWorkitemDetails = data.body["whatsAppConversationWorkItem"];
      this.whatsappMessagesList = data.body["whatsAppWorkConversationHistoryList"];
      this.openWorkitemForReassignment(workitemFromQueue,this.whatsappWorkitemDetails);
    }
  });
  }

  else if(workitemFromQueue.workItemType=="IVR"){
    this.ivrWorkItemDetailService.getIVRWorkitemStateChange(this.workitemItemDetailInput).subscribe(data=>{
      
      if(data.status==200 && data.body["responseType"]=="SUCCESS"){
        
        this.workItemDetailOutput = data.body['ivrWorkItem'];
        this.openWorkitemForReassignment(workitemFromQueue,this.workItemDetailOutput);
      }

      else if(data.body['responseType']== 'SESSION_TIMEOUT'){

        alert("Session timeout!Please Login Again");
        this.loginService.storeddata(null);
        this.router.navigate(['']);
      }

      else{
        alert("Something went wrong");
      }
    });

  }
  else if(workitemFromQueue.workItemType=="CHAT"){
    this.chatWorkItemDetailService.getChatWorkItem(this.workitemItemDetailInput).subscribe(
      data=>{
        
      if(data.status==200 && data.body["responseType"]=="SUCCESS"){
        
        this.workItemDetailOutput = data.body['liveChatWorkItem'];
        this.openWorkitemForReassignment(workitemFromQueue,this.workItemDetailOutput);
      }

      else if(data.body['responseType']== 'SESSION_TIMEOUT'){

        alert("Session timeout!Please Login Again");
        this.loginService.storeddata(null);
        this.router.navigate(['']);
      }

      else{
        alert("Something went wrong");
      }
      }
    );

  }
  }

  openWorkitemForReassignment(workitemFromQueue,workItemDetailOutputt){
    const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = "95%";
        dialogConfig.width = "750px";
        dialogConfig.data = {
          usersList:this.userList,
          workitemFromQueue:workitemFromQueue,
          id:this.id,
          token:this.token,
          workitemCategory:this.workitemCategory,
          workitemDetailOutput:workItemDetailOutputt,
          whatsappMessagesList:this.whatsappMessagesList
        };
         this.dialog.open(ReassignWorkitemComponent,dialogConfig).afterClosed().subscribe(data=>{
          this.liveMontorService.getWorkitemsFromQueues(this.workitemsFromQueueInput).subscribe(data=>{

            

            if(data.status==200 && data.body["responseType"]=="SUCCESS"){

              
              this.completedCount = 0;
              this.inProgressCount = 0;
              this.showWorkitems = true;
              this.workitemsFromQueueOutputArray = data.body['iqdWorkItemInfoVOList'];
              this.workitemsFromQueueOutputArrayForDisplay = this.workitemsFromQueueOutputArray;
              for(let i=0;i<this.workitemsFromQueueOutputArrayForDisplay.length;i++){
                if(this.workitemsFromQueueOutputArrayForDisplay[i].workItemStatus=="COMPLETED"){
                  this.completedCount++;
                }
                else {
                  this.inProgressCount++;
                }
              }

            }
            else if(data.body['responseType']== 'SESSION_TIMEOUT'){

              alert("Session timeout!Please Login Again");
              this.loginService.storeddata(null);
              this.router.navigate(['']);
            }
            else{
              alert("Something went wrong");
            }
          });
         });

    }
    changePassword(){
      
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
          sessionToken:this.token,
          userId:this.id
        };
      this.dialog.open(ChangePasswordComponent,dialogConfig);
    }

    newMail()
    {

      const dialogConfig = new MatDialogConfig();


         dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
        dialogConfig.data={
          crmUserId: this.id,
        sessionToken: this.token,
        };
        dialogConfig.height="80%";

        this.dialogRef = this.dialog.open(EmailreplyComponent,dialogConfig);



    }


    onUpdateAgentAvailability(agentAvailability){
      
      this.updateAgentStatusModel.agentAvailability = agentAvailability;
      this.updateAgentStatusModel.crmUserId = this.crmUser.crmUserId;
      this.updateAgentStatusModel.sessionToken = this.crmUser.sessionToken;
        this.updateAgentStatusService.updateAgentStatus(this.updateAgentStatusModel).
        subscribe(data=>{

          if(data.status==200 && data.body["responseType"]=="SUCCESS"){
            
            if(agentAvailability==AgentAvailability.ONLINE){
              this.agentAvailabilityCheck = AgentAvailability.ONLINE;
            }
            else if(agentAvailability==AgentAvailability.AWAY){
              this.agentAvailabilityCheck = AgentAvailability.AWAY;
            }
            else if(agentAvailability==AgentAvailability.OFFLINE){
              this.agentAvailabilityCheck =AgentAvailability.OFFLINE;
            }
          }
                   });
    }


    openAgentProfile(){

      
        this.agentProfileService.getAgentDetails(this.crmUser.crmUserId, this.crmUser.sessionToken).subscribe(
          data=>{
            


            if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
              this.agentProfile.name = data.body['name'];
              this.agentProfile.emailId = data.body['emailAddress'];
              this.agentProfile.skills = data.body['skills'];
              this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              dialogConfig.height = "auto";
              dialogConfig.width = "600px";
              dialogConfig.data = {
              agentProfile: this.agentProfile
              };
               this.dialog.open(AgentProfileComponent,dialogConfig);

            }
            else if(data.body['responseType']== 'SESSION_TIMEOUT'){

              alert("Session timeout!Please Login Again");
              this.loginService.storeddata(null);
              this.router.navigate(['']);
                                                          }
          }
        );


      }

      reminder(){
        
         
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
          crmUserId: this.id,
          sessionToken: this.token,
          detailedWorkitem:null,
          isServed:false
        };
        this.dialog.open(ReminderConfigurationComponent,dialogConfig);
       }


}
