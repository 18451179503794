import { Component, OnInit,Inject } from '@angular/core';
import {ActivatedRoute,Router,NavigationExtras} from '@angular/router';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material';
import {MatDialog,MatDialogConfig} from '@angular/material';
import { FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import { Customer } from '../models/customer.model';
import { AddCustomerService } from '../services/addCustomer.service';
import {EditCustomerComponent} from '../editCustomer/editCustomer.component';
import { CustomerHistoryService } from '../services/CustomerHistory.service';
import { CustomerHistoryInput } from '../models/CustomerHistoryInput.model';
import { CustomerHistoryOutput } from '../models/CustomerHistoryOutput.model';
import { CustomerDetails } from '../models/CustomerDetails.model';
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
import { UpdateAgentStatusService } from '../services/updateAgentStatus.service';
import { CrmUser } from '../models/crmuser.model';
import {LogoutService} from '../services/logout.service';
import {CutomerEnagagementHistoryComponent} from '../cutomer-enagagement-history/cutomer-enagagement-history.component';
import {ReportsService} from '../services/reports.service';
import{EmailreplyComponent} from '../emailreply/emailreply.component';
import {LoginService} from '../services/login.service';
import { AgentProfileService } from '../services/AgentProfile.service';
import { AgentProfile } from '../models/AgentProfile.model';
import { AgentProfileComponent } from '../agent-profile/agent-profile.component';
import { AgentAvailability } from '../AgentAvailability.enum';
import { BehaviorSubject } from 'rxjs';
import { SearchHistoryService } from '../services/SearchHistoryc.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { WorkitemItemDetailOutput } from '../models/workitemDetailOutput.model';
import { WorkItemCategory } from '../models/workitemcategory.model';
import { WorkItemSubCategory } from '../models/workitemsubcategory.model';
import { CategoryAndSubCategoryService } from '../services/categoryandsubcategory.service';
import { DatePipe } from '@angular/common';
import { Attachment } from '../models/attachment.model';
import { UploadAttachmentComponent } from '../uploadAttachment/uploadAttachment.component';
import { UploadRequest } from '../UploadRequest.enum';
import { EmailReplyService } from '../services/emailReply.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CreateWorkitem } from '../models/CreateWorkitem.model';
import { CreateManualWorkitemService } from '../services/CreateManualWorkitem.service';
@Component({
  selector: 'app-searchHistory',
  templateUrl: './searchHistory.component.html',
  styleUrls: ['./searchHistory.component.css'],

})
export class SearchHistoryComponent  implements OnInit{
  public searchName:string;
  public searchEmail:string;
  public searchFacebookId:string;
  public searchTwitterId:string;
  public searchphoneNo:number;
  customer:Customer;
  agentProfile:AgentProfile;
  showSearchResults:boolean=false;
  searchCustomer:Array<Customer>;
  private token:string;
  submitted = false;
  crmUser:CrmUser;
  isShowMessage:boolean=false;
  showAddCustomer:boolean=false;
  private userId:number;
  searchHistoryForm:FormGroup;
  sender:string='';
  caller:number;
  workItemType:string;
  facebookId:string;
  twitterId:string;
  updateAgentStatusModel:UpdateAgentStatusModel;
  customerHistoryInput:CustomerHistoryInput;
  customerDetails:CustomerDetails;
  dialogRef: MatDialogRef<EmailreplyComponent> | null;
  customerHistoryOutput:CustomerHistoryOutput;
  agentAvailabilityCheck:string= AgentAvailability.ONLINE;


  selectedCategory:number;
  selectedSubCategory:number;
  workItemDetailOutput: WorkitemItemDetailOutput;
  workitemCategory:Array<WorkItemCategory>=new Array<WorkItemCategory>();
  workitemSubCategory:Array<WorkItemSubCategory>=new Array<WorkItemSubCategory>();
  createWorkitem:CreateWorkitem;

  PriorityList : {
    id:number,
    value:String
  };
  priorityListValues = ["Select Priority","LOW","MEDIUM","HIGH"];
  propertyList = ["Property 1","Property 2","Property 3","Property 4","Property 5"];

  maxDate=new Date();
  file:File;
  files:File[]=[];
  deleteAttachment:Attachment;
  randomNumber:any;
  emailItemId:number;
  dropdownSettings:IDropdownSettings = {};
  categoryNotSelected:boolean = false;

  public StoredData: BehaviorSubject <string>= new BehaviorSubject(this.agentAvailabilityCheck);
  currentData = this.StoredData.asObservable();
  storeddata(data: any) {
    this.StoredData.next(data);
    console.log("current check is "+this.agentAvailabilityCheck);
  }
    constructor(
      private searchHistoryService:SearchHistoryService,
      private agentProfileService:AgentProfileService,
      private formBuilder: FormBuilder,
      private form:FormBuilder,
        private addCustomerService:AddCustomerService,
        //private dialogRef: MatDialogRef<AddCustomerComponent>,
        private dialog:MatDialog,private route:ActivatedRoute,
        private customerHistoryService:CustomerHistoryService,private router:Router,
        private updateAgentStatusService:UpdateAgentStatusService,
        private logoutService:LogoutService,
        private reportService:ReportsService,
        private loginService:LoginService,
        private categoryandsubcategoryService:CategoryAndSubCategoryService,
        private datePipe: DatePipe,
        private emailReplyService:EmailReplyService,
        private createManualWorkitemService:CreateManualWorkitemService
        ){

          this.datePipe.transform(this.maxDate, 'mm/dd/yyyy');

          let range = 100000;
          this.randomNumber = Math.floor(Math.random()*Math.floor(range));


       // @Inject(MAT_DIALOG_DATA) data) {
          this.searchHistoryForm = this.formBuilder.group({
            searchName: new FormControl('', [
              Validators.minLength(3),
              Validators.maxLength(30),
              Validators.pattern('^[a-zA-Z ]*$')]),
              searchphoneNo: new FormControl('', [
              Validators.minLength(8),
              Validators.maxLength(12),
              Validators.pattern('^[0-9]*$')]),
            searchEmail: new FormControl('', [
              Validators.minLength(5),
              Validators.maxLength(80),
              Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
            ]),
            searchFacebookId: new FormControl('', [
              Validators.minLength(5),
              Validators.maxLength(80),
              Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
            ]),
            searchTwitterId: new FormControl('', [
              Validators.minLength(5),
              Validators.maxLength(80),
              Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
            ]),

            // name:new FormControl('',[
            //   Validators.required
            // ]),
            // category:new FormControl(0,[
            //   Validators.required
            // ]),
            // subCategory:new FormControl(0,[
            // ]),
            // priority:new FormControl('',[
            //   Validators.required
            // ]),
            // browse:new FormControl('',[
            // ]),
            // description:new FormControl('',[
            //   Validators.required
            // ]),
            // property:new FormControl('',[
            //   Validators.required
            // ]),
            // dueDate:new FormControl('',[
            //   Validators.required
            // ])
          });


    }






         ngOnInit() {
            

            this.selectedCategory = 0;
            this.selectedSubCategory = 0;
            this.updateAgentStatusModel = new UpdateAgentStatusModel();
            this.agentProfile = new AgentProfile();

            this.createWorkitem = new CreateWorkitem();

            this.searchHistoryService.currentData.subscribe((data)=>{
              
              this.agentAvailabilityCheck=data;

            });

            this.dropdownSettings = {
              maxHeight:100,
              singleSelection: false,
              idField: 'id',
              textField: 'name',
              selectAllText: 'Select All',
              unSelectAllText: 'UnSelect All',
              allowSearchFilter: true,
              limitSelection:1,
              closeDropDownOnSelection:true
            };

          this.loginService.currentData.subscribe((data) => {
            this.crmUser = data;
           if(this.crmUser==null || this.crmUser.sessionToken == null){
               this.router.navigate(['']);
             }
             else{
               this.userId=this.crmUser.crmUserId;
               this.token=this.crmUser.sessionToken;
             }
        });
        this.customer=new Customer();
        this.customerHistoryInput = new CustomerHistoryInput();
        this.customerHistoryOutput = new CustomerHistoryOutput();
        this.customerDetails = new CustomerDetails();

        this.categoryandsubcategoryService.currentData.subscribe((data)=>{
          
          this.workitemCategory=data;
          console.log(this.workitemCategory);
        })

        // for(let i=0;i<this.priorityListValues.length;i++){
        //   this.PriorityList.id=i+1;
        //   this.PriorityList.value = this.priorityListValues[i];
        // }
    }

    get f() {return this.searchHistoryForm.controls; }


  getSelectedCategoryAndSubCategoryForEmail(){
     

     this.selectedCategory=this.workItemDetailOutput.workItemCategory['id'];

    var list = this.workitemCategory.filter(x => x.id == this.selectedCategory);

    var temp=list[0];
    if(temp!=undefined){
      this.workitemSubCategory=(temp['subCategories']);
      this.selectedSubCategory=this.workItemDetailOutput.workItemSubCategory['id'];
    }
    else{
      this.workitemSubCategory=[];
      this.selectedSubCategory = 0;
    }
                                    //  this.selectedSubCategory=0;

}


onChangeCategory(){
  
 if(this.searchHistoryForm.value.category>0){
  var list = this.workitemCategory.filter(x => x.id == this.searchHistoryForm.value.category);

var temp=list[0];
this.workitemSubCategory=(temp['subCategories']);


  }else{

    this.workitemSubCategory=null;
    this.selectedSubCategory=0;
  }

}

    get searchHistory(){return this.searchHistoryForm.controls};


    browseAndUpload(){

    }

    onUpdateAgentAvailability(agentAvailability){
      
      this.updateAgentStatusModel.agentAvailability = agentAvailability;
      this.updateAgentStatusModel.crmUserId = this.crmUser.crmUserId;
      this.updateAgentStatusModel.sessionToken = this.crmUser.sessionToken;
        this.updateAgentStatusService.updateAgentStatus(this.updateAgentStatusModel).
        subscribe(data=>{
          console.log(data);
          if(data.status==200 && data.body["responseType"]=="SUCCESS"){
            
            if(agentAvailability==AgentAvailability.ONLINE){
              this.agentAvailabilityCheck = AgentAvailability.ONLINE;
            }
            else if(agentAvailability==AgentAvailability.AWAY){
              this.agentAvailabilityCheck = AgentAvailability.AWAY;
              // this.pauseTimer();
            }
            else if(agentAvailability==AgentAvailability.OFFLINE){
              this.agentAvailabilityCheck =AgentAvailability.OFFLINE;
              // this.pauseTimer();
            }
            // this.pauseTimer();
          }
                   })
    }
    onHistorySearch(){
        
        this.submitted = true;
        if(this.searchHistoryForm.valid){
          if(this.searchName==undefined && this.searchEmail== undefined &&this.searchFacebookId==undefined && this.searchTwitterId==undefined && this.searchphoneNo==undefined)
          {
            alert("Please enter atleast one field!!")
            return this.searchHistory.searchName.errors;

          }
          console.log("valid");
           this.customer.customerName= this.searchName;
            this.customer.customerEmailAddress=this.searchEmail;
           this.customer.customerFacebookId=this.searchFacebookId;
           this.customer.customerTwitterId=this.searchTwitterId;
            this.customer.customerContactNumber=this.searchphoneNo;
          this.customer.sessionToken=this.token;
          this.customer.crmUserId=this.userId;
           this.addCustomerService.searchCustomer(this.customer)
           .subscribe(
           data=>{
         
         if(data.status==200){
           console.log(data);
           if(data.body["searchResults"]!=null && data.body["searchResults"]!='')
           {
           this.searchCustomer =data.body["searchResults"];
           for(let i=0;i<this.searchCustomer.length;i++){
             if( this.searchCustomer[i].customerContactNumber!=null){
               let numberInString = this.searchCustomer[i].customerContactNumber.toString();
               let arrayFromString  = Array.from(numberInString);
                for(let j=0;j<arrayFromString.length-2;j++){
                  arrayFromString[j] = 'x';
                }

                numberInString = arrayFromString.join("");
                this.searchCustomer[i].numberForScreen = numberInString;  
             }
           }
           this.showSearchResults=true;
           this.showAddCustomer=false;
         }
         else{
           this.searchCustomer = null;
          alert("No result found!!");
          
         }
             }});
            }
    }
    onAdddCust(){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        ///dialogConfig.width="50%";
      //   dialogConfig.position = {
      //     'top': '130',
      //     left: '130'
      // };
      dialogConfig.data = {
        id: this.userId,
        token:  this.token
    };
       // this.dialog.open(AddCustomerComponent,dialogConfig);
       if(this.sender!=null && this.sender!='')
       {
       window.open('/addCustomer?id='+this.userId+'&token='+this.token+'&sender='+this.sender+'&wiType='+this.workItemType, '_blank', 'location=yes,height=570,width=900,scrollbars=yes,status=yes');
    }
  else if(this.caller!=null)
  {
    window.open('/addCustomer?id='+this.userId+'&token='+this.token+'&caller='+this.caller+'&wiType='+this.workItemType, '_blank', 'location=yes,height=570,width=900,scrollbars=yes,status=yes');
  }
  }


  // openHistory(customerId){
  
//       
//       this.customerHistoryInput.crmUserId = this.crmUser.crmUserId;
//       this.customerHistoryInput.sessionToken = this.crmUser.sessionToken;
//       this.customerHistoryInput.customerId = customerId;
//       if(this.customerHistoryInput.customerId!=null && this.customerHistoryInput.customerId>0){
//         this.customerHistoryService.getCustomerHistory(this.customerHistoryInput).
//         subscribe(data=>{
//           
//           if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
//             console.log(data);
//             this.customerDetails = data.body;
//             const dialogConfig = new MatDialogConfig();
//             dialogConfig.disableClose = true;
//             dialogConfig.autoFocus = true;
//             dialogConfig.data = {
//               customerDetails: this.customerDetails
//             };
//             this.dialog.open(CutomerEnagagementHistoryComponent,dialogConfig);
//           }
  
  
//         });
//       }
//       else {
//         alert("No history available");
//       }
//     }


  openHistory(customer:Customer){
    

    this.customerHistoryInput.crmUserId = this.userId;
    this.customerHistoryInput.sessionToken = this.token;
    this.customerHistoryInput.customerId = customer.customerId;
    this.customerHistoryService.getCustomerHistory(this.customerHistoryInput).
    subscribe(data=>{
      
      console.log(data);
            if(data.status==200){
                this.customerDetails = data.body;
               // this.customerHistoryService.storeddata(this.customerDetails);
                const dialogConfig = new MatDialogConfig();
                dialogConfig.disableClose = true;
                  dialogConfig.autoFocus = true;
                dialogConfig.data = {
                  customerDetails: this.customerDetails
              };
                this.dialog.open(CutomerEnagagementHistoryComponent,dialogConfig);
                //sessionStorage.setItem("One",JSON.stringify(this.customerDetails));
               // window.open('/cutomerEngagementHistory','_new','location=yes,height=570,width=1100,scrollbars=yes,status=yes');



            }

    });
  }
    close()
    {
     // this.dialogRef.close();
    }
    onHistoryTab(){
    this.router.navigate(["searchHistory"]);
     // window.open('/searchHistory?id='+this.id+'&token='+this.token, '_blank', 'location=yes,height=570,width=900,scrollbars=yes,status=yes');
    }
    onDashboardClick(){
      this.searchHistoryService.storeddata(this.agentAvailabilityCheck);
    this.router.navigate(["agentDashBoard"]);
    }

    onLiveMonitorClick(){
      this.searchHistoryService.storeddata(this.agentAvailabilityCheck);
      this.router.navigate(["liveMonitor"]);
    }

    logoutUser(){
      
      this.logoutService.logoutUser(this.userId, this.token).subscribe(
        data=>{

          
          console.log(data);
          if(data.status==200 ){
            this.router.navigate(['/']);
          }
        }
      )

    }
    updateCustInfo(customer:Customer){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
       // dialogConfig.width="50%";
      //   dialogConfig.position = {
      //     'top': '130',
      //     left: '130'
      // };
      dialogConfig.data = {
        id: this.userId,
        token:  this.token,
        customerToBeUpdated: customer
    };
        this.dialog.open(EditCustomerComponent,dialogConfig);


    }
    newMail()
    {

      const dialogConfig = new MatDialogConfig();


         dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
        dialogConfig.data={
          crmUserId: this.userId,
        sessionToken: this.token,
        };
        dialogConfig.height="80%";

        this.dialogRef = this.dialog.open(EmailreplyComponent,dialogConfig);



    }

    openAgentProfile(){

      
        this.agentProfileService.getAgentDetails(this.crmUser.crmUserId, this.crmUser.sessionToken).subscribe(
          data=>{
            

            console.log(data);
            if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
              this.agentProfile.name = data.body['name'];
              this.agentProfile.emailId = data.body['emailAddress'];
              this.agentProfile.skills = data.body['skills'];
              this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              dialogConfig.height = "auto";
              dialogConfig.width = "600px";
              dialogConfig.data = {
              agentProfile: this.agentProfile
              };
               this.dialog.open(AgentProfileComponent,dialogConfig);

            }
            else if(data.body['responseType']== 'SESSION_TIMEOUT'){

              alert("Session timeout!Please Login Again");
              this.loginService.storeddata(null);
              this.router.navigate(['']);
                                                          }
          }
        );


      }


      changePassword(){
        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            sessionToken:this.token,
            userId:this.userId
          };
          // dialogConfig.maxWidth='400px';
        this.dialog.open(ChangePasswordComponent,dialogConfig);
      }


      deleteFiles(file)
{
  this.deleteAttachment= new Attachment();
  this.deleteAttachment.token=this.token;
  this.deleteAttachment.userId=this.userId;
  this.deleteAttachment.fileName=file.name;
  this.deleteAttachment.emailItemId=this.randomNumber;
  this.emailReplyService.deleteAttachment(this.deleteAttachment).subscribe(data=>{
    console.log(data);
  });
this.files.splice(file,1);
}


openUploadDialog()
{
  

  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.width="300px";
  dialogConfig.height="200px";

  //   dialogConfig.position = {
  //     'top': '130',
  //     left: '130'
  // };
  dialogConfig.data = {
    id: this.userId,
    token:  this.token,
    itemId:this.emailItemId,
    requestFrom:UploadRequest.NEWMAIL,
    randomNumber:this.randomNumber
};
    this.dialog.open(UploadAttachmentComponent,dialogConfig).afterClosed().

    subscribe(files => {
      
      for (var i = 0; i < files.length; i++) {
        this.files.push(files[i]);
      }

    });
}

onSubmit(){
  
this.submitted = true;

if(this.searchHistoryForm.value.category==0){
  this.categoryNotSelected = true;
}
this.createWorkitem.crmUserId = this.userId;
  this.createWorkitem.sessionToken = this.token;
  this.createWorkitem.title = this.searchHistoryForm.value.name;
  this.createWorkitem.categoryId = this.searchHistoryForm.value.category;
  this.createWorkitem.subCategoryId = this.searchHistoryForm.value.subCategory;
  this.createWorkitem.manualWorkItemPriority = this.searchHistoryForm.value.priority;
  this.createWorkitem.propertyId = this.searchHistoryForm.value.property;
  this.createWorkitem.description = this.searchHistoryForm.value.description;
  this.createWorkitem.dueDate = this.searchHistoryForm.value.dueDate;
  if(this.searchHistoryForm.valid){
    this.createManualWorkitemService.createManualWorkitem(this.createWorkitem).subscribe(data=>{
      console.log(data);
    });
  }
}

onItemSelect(item: any) {
   
  // this.selectedItems.push(item);
   console.log("selected");

}

onItemUnSelect(item:any){
  // 
  // for(let i= 0;i<this.selectedItems.length;i++){
  //   if(this.selectedItems[i].id==item.id){
  //     for(let j = i;j<(this.selectedItems.length-1);j++){
  //       this.selectedItems[j] = this.selectedItems[j+1];
  //     }
  //     this.selectedItems.pop();
  //     break;
  //   }
 // }

  //console.log("unselected")
}
onSelectAll(items: any) {
  // 
  // this.selectedItems = [];
  // for(let i=0;i<items.length;i++){
  //   this.selectedItems.push(items[i]);
  // }
}

onDeSelectAll(items:any){
  // 
  // this.selectedItems = [];
}



}
