export class ReassignWorkitemInputModel{
    public crmUserId:number;
    public existingCrmUserId:number;
    public newCrmUserId:number;
    public newWorkItemStatus:string;
    public previousWorkItemStatus:string;
    public queueId:number;
    public reassignmentComment:string;
    public reassignmentType:string;
    public sessionToken:string;
    public workItemId:number;
}