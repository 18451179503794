import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { ShowDashboard } from '../models/showDashboard.model';
import { WorkitemDetails } from '../models/workitemDetails.model';
@Injectable()
export class ShowDashboardService{

public StoredData: BehaviorSubject<WorkitemDetails[]> = new BehaviorSubject<WorkitemDetails[]>([]);  
currentData = this.StoredData.asObservable();
  storeddata(data: any) {
    this.StoredData.next(data)
  }
  private Url=environment.baseUrl+'loadAgentDashboard';
constructor(private http:HttpClient){
    
}
getWorkitemDetails(showDashboard:ShowDashboard){

     return this.http.post<WorkitemDetails[]>(this.Url, showDashboard, {observe: 'response'})
   
}  

}
