import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { WhatsappWorkitemService } from 'src/app/services/WhatsappWorkitem.service';

@Component({
  selector: 'app-whatsapp-chat-history',
  templateUrl: './whatsapp-chat-history.component.html',
  styleUrls: ['./whatsapp-chat-history.component.css']
})
export class WhatsappChatHistoryComponent implements OnInit {

  token:string;
  id:number;
  conversationHistoryArray:Array<any> = new Array<any>();
  inputMessageForWhatsapp:string = "";
  constructor(
    private dialogRef: MatDialogRef<WhatsappChatHistoryComponent>,
    private dialog:MatDialog,
    private whatsappWorkitemService:WhatsappWorkitemService,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    
    this.id = data['crmUserId'];
    this.token = data['sessionToken'];
    this.conversationHistoryArray = data['coversationArray'];

  }

  ngOnInit() {
  }
  close(){
    this.dialogRef.close();
  }

    sendWhatsappMessage(){
        // this.whatsappWorkitemService.sendWhatsappMessage(this.id,this.whatsappWorkitemDetails.callingNumber,this.token,this.inputMessageForWhatsapp).subscribe(
        //   data=>{
        //     
        //     if(data.status ==200 && data.body["responseType"]=="SUCCESS"){
        //       this.singlehatsappMessage.destinationNumber = this.whatsappWorkitemDetails.callingNumber;
        //       this.singlehatsappMessage.msgBody = this.inputMessageForWhatsapp;
        //       this.singlehatsappMessage.messagingNumber = this.whatsappWorkitemDetails.callingNumber;
        //       //this.singlehatsappMessage.
        //       this.whatsappMessageArrayForDisplay.push({'messageBody':this.singlehatsappMessage,'source':"user"});
        //       console.log(data);
        //       this.inputMessageForWhatsapp = "";
        //     }
        //   }
        // );
      }

}
