import{ Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
 import { environment } from "../environment";



@Injectable()
export class ForgotPasswordService{
     private Url=environment.baseUrl+'agentassist/forgotPassword';
constructor(private http:HttpClient){
    
}
getForgetPassword(email:string){
     return this.http.post<any>(this.Url, {'emailAddress' : email }, {observe: 'response'})
   
}  

}

