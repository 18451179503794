export class WIByPropertiesRequest{

    public accessedBy:number;
    public crmUserId:number;
    public endDate:string;
    public propertyIds:Array<number>;
    public reportId:number;
    public reportType:string;
    public sessionToken:string;
    public startDate:string;
}