export class AddQueue{
    public crmUserId:number;
    public description:string;
    public name:string;
    public queue_code:string;
    public sessionToken:string;
    public skillIds:Array<number>;
    public sla:number;
    public queueEnabled:boolean;
    public queuePriority:string;

}