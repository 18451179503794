import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AgentProfileComponent } from 'src/app/agent-profile/agent-profile.component';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
import { LoginService } from 'src/app/services/login.service';
import { LogoutService } from 'src/app/services/logout.service';
import { AgentProfileService } from 'src/app/services/AgentProfile.service';
import { ChangePasswordComponent } from 'src/app/change-password/change-password.component';
import { CrmUser } from 'src/app/models/crmuser.model';
import { filter } from 'rxjs/operators';
import { EmailreplyComponent } from 'src/app/emailreply/emailreply.component';
import { AgentProfile } from 'src/app/models/AgentProfile.model';
import { WorkitemReminderService } from 'src/app/services/WorkitemReminder.service';
import { CustomFieldsService } from 'src/app/services/CustomFields.service';
import { WebSocketService } from 'src/app/services/WebSocket.service';
import { NotificationConfigurationComponent } from 'src/app/notification-configuration/notification-configuration.component';
import { ReminderConfigurationService } from 'src/app/services/ReminderConfiguration.service';
import { GetQueueListService } from 'src/app/services/GetQueueList.service';

@Component({
  selector: 'app-base-admin-controller',
  templateUrl: './base-admin-controller.component.html',
  styleUrls: ['./base-admin-controller.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class BaseAdminControllerComponent implements OnInit {
  id:number;
  token:string;
  opened:boolean= true;
  activeOption:string = '';
  crmUser:CrmUser;

  isAdmin:boolean;
  isClient:boolean;
  dialogRef: MatDialogRef<EmailreplyComponent> | null;
  agentProfile:AgentProfile;
  customFieldsList:Array<any> = new Array<any>();
  notificationVisible:boolean = false;
  notificationResult:any;

  showSideNav:boolean = true;

  constructor(private router:Router,
    private dialog:MatDialog,
    private loginService:LoginService,
    private logoutService:LogoutService,
    private adminProfileService:AgentProfileService,
    private agentProfileService:AgentProfileService,
    private workitemReminderService:WorkitemReminderService,
    private customFieldsService:CustomFieldsService,
    private webSocketService: WebSocketService,
    private getQueueListService:GetQueueListService,
  ) {

    let stompClient = this.webSocketService.connect();
    
        stompClient.connect({}, frame => {
          
                
          stompClient.subscribe('/topic/notification', notifications => {
            
            console.log("the result of web socket is "+notifications);
           
            console.log(notifications.body);
           this.notificationResult = JSON.parse(notifications.body);
           this.notificationVisible = true;              
          })
        });
    router.events.pipe(filter(event=> event instanceof NavigationStart)).subscribe((event: NavigationStart)=>{
      
      console.log(event.url);
      this.workitemReminderService.storeddata(null);
      this.activeOption = event.url;
    });

   }

  ngOnInit() {
    this.loginService.currentData.subscribe((data) => {

      this.crmUser = data;
      this.token=this.crmUser.sessionToken;
      this.id=this.crmUser.crmUserId;
      this.isAdmin = this.crmUser.admin;
      if(this.crmUser.userRole=="CLIENT"){
        this.isClient = true;
      }
      else{
        this.isClient = false;
      }
      this.agentProfile = new AgentProfile();

      this.customFieldsService.customFieldsList(this.id,this.token).subscribe(data=>{
        
        console.log(data);
        if(data.status==200 && data.body['responseType']=="SUCCESS"){
          this.customFieldsList = data.body['customFieldVOS'];
          this.customFieldsService.storeddata(this.customFieldsList);
        }
      });



      this.getQueueListService.getQueueList(this.id, this.token).subscribe(
        data=>{
          
         if(data.status==200 && data.body["responseType"]=="SUCCESS"){
  
          this.getQueueListService.storeddata(data.body['queueList']);
         
         }
         else if(data.body['responseType']== 'SESSION_TIMEOUT'){
  
           alert("Session timeout!Please Login Again");
           this.loginService.storeddata(null);
           this.router.navigate(['']);
         }
        }
      );
    }


    
  );
    
  }

  onToggle(){
    
    this.opened = !this.opened;
  }

  
    

  reminder(){
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data={

    };
    this.dialog.open(ReminderConfigurationComponent,dialogConfig);
   }


   newMail()
   {
 
     const dialogConfig = new MatDialogConfig();
   
   ;
        dialogConfig.disableClose = true;
           dialogConfig.autoFocus = true;
       dialogConfig.data={
         crmUserId: this.id,
       sessionToken: this.token,
       };

       dialogConfig.width="800px"
   
    
       this.dialogRef = this.dialog.open(EmailreplyComponent,dialogConfig);
   }


   openAgentProfile(){
    
    
      this.agentProfileService.getAgentDetails(this.crmUser.crmUserId, this.crmUser.sessionToken).subscribe(
        data=>{
          
    
          console.log(data);
          if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
            this.agentProfile.name = data.body['name'];
            this.agentProfile.emailId = data.body['emailAddress'];
            this.agentProfile.skills = data.body['skills'];
            this.agentProfile.lastLoginDate = data.body['lastLoginDate'];
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.height = "auto";
            dialogConfig.width = "600px";
            dialogConfig.data = {
            agentProfile: this.agentProfile
            };
             this.dialog.open(AgentProfileComponent,dialogConfig);
    
          }
          else if(data.body['responseType']== 'SESSION_TIMEOUT'){
    
            alert("Session timeout!Please Login Again");
            this.loginService.storeddata(null);
            this.router.navigate(['']);
                                                        }
        }
      );
    
    
    }
    
   
   
      changePassword(){
        
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.data={
            sessionToken:this.token,
            userId:this.id
          };
          
        this.dialog.open(ChangePasswordComponent,dialogConfig);
      }


      logoutUser(){
        
        this.loginService.endInterval();
        this.workitemReminderService.storeddata(null);
        this.logoutService.logoutUser(this.id, this.token).subscribe(
          data=>{
    
            
            console.log(data);
            if(data.status==200 ){
              this.router.navigate(['/']);
            }
          }
        )
    
      }

      notification(){
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data={
    
        };
        this.dialog.open(NotificationConfigurationComponent,dialogConfig);
      }

      closePopup(){
        this.notificationVisible = false;
      }
}
