import { Injectable } from '@angular/core';
import { environment } from '../environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LogoutService{
    private Url=environment.baseUrl+'logoutUser';

    constructor(private http:HttpClient){
    
    }


    logoutUser(crmuser, sessionToken){
      
        return this.http.post(this.Url, {"crmUserId":crmuser, "sessionToken":sessionToken}, {observe: 'response'});
      
   }
}