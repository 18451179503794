import { Component, OnInit, Inject  } from '@angular/core';
import { CustomerHistoryService } from '../services/CustomerHistory.service';
import { CustomerHistoryOutput } from '../models/CustomerHistoryOutput.model';
import { CustomerDetails } from '../models/CustomerDetails.model';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material';
import { CustomerHistoryInput } from '../models/CustomerHistoryInput.model';
import { CrmUser } from '../models/crmuser.model';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-engagement-history',
  templateUrl: './engagement-history.component.html?v=1',
  styleUrls: ['./engagement-history.component.css']
})
export class EngagementHistoryComponent implements OnInit{
  panelOpenState = false;
  customerHistoryOutput:Array<CustomerHistoryOutput>;
  customerDetails: CustomerDetails;
  crmUser:CrmUser;
 icon:Array<Number>=new Array<Number>();
 icon2:Array<boolean> = new Array<boolean>();
  isLoaded: boolean = false;
  newDate:Date;
  p:number = 1;
  customerHistoryInput = new CustomerHistoryInput();


  constructor(
    private customerHistoryService: CustomerHistoryService,   
    private dialog:MatDialog,
    private loginService:LoginService,
    private router:Router,
    private dialogRef: MatDialogRef<EngagementHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.customerDetails = data.customerDetails;
   this.isLoaded = true;
   }

  ngOnInit() {
	this.icon2.push(false);   
  }

pushStatus(){
  this.icon2.push(false);
}

  toggleIcon(i){
   this.icon2[i] = !this.icon2[i];
  }
  close(){
    this.dialogRef.close();
  }

  getPage(page: number, sort: string, order: string ) {
	this.p = page;
  let crmUserId = this.customerDetails.crmUserId;
  let sessionToken = this.customerDetails.sessionToken;
  let emailAddress=this.customerDetails.customerEmailAddress;

  this.getEngagementData(this.p);
  }
    getEngagementData(currentIndexpageNo,){
      this.customerHistoryService.currentData.subscribe((data) => {
            this.customerHistoryInput.crmUserId = data.crmUserId;
            this.customerHistoryInput.sessionToken = data.sessionToken;
            this.customerHistoryInput.emailAddress=data.customerEmailAddress;
      });

      if(this.p > 0) {
        currentIndexpageNo = currentIndexpageNo - 1;
      }
      this.customerHistoryInput.pageNumber = currentIndexpageNo;
      this.customerHistoryInput.pageSize = 5;
      console.log(this.customerHistoryInput);
      if(this.customerHistoryInput.emailAddress!=null)
      {
    this.customerHistoryService.getCustomerEmailHistory(this.customerHistoryInput).subscribe(data=>{
       
      if(data.status==200 &&  data.body["responseType"]=="SUCCESS"){
        console.log(data);
         this.customerDetails = data.body;
         {
           customerDetails: this.customerDetails
       };
        // window.open('/cutomerEngagementHistory','_new','location=yes,height=570,width=1100,scrollbars=yes,status=yes');
  
     }
     else if(data.body['responseType']== 'SESSION_TIMEOUT'){
  
      alert("Session timeout!Please Login Again");
      this.loginService.storeddata(null);
      this.router.navigate(['']);
    }
    });
      }
      else{
        alert("No History Available");
      }
    }
      }

