import{ Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../environment";
import { CrmUser } from '../models/crmuser.model';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ReminderConfigurationComponent } from 'src/app/reminder-configuration/reminder-configuration.component';
import { GetReminderRequest } from 'src/app/models/GetReminderRequest.model';
import { ReminderConfigurationService } from 'src/app/services/ReminderConfiguration.service';
import { ReminderPopupComponent } from 'src/app/reminder-popup/reminder-popup.component';


@Injectable()
export class LoginService{
  interval;
  localInterval;
  remindersList = [];
  dialogClosed:boolean = true;
  getReminderRequest:GetReminderRequest = new GetReminderRequest();
  
     public StoredData: BehaviorSubject<CrmUser> = new BehaviorSubject<CrmUser>(null);  
     currentData = this.StoredData.asObservable();
       storeddata(data: any) {
         this.StoredData.next(data)
       }
       private Url=environment.baseUrl+'loginUser'
constructor(private http:HttpClient,
  private dialog:MatDialog,
  private reminderConfigurationService:ReminderConfigurationService,){
    
}
getUserDetails(email:string,password:string){
     return this.http.post<CrmUser>(this.Url, {'emailId' : email , 'password' : password}, {observe: 'response'})
   
}  

startInterval(){
  
  this.interval = setInterval(() => {
    
     
    const dialogConfig = new MatDialogConfig();
    var crmmm;
    
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          dialogConfig.width = "500px";
          this.currentData.subscribe((data) => {
            
            crmmm = data;
            this.getReminderRequest.crmUserId = crmmm.crmUserId;
            this.getReminderRequest.sessionToken = crmmm.sessionToken;
            this.getReminderRequest.searchByUser = true;
            this.getReminderRequest.searchCompleted = true;
            this.getReminderRequest.searchCriteriaId = crmmm.crmUserId;
            if(this.dialogClosed){
            this.reminderConfigurationService.getReminder(this.getReminderRequest).subscribe(data=>{
              
              if(data.body["reminderVOList"]==null ||data.body["reminderVOList"]==[] ||data.body["reminderVOList"].length==0){

              }
              else{
              this.remindersList = data.body["reminderVOList"];
              console.log(this.remindersList);
              console.log("remainder data>>>>>>"+data.body['reminderVOList']);
              dialogConfig.data={
                crmUserId: crmmm.crmUserId,
                sessionToken: crmmm.sessionToken,
                remindersList:this.remindersList,
              };
              this.dialogClosed = false;
              this.dialog.open(ReminderPopupComponent,dialogConfig).afterClosed().subscribe(data=>{
                this.dialogClosed = true;
              });
            }
            }
          );
        }
          });
         
        },180000);
}
endInterval(){
  
  clearInterval(this.interval);
}


startoneInterval(){
  
  


  const dialogConfig = new MatDialogConfig();
  var crmmm;
  
        dialogConfig.disableClose = true;
        dialogConfig.width = "500px";
        dialogConfig.autoFocus = true;
        this.currentData.subscribe((data) => {
          
          crmmm = data;
          this.getReminderRequest.crmUserId = crmmm.crmUserId;
          this.getReminderRequest.sessionToken = crmmm.sessionToken;
          this.getReminderRequest.searchByUser = true;
          this.getReminderRequest.searchCompleted = true;
          this.getReminderRequest.searchCriteriaId = crmmm.crmUserId;
          this.reminderConfigurationService.getReminder(this.getReminderRequest).subscribe(data=>{
            
            this.remindersList = data.body["reminderVOList"];
            console.log(this.remindersList);
            console.log("remainder data>>>>>>"+data.body['reminderVOList']);
            if(data.body["reminderVOList"]==null ||data.body["reminderVOList"]==[] ||data.body["reminderVOList"].length==0){
            
            }
            else{
            dialogConfig.data={
              crmUserId: crmmm.crmUserId,
              sessionToken: crmmm.sessionToken,
              remindersList:this.remindersList,
            };
            this.dialogClosed = false;
            this.dialog.open(ReminderPopupComponent,dialogConfig).afterClosed().subscribe(data=>{
              this.dialogClosed = true;
            });
          }
          });
          
        });





 
}

}

