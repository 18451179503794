import { Component, OnInit , ElementRef, ViewChild,Inject} from '@angular/core';
import { Router,NavigationExtras,ActivatedRoute } from '@angular/router';
import {MatDialogRef,MAT_DIALOG_DATA,MatDialogConfig, MatDialog} from '@angular/material';
import {FormGroup,FormBuilder,FormControl,Validators,FormArray} from '@angular/forms';
import {UpdateAgentStatusService} from '../services/updateAgentStatus.service';
import { UpdateAgentStatusModel } from '../models/updateAgentStatus.model';
import {LogoutService} from '../services/logout.service';
import {AddCustomerMessageComponent} from '../messagePopUp/addCustomerMessage.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {ConfigureEmail} from '../models/configureEmail.model';
import { ConfigureEmailService } from '../services/configureEmail.service';
@Component({
    selector: 'app-addemail',
    templateUrl: './add-email.component.html',
    styleUrls:['./add-email.component.css']
  })
export class AddEmailComponent implements OnInit{
  token:string;
  id:number;
  addEmailForm:FormGroup; 
  submitted:boolean=false;
  showRow:boolean=false;
  updateAgentStatusModel:UpdateAgentStatusModel;
  queueList = [];
  emailTemplateList=[];
  selectedItemss = [];
  checked:boolean=false;
  showReplyFields:boolean=false;
  public emailConfigureData:ConfigureEmail;
  dropdownSettings = {};
    constructor(private formBuilder: FormBuilder,private route:ActivatedRoute,private router:Router,
        private updateAgentStatusService:UpdateAgentStatusService,private logoutService:LogoutService,
        private dialog:MatDialog,private dialogRef: MatDialogRef<AddEmailComponent>,private configureEmailService:ConfigureEmailService, @Inject(MAT_DIALOG_DATA) data){
          this.route.queryParams.subscribe(params => {
            this.id = data.id;
            this.token = data.token;
           
        
        });
          this.addEmailForm=this.formBuilder.group({
            mailBoxName:new FormControl('',[Validators.required]),
        queueName:new FormControl('', [  
          Validators.required,  
          Validators.minLength(3)]),
          templateName:new FormControl('',[Validators.required]),
          emailId:new FormControl('', [ 
            Validators.required, 
            Validators.minLength(5),  
            Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
          ]), 
          password:new FormControl('', [ 
            Validators.required, 
            Validators.minLength(5),  
            Validators.maxLength(80),  
          ]), 
          sendingUrl:new FormControl('',[Validators.required]),
          sendingPort:new FormControl('',[Validators.required]),
          folderName:new FormControl(''),
          scanEnabled:new FormControl(''),
          replyUrl:new FormControl(''),
          replyPort:new FormControl(''),
          replyEnabled:new FormControl(''), 
      })
      }
    ngOnInit() {
      
      this.configureEmailService.getQueueList(this.id,this.token).subscribe(
        data=>{
        console.log(data);
        if(data.status==200)
        {
          this.queueList=data.body['queueList'];
        }
        }
      )
      this.configureEmailService.getEmailTemplateList(this.id,this.token).subscribe(
        data=>{
          console.log("template>>"+data);
          if(data.status==200)
          {
            this.emailTemplateList=data.body['emailTemplates'];
          }
        }
      )
      // this.skills = [
      //   { id: 'English', value: 'English' },
      //   { id: 'Japenese', value: 'Japenese' },
      //   { id: 'Korian', value: 'Korian' },
      // ];
      //   { id: 'English', value: 'English' },
      //   { id: 'Japenese', value: 'Japenese' },
      //   { id: 'Korian', value: 'Korian' }
      
      // ];
      this.dropdownSettings= {
        singleSelection: false,
        idField: 'id',
        textField: 'value',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        closeDropDownOnSelection:true,
             
        //itemsShowLimit: 3,
       // allowSearchFilter: false
      };
     
    }
   
    get f() {return this.addEmailForm.controls; }

    onAddEmailSubmit(){
      
      console.log("addemailSubmit");
      console.log("selected items>>"+this.selectedItemss);
      this.submitted=true;
      this.emailConfigureData= new ConfigureEmail();
      this.emailConfigureData.mailBoxName=this.addEmailForm.value.mailBoxName;
      this.emailConfigureData.emailId=this.addEmailForm.value.emailId;
      this.emailConfigureData.password=this.addEmailForm.value.password;
      this.emailConfigureData.replyEnabled=this.addEmailForm.value.replyEnabled;
      this.emailConfigureData.folderName=this.addEmailForm.value.folderName;
      this.emailConfigureData.replyPort=this.addEmailForm.value.replyPort;
      this.emailConfigureData.sendingPort=this.addEmailForm.value.sendingPort;
      this.emailConfigureData.sendingUrl=this.addEmailForm.value.sendingUrl;
      this.emailConfigureData.scanEnabled=this.addEmailForm.value.scanEnabled;
      this.emailConfigureData.replyUrl=this.addEmailForm.value.replyUrl;
      this.emailConfigureData.queueName=this.addEmailForm.value.queueName;
      this.emailConfigureData.emailTemplate=this.addEmailForm.value.templateName;
      this.emailConfigureData.sessionToken=this.token;
      this.emailConfigureData.crmUserId=this.id;

      if(this.addEmailForm.valid)
      {
        this.configureEmailService.addMailBox(this.emailConfigureData).subscribe(
          data=>{        

            if(data.status==200 && data.body["responseType"]=="SUCCESS"){
              const dialogConfig= new MatDialogConfig();
              dialogConfig.disableClose=true;
              dialogConfig.autoFocus=true;
              dialogConfig.width="60%";
              dialogConfig.height="40%";
              this.dialog.open(AddCustomerMessageComponent,dialogConfig);
              this.dialogRef.close();

            }
            else if(data.status==200 && data.body["responseType"]=="SERVER_ERROR"){
              alert(data.body['responseMessage']);
            }
            else{
              alert("Unable to save data");
            }
          }
        )
       
    }
  }
 
    close()
    {
        this.dialogRef.close(this.showRow);
    }

    onItemSelect(item: any) {
     // this.selectedItemss.push(item);
      console.log("item>>"+this.selectedItemss);
    }
    onSelectAll(items: any) {
      //this.selectedItemss.push(items);
      console.log("items>>>"+this.selectedItemss);
    }
    OnItemDeSelect(item:any){
      
     // this.selectedItemss.splice(item,1);
      console.log("items>>>"+this.selectedItemss);
  }
  onDeSelectAll(items: any){
    //this.selectedItemss.pop();
    console.log("items>>>"+this.selectedItemss);
}
clearFormFields(){
  this.addEmailForm = new FormGroup({
    queueName: new FormControl(''),
    emailId: new FormControl(''),
    password:new FormControl(''),
    skill:new FormControl(''),
                                });
                              this.addEmailForm = this.formBuilder.group({
                                queueName:['',[Validators.required]],
                                emailId: ['', [Validators.required, Validators.email]],
                                  password: ['', [Validators.required]],
                                  skill:['',[Validators.required]]
                              });
  
 
} 
onValueChange(eve:any){
this.checked=!this.checked;
if(this.checked==true)
{
this.showReplyFields=true;
}
}
 
}