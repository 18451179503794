import { Component, OnInit,Inject } from '@angular/core';
import {ActivatedRoute,Router} from '@angular/router';
import {MAT_DIALOG_DATA,MatDialogRef} from '@angular/material';
import {MatDialog,MatDialogConfig} from '@angular/material';
import { FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import { Customer } from '../models/customer.model';
import { AddCustomerService } from '../services/addCustomer.service';
import {AddCustomerMessageComponent} from '../messagePopUp/addCustomerMessage.component';
import {CustomerExistsMessageComponent} from '../messagePopUp/customerAlreadyExists.component';
@Component({
  selector: 'app-addCustomer',
  templateUrl: './addCustomer.component.html'
  
})
export class AddCustomerComponent  implements OnInit{
  private name:string;
  private email:string;
  private facebookID:string;
  private twitterID:string;
  private phoneNo:number;
  customer:Customer;
  private token:string;
  submitted = false;
  isShowMessage:boolean=false;
  private userId:number;
  addCustForm:FormGroup;
  sender:string='';
  caller:number;
  twitterId='';
  facebookId='';
  workItemType:string;
    constructor(private formBuilder: FormBuilder,
        private addCustomerService:AddCustomerService,
        private dialogRef: MatDialogRef<AddCustomerComponent>,
        private dialog:MatDialog,private route:ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) data
       ) {
         
        this.route.queryParams.subscribe(params => {
          this.userId = data.id;
          this.token = data.token;
          if(data.sender!=null){
          this.sender=data.sender;
          }
          else if(data.caller!=null)
          {
          this.caller=data.caller;
          }
          this.workItemType=data.wiType;
      });
        this.addCustForm = this.formBuilder.group({  
          name: new FormControl('', [  
            Validators.required,  
            Validators.minLength(3),  
            Validators.maxLength(30),  
            Validators.pattern('^[a-zA-Z ]*$')]),
            phoneNo: new FormControl('', [  
            Validators.minLength(8),  
           // Validators.maxLength(12),  
            //Validators.pattern('^[0-9]*$')]), 
            Validators.pattern('^([0|\+[0-9]{1,5})?([0-9]{12})$')]),  
          email: new FormControl('', [  
            Validators.minLength(5),  
            Validators.maxLength(80),  
            Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
          ]),  
          facebookID: new FormControl('', [  
            Validators.minLength(5),  
            Validators.maxLength(80),  
            Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
          ]), 
          twitterID: new FormControl('', [  
            Validators.minLength(5),  
            Validators.maxLength(80),  
            Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")  
          ])
        });  
    }
        
         ngOnInit() {
                                       
                                        this.customer=new Customer();
                                 }
                                     // convenience getter for easy access to form fields
                                     get addCustFields() { return this.addCustForm.controls; }

onAddSubmit() {
    
    this.submitted = true;
    if(this.workItemType=='EMAIL' && (this.sender==null || this.sender==''))
    {
      alert("please enter email");
     return this.addCustForm.value.email.invalid;
    }
    else if(this.workItemType=='IVR' && (this.caller==null ))
    {
      alert("please enter phoneNo");
      return this.addCustForm.value.phoneNo.invalid;
    }
   if(this.addCustForm.valid){
      this.customer.customerName= this.addCustForm.value.name;
      this.customer.customerEmailAddress=this.sender;
      this.customer.customerFacebookId=this.facebookId;
      this.customer.customerTwitterId=this.twitterId;
      this.customer.customerContactNumber=this.caller;
     this.customer.sessionToken=this.token;
     this.customer.crmUserId=this.userId;
      this.addCustomerService.addCustomer(this.customer)
      .subscribe(
      data=>{
    
      console.log(data);
      if(data.status==200 && data.body.responseType=='SUCCESS'){
        this.isShowMessage=true;
        const dialogConfig= new MatDialogConfig();
        dialogConfig.disableClose=true;
        dialogConfig.autoFocus=true;
        dialogConfig.width="60%";
        dialogConfig.height="40%";
        this.dialog.open(AddCustomerMessageComponent,dialogConfig);
      }else if(data.status==200 && data.body["responseType"]=="SERVER_ERROR"){
        alert(data.body['responseMessage']);
      }
      else {
        
        const dialogConfig= new MatDialogConfig();
    dialogConfig.disableClose=true;
    dialogConfig.autoFocus=true;
    dialogConfig.width="60%";
    dialogConfig.height="40%";
    this.dialog.open(CustomerExistsMessageComponent,dialogConfig);
      }
        }
       );
        
        //this.dialogRef.close();
      }
    
    }
    close()
  {
    this.dialogRef.close();
  }
}